import axiosUnique from '@/utils/axiosUnique'
import StoreHash from '@/utils/StoreHash'
import axios from 'axios'


const LOCATION_SEARCH_AVAILABLE = {
    'google': true,
    'locationsvc': true,
}

const UFB_SEARCH_AVAILABLE = {
    'UFB': true,
    'SLAM': true,
    'CHORUS': true,
}

const FWA_SEARCH_AVAILABLE = {
    'GOOGLE': true,
    'SLAM': true,
}

const BROADBAND_LOCATION_SEARCH = process.env.VUE_APP_BROADBAND_LOCATION_SEARCH && LOCATION_SEARCH_AVAILABLE[process.env.VUE_APP_BROADBAND_LOCATION_SEARCH]
    ? process.env.VUE_APP_BROADBAND_LOCATION_SEARCH
    : Object.keys(LOCATION_SEARCH_AVAILABLE)[0]

const FWA_SEARCH_PROVIDER = process.env.VUE_APP_FWA_SEARCH_PROVIDER && FWA_SEARCH_AVAILABLE[process.env.VUE_APP_FWA_SEARCH_PROVIDER]
    ? process.env.VUE_APP_FWA_SEARCH_PROVIDER
    : Object.keys(FWA_SEARCH_AVAILABLE)[0]

const UFB_SEARCH_PROVIDER = process.env.VUE_APP_UFB_SEARCH_PROVIDER && UFB_SEARCH_AVAILABLE[process.env.VUE_APP_UFB_SEARCH_PROVIDER]
    ? process.env.VUE_APP_UFB_SEARCH_PROVIDER
    : Object.keys(UFB_SEARCH_AVAILABLE)[0]

let hashed = {
    broadband: {
        packages: new StoreHash(5 * 60 * 1000),
        my_services: new StoreHash(5 * 60 * 1000),
        my_locations: new StoreHash(5 * 60 * 1000),
    }
}

const state = {
    broadband_location_search: BROADBAND_LOCATION_SEARCH, // google | locationsvc
    ufb_search_providers: [ UFB_SEARCH_PROVIDER ], // UFB
    fwa_search_providers: [ FWA_SEARCH_PROVIDER ], // FWA

    broadband: {
        packages: [],
        my_services: [],
        my_locations: [],
    },
}

const getters = {
    ufb_search_providers: state => state.ufb_search_providers,
    fwa_search_providers: state => state.fwa_search_providers,

    broadband_location_search: state => state.broadband_location_search,
    broadband_location_search_by_google: state => state.broadband_location_search == 'google',
    broadband_location_search_by_locationsvc: state => state.broadband_location_search == 'locationsvc',

    broadband_packages: state => state.broadband.packages,
}

const mutations = {
    saveBroadbandPackages(state, packages) {
        state.broadband.packages = packages
    },

    saveBroadbandLocation(state, location) {
        state.broadband.my_locations.unshift(location)
    },
}

const actions = {
    searchBroadbandLocation({getters}, payload) {
        const params = {
            ...payload,
            prefermethod: getters.broadband_location_search,
            noauth: true,
        }

        return axiosUnique.get('https://testapi.vfnz.tv/api/v2/:spid/location/search', { params }).then(({apidata}) => {
            return apidata.Locations && apidata.Locations.length
                ? Promise.resolve(apidata.Locations)
                : Promise.reject(new Error('This address is not available for connection'))
        }).catch(error => Promise.reject(error))
    },
    
    loadBroadbandPackages({getters, commit}) {
        if (hashed.broadband.packages.expired()) {
            /**
             * @todo Replace 2 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/broadband/broadband-packages.json').then(apidata => {
                commit('saveBroadbandPackages', apidata.default)

            // return axiosUnique.get('/v2/:spid/broadband/packages').then(({apidata}) => {
            //     commit('saveBroadbandPackages', apidata)

                hashed.broadband.packages.fix()

                return Promise.resolve(getters.broadband_packages)
            }).catch(error => Promise.reject(error))
        } else {
            return Promise.resolve(getters.broadband_packages)
        }
    },

    saveBroadbandLocation({getters, commit}, location) {
        /**
         * @todo Delete test request
         */
        const saved_location = {
            "UUID": "1062d487-6f50-4e33-b895-b8e63806629a",
            "CustomerUUID": "7622b3bd-25d9-4a2b-b0e3-5a8c4ff75295",
            "SlamID": 396639249,
            "SamID": 1686393,
            "DPID": 699548,
            "CreatedAtNanos": 1578898303231646000,
            "UpdatedAtNanos": 1578898303231646000,
            "DeletedAtNanos": 0,
            "SPID": 1,
            "AddressProvider": "Vodafone",
            "AddressProviderIDX": "",
            "UnitType": "",
            "UnitTypeExtra": "",
            "Number": "42",
            "NumberPre": "",
            "NumberPost": "A",
            "StreetName": "TEST",
            "StreetType": "TEST",
            "Suburb": "TEST",
            "City": "TEST",
            "State": "TEST",
            "Country": "TEST",
            "PostCode": "TEST",
            "Formatted": location.address,
            "FormattedOriginal": "",
            "Notes": "",
            "Latitude": "",
            "Longitude": "",
            "Rank": 0,
            "Score": 0
        }

        commit('saveBroadbandLocation', saved_location)

        hashed.broadband.my_locations.fix()

        return Promise.resolve(getters.my_broadband_location)

        return axios.post(`/v2/:spid/customer/:uuid/location/address/${ location.SlamID }`).then(saved_location => {
            commit('saveBroadbandLocation', saved_location)

            hashed.broadband.my_locations.fix()

            return Promise.resolve(getters.my_broadband_location)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
