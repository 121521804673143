import axios from "axios";

const state = {
    conditionAddOptions: {
        'Tag': 'User Tag',
        'Date': 'Date',
        'GroupName': 'Group Name',
        'GroupType': 'Group Type',
        'GroupSize': 'Group Size',
        'Sub': 'Sub Condition',
        'NewService': 'New Service',
        'FirstBasePLan': 'First Base Plan',
        'GroupOwner': 'Group Owner'
    },
    actionAddOptions: {
        'PriceOffAction': 'Price Off',
        'PercentOffAction': 'Percent Off',
        'FlatPriceAction': 'Flat Price',
        'CreditAction': 'Credit',
        'PeriodActions': 'Periods'
    },
    periodActionAddOptions: {
        'PriceOffAction': 'Price Off',
        'PercentOffAction': 'Percent Off',
        'FlatPriceAction': 'Flat Price',
        'CreditAction': 'Credit',
    },
    relationOptions: {
        'And': 'And',
        'Or': 'Or'
    }
}

const getters = {
    conditionAddOptions: state => state.conditionAddOptions,
    actionAddOptions: state => state.actionAddOptions,
    periodActionAddOptions: state => state.periodActionAddOptions,
    relationOptions: state => state.relationOptions,
}

const mutations = {}

const actions = {
    getOffers({}, params) {
        return axios.get(`/sld/offers/list`, { params })
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    getOfferByOfferID({}, offerID) {
        return axios.get(`/sld/offer/${offerID}`)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    addOffer({}, params) {
        return axios.post(`/sld/offer/add`, params)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    deleteOfferByOfferID({}, offerID) {
        return axios.delete(`/sld/offer/${offerID}`)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    activateOffer({}, params) {
        return axios.patch(`/sld/offer/${params.OfferID}/activate`, null, { params })
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}