import StoreHash from '@/utils/StoreHash';

const hashed = {
    serviceProviders: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    serviceProviders: {}
}

const getters = {
    serviceProviders: state => state.serviceProviders
}

const mutations = {
    mutServiceProviders(state, serviceProviders) {
        serviceProviders.forEach(serviceProvider => {
            state.serviceProviders[serviceProvider.UUID] = serviceProvider
        })
        hashed.serviceProviders.fix();
    }
}

const actions = {
    getCachedServiceProviders({ getters, dispatch, commit }, { RefreshCache, payload }) {
        if (RefreshCache || hashed.serviceProviders.expired()) {
            return dispatch('api_serviceprovider/FindServiceProvidersPaginated', payload)
                .then(response => {
                    if (response?.ServiceProviders.length > 0) {
                        commit('mutServiceProviders', response.ServiceProviders);
                    }
                    return Promise.resolve(response);
                })
        }
        return Promise.resolve(getters.serviceProviders)

    }
}

export default {
    state,
    getters,
    mutations,
    actions
}