// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: approval.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Approvals: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ApprovalSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getApprovalByUUID: state => (UUID) => (state.Approvals.UUIDs[ UUID ] && !state.Approvals.UUIDs[ UUID ].expired()) ?  state.Approvals.UUIDs[ UUID ].hashedData() : null,
	getApprovalsForSearchText: state => (SPIDwithSearchText) => state.ApprovalSearches[ SPIDwithSearchText ] ? state.ApprovalSearches[ SPIDwithSearchText ] : null,
};

const mutations = {
	// mutSaveApproval saves a single Approval object
	// into both the StoreHash cache and the VueX store
	mutSaveApproval(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Approvals.UUIDs)) {
			state.Approvals.UUIDs[ obj.UUID ] = new StoreHash(state.Approvals.lifetime)
		}
		state.Approvals.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveApprovalByUUID removes a Approval object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveApprovalByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.Approvals.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveApprovalSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveApprovalSearch(state, { CacheKey, SPID, SearchText, Response }) {
		if (!CacheKey || !SearchText || !Response) { return } // Must have CacheKey + SearchText + Response to Save object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Approvals)) {
			for (let i = 0; i < Response.Approvals.length; i++) {
				storedObj.UUIDs.push(Response.Approvals[i].UUID)
			}
		}
		state.ApprovalSearches[ CacheKey ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(CacheKey in state.ApprovalSearches.SearchTexts)) {
			state.ApprovalSearches.SearchTexts[ CacheKey ] = new StoreHash(state.ApprovalSearches.lifetime)
		}
		state.ApprovalSearches.SearchTexts[ CacheKey ].fix() // Add item to StoreHash
	},
	// mutRemoveApprovalSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveApprovalSearch(state, CacheKey) {
		if (!CacheKey) { return } // Must have CacheKey to Remove
		state.ApprovalSearches[ CacheKey ] = null               // cause VueX to trigger a data cascade
		delete state.ApprovalSearches[ CacheKey ]               // remove item from VueX state
		delete state.ApprovalSearches.SearchTexts[ CacheKey ]  // remove item from StoreHash
	},
	// mutSaveApprovals saves a paginated response of Approvals
	mutSaveApprovals(state, { Response }) {
		if (!Response || !Array.isArray(Response.Approvals)) { return } // Must have Response object with results
		for (let i = 0; i < Response.Approvals.length; i++) {
			if (!(Response.Approvals[i].UUID in state.Approvals.UUIDs)) {
				state.Approvals.UUIDs[ Response.Approvals[i].UUID ] = new StoreHash(state.Approvals.lifetime)
			}
			state.Approvals.UUIDs[ Response.Approvals[i].UUID ].fix(Response.Approvals[i])  // Add single Approval to StoreHash
		}
	},
};

const actions = {
	// getCachedApprovalByUUID fetches a Approval from the cache
	// and if not in the cache, fetches it from the API
	getCachedApprovalByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Approvals.UUIDs[ UUID ] && !state.Approvals.UUIDs[ UUID ].expired()) {			
			return state.Approvals.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetApprovalByUUID', { UUID }).then(respPayload => {
			commit('mutSaveApproval', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveApprovalByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedApprovalsBySearch fetches Approvals from the cache
	// and if not in the cache, fetches it from the API
	getCachedApprovalsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		const cacheKey = SPID+':'+SearchText
		if (!RefreshCache && state.ApprovalSearches.SearchTexts[ cacheKey ] && !state.ApprovalSearches.SearchTexts[ cacheKey ].expired()) {
			// Return the cached records
			let resp = getters.getApprovalsForSearchText(cacheKey)
			resp.Approvals = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Approvals.push(getters.getApprovalByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetApprovalsPaginated', apiReq).then(respPayload => {
			commit('mutSaveApprovalSearch', { CacheKey: cacheKey, SPID: SPID, SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveApprovalSearch', cacheKey)
			return Promise.reject(error)
		})
	},

	//
	// Service: ApprovalRPC
	// 
	/**
	* RPC Method: ActionApproval
	* Description: Get a single Approval object using the ApprovalCode
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/customer/approval/action
	* @param    {ApprovalActionRequest} req The API payload object (ApprovalActionRequest)
	* @return   {Approval} The API response object (Approval)
	*
	* @typedef  {Object}  ApprovalActionRequest
	* @property {Number}  SPID         Service Provider ID - always required
	* @property {String}  UUID         UUID of the Approval, if known - Used to find the Approval by UUID
	* @property {String}  ApprovalCode ApprovalCode is used to find the Approval by the ApprovalCode - used if UUID is not known
	* @property {String}  CustomerUUID CustomerUUID of who is taking the action
	* @property {ENUM}    Action       
	*
	* @typedef  {Object}  Approval
	* @property {String}  UUID                    Invite is used to send another customer an invitation for access
	* @property {Number}  SPID                    GetCustomerApprovals gets a list of all invites and approvals for a customer
	* @property {Number}  CreatedAtNanos          ActionApproval is used to approve or reject an Approval (request)
	* @property {String}  CreatedBy               here
	* @property {Number}  UpdatedAtNanos          When the object was last updated
	* @property {String}  UpdatedBy               Who the object was created by
	* @property {Number}  DeletedAtNanos          When the object was deleted
	* @property {String}  DeletedBy               Who the object was created by
	* @property {Number}  ActionedAtNanos         When the object was redeemed
	* @property {String}  ActionedBy              Who the object was Actioned by
	* @property {Number}  ExpiresAtNanos          When the approval expires
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  RequestedToCustomerName RequestedToCustomerName is the name of the customer that requested this approval
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {String}  Description             Approval Description (Presented to Customer)
	* @property {String}  SubjectUUID             SubjectUUID is the UUID of the subject that is being given access,  a CustomerUUID (for Group request types), or a,  a IdentityUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceUUID            ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails         ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example, for a Identity approval, this is the list of IDM roles being granted,  from the ResourceFromUUID to the ResourceToUUID
	*
	*/
	ActionApproval({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/customer/approval/action`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ActionApproval ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ActionApproval ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ActionApproval ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ActionApproval UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ActionApproval'))
			})
	},

	/**
	* RPC Method: AddApproval
	* Description: Add a Approval object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/customer/approval
	* @param    {Approval} req The API payload object (Approval)
	* @return   {Approval} The API response object (Approval)
	*
	* @typedef  {Object}  Approval
	* @property {String}  UUID                    Invite is used to send another customer an invitation for access
	* @property {Number}  SPID                    GetCustomerApprovals gets a list of all invites and approvals for a customer
	* @property {Number}  CreatedAtNanos          ActionApproval is used to approve or reject an Approval (request)
	* @property {String}  CreatedBy               here
	* @property {Number}  UpdatedAtNanos          When the object was last updated
	* @property {String}  UpdatedBy               Who the object was created by
	* @property {Number}  DeletedAtNanos          When the object was deleted
	* @property {String}  DeletedBy               Who the object was created by
	* @property {Number}  ActionedAtNanos         When the object was redeemed
	* @property {String}  ActionedBy              Who the object was Actioned by
	* @property {Number}  ExpiresAtNanos          When the approval expires
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  RequestedToCustomerName RequestedToCustomerName is the name of the customer that requested this approval
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {String}  Description             Approval Description (Presented to Customer)
	* @property {String}  SubjectUUID             SubjectUUID is the UUID of the subject that is being given access,  a CustomerUUID (for Group request types), or a,  a IdentityUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceUUID            ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails         ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example, for a Identity approval, this is the list of IDM roles being granted,  from the ResourceFromUUID to the ResourceToUUID
	*
	*/
	AddApproval({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/${req.SPID}/customer/approval`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddApproval ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddApproval ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddApproval ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddApproval UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddApproval'))
			})
	},

	/**
	* RPC Method: DeleteApprovalByUUID
	* Description: Deletes a Approval object
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/customer/approval/${req.UUID}
	* @param    {ApprovalUUIDSearch} req The API payload object (ApprovalUUIDSearch)
	* @return   {Approval} The API response object (Approval)
	*
	* @typedef  {Object}  ApprovalUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Approval
	* @property {String}  UUID                    Invite is used to send another customer an invitation for access
	* @property {Number}  SPID                    GetCustomerApprovals gets a list of all invites and approvals for a customer
	* @property {Number}  CreatedAtNanos          ActionApproval is used to approve or reject an Approval (request)
	* @property {String}  CreatedBy               here
	* @property {Number}  UpdatedAtNanos          When the object was last updated
	* @property {String}  UpdatedBy               Who the object was created by
	* @property {Number}  DeletedAtNanos          When the object was deleted
	* @property {String}  DeletedBy               Who the object was created by
	* @property {Number}  ActionedAtNanos         When the object was redeemed
	* @property {String}  ActionedBy              Who the object was Actioned by
	* @property {Number}  ExpiresAtNanos          When the approval expires
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  RequestedToCustomerName RequestedToCustomerName is the name of the customer that requested this approval
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {String}  Description             Approval Description (Presented to Customer)
	* @property {String}  SubjectUUID             SubjectUUID is the UUID of the subject that is being given access,  a CustomerUUID (for Group request types), or a,  a IdentityUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceUUID            ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails         ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example, for a Identity approval, this is the list of IDM roles being granted,  from the ResourceFromUUID to the ResourceToUUID
	*
	*/
	DeleteApprovalByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/customer/approval/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveApproval', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteApprovalByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteApprovalByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteApprovalByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteApprovalByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteApprovalByUUID'))
			})
	},

	/**
	* RPC Method: GetApprovalByUUID
	* Description: Get a single Approval object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/customer/approval/${req.UUID}
	* @param    {ApprovalUUIDSearch} req The API payload object (ApprovalUUIDSearch)
	* @return   {Approval} The API response object (Approval)
	*
	* @typedef  {Object}  ApprovalUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Approval
	* @property {String}  UUID                    Invite is used to send another customer an invitation for access
	* @property {Number}  SPID                    GetCustomerApprovals gets a list of all invites and approvals for a customer
	* @property {Number}  CreatedAtNanos          ActionApproval is used to approve or reject an Approval (request)
	* @property {String}  CreatedBy               here
	* @property {Number}  UpdatedAtNanos          When the object was last updated
	* @property {String}  UpdatedBy               Who the object was created by
	* @property {Number}  DeletedAtNanos          When the object was deleted
	* @property {String}  DeletedBy               Who the object was created by
	* @property {Number}  ActionedAtNanos         When the object was redeemed
	* @property {String}  ActionedBy              Who the object was Actioned by
	* @property {Number}  ExpiresAtNanos          When the approval expires
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  RequestedToCustomerName RequestedToCustomerName is the name of the customer that requested this approval
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {String}  Description             Approval Description (Presented to Customer)
	* @property {String}  SubjectUUID             SubjectUUID is the UUID of the subject that is being given access,  a CustomerUUID (for Group request types), or a,  a IdentityUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceUUID            ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails         ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example, for a Identity approval, this is the list of IDM roles being granted,  from the ResourceFromUUID to the ResourceToUUID
	*
	*/
	GetApprovalByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/customer/approval/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveApproval', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetApprovalByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetApprovalByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetApprovalByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetApprovalByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetApprovalByUUID'))
			})
	},

	/**
	* RPC Method: GetApprovalsPaginated
	* Description: Searches for multiple Approval objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/customer/approval
	* @param    {ApprovalSearchRequest} req The API payload object (ApprovalSearchRequest)
	* @return   {ApprovalPageResponse} The API response object (ApprovalPageResponse)
	*
	* @typedef  {Object}  ApprovalSearchRequest
	* @property {SearchOptions[]} SearchOptions           Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted          IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID                    (optional) SPID
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {Number}  ExpiresAtNanosFrom      Filter byExpiresAtNanos starting from a given Unix nanos
	* @property {Number}  ExpiresAtNanosUntil     Filter byExpiresAtNanos ending at a given Unix nanos
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ApprovalPageResponse
	* @property {PageInfo} PageInfo  PageInfo contains the pagination information for this response
	* @property {Approval[]} Approvals Approvals is an array of the Approvals in the response
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetApprovalsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/customer/approval`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveApprovals', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetApprovalsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetApprovalsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetApprovalsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetApprovalsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetApprovalsPaginated'))
			})
	},

	/**
	* RPC Method: Invite
	* Description: Create an invitation approval
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/customer/approval/invite
	* @param    {ApprovalRequest} req The API payload object (ApprovalRequest)
	* @return   {Approvals} The API response object (Approvals)
	*
	* @typedef  {Object}  ApprovalRequest
	* @property {Number}  SPID            Service Provider ID - always required
	* @property {String}  CustomerUUID    CustomerUUID is the UUID of the customer that is making this invitation/join request
	* @property {String}  RequestedTo     RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {ENUM}    ApprovalType    Approval Type
	* @property {Number}  ExpiresAtNanos  ExpiresAtNanos is the time at which the approval expires - preferred over ExpiresInNanos
	* @property {Number}  ExpiresInNanos  ExpiresInNanos is the time in nanoseconds from now at which the approval expires - used only if ExpiresAtNanos is not set
	* @property {String}  Description     Approval Description (Presented to Customer)
	* @property {String}  ResourceUUID    ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example;,   - for a Identity approval, this is the list of IDM roles being granted from the ResourceFromUUID to the ResourceToUUID,   - for a Group approval, this is the CustomerGroupType,UUID (defaults to "COMPANION," as there can only be 1 companion group, so GroupUUID is not required)
	*
	* @typedef  {Object}  Approvals
	* @property {Approval[]} Approvals Approvals is an array of the Approvals in the response
	*
	*/
	Invite({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/customer/approval/invite`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('Invite ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('Invite ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('Invite ERROR:', error)
					return Promise.reject(error)
				}
				console.log('Invite UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in Invite'))
			})
	},

	/**
	* RPC Method: PurgeApprovalByUUID
	* Description: Purges a Approval object
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/customer/approval/${req.UUID}/purge
	* @param    {ApprovalUUIDSearch} req The API payload object (ApprovalUUIDSearch)
	* @return   {Approval} The API response object (Approval)
	*
	* @typedef  {Object}  ApprovalUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Approval
	* @property {String}  UUID                    Invite is used to send another customer an invitation for access
	* @property {Number}  SPID                    GetCustomerApprovals gets a list of all invites and approvals for a customer
	* @property {Number}  CreatedAtNanos          ActionApproval is used to approve or reject an Approval (request)
	* @property {String}  CreatedBy               here
	* @property {Number}  UpdatedAtNanos          When the object was last updated
	* @property {String}  UpdatedBy               Who the object was created by
	* @property {Number}  DeletedAtNanos          When the object was deleted
	* @property {String}  DeletedBy               Who the object was created by
	* @property {Number}  ActionedAtNanos         When the object was redeemed
	* @property {String}  ActionedBy              Who the object was Actioned by
	* @property {Number}  ExpiresAtNanos          When the approval expires
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  RequestedToCustomerName RequestedToCustomerName is the name of the customer that requested this approval
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {String}  Description             Approval Description (Presented to Customer)
	* @property {String}  SubjectUUID             SubjectUUID is the UUID of the subject that is being given access,  a CustomerUUID (for Group request types), or a,  a IdentityUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceUUID            ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails         ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example, for a Identity approval, this is the list of IDM roles being granted,  from the ResourceFromUUID to the ResourceToUUID
	*
	*/
	PurgeApprovalByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/customer/approval/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveApproval', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeApprovalByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeApprovalByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeApprovalByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeApprovalByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeApprovalByUUID'))
			})
	},

	/**
	* RPC Method: UpdateApproval
	* Description: Update a single Approval object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/customer/approval/${req.UUID}
	* @param    {Approval} req The API payload object (Approval)
	* @return   {Approval} The API response object (Approval)
	*
	* @typedef  {Object}  Approval
	* @property {String}  UUID                    Invite is used to send another customer an invitation for access
	* @property {Number}  SPID                    GetCustomerApprovals gets a list of all invites and approvals for a customer
	* @property {Number}  CreatedAtNanos          ActionApproval is used to approve or reject an Approval (request)
	* @property {String}  CreatedBy               here
	* @property {Number}  UpdatedAtNanos          When the object was last updated
	* @property {String}  UpdatedBy               Who the object was created by
	* @property {Number}  DeletedAtNanos          When the object was deleted
	* @property {String}  DeletedBy               Who the object was created by
	* @property {Number}  ActionedAtNanos         When the object was redeemed
	* @property {String}  ActionedBy              Who the object was Actioned by
	* @property {Number}  ExpiresAtNanos          When the approval expires
	* @property {ENUM}    ApprovalType            Approval Type
	* @property {ENUM}    Status                  Approval status
	* @property {String}  RequestedByCustomerUUID RequestedByCustomerUUID is the UUID of the customer that requested this approval
	* @property {String}  RequestedByCustomerName RequestedByCustomerName is the name of the customer that requested this approval
	* @property {String}  RequestedTo             RequestedTo is the requestor-provided details of the customer that this approval is for.,  This typically contains the Email address or UUID, and is used to determine the RequestedToCustomerUUID
	* @property {String}  RequestedToCustomerUUID RequestedToCustomerUUID is the UUID of the customer that this approval is for.,  This is also the Customer who can approve or reject the approval request,  The type of approval (invite vs request) determines who is granted the access (the requestor or the requested)
	* @property {String}  RequestedToCustomerName RequestedToCustomerName is the name of the customer that requested this approval
	* @property {String}  ApprovalCode            Approval Code (max 250 chars, immutable after created)
	* @property {String}  Description             Approval Description (Presented to Customer)
	* @property {String}  SubjectUUID             SubjectUUID is the UUID of the subject that is being given access,  a CustomerUUID (for Group request types), or a,  a IdentityUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceUUID            ResourceUUID is the UUID of the Resource to which access is being granted,  a GroupUUID (for Group request types), or a,  a CustomerUUID (for Identity request types), or a,  a BillingAccountUUID (for Billing request types)
	* @property {String}  ResourceDetails         ResourceDetails - data to store with the approval.,  The format of this data is specific to the approval type,  For example, for a Identity approval, this is the list of IDM roles being granted,  from the ResourceFromUUID to the ResourceToUUID
	*
	*/
	UpdateApproval({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/${req.SPID}/customer/approval/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveApproval', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateApproval ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateApproval ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateApproval ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateApproval UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateApproval'))
			})
	},

	/**
	* RPC Method: GetCustomerApprovals
	* Description: Searches for multiple Approval objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/invites_approvals
	* @param    {CustomerApprovalSearchRequest} req The API payload object (CustomerApprovalSearchRequest)
	* @return   {CustomerApprovalSearchResponse} The API response object (CustomerApprovalSearchResponse)
	*
	* @typedef  {Object}  CustomerApprovalSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  CustomerUUID   CustomerUUID is the UUID of the customer wishing to view their invites and approvals
	*
	* @typedef  {Object}  CustomerApprovalSearchResponse
	* @property {Approval[]} Invites   Approvals is an array of the Approvals in the response
	* @property {Approval[]} Approvals 
	*
	*/
	GetCustomerApprovals({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		return axios.get(`/v3/${paramSPID}/customer/${paramCustomerUUID}/invites_approvals`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerApprovals ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerApprovals ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerApprovals ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerApprovals UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerApprovals'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};