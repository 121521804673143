import axios from "axios"

const state = {}

const getters = {}

const mutations = {}

const actions = {
    GetCategoriesPaginated({}, params) {
        return axios.post(`/admin/eshop/category_search`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddCategory({}, params) {
        return axios.post(`/admin/eshop/category`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateCategory({}, params) {
        return axios.put(`/admin/eshop/category/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteCategoryByUUID({}, params) {
        return axios.delete(`/admin/eshop/category/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },

    GetItemsPaginated({}, params) {
        return axios.post(`/admin/eshop/eshop_item_search`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddItem({}, params) {
        return axios.post(`/admin/eshop/eshop_item`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    GetItemByUUID({}, req) {
        return axios.get(`/admin/eshop/eshop_item/${req.UUID}`, { params: req }).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateItem({}, params) {
        return axios.put(`/admin/eshop/eshop_item/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteItemByUUID({}, {UUID}) {
        return axios.delete(`/admin/eshop/eshop_item/${UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },

    GetItemVariantsPaginated({}, params) {
        return axios.post(`/admin/eshop/item_variant_search`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    GetItemAttributeSetsPaginated({}, params) {
        return axios.post(`/admin/eshop/item_attribute_set_search`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    GetItemVariantAttributesPaginated({}, params) {
        return axios.post(`/admin/eshop/item_variant_attribute_search`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteItemVariantAttributeByUUID({}, {UUID}) {
        return axios.delete(`/admin/eshop/item_variant_attribute/${UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    GetItemAttributeSetAttributesPaginated({}, params) {
        return axios.post(`/admin/eshop/item_attribute_set_attribute_search`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddItemVariant({}, params) {
        return axios.post(`/admin/eshop/item_variant`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateItemVariant({}, params) {
        return axios.post(`/admin/eshop/item_variant/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteItemVariantByUUID({}, {UUID}) {
        return axios.delete(`/admin/eshop/item_variant/${UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddItemVariantAttribute({}, params) {
        return axios.post(`/admin/eshop/item_variant_attribute`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateItemVariantAttribute({}, params) {
        return axios.put(`/admin/eshop/item_variant_attribute/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteItemImageByUUID({}, params) {
        return axios.delete(`/admin/eshop/eshop_item/${params.ItemUUID}/item_image/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddItemAttributeSet({}, params) {
        return axios.post('/admin/eshop/item_attribute_set', params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateItemAttributeSet({}, params) {
        return axios.put(`/admin/eshop/item_attribute_set/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteItemAttributeSetByUUID({}, { UUID }) {
        return axios.delete(`/admin/eshop/item_attribute_set/${UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddItemAttributeSetAttribute({}, params) {
        return axios.post('/admin/eshop/item_attribute_set_attribute', params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateItemAttributeSetAttribute({}, params) {
        return axios.put(`/admin/eshop/item_attribute_set_attribute/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteItemAttributeSetAttributeByUUID({}, { UUID }) {
        return axios.delete(`/admin/eshop/item_attribute_set_attribute/${UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },

    GetOrdersPaginated({}, params) {
        return axios.post('/admin/eshop/v2/orders', params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    GetOrderByNumber({}, orderNumber) {
        return axios.get(`/admin/eshop/v2/order/${orderNumber}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    AddOrderNote({}, payload) {
        return axios.post('/admin/eshop/order-note', payload).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteOrderNoteByUUID({}, { UUID }) {
        return axios.delete(`/admin/eshop/order-note/${UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    CreateProductOrderWithVoucher({}, params) {
        return axios.post('/admin/eshop/order-voucher', params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    ChangeMobileAddon({}, params) {
        const CustomerUUID = params?.CustomerUUID
        return axios.post(`/eshop/customer/${CustomerUUID}/change-addon`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    ChangePlan({}, params) {
        return axios.post('/eshop/customer/change-plan', params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
}
