import axios from "axios";

const state = {}

const getters = {}

const mutations = {}

const actions = {
    GetShipmentsPaginated({}, params) {
        return axios.get(`/packing/v1/requests`, { params })
            .then(({data}) => {
                return Promise.resolve(data)
            })
            .catch(error => Promise.reject(error))
    },
    ShipmentProcess({}, params) {
        return axios.post(`/packing/v1/details`, params)
            .then(({data}) => {
                return Promise.resolve(data)
            })
            .catch(error => Promise.reject(error))
    },
    SelectRequest({}, { ID }) {
        return axios.get(`/packing/v1/requests/${ID}`)
            .then(({data}) => {
                return Promise.resolve(data)
            })
            .catch(error => Promise.reject(error))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}