import Swal from 'sweetalert2';

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
    },
    buttonsStyling: false
})

export function errMessage(error) {
    return swalWithBootstrapButtons.fire({
        title: 'Error!',
        icon: 'error',
        confirmButtonText: 'Ok',
        timer: 15000,
        html: error
        // text: error
    });
}