import { extend } from 'vee-validate';

function maxGroupSize() {
    extend("maxGroupSize", {
        params: ['max'],
        validate(value, { max }) {
            return value >= max
        },
        message: 'Group has {max} members already'
    })
}

export default maxGroupSize