<template>
    <b-modal
        id="hot-reload"
        size="lg"
        centered
        hide-footer
        @hide="$emit('close')"
    >
        <div class="modal-wrapper">
            <div class="h1 text-center mb-3">
                <b-icon icon="exclamation-circle" variant="primary" style="width: 100px; height: 100px;"></b-icon>
            </div>

            <div class="h4 text-center mb-4">This portal has been updated recently. Please refresh the page to load the latest version</div>

            <div class="text-center mb-4">
                <b-button type="button" variant="primary pl-5 pr-5 ml-3 mr-3 mb-2" @click="$emit('reload')">Refresh</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            modalShow: true
        }
    }
}
</script>

<style scoped>

</style>