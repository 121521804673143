// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: mobileproduct.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: MobileRPC
	//
	/**
	 * RPC Method: ChangeMSISDN
	 * Description: ## ChangeMSISDN
	 *
	 * The change MSISDN feature allows a mobile subscriber that has an active mobile service to change the primary MSISDN
	 * that was associated with their subscription at time of initial order.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |MSISDN|MSISDN(string)|Change the MSISDN this product has|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "MSISDN": "6401150310635192",
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/msisdn_change
	 * @param    {MobileMSISDNChangeRequest} req The API payload object (MobileMSISDNChangeRequest)
	 * @return   {MSISDNChangeResponse} The API response object (MSISDNChangeResponse)
	 *
	 * @typedef  {Object}  MobileMSISDNChangeRequest
	 * @property {Number}  SPID   SPID is the service provider ID that the product belongs to
	 * @property {String}  UUID   Limit search to a particular product instance
	 * @property {String}  MSISDN MSISDN you want to change to
	 *
	 * @typedef  {Object}  MSISDNChangeResponse
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 *
	 */
	ChangeMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/mobile_product/${req.UUID}/msisdn_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeMSISDN'))
			})
	},

	/**
	 * RPC Method: DeprovisionSIM
	 * Description: De-provision a SIM on the network - If there is a ported number, the ported number will be released back to IPMS, and a new number will be allocated to the SIM. Removes number off the SIM record
	 * HTTP Method: POST
	 * API Path: /api/v3/deprovision_sim
	 * @param    {SIMDeprovisionRequest} req The API payload object (SIMDeprovisionRequest)
	 * @return   {SIMDeprovisionResponse} The API response object (SIMDeprovisionResponse)
	 *
	 * @typedef  {Object}  SIMDeprovisionRequest
	 * @property {Number}  SPID
	 * @property {String}  IMSI                IMSI of the provisioned SIM
	 * @property {String}  SIMID               SIMID of the provisioned SIM
	 * @property {String}  ICCID               ICCID of the provisioned SIM
	 * @property {String}  MSISDN              MSISDN of the provisioned SIM
	 * @property {String}  ProductInstanceUUID Product UUID
	 *
	 * @typedef  {Object}  SIMDeprovisionResponse
	 * @property {Number}  SPID
	 * @property {String}  IMSI
	 * @property {String}  SIMID
	 * @property {String}  ICCID
	 * @property {String}  MSISDN Optional new MSISDN allocated as part of deprovisioning process
	 *
	 */
	DeprovisionSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/deprovision_sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeprovisionSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeprovisionSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeprovisionSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeprovisionSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeprovisionSIM'))
			})
	},

	/**
	 * RPC Method: GetDataSettings
	 * Description: ## GetDataSettings
	 *
	 * Get Data Settings of the product
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 *
	 * ### Example Request
	 *
	 * {
	 * /api/v1/1/mobile_product/1bcdb203-ec7e-461c-8870-d8467a167a78/data_settings
	 * }
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/data_settings
	 * @param    {MobileGetDataSettingsRequest} req The API payload object (MobileGetDataSettingsRequest)
	 * @return   {MobileGetDataSettingsResponse} The API response object (MobileGetDataSettingsResponse)
	 *
	 * @typedef  {Object}  MobileGetDataSettingsRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID ProductInstanceUUID
	 *
	 * @typedef  {Object}  MobileGetDataSettingsResponse
	 * @property {DataEnabled} DataEnabled
	 *
	 */
	GetDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/mobile_product/${paramUUID}/data_settings`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetDataSettings'))
			})
	},

	/**
	 * RPC Method: GetMobileProductByMSISDN
	 * Description: Get a single MobileProduct object using the MSISDN
	 * HTTP Method: GET
	 * API Path: /api/v3/mobile_product_by_msisdn/${req.MSISDN}
	 * @param    {MobileProductMSISDNSearch} req The API payload object (MobileProductMSISDNSearch)
	 * @return   {MobileProduct} The API response object (MobileProduct)
	 *
	 * @typedef  {Object}  MobileProductMSISDNSearch
	 * @property {Number}  SPID       Service Provider ID - always required
	 * @property {String}  MSISDN     (optional) Description
	 * @property {Boolean} LatestOnly
	 *
	 * @typedef  {Object}  MobileProduct
	 * @property {String}  UUID           option (iqopts.message_opts) = {,          table: "mobile_products",,  //        smart_search_fields: ["UUID|ilike%"],      };
	 * @property {Number}  SPID           Default Service Provider ID
	 * @property {Number}  CreatedAtNanos When the object was first created
	 * @property {Number}  UpdatedAtNanos When the object was last updated
	 * @property {Number}  DeletedAtNanos When the object was deleted
	 * @property {ENUM}    ServiceType    ServiceType
	 * @property {String}  CustomerUUID
	 *
	 */
	GetMobileProductByMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramMSISDN = encodeURIComponent(req.MSISDN)
		return axios.get(`/v3/mobile_product_by_msisdn/${paramMSISDN}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProductByMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProductByMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProductByMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProductByMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProductByMSISDN'))
			})
	},

	/**
	 * RPC Method: GetMobileProductByUUID
	 * Description: Get a single MobileProduct object using the UUID
	 * HTTP Method: GET
	 * API Path: /api/v3/mobile_product/${req.UUID}
	 * @param    {MobileProductUUIDSearch} req The API payload object (MobileProductUUIDSearch)
	 * @return   {MobileProduct} The API response object (MobileProduct)
	 *
	 * @typedef  {Object}  MobileProductUUIDSearch
	 * @property {String}  UUID
	 * @property {Number}  SPID        Service Provider ID - always required
	 * @property {String}  Description (optional) Description
	 *
	 * @typedef  {Object}  MobileProduct
	 * @property {String}  UUID           option (iqopts.message_opts) = {,          table: "mobile_products",,  //        smart_search_fields: ["UUID|ilike%"],      };
	 * @property {Number}  SPID           Default Service Provider ID
	 * @property {Number}  CreatedAtNanos When the object was first created
	 * @property {Number}  UpdatedAtNanos When the object was last updated
	 * @property {Number}  DeletedAtNanos When the object was deleted
	 * @property {ENUM}    ServiceType    ServiceType
	 * @property {String}  CustomerUUID
	 *
	 */
	GetMobileProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/mobile_product/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProductByUUID'))
			})
	},

	/**
	 * RPC Method: GetMobileProductsPaginated
	 * Description: Searches for multiple MobileProduct objects using a search request
	 * HTTP Method: GET
	 * API Path: /api/v3/mobile_product
	 * @param    {MobileProductSearchRequest} req The API payload object (MobileProductSearchRequest)
	 * @return   {MobileProductPageResponse} The API response object (MobileProductPageResponse)
	 *
	 * @typedef  {Object}  MobileProductSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	 * @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	 * @property {Number}  SPID           (optional) SPID
	 * @property {String}  Description    (optional) Description
	 * @property {String}  CustomerUUID   (optional) CustomerUUID
	 * @property {String}  ServiceType    (optional) ServiceType
	 *
	 * @typedef  {Object}  MobileProductPageResponse
	 * @property {PageInfo} PageInfo       PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {MobileProduct[]} MobileProducts
	 *
	 */
	GetMobileProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v3/mobile_product`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProductsPaginated'))
			})
	},

	/**
	 * RPC Method: GetVoiceSettings
	 * Description: ## GetVoiceSettings
	 *
	 * Get voice settings of the product
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |UUID|string|The product instance UUID|
	 *
	 * ### Example Request
	 *
	 * {
	 * /api/v1/1/mobile_product/1bcdb203-ec7e-461c-8870-d8467a167a78/voicesettings
	 * }
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/voicesettings
	 * @param    {MobileGetVoiceSettingsRequest} req The API payload object (MobileGetVoiceSettingsRequest)
	 * @return   {MobileGetVoiceSettingsResponse} The API response object (MobileGetVoiceSettingsResponse)
	 *
	 * @typedef  {Object}  MobileGetVoiceSettingsRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID ProductInstanceUUID
	 *
	 * @typedef  {Object}  MobileGetVoiceSettingsResponse
	 * @property {VoiceSettings[]} VoiceSettings
	 *
	 */
	GetVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/mobile_product/${paramUUID}/voicesettings`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVoiceSettings'))
			})
	},

	/**
	 * RPC Method: ProvisionSIM
	 * Description: Provision a SIM on the network
	 * HTTP Method: POST
	 * API Path: /api/v3/provision_sim
	 * @param    {SIMProvisionRequest} req The API payload object (SIMProvisionRequest)
	 * @return   {SIMProvisionResponse} The API response object (SIMProvisionResponse)
	 *
	 * @typedef  {Object}  SIMProvisionRequest
	 * @property {Number}  SPID
	 * @property {String}  IMSI                           Require IMSI or SIMID or ICCID
	 * @property {String}  SIMID
	 * @property {String}  ICCID                          option (google.api.http) = {,      put: "/api/v3/preactivate_mobile/{UUID}",      body: "*",  };
	 * @property {String}  MSISDN                         option (google.api.http) = {,      put: "/api/v3/preactivate_mobile/{UUID}",      body: "*",  };
	 * @property {String}  ProductCatalogAvailabilityUUID
	 *
	 * @typedef  {Object}  SIMProvisionResponse
	 * @property {Number}  SPID
	 * @property {String}  IMSI                           IMSI of the provisioned SIM
	 * @property {String}  SIMID                          SIMID of the provisioned SIM
	 * @property {String}  ICCID                          ICCID of the provisioned SIM
	 * @property {String}  MSISDN                         MSISDN of the provisioned SIM
	 * @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID used to activate
	 * @property {String}  ProductInstanceUUID            Product UUID
	 * @property {String}  PrepayProductInstanceUUID      (optional) Prepay Product UUID
	 * @property {String}  PostpayProductInstanceUUID     (optional) Postpay Product UUID
	 * @property {String}  CustomerUUID
	 *
	 */
	ProvisionSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/provision_sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ProvisionSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ProvisionSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ProvisionSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ProvisionSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ProvisionSIM'))
			})
	},

	/**
	 * RPC Method: ProvisionSIMs
	 * Description: This API call takes loaded SIMs and MSISDNs and preprovisions them for sale
	 * HTTP Method: POST
	 * API Path: /api/v3/provision_sims
	 * @param    {ProvisonRequest} req The API payload object (ProvisonRequest)
	 * @return   {ProvisionResponse} The API response object (ProvisionResponse)
	 *
	 * @typedef  {Object}  ProvisonRequest
	 * @property {Number}  SPID
	 * @property {String}  ProductAvailabilityUUID UUID of MobileProduct,  ProductAvailabilityUUID is the mobile product that is being provisioned onto the SIM
	 * @property {String}  AddonAvailabilityUUID   AddonUUID is the AddOn that should be provisioned when the SIM is activated by a customer,  there are some constraints, the retail price will be $0.00, it will not be configured to repeat,  the wholesaler will be charged the wholesale price
	 * @property {Number}  IMSIRangeStart          The start and end of the SIMID range to preprovision
	 * @property {Number}  IMSIRangeCount
	 * @property {Boolean} Force                   The start and end of the MSISDN range to preprovosion,  int64 MSISDNRangeStart = 6;,  int64 MSISDNRangeEnd = 7;
	 *
	 * @typedef  {Object}  ProvisionResponse
	 * @property {SIMDetails[]} SIMDetails
	 *
	 */
	ProvisionSIMs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/provision_sims`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ProvisionSIMs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ProvisionSIMs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ProvisionSIMs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ProvisionSIMs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ProvisionSIMs'))
			})
	},

	/**
	 * RPC Method: ReprovisionSIM
	 * Description: Re-provision a SIM back, including deprovisioning of all sub-service. Internally calls DeprovisionSIM and then ProvisionSIM
	 * HTTP Method: POST
	 * API Path: /api/v3/reprovision_sim
	 * @param    {SIMReprovisionRequest} req The API payload object (SIMReprovisionRequest)
	 * @return   {SIMProvisionResponse} The API response object (SIMProvisionResponse)
	 *
	 * @typedef  {Object}  SIMReprovisionRequest
	 * @property {Number}  SPID
	 * @property {String}  IMSI                           Option 1: Permanent hidden
	 * @property {String}  SIMID                          Option 3: Temporary showed
	 * @property {String}  ICCID
	 * @property {String}  MSISDN                         MSISDN is required
	 * @property {String}  ProductCatalogAvailabilityUUID
	 * @property {String}  ProductInstanceUUID
	 *
	 * @typedef  {Object}  SIMProvisionResponse
	 * @property {Number}  SPID
	 * @property {String}  IMSI                           IMSI of the provisioned SIM
	 * @property {String}  SIMID                          SIMID of the provisioned SIM
	 * @property {String}  ICCID                          ICCID of the provisioned SIM
	 * @property {String}  MSISDN                         MSISDN of the provisioned SIM
	 * @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID used to activate
	 * @property {String}  ProductInstanceUUID            Product UUID
	 * @property {String}  PrepayProductInstanceUUID      (optional) Prepay Product UUID
	 * @property {String}  PostpayProductInstanceUUID     (optional) Postpay Product UUID
	 * @property {String}  CustomerUUID
	 *
	 */
	ReprovisionSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/reprovision_sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ReprovisionSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ReprovisionSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ReprovisionSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ReprovisionSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ReprovisionSIM'))
			})
	},

	/**
	 * RPC Method: UpdateDataSettings
	 * Description: ## UpdateDataSettings
	 *
	 * Update Data Settings of the product
	 * All DataEnabled products need to be set at the same time
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |DataEnabled|object(DataEnabled)|The DataEnabled to be set|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "SPID": 1,
	 *        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	 *     	"DataEnabled": {
	 *            "OCSChargeableDataRGBarred": false,
	 *        }
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/data_settings
	 * @param    {MobileUpdateDataSettingsRequest} req The API payload object (MobileUpdateDataSettingsRequest)
	 * @return   {MobileUpdateDataSettingsResponse} The API response object (MobileUpdateDataSettingsResponse)
	 *
	 * @typedef  {Object}  MobileUpdateDataSettingsRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 * @property {DataEnabled} DataEnabled
	 *
	 * @typedef  {Object}  MobileUpdateDataSettingsResponse
	 * @property {DataEnabled} DataEnabled
	 *
	 */
	UpdateDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/mobile_product/${req.UUID}/data_settings`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateDataSettings'))
			})
	},

	/**
	 * RPC Method: UpdateVoiceSettings
	 * Description: ## UpdateVoiceSettings
	 *
	 * Update voice settings of the product.
	 * All VoiceSettings products need to be set at the same time.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |VoiceSettings|object(VoiceSettings)|The voice settings to be set|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "SPID": 1,
	 *        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	 *     	"VoiceSettings": {
	 *          "OutgoingCallerID": 3,
	 *          "IncomingCallerID": true,
	 *          "AllowConferencing": true,
	 *          "CallWaitingAvailable": true,
	 *          "CallWaitingEnabled": true,
	 * 	        "CFBActive":false,
	 * 	        "CFBAvailable":true,
	 * 	        "CFBDestination":"CFBDestination stub",
	 * 	        "CFNRCActive":false,
	 * 	        "CFNRCAvailable":true,
	 * 	        "CFNRCDestination":"CFNRCDestination stub",
	 * 	        "CFNRYAvailable":true,
	 * 	        "CFNRYActive":false,
	 * 	        "CFNRYDestination":true,
	 * 	        "CFNRYRingtime":30,
	 * 	        "CFUActive":false,
	 * 	        "CFUAvailable":true,
	 * 	        "CFUDestination":"CFUDestination stub",
	 * 	        "BarIncomingCalls":false,
	 * 	        "BarOutgoingCalls":false,
	 * 	        "AllowIncomingCalls":true,
	 * 	        "AllowOutgoingCalls":true,
	 * 	        "AllowSMSSend":true,
	 * 	        "AllowSMSRecieve":true,
	 * 	        "OCSBlockPremiumCalls":false,
	 * 	        "OCSBlockPremiumSMS":false,
	 * 	        "OCSOutgoingIDDBarred":false,
	 * 	        "OCSOutgoingTextBarred":false,
	 * 	        "OCSRoamingBarred":false,
	 * 	        "OCSOutCallsWhileRoamingBarred":false,
	 * 	        "OCSInCallsWhileRoamingBarred":false,
	 * 	        "OCSOOBWhileRoamingBarred":false,
	 *        }
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/voicesettings
	 * @param    {MobileUpdateVoiceSettingsRequest} req The API payload object (MobileUpdateVoiceSettingsRequest)
	 * @return   {MobileUpdateVoiceSettingsResponse} The API response object (MobileUpdateVoiceSettingsResponse)
	 *
	 * @typedef  {Object}  MobileUpdateVoiceSettingsRequest
	 * @property {Number}  SPID          Service Provider ID
	 * @property {String}  UUID          UUID
	 * @property {VoiceSettings[]} VoiceSettings
	 *
	 * @typedef  {Object}  MobileUpdateVoiceSettingsResponse
	 * @property {VoiceSettings[]} VoiceSettings
	 *
	 */
	UpdateVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/mobile_product/${req.UUID}/voicesettings`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoiceSettings'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
