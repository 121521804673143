const UNSUPPORTED = process.env.VUE_APP_UNSUPPORTED_SERVICES ? process.env.VUE_APP_UNSUPPORTED_SERVICES.split(',') : []

const state = {}

const getters = {
    isUnsupported: (state, getters) => item => !UNSUPPORTED.includes(item),
    isSupportCustomerGroups: (state, getters) => getters.isUnsupported('CUSTOMER_GROUPS') && getters.hasCustomerRole,
    isSupportMobileGroups: (state, getters) => getters.isUnsupported('MOBILE_GROUPS') && getters.hasMobileReadRole,
    isSupportBankTransfer: (state, getters) => getters.isUnsupported('BANK_TRANSFER'),
    isSupportApiKeyManagement: (state, getters) => getters.isSuperAdmin || getters.isUnsupported('API_KEY_MANAGEMENT'),
    isSupportPreActivation: (state, getters) => getters.isUnsupported('PRE_ACTIVATION'),
    isSupportAddonAutoRenew: (state, getters) => getters.isUnsupported('ADDON_AUTO_RENEW') ? getters.isUnsupported('ADDON_AUTO_RENEW') : !!(getters.isSuperAdmin || getters.isPrereleaseMode),
    isSupportPostPay: (state, getters) => getters.isUnsupported('POSTPAY'),
    isSupportPrePay: (state, getters) => getters.isUnsupported('PREPAY'),
    isSupportMobile: (state, getters) => getters.isUnsupported('MOBILE'),
    isSupportCommercialView: (state, getters) => getters.isUnsupported('COMMERCIAL_VIEW'),
    isSupportAdminNotifications: (state, getters) => getters.isUnsupported('ADMIN_NOTIFICATIONS'),
    isSupportCustomerNotifications: (state, getters) => getters.isUnsupported('CUSTOMER_NOTIFICATIONS'),
    isSupportResetSim: (state, getters) => (getters.isUnsupported('RESET_SIM_SPADMIN') && getters.isSPAdmin) || ((getters.isUnsupported('RESET_SIM_SUPERADMIN') && getters.isSuperAdmin)),
    isSupportVouchers: (state, getters) => getters.isUnsupported('VOUCHERS'),
    isSupportVoucherCreateProductType: (state, getters) => getters.isUnsupported('VOUCHER_CREATE_PRODUCT_TYPE'),
    isSupportVoucherCreateTopUpType: (state, getters) => getters.isUnsupported('VOUCHER_CREATE_TOP_UP_TYPE'),
    isSupportVoucherCreate: (state, getters) => getters.isSupportVoucherCreateProductType || getters.isSupportVoucherCreateTopUpType,
    isSupportBillRouting: (state, getters) => getters.isUnsupported('BILL_ROUTING'),
    isSupportMigration: (state, getters) => getters.isUnsupported('MIGRATION'),
    isSupportInvoices: (state, getters) => getters.isUnsupported('INVOICES'),
    isSupportReceipts: (state, getters) => getters.isUnsupported('RECEIPTS'),
    isSupportExternalAccount: (state, getters) => getters.isUnsupported('EXTERNAL_ACCOUNT'),
    isSupportUFB: (state, getters) => getters.isUnsupported('UFB') && getters.hasUFBReadRole,
    isSupportProductTransfer: (state, getters) => getters.isUnsupported('PRODUCT_TRANSFER') && getters.hasMobileManageRole,
    isSupportChargeAddPlan: (state, getters) => getters.isUnsupported('CHARGE_ADD_PLAN')
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}