import { extend } from 'vee-validate';

function fullName() {
    extend("fullName", {
        validate(value) {
            const regex = new RegExp("^(?=.{1,32}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\\ |\\'|\\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$");
            return regex.test(value);
        },
        message: field => `Please, enter ${field}. It must contain from 1 to 32 characters`,
    });
}

export default fullName