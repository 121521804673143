import axios from "axios"

import StoreHash from '@/utils/StoreHash'

const hashed = {
    ufbPlansAll: new StoreHash(5 * 60 * 1000), // 5 min
}

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const state = {
    ufbPlans: [],
}

const getters = {
    ufbPlans: state => state.ufbPlans
}

const mutations = {
    mutUfbPlans(state, ufbPlans) {
        state.ufbPlans = ufbPlans
        hashed.ufbPlansAll.fix()
    },
    resetUfbPlansCache(state) {
        state.ufbPlans = []
        hashed.ufbPlansAll.reset()
    }
}

const actions = {
    GetCachedUfbPlans({ dispatch, getters, commit }, params) {
        if (hashed.ufbPlansAll.expired()) {
            return dispatch('api_ufbprod/GetUFBPlans', params, { root:true })
                .then(({UFBPlans}) => {
                    commit('mutUfbPlans', UFBPlans)
                    return Promise.resolve(UFBPlans)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        } else {
            return Promise.resolve(getters.ufbPlans)
        }
    },
    AdminSearchUFBProducts({}, params) {
        return axios.post(`${API_URL_RESELLER}/admin/ufb/ufbproduct/search`, params)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    AdminGetUFBProductByUUID({}, req) {
        return axios.get(`${API_URL_RESELLER}/admin/ufb/ufbproduct/${req.UUID}`, { params: req })
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    TerminateUFBService({}, params) {
        return axios.delete(`${API_URL_RESELLER}/ufb/ufbproduct/${params.UUID}`, params)
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    GetUFBServiceNotes({}, req) {
        return axios.get(`${API_URL_RESELLER}/ufb/ufbproduct/${req.UUID}/note`, { params: req })
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    AddUFBServiceNote({}, params) {
        return axios.post(`${API_URL_RESELLER}/ufb/ufbproduct/${params.UUID}/note`, params)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    AdminSearchUFBOrders({}, req) {
        return axios.get(`${API_URL_RESELLER}/admin/ufb/order/search`, { params: req })
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    GetUFBOrderByUUID({}, req) {
        return axios.get(`${API_URL_RESELLER}/ufb/order/${req.UUID}`, { params: req })
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    GetUFBOrderNotes({}, req) {
        return axios.get(`${API_URL_RESELLER}/ufb/order/${req.UUID}/note`, { params: req })
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    AddUFBOrderNote({}, params) {
        return axios.post(`${API_URL_RESELLER}/ufb/order/${params.UUID}/note`, params)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    CancelUFBOrder({}, params) {
        return axios.delete(`${API_URL_RESELLER}/ufb/order/${params.UUID}`, params)
            .then(({data})=> Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}