import StoreHash from '@/utils/StoreHash'

let hashed = {
    postpayMobilePlans: new StoreHash(3 * 60 * 1000), // 3 min,
    postpayAddons: new StoreHash(3 * 60 * 1000), // 3 min,
}

const state = {
    postpayMobilePlans: {},
    postpayAddons: {}
}

const getters = {
    postpayMobilePlans: state => Object.values(state.postpayMobilePlans),
    postpayMobilePlanByUUID: state => uuid => state.postpayMobilePlans[uuid],
    postpayAddons: state => Object.values(state.postpayAddons),
    postpayAddonByUUID: state => uuid => state.postpayAddons[uuid],
}

const mutations = {
    mutPostpayMobilePlans(state, postpayMobilePlans) {
        const result = {};

        postpayMobilePlans.forEach(postpayMobilePlan => {
            result[postpayMobilePlan.UUID] = postpayMobilePlan;
        })
        state.postpayMobilePlans = result;
        hashed.postpayMobilePlans.fix();
    },

    resetPostpayMobilePlansCache(state) {
        hashed.postpayMobilePlans.reset();
        state.postpayMobilePlans = {};
    },

    mutPostpayAddons(state, postpayAddons) {
        const result = {};

        postpayAddons.forEach(postpayAddon => {
            result[postpayAddon.UUID] = postpayAddon;
        })
        state.postpayAddons = result;
        hashed.postpayAddons.fix();
    },

    resetPostpayAddonsCache(state) {
        hashed.postpayAddons.reset();
        state.postpayAddons = {};
    }
}

const actions = {
    getAllCachedPostpayMobilePlans({ dispatch, getters, commit }, params) {
        const refresh = params?.refresh;
        if (hashed.postpayMobilePlans.expired() || refresh) {
            const payload = {
                SPID: getters.spid,
                'SearchOptions.PageSize': -1
            }
            return dispatch('api_postpay/FindPostpayMobilePlansPaginated', payload)
                .then(apidata => {
                    if (apidata?.PostpayMobilePlans) {
                        commit('mutPostpayMobilePlans', apidata.PostpayMobilePlans);
                        return Promise.resolve(apidata.PostpayMobilePlans);
                    }
                    return Promise.reject(new Error('No Post Pay Plans'))
                }).catch(error => Promise.reject(error))
        } else {
            return Promise.resolve(getters.postpayMobilePlans)
        }
    },
    getAllCachedPostpayAddonsPaginated({ dispatch, getters, commit }, params) {
        const refresh = params?.refresh;
        if (hashed.postpayAddons.expired() || refresh) {
            const payload = {
                SPID: getters.spid,
                'SearchOptions.PageSize': -1
            }
            return dispatch('api_postpay/GetAvailablePostpayAddonsPaginated', payload)
                .then(apidata => {
                    if (apidata?.PostpayAddonCatalogs) {
                        const postPayMobileAddons = apidata.PostpayAddonCatalogs.filter(addon => {
                            return addon?.ProductType === "postpaymobileAddon";
                        })
                        commit('mutPostpayAddons', postPayMobileAddons);
                        return Promise.resolve(postPayMobileAddons);
                    }
                    return Promise.reject(new Error('No Post Pay Plans'))
                }).catch(error => Promise.reject(error))
        } else {
            return Promise.resolve(getters.postpayAddons)
        }

    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}