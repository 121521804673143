// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: event.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Events: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	EventSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getEventByUUID: state => (UUID) => (state.Events.UUIDs[ UUID ] && !state.Events.UUIDs[ UUID ].expired()) ?  state.Events.UUIDs[ UUID ].hashedData() : null,
	getEventsForSearchText: state => (SearchText) => state.EventSearches[ SearchText ] ? state.EventSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveEvent saves a single Event object
	// into both the StoreHash cache and the VueX store
	mutSaveEvent(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Events.UUIDs)) {
			state.Events.UUIDs[ obj.UUID ] = new StoreHash(state.Events.lifetime)
		}
		state.Events.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveEventByUUID removes a Event object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveEventByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Events.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveEventSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveEventSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Events)) {
			for (let i = 0; i < Response.Events.length; i++) {
				storedObj.UUIDs.push(Response.Events[i].UUID)
			}
		}
		state.EventSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.EventSearches.SearchTexts)) {
			state.EventSearches.SearchTexts[ SearchText ] = new StoreHash(state.EventSearches.lifetime)
		}
		state.EventSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveEventSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveEventSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.EventSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.EventSearches[ SearchText ]               // remove item from VueX state
		delete state.EventSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveEvents saves a paginated response of Events
	mutSaveEvents(state, { Response }) {
		if (!Response || !Array.isArray(Response.Events)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Events.length; i++) {
			if (!(Response.Events[i].UUID in state.Events.UUIDs)) {
				state.Events.UUIDs[ Response.Events[i].UUID ] = new StoreHash(state.Events.lifetime)
			}
			state.Events.UUIDs[ Response.Events[i].UUID ].fix(Response.Events[i])  // Add single Event to StoreHash
		}
	},
};

const actions = {
	// getCachedEventByUUID fetches a Event from the cache
	// and if not in the cache, fetches it from the API
	getCachedEventByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Events.UUIDs[ UUID ] && !state.Events.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Events.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetEventByUUID', { UUID }).then(respPayload => {
			commit('mutSaveEvent', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveEventByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedEventsBySearch fetches Events from the cache
	// and if not in the cache, fetches it from the API
	getCachedEventsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.EventSearches.SearchTexts[ SearchText ] && !state.EventSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getEventsForSearchText(SearchText)
			resp.Events = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Events.push(getters.getEventByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindEventsPaginated', apiReq).then(respPayload => {
			commit('mutSaveEventSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveEventSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: EventRPC
	//
	/**
	* AddEvent:
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/event
	* @param    {Event} req The API payload object (Event)
	* @return   {Event} The API response object (Event)
	*
	* @typedef  {Object}  Event
	* @property {String}  UUID            option (grpc.gateway.protoc_gen_swagger.options.openapiv2_operation) = {,              security: {};,         };
	* @property {Number}  SPID
	* @property {String}  CustomerUUID    The Customer UUID
	* @property {String}  CustomerName    The Customer name (usually "First Last")
	* @property {String}  AgentUUID
	* @property {String}  AgentName       The Agent name (usually "First Last")
	* @property {Number}  TimestampNanos  The UnixNano timestamp of when the Event was generated
	* @property {Number}  MessageCode
	* @property {String}  Message
	* @property {String}  SystemComponent The system component (service) that generated this event
	* @property {Boolean} ViewOperator    Can this event be viewed by an Operator user?
	* @property {Boolean} ViewAgent       Can this event be viewed by an Agent user?
	* @property {Boolean} ViewCustomer    Can this event be viewed by a Customer?
	* @property {String}  DebugPayload
	* @property {ENUM}    EventType
	* @property {Number}  DeletedAt
	* @property {String}  UniqueID
	* @property {String}  AgentAuthID
	*
	*/
	AddEvent({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/event`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddEvent ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddEvent ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddEvent ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddEvent UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddEvent'))
			})
	},

	/**
	* DeleteEvent:
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/event/${req.UUID}
	* @param    {EventUUIDSearch} req The API payload object (EventUUIDSearch)
	* @return   {Event} The API response object (Event)
	*
	* @typedef  {Object}  EventUUIDSearch
	* @property {Number}  SPID
	* @property {String}  UUID
	*
	* @typedef  {Object}  Event
	* @property {String}  UUID            option (grpc.gateway.protoc_gen_swagger.options.openapiv2_operation) = {,              security: {};,         };
	* @property {Number}  SPID
	* @property {String}  CustomerUUID    The Customer UUID
	* @property {String}  CustomerName    The Customer name (usually "First Last")
	* @property {String}  AgentUUID
	* @property {String}  AgentName       The Agent name (usually "First Last")
	* @property {Number}  TimestampNanos  The UnixNano timestamp of when the Event was generated
	* @property {Number}  MessageCode
	* @property {String}  Message
	* @property {String}  SystemComponent The system component (service) that generated this event
	* @property {Boolean} ViewOperator    Can this event be viewed by an Operator user?
	* @property {Boolean} ViewAgent       Can this event be viewed by an Agent user?
	* @property {Boolean} ViewCustomer    Can this event be viewed by a Customer?
	* @property {String}  DebugPayload
	* @property {ENUM}    EventType
	* @property {Number}  DeletedAt
	* @property {String}  UniqueID
	* @property {String}  AgentAuthID
	*
	*/
	DeleteEvent({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/event/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveEvent', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteEvent ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteEvent ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteEvent ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteEvent UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteEvent'))
			})
	},

	/**
	* FindEventsPaginated:
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/event
	* @param    {EventSearchRequest} req The API payload object (EventSearchRequest)
	* @return   {EventPageResponse} The API response object (EventPageResponse)
	*
	* @typedef  {Object}  EventSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted
	* @property {Number}  SPID
	* @property {String}  CustomerUUID
	* @property {String}  AgentUUID
	* @property {Number}  TimeNanosFrom
	* @property {Number}  TimeNanosUntil
	* @property {ENUM}    EventType
	* @property {String}  SmartSearch
	* @property {String}  UUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  EventPageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Event[]} Events
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of products on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total products available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindEventsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/event`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveEvents', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindEventsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindEventsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindEventsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindEventsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindEventsPaginated'))
			})
	},

	/**
	* GetEventByUUID:
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/event/${req.UUID}
	* @param    {EventUUIDSearch} req The API payload object (EventUUIDSearch)
	* @return   {Event} The API response object (Event)
	*
	* @typedef  {Object}  EventUUIDSearch
	* @property {Number}  SPID
	* @property {String}  UUID
	*
	* @typedef  {Object}  Event
	* @property {String}  UUID            option (grpc.gateway.protoc_gen_swagger.options.openapiv2_operation) = {,              security: {};,         };
	* @property {Number}  SPID
	* @property {String}  CustomerUUID    The Customer UUID
	* @property {String}  CustomerName    The Customer name (usually "First Last")
	* @property {String}  AgentUUID
	* @property {String}  AgentName       The Agent name (usually "First Last")
	* @property {Number}  TimestampNanos  The UnixNano timestamp of when the Event was generated
	* @property {Number}  MessageCode
	* @property {String}  Message
	* @property {String}  SystemComponent The system component (service) that generated this event
	* @property {Boolean} ViewOperator    Can this event be viewed by an Operator user?
	* @property {Boolean} ViewAgent       Can this event be viewed by an Agent user?
	* @property {Boolean} ViewCustomer    Can this event be viewed by a Customer?
	* @property {String}  DebugPayload
	* @property {ENUM}    EventType
	* @property {Number}  DeletedAt
	* @property {String}  UniqueID
	* @property {String}  AgentAuthID
	*
	*/
	GetEventByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/event/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveEvent', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetEventByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetEventByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetEventByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetEventByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetEventByUUID'))
			})
	},

	/**
	* PurgeEvent:
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/event/${req.UUID}/purge
	* @param    {EventUUIDSearch} req The API payload object (EventUUIDSearch)
	* @return   {Event} The API response object (Event)
	*
	* @typedef  {Object}  EventUUIDSearch
	* @property {Number}  SPID
	* @property {String}  UUID
	*
	* @typedef  {Object}  Event
	* @property {String}  UUID            option (grpc.gateway.protoc_gen_swagger.options.openapiv2_operation) = {,              security: {};,         };
	* @property {Number}  SPID
	* @property {String}  CustomerUUID    The Customer UUID
	* @property {String}  CustomerName    The Customer name (usually "First Last")
	* @property {String}  AgentUUID
	* @property {String}  AgentName       The Agent name (usually "First Last")
	* @property {Number}  TimestampNanos  The UnixNano timestamp of when the Event was generated
	* @property {Number}  MessageCode
	* @property {String}  Message
	* @property {String}  SystemComponent The system component (service) that generated this event
	* @property {Boolean} ViewOperator    Can this event be viewed by an Operator user?
	* @property {Boolean} ViewAgent       Can this event be viewed by an Agent user?
	* @property {Boolean} ViewCustomer    Can this event be viewed by a Customer?
	* @property {String}  DebugPayload
	* @property {ENUM}    EventType
	* @property {Number}  DeletedAt
	* @property {String}  UniqueID
	* @property {String}  AgentAuthID
	*
	*/
	PurgeEvent({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v2/${req.SPID}/event/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveEvent', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeEvent ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeEvent ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeEvent ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeEvent UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeEvent'))
			})
	},

	/**
	* UpdateEvent:
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/event/${req.UUID}
	* @param    {Event} req The API payload object (Event)
	* @return   {Event} The API response object (Event)
	*
	* @typedef  {Object}  Event
	* @property {String}  UUID            option (grpc.gateway.protoc_gen_swagger.options.openapiv2_operation) = {,              security: {};,         };
	* @property {Number}  SPID
	* @property {String}  CustomerUUID    The Customer UUID
	* @property {String}  CustomerName    The Customer name (usually "First Last")
	* @property {String}  AgentUUID
	* @property {String}  AgentName       The Agent name (usually "First Last")
	* @property {Number}  TimestampNanos  The UnixNano timestamp of when the Event was generated
	* @property {Number}  MessageCode
	* @property {String}  Message
	* @property {String}  SystemComponent The system component (service) that generated this event
	* @property {Boolean} ViewOperator    Can this event be viewed by an Operator user?
	* @property {Boolean} ViewAgent       Can this event be viewed by an Agent user?
	* @property {Boolean} ViewCustomer    Can this event be viewed by a Customer?
	* @property {String}  DebugPayload
	* @property {ENUM}    EventType
	* @property {Number}  DeletedAt
	* @property {String}  UniqueID
	* @property {String}  AgentAuthID
	*
	*/
	UpdateEvent({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/event/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveEvent', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateEvent ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateEvent ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateEvent ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateEvent UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateEvent'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
