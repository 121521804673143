const EMAIL_TEMPLATE_IDS = [
  { TemplateID: -1, Name: 'Custom Template ID'               },
  { TemplateID:  1, Name: 'Payment Card Expiry'              },
  { TemplateID:  2, Name: 'Automatic Minimum Topup Failure'  },
  { TemplateID:  3, Name: 'Password Reset Request'           },
  { TemplateID:  4, Name: 'Account Overdue'                  },
  { TemplateID:  5, Name: 'Number Port Out Request'          },
  { TemplateID:  6, Name: 'Password Changed'                 },
  { TemplateID:  7, Name: 'Payment Card Changed'             },
  { TemplateID:  8, Name: 'Number Port In Request Accepted'  },
  { TemplateID:  9, Name: 'Number Port In Request Completed' },
  { TemplateID: 10, Name: 'Billing Card Payment Failed'      },
  { TemplateID: 11, Name: 'Topup Successful'                 },
  { TemplateID: 12, Name: 'Low Balance'                      },
  { TemplateID: 13, Name: 'Outof Balance'                    },
  { TemplateID: 14, Name: 'Card Requires Authentication'     },
  { TemplateID: 15, Name: 'Bill Due'                         },
]

const state = {}

const getters = {
    email_template_ids: () => EMAIL_TEMPLATE_IDS,
    email_template_id_names: () => EMAIL_TEMPLATE_IDS.reduce((names, template) => (names[template.TemplateID] = template.Name) && names, {}),
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}