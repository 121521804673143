<template>
     <div id="app" :class="{ 'is-touch-device': is_touch_device }">
        <router-view />

        <app-sidebar-modal :route="$root.sidebar_modal_route" v-if="$root.sidebar_modal_route" />

         <app-hot-reload
            @reload="reload"
            @close="handleClose"
         />

        <app-spinner
            v-if="isLoader"
            is-overload
            is-fixed
            is-body
        />
     </div>
</template>

<script>
import AppSpinner from '@/components/app-spinner'
import AppSidebarModal from '@/components/app-sidebar-modal'
import AppHotReload from "@/components/modals/hotReload"

import isTouchDevice from '@/helpers/isTouchDevice'

import {mapActions, mapGetters, mapState} from 'vuex'

import AppPageTitle from "@/components/app-page-title/index.vue"

export default {
    components: {
        AppPageTitle,
        AppSpinner,
        AppSidebarModal,
        AppHotReload,
    },
    computed: {
        ...mapState([
            'isLoader'
        ]),
        ...mapGetters([
            'usePoorRefreshExperience',
            'isPrereleaseMode',
            'authID',
            'email',
            'isSentryReporting',
        ]),
        is_touch_device() {
            return isTouchDevice()
        }
    },
    methods: {
        ...mapActions([
            'hotReload'
        ]),
        throwSentryErrorForPremode() {
            if (this.isSentryReporting && this.isPrereleaseMode) {
                throw new Error(`Using PREMODE: AuthID: ${this.authID}, Email: ${this.email}`)
            }
        },
        hotReloadChecking() {
            this.hotReload()
                .then(() => {
                    if (this.usePoorRefreshExperience) {
                        location.reload()
                    } else {
                        this.$bvModal.show('hot-reload')
                    }
                })
                .catch(() => {})
        },
        reload() {
            location.reload()
        },
        handleClose() {
            this.reload()
        }
    },
    mounted() {
        this.hotReloadChecking()
        this.throwSentryErrorForPremode()
    },
}
</script>

<style lang="scss">
#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}
</style>