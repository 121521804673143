// import { extend } from 'vee-validate'
//
// function email() {
//     extend("email", {
//         params: ['length'],
//         validate(value, { length }) {
//             const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i)
//             return value.length <= length && regex.test(value)
//         },
//     });
// }
//
// export default email

import { extend } from 'vee-validate'
function email() {
    let isLonger = false
    let length = 255
    extend("email", {
        params: ['max'],
        validate(value, { max }) {
            if (max && value.length > max) {
                length = max
                isLonger = true
                return false
            }

            const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i)
            return regex.test(value)
        },
        message: field => isLonger ? `The ${field} field may not be greater than ${length} characters` : `You should add a valid ${field} address`
    })
}
export default email