<template>
    <div class="app-back-to">
        <router-link :to="{ name, params: { page: pageRoute || page } }">
            <b-icon icon="chevron-left"></b-icon>
            Back to {{title}}
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        page: {
            type: Number | String,
            default: 1
        }
    },
    computed: {
        pageRoute() {
            return this.$route.params?.page
        }
    }
}
</script>

<style lang="scss">
.app-back-to {
    a {
        color: $color-text;
        font-weight: normal;
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;
        svg {
            margin-right: 6px;
            fill: $color;
        }
        &:hover {
            color: $color;
        }
    }
}
</style>