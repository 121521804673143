<template>
    <b-modal
        id="app-sidebar-modal"
        size="lg"
        scrollable
        centered
        hide-footer
        @hide="onHide"
        @shown="onShown"
        @hidden="onHidden"
        :body-class="[{ busy: isBusy }]"
        :header-class="[{ 'details-title': title }, { scrolled: scrollPosition > 0 }]"
        :modal-class="[[...modalClass], { lighten }]"
    >
        <template
            #modal-header="{ close }"
        >
            <div
                v-if="title"
                class="sidebar-modal__title text-truncate"
            >{{title}}</div>
            <div class="btn-bar">
                <button
                    v-if="isRouteToSinglePage"
                    class="btn btn-icon-bg btn-lg"
                    @click="routeToSinglePage"
                >
                    <inline-svg :src="require('./images/external.svg')"/>
                </button>

                <button type="button" aria-label="Close" class="close" @click="close()">
                    <inline-svg :src="require('./images/close.svg')" width="24" height="24"/>
                </button>
            </div>
        </template>

        <div id="modal-body" class="modal-body__wrap">
            <keep-alive>
                <component
                    :is="component"
                    :params="route.params"
                    :parent="parent"
                    @loading="loading"
                    @scrollTo="scrollTo"
                />
            </keep-alive>
        </div>

        <app-spinner
            v-if="isBusy"
            is-overload
        />
    </b-modal>
</template>

<script>
import InlineSvg from "vue-inline-svg"
import AppIconBtn from "@/components/app-icon-btn"
import AppSpinner from '@/components/app-spinner'

const routeByName = (name, routes) => {
    let route = null
    let childrens = null
    const routes_count = routes.length

    if (name && routes_count) {
        for (let i = 0; i < routes_count; i++) {
            if (routes[i]?.name == name) {
                route = routes[i]
                break
            }
        }

        if (!route) {
            childrens = []

            for (let i = 0; i < routes_count; i++) {
                if ('children' in routes[i]) {
                    for (let n = 0, len = routes[i].children.length; n < len; n++) {
                        childrens.push(routes[i].children[n])
                    }
                }
            }
        }
    }

    return route
        ? route
        : childrens
            ? routeByName(name, childrens)
            : null
}

const history = []

export default {
    data() {
        return {
            isBusy: false,
            scrollPosition: 0,
        }
    },
    props: {
        route: {
            type: Object,
            required: true
        },
    },
    components: {
        AppSpinner,
        InlineSvg,
        AppIconBtn
    },
    computed: {
        _route() {
            return routeByName(this.route.name, this.$router.options.routes)
        },
        component() {
            /**
             * @todo сделать проверку на beforeEnter если такие появятся
             */
            this.isBusy = false
            return this._route?.component
        },
        $modal() {
            return this.route.$modal
        },
        title() {
            return this.$modal?.title
        },
        modalClass() {
            return this.$modal?.modalClass
        },
        isRouteToSinglePage() {
            return this.$modal?.isRouteToSinglePage
        },
        lighten() {
            return this.$modal?.lighten
        },
        parent() {
            return this.route?.$parent || {}
        }
    },
    methods: {
        onHide($event) {
            if (history.length) {
                $event.preventDefault()

                const route = history.pop()
                      route.$history = false

                this.$root.pushSidebarModal(route)
            }
        },
        onHidden() {
            this.resetScrollPosition()
        },
        onShown() {
            const el = document.getElementById('modal-body')
            el.addEventListener('scroll', this.handleScroll)
        },
        loading(isBusy) {
            this.isBusy = isBusy
        },
        scrollTo(name) {
            const el = document.getElementById(name)
            if (el) {
                setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' })
                }, 0)
            }
        },
        handleScroll(e) {
            this.scrollPosition = e.currentTarget.scrollTop
        },
        resetScrollPosition() {
            this.$nextTick(() => {
                this.scrollPosition = 0
            })
        },
        removeEventScroll() {
            const el = document.getElementById('modal-body')
            if (el) {
                el.removeEventListener('scroll', this.handleScroll)
            }
        },
        routeToSinglePage() {
            history.length = 0
            this.$router.push({
                name: this.route.name,
                params: this.route.params
            })
        }
    },
    watch: {
        $route() {
            history.length = 0
            this.$bvModal.hide('app-sidebar-modal')
        },
        route(new_route, old_route) {
            if ('$history' in new_route) {
                if (new_route.$history) {
                    history.push(old_route)
                }
            } else {
                history.length = 0
            }

            const el = document.getElementById('modal-body')
            if (el) {
                el.scrollTo(0, 0)
            }
        },
    },
    beforeDestroy() {
        this.removeEventScroll()
    },
}
</script>

<style lang="scss">
#app-sidebar-modal {
    &.bg-w, &.lighten {
        & > .modal-dialog {
            .modal-content {
                background: #fff;
            }
        }
    }
    & > .modal-dialog {
        align-items: stretch;
        justify-content: flex-end;
        margin: 25px 25px 25px auto;
        min-height: calc(100% - 50px);

        .modal-content {
            flex-grow: 1;
            background: $color-details;

            .modal-header {
                position: relative;
                z-index: 5;
                transition: all 0.2s linear;
                width: 100%;
                align-items: center;
                &.scrolled {
                    box-shadow: $box-shadow-bright;
                }

                &.details-title {
                    padding: 20px 20px 20px 20px;
                    .sidebar-modal__title {
                        font-size: 40px;
                        font-weight: 600;
                        padding-right: 10px;
                        width: 100%;
                    }
                }
                .btn-bar {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    .btn {
                        margin: 0 15px 0 0;
                    }
                }

                .close {
                    color: $color-text2;
                    opacity: 1;
                    padding: 0;
                    margin: 0;
                    font-size: 0;
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
            
            .modal-body {
                overflow: hidden;
                padding: 0;
                display: flex;
                flex-direction: column;

                &__wrap {
                    padding: 15px 20px 0;
                    overflow-y: auto;
                    height: 100%;
                }

                &.busy {
                    overflow: hidden;
                    position: static;
                }
            }
        }
    }
}

@media screen and (max-width: $grid-breakpoint-lg) {
    #app-sidebar-modal {
        & > .modal-dialog {
            max-width: 100%;
            margin: 20px;
            min-height: calc(100% - 40px);
        }
    }
}

@media screen and (max-width: $grid-breakpoint-md) {
    #app-sidebar-modal {
        padding: 0 !important;

        & > .modal-dialog {
            margin: 0;
            min-height: 100%;
            max-height: 100%;

            .modal-content {
                border-radius: 0;

                .modal-header {
                    &.details-title {
                        padding: 15px;

                        .sidebar-modal__title {
                            font-size: 32px;
                        }
                    }
                }

                .modal-body {
                    &__wrap {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
</style>