import { extend } from 'vee-validate';

function name() {
    extend("name", {
        validate(value) {
            // const regex = new RegExp("^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\\ |\\'|\\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$");
            const regex = new RegExp("^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪ ]+((\\'|\\-)?[a-zA-ZāēīōūĀĒĪŌŪ ])*$");
            return regex.test(value);
        },
        message: field => `Please, enter ${field}. It must contain from 1 to 16 characters`,
    });
}

export default name