import Vue from 'vue'
import Vuex from 'vuex'

import modulesLoader from './modules'

Vue.use(Vuex)

const SPID = process.env.VUE_APP_SPID
const THEME = process.env.VUE_APP_THEME ? process.env.VUE_APP_THEME : 'vodafone'
const PROVIDER_KEY = process.env.VUE_APP_PROVIDER_KEY
const PRE_RELEASE_MODE = (localStorage.getItem('UkLTq') == '1')
const CUSTOMER_FACING_BRAND_NAME = process.env.VUE_APP_CUSTOMER_FACING_BRAND_NAME || ''
const LEGAL_COMPANY_ENTITY_NAME = process.env.VUE_APP_LEGAL_COMPANY_ENTITY_NAME || ''
const BANK_ACCOUNT_NUMBER = process.env.VUE_APP_BANK_ACCOUNT_NUMBER || ''
const BANK_ACCOUNT_NAME = process.env.VUE_APP_BANK_ACCOUNT_NAME || ''
const API_REQUEST_TIMEOUT = process.env.VUE_APP_API_REQUEST_TIMEOUT ? process.env.VUE_APP_API_REQUEST_TIMEOUT : 65

const modules = modulesLoader()

export default new Vuex.Store({
    modules,

    state: {
        isLoader: false
    },

    getters: {
        spid: () => parseInt(SPID),
        theme: () => THEME,
        providerKey: () => PROVIDER_KEY,
        host: () => `${window.location.protocol}//${window.location.host}`,
        isIQEmail: (state, getters) => getters.email.includes('@iqhive.com'),
        isPrereleaseMode: (state, getters) => PRE_RELEASE_MODE && getters.isIQEmail,
        customerFacingBrandName: () => CUSTOMER_FACING_BRAND_NAME,
        legalCompanyEntityName: () => LEGAL_COMPANY_ENTITY_NAME,
        bankAccountNumber: () => BANK_ACCOUNT_NUMBER,
        bankAccountName: () => BANK_ACCOUNT_NAME,
        apiRequestTimeout: () => API_REQUEST_TIMEOUT * 1000,
    },

    mutations: {
        setLoader(state, loader){
            state.isLoader = loader
        }
    },
    actions: {}
})
