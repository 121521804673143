// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: notification.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Notifications: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	NotificationResponses: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	NotificationTemplates: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getNotificationByUUID: state => (UUID) => (state.Notifications.UUIDs[ UUID ] && !state.Notifications.UUIDs[ UUID ].expired()) ?  state.Notifications.UUIDs[ UUID ].hashedData() : null,
	getNotificationResponseByUUID: state => (UUID) => (state.NotificationResponses.UUIDs[ UUID ] && !state.NotificationResponses.UUIDs[ UUID ].expired()) ?  state.NotificationResponses.UUIDs[ UUID ].hashedData() : null,
	getNotificationTemplateByUUID: state => (UUID) => (state.NotificationTemplates.UUIDs[ UUID ] && !state.NotificationTemplates.UUIDs[ UUID ].expired()) ?  state.NotificationTemplates.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveNotification saves a single Notification object
	// into both the StoreHash cache and the VueX store
	mutSaveNotification(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Notifications.UUIDs)) {
			state.Notifications.UUIDs[ obj.UUID ] = new StoreHash(state.Notifications.lifetime)
		}
		state.Notifications.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNotificationByUUID removes a Notification object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNotificationByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Notifications.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNotifications saves a paginated response of Notifications
	mutSaveNotifications(state, { Response }) {
		if (!Response || !Array.isArray(Response.Notifications)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Notifications.length; i++) {
			if (!(Response.Notifications[i].UUID in state.Notifications.UUIDs)) {
				state.Notifications.UUIDs[ Response.Notifications[i].UUID ] = new StoreHash(state.Notifications.lifetime)
			}
			state.Notifications.UUIDs[ Response.Notifications[i].UUID ].fix(Response.Notifications[i])  // Add single Notification to StoreHash
		}
	},
	// mutSaveNotificationResponse saves a single NotificationResponse object
	// into both the StoreHash cache and the VueX store
	mutSaveNotificationResponse(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.NotificationResponses.UUIDs)) {
			state.NotificationResponses.UUIDs[ obj.UUID ] = new StoreHash(state.NotificationResponses.lifetime)
		}
		state.NotificationResponses.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNotificationResponseByUUID removes a NotificationResponse object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNotificationResponseByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.NotificationResponses.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNotificationResponses saves a paginated response of NotificationResponses
	mutSaveNotificationResponses(state, { Response }) {
		if (!Response || !Array.isArray(Response.NotificationResponses)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.NotificationResponses.length; i++) {
			if (!(Response.NotificationResponses[i].UUID in state.NotificationResponses.UUIDs)) {
				state.NotificationResponses.UUIDs[ Response.NotificationResponses[i].UUID ] = new StoreHash(state.NotificationResponses.lifetime)
			}
			state.NotificationResponses.UUIDs[ Response.NotificationResponses[i].UUID ].fix(Response.NotificationResponses[i])  // Add single NotificationResponse to StoreHash
		}
	},
	// mutSaveNotificationTemplate saves a single NotificationTemplate object
	// into both the StoreHash cache and the VueX store
	mutSaveNotificationTemplate(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.NotificationTemplates.UUIDs)) {
			state.NotificationTemplates.UUIDs[ obj.UUID ] = new StoreHash(state.NotificationTemplates.lifetime)
		}
		state.NotificationTemplates.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNotificationTemplateByUUID removes a NotificationTemplate object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNotificationTemplateByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.NotificationTemplates.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNotificationTemplates saves a paginated response of NotificationTemplates
	mutSaveNotificationTemplates(state, { Response }) {
		if (!Response || !Array.isArray(Response.NotificationTemplates)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.NotificationTemplates.length; i++) {
			if (!(Response.NotificationTemplates[i].UUID in state.NotificationTemplates.UUIDs)) {
				state.NotificationTemplates.UUIDs[ Response.NotificationTemplates[i].UUID ] = new StoreHash(state.NotificationTemplates.lifetime)
			}
			state.NotificationTemplates.UUIDs[ Response.NotificationTemplates[i].UUID ].fix(Response.NotificationTemplates[i])  // Add single NotificationTemplate to StoreHash
		}
	},
};

const actions = {
	// getCachedNotificationByUUID fetches a Notification from the cache
	// and if not in the cache, fetches it from the API
	getCachedNotificationByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Notifications.UUIDs[ UUID ] && !state.Notifications.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Notifications.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNotificationByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNotification', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNotificationByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedNotificationResponseByUUID fetches a NotificationResponse from the cache
	// and if not in the cache, fetches it from the API
	getCachedNotificationResponseByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.NotificationResponses.UUIDs[ UUID ] && !state.NotificationResponses.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.NotificationResponses.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNotificationResponseByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNotificationResponse', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNotificationResponseByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedNotificationTemplateByUUID fetches a NotificationTemplate from the cache
	// and if not in the cache, fetches it from the API
	getCachedNotificationTemplateByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.NotificationTemplates.UUIDs[ UUID ] && !state.NotificationTemplates.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.NotificationTemplates.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNotificationTemplateByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNotificationTemplate', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNotificationTemplateByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: NotificationRPC
	// 
	/**
	* AddNotificationTemplate: Add a new NotificationTemplate record
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/notification_template
	* @param    {NotificationTemplate} req The API payload object (NotificationTemplate)
	* @return   {NotificationTemplate} The API response object (NotificationTemplate)
	*
	* @typedef  {Object}  NotificationTemplate
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  Description         Description of the Template
	* @property {Number}  TemplateID          TemplateIDs < 1000 are reserved, and should not be used for custom templates
	* @property {Boolean} Active              Is this template active?
	* @property {String}  TemplateEmailHTML   TemplateEmail contains the content of the email template in HTML format
	* @property {String}  TemplateEmailMJML   option (google.api.http) = {,      get: "/api/v2/{SPID}/notifications/{UUID}",  };,  option (google.api.http) = {,      post: "/api/v2/{SPID}/discount/{UUID}",      body: "*",  };
	* @property {String}  TemplateSMS         TemplateSMS contains the body of the SMS message template
	* @property {String}  SourceEmail         SourceEmail specified which email address to send the notification from
	* @property {String}  SourceEmailSubject  SourceEmailSubject specifies the subject of the email (max length 78, recommended < 20)
	* @property {String}  SourceEmailAlias    SourceEmail specifies an email address to also allow replies to
	* @property {String}  SourceEmailName     SourceEmailName is the name on the email
	* @property {String}  SourceSMS           SourceSMS specifies the source phone number to send the SMS from
	* @property {String}  SourceSMSName       SourceSMSName specifies the source name to send the SMS from (not supported for replies)
	* @property {StringSlice} WebhookHeaderKeys   WebhookHeaderKeys sets HTTP Header keys, in CSV format
	* @property {StringSlice} WebhookHeaderValues WebhookHeaderValues sets a HTTP Header values for the keys, in CSV format
	* @property {String}  WebhookURL          WebhookURL is the URL used for making a webhook call
	* @property {Boolean} WebhookMethodGET    WebhookMethodGET signals that the Webhook will be a HTTP(S) GET call (POST is the default)
	* @property {Number}  CreatedAtNanos      When the record was first created
	* @property {Number}  UpdatedAtNanos      When the record was last updated
	* @property {Number}  DeletedAtNanos      When the record was deleted
	* @property {ENUM}    NotificationType    
	*
	*/
	AddNotificationTemplate({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/notification_template`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddNotificationTemplate ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddNotificationTemplate ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddNotificationTemplate ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddNotificationTemplate UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddNotificationTemplate'))
			})
	},

	/**
	* DeleteNotificationTemplateByUUID: delete customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/notification_template/${req.UUID}
	* @param    {NotificationTemplateUUIDSearch} req The API payload object (NotificationTemplateUUIDSearch)
	* @return   {NotificationTemplate} The API response object (NotificationTemplate)
	*
	* @typedef  {Object}  NotificationTemplateUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  NotificationTemplate
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  Description         Description of the Template
	* @property {Number}  TemplateID          TemplateIDs < 1000 are reserved, and should not be used for custom templates
	* @property {Boolean} Active              Is this template active?
	* @property {String}  TemplateEmailHTML   TemplateEmail contains the content of the email template in HTML format
	* @property {String}  TemplateEmailMJML   option (google.api.http) = {,      get: "/api/v2/{SPID}/notifications/{UUID}",  };,  option (google.api.http) = {,      post: "/api/v2/{SPID}/discount/{UUID}",      body: "*",  };
	* @property {String}  TemplateSMS         TemplateSMS contains the body of the SMS message template
	* @property {String}  SourceEmail         SourceEmail specified which email address to send the notification from
	* @property {String}  SourceEmailSubject  SourceEmailSubject specifies the subject of the email (max length 78, recommended < 20)
	* @property {String}  SourceEmailAlias    SourceEmail specifies an email address to also allow replies to
	* @property {String}  SourceEmailName     SourceEmailName is the name on the email
	* @property {String}  SourceSMS           SourceSMS specifies the source phone number to send the SMS from
	* @property {String}  SourceSMSName       SourceSMSName specifies the source name to send the SMS from (not supported for replies)
	* @property {StringSlice} WebhookHeaderKeys   WebhookHeaderKeys sets HTTP Header keys, in CSV format
	* @property {StringSlice} WebhookHeaderValues WebhookHeaderValues sets a HTTP Header values for the keys, in CSV format
	* @property {String}  WebhookURL          WebhookURL is the URL used for making a webhook call
	* @property {Boolean} WebhookMethodGET    WebhookMethodGET signals that the Webhook will be a HTTP(S) GET call (POST is the default)
	* @property {Number}  CreatedAtNanos      When the record was first created
	* @property {Number}  UpdatedAtNanos      When the record was last updated
	* @property {Number}  DeletedAtNanos      When the record was deleted
	* @property {ENUM}    NotificationType    
	*
	*/
	DeleteNotificationTemplateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/notification_template/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNotificationTemplate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteNotificationTemplateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteNotificationTemplateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteNotificationTemplateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteNotificationTemplateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteNotificationTemplateByUUID'))
			})
	},

	/**
	* FindNotificationTemplatesPaginated: Search for NotificationTemplates by given fields
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_template
	* @param    {NotificationTemplateSearchRequest} req The API payload object (NotificationTemplateSearchRequest)
	* @return   {NotificationTemplatePageResponse} The API response object (NotificationTemplatePageResponse)
	*
	* @typedef  {Object}  NotificationTemplateSearchRequest
	* @property {SearchOptions[]} SearchOptions    
	* @property {String}  UUID             
	* @property {Number}  SPID             
	* @property {String}  Description      
	* @property {Number}  TemplateID       
	* @property {Boolean} IncludeInactive  
	* @property {Boolean} IncludeTemplates 
	* @property {Boolean} IncludeDeleted   
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  NotificationTemplatePageResponse
	* @property {PageInfo} PageInfo              PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {NotificationTemplate[]} NotificationTemplates 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindNotificationTemplatesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/notification_template`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveNotificationTemplates', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindNotificationTemplatesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindNotificationTemplatesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindNotificationTemplatesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindNotificationTemplatesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindNotificationTemplatesPaginated'))
			})
	},

	/**
	* GetNotificationByUUID: Get a Notification Request using the unique NotificationResponse UUID
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_request/${req.UUID}
	* @param    {NotificationUUIDSearch} req The API payload object (NotificationUUIDSearch)
	* @return   {Notification} The API response object (Notification)
	*
	* @typedef  {Object}  NotificationUUIDSearch
	* @property {String}  UUID         
	* @property {Number}  SPID         
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  Notification
	* @property {String}  UUID                       
	* @property {Number}  SPID                       Service Provider ID
	* @property {String}  CustomerUUID               Customer UUID (optional)
	* @property {ENUM}    NotificationMethod         NotificationMethod [ TXT | EMAIL ]
	* @property {Number}  TemplateID                 TemplateID determines which template number to send (or which WebhookGroupNumber to use)
	* @property {String}  Source                     Source (address/number/etc) of this Notification (eg: The textual format of the short code)
	* @property {String}  Destination                Destination (email address/mobile number/etc) for this Notification. (Will use customer information if this is not provided)
	* @property {String}  Language                   
	* @property {String}  SendingSystem              Which system is sending this notification
	* @property {String}  SendingReference           System reference for this notification
	* @property {Number}  RequestedAtNanos           When was this notification requested
	* @property {Number}  SentAtNanos                When was this notification sent
	* @property {Number}  LastErrorNanos             When did this notification last encounter and error
	* @property {String}  LastErrorMessage           The last error message this notification encountered
	* @property {String}  CustomData1                
	* @property {String}  CustomData2                
	* @property {String}  CustomData3                
	* @property {String}  CustomData4                
	* @property {String}  CustomData5                
	* @property {ENUM}    ResponseNotificationMethod 
	* @property {String}  ResponseDestination        
	* @property {Number}  ResponseTemplateID         
	* @property {Number}  RepliesValidUntilNanos     When are responses to this notification valid until (0 = never)
	* @property {String}  SourceAlias                The aliased source address/number of this reply (eg: The actual short code)
	* @property {String}  ReplyToUUID                If this is a reply, the UUID of the original message
	* @property {Number}  ReplyCount                 Number of replies to this notification
	* @property {String}  DestinationName            Destination name (Will use customer name if this is not provided)
	* @property {ENUM}    NotificationType           
	*
	*/
	GetNotificationByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/notification_request/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNotification', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNotificationByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNotificationByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNotificationByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNotificationByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNotificationByUUID'))
			})
	},

	/**
	* GetNotificationResponseByUUID: Get a Notification Response using the unique NotificationResponse UUID
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_response/${req.UUID}
	* @param    {NotificationResponseUUIDSearch} req The API payload object (NotificationResponseUUIDSearch)
	* @return   {NotificationResponse} The API response object (NotificationResponse)
	*
	* @typedef  {Object}  NotificationResponseUUIDSearch
	* @property {String}  UUID         
	* @property {Number}  SPID         
	* @property {String}  CustomerUUID 
	*
	* @typedef  {Object}  NotificationResponse
	* @property {String}  UUID               
	* @property {String}  CustomerUUID       
	* @property {ENUM}    NotificationMethod 
	* @property {String}  Destination        
	* @property {Number}  TemplateID         
	* @property {String}  Language           
	* @property {String}  SendingSystem      
	* @property {String}  SendingReference   
	* @property {Number}  TimeRequested      
	* @property {Number}  TimeSent           
	* @property {Number}  TimeLastError      
	* @property {String}  LastErrorMessage   
	* @property {String}  Message            
	* @property {Number}  SPID               
	*
	*/
	GetNotificationResponseByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/notification_response/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNotificationResponse', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNotificationResponseByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNotificationResponseByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNotificationResponseByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNotificationResponseByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNotificationResponseByUUID'))
			})
	},

	/**
	* GetNotificationResponsesPaginated: Get a Notification Responses using search request
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_response
	* @param    {NotificationResponseSearchRequest} req The API payload object (NotificationResponseSearchRequest)
	* @return   {NotificationResponsePageResponse} The API response object (NotificationResponsePageResponse)
	*
	* @typedef  {Object}  NotificationResponseSearchRequest
	* @property {String}  UUID           
	* @property {String}  CustomerUUID   
	* @property {Boolean} AllowFetchAll  
	* @property {Number}  SPID           
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted 
	* @property {String}  SmartSearch    
	* @property {Number}  TimeNanosFrom  
	* @property {Number}  TimeNanosUntil 
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  NotificationResponsePageResponse
	* @property {PageInfo} PageInfo              PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {NotificationResponse[]} NotificationResponses 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetNotificationResponsesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/notification_response`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveNotificationResponses', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNotificationResponsesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNotificationResponsesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNotificationResponsesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNotificationResponsesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNotificationResponsesPaginated'))
			})
	},

	/**
	* GetNotificationTemplateByUUID: Get a NotificationTemplate using the unique NotificationTemplateUUID
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_template/${req.UUID}
	* @param    {NotificationTemplateUUIDSearch} req The API payload object (NotificationTemplateUUIDSearch)
	* @return   {NotificationTemplate} The API response object (NotificationTemplate)
	*
	* @typedef  {Object}  NotificationTemplateUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  NotificationTemplate
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  Description         Description of the Template
	* @property {Number}  TemplateID          TemplateIDs < 1000 are reserved, and should not be used for custom templates
	* @property {Boolean} Active              Is this template active?
	* @property {String}  TemplateEmailHTML   TemplateEmail contains the content of the email template in HTML format
	* @property {String}  TemplateEmailMJML   option (google.api.http) = {,      get: "/api/v2/{SPID}/notifications/{UUID}",  };,  option (google.api.http) = {,      post: "/api/v2/{SPID}/discount/{UUID}",      body: "*",  };
	* @property {String}  TemplateSMS         TemplateSMS contains the body of the SMS message template
	* @property {String}  SourceEmail         SourceEmail specified which email address to send the notification from
	* @property {String}  SourceEmailSubject  SourceEmailSubject specifies the subject of the email (max length 78, recommended < 20)
	* @property {String}  SourceEmailAlias    SourceEmail specifies an email address to also allow replies to
	* @property {String}  SourceEmailName     SourceEmailName is the name on the email
	* @property {String}  SourceSMS           SourceSMS specifies the source phone number to send the SMS from
	* @property {String}  SourceSMSName       SourceSMSName specifies the source name to send the SMS from (not supported for replies)
	* @property {StringSlice} WebhookHeaderKeys   WebhookHeaderKeys sets HTTP Header keys, in CSV format
	* @property {StringSlice} WebhookHeaderValues WebhookHeaderValues sets a HTTP Header values for the keys, in CSV format
	* @property {String}  WebhookURL          WebhookURL is the URL used for making a webhook call
	* @property {Boolean} WebhookMethodGET    WebhookMethodGET signals that the Webhook will be a HTTP(S) GET call (POST is the default)
	* @property {Number}  CreatedAtNanos      When the record was first created
	* @property {Number}  UpdatedAtNanos      When the record was last updated
	* @property {Number}  DeletedAtNanos      When the record was deleted
	* @property {ENUM}    NotificationType    
	*
	*/
	GetNotificationTemplateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/notification_template/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNotificationTemplate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNotificationTemplateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNotificationTemplateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNotificationTemplateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNotificationTemplateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNotificationTemplateByUUID'))
			})
	},

	/**
	* GetNotificationTemplatesUUIDs: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_template_uuids
	* @param    {NotificationTemplateSearchRequest} req The API payload object (NotificationTemplateSearchRequest)
	* @return   {tktcommonproto.UUIDsResponse} The API response object (tktcommonproto.UUIDsResponse)
	*
	* @typedef  {Object}  NotificationTemplateSearchRequest
	* @property {SearchOptions[]} SearchOptions    
	* @property {String}  UUID             
	* @property {Number}  SPID             
	* @property {String}  Description      
	* @property {Number}  TemplateID       
	* @property {Boolean} IncludeInactive  
	* @property {Boolean} IncludeTemplates 
	* @property {Boolean} IncludeDeleted   
	*
	* @typedef  {Object}  tktcommonproto.UUIDsResponse
	*
	*/
	GetNotificationTemplatesUUIDs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: FindUUIDs)
		return axios.get(`/v2/${req.SPID}/notification_template_uuids`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNotificationTemplatesUUIDs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNotificationTemplatesUUIDs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNotificationTemplatesUUIDs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNotificationTemplatesUUIDs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNotificationTemplatesUUIDs'))
			})
	},

	/**
	* GetNotificationsPaginated: Get a Notification Requests using search request
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/notification_request
	* @param    {NotificationSearchRequest} req The API payload object (NotificationSearchRequest)
	* @return   {NotificationPageResponse} The API response object (NotificationPageResponse)
	*
	* @typedef  {Object}  NotificationSearchRequest
	* @property {String}  UUID           
	* @property {String}  CustomerUUID   
	* @property {Boolean} AllowFetchAll  
	* @property {Number}  SPID           
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted 
	* @property {String}  SmartSearch    
	* @property {Number}  TimeNanosFrom  
	* @property {Number}  TimeNanosUntil 
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  NotificationPageResponse
	* @property {PageInfo} PageInfo      PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Notification[]} Notifications 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetNotificationsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/notification_request`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveNotifications', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNotificationsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNotificationsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNotificationsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNotificationsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNotificationsPaginated'))
			})
	},

	/**
	* PurgeNotificationTemplateByUUID: Purge customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/notification_template/${req.UUID}/purge
	* @param    {NotificationTemplateUUIDSearch} req The API payload object (NotificationTemplateUUIDSearch)
	* @return   {NotificationTemplate} The API response object (NotificationTemplate)
	*
	* @typedef  {Object}  NotificationTemplateUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID 
	*
	* @typedef  {Object}  NotificationTemplate
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  Description         Description of the Template
	* @property {Number}  TemplateID          TemplateIDs < 1000 are reserved, and should not be used for custom templates
	* @property {Boolean} Active              Is this template active?
	* @property {String}  TemplateEmailHTML   TemplateEmail contains the content of the email template in HTML format
	* @property {String}  TemplateEmailMJML   option (google.api.http) = {,      get: "/api/v2/{SPID}/notifications/{UUID}",  };,  option (google.api.http) = {,      post: "/api/v2/{SPID}/discount/{UUID}",      body: "*",  };
	* @property {String}  TemplateSMS         TemplateSMS contains the body of the SMS message template
	* @property {String}  SourceEmail         SourceEmail specified which email address to send the notification from
	* @property {String}  SourceEmailSubject  SourceEmailSubject specifies the subject of the email (max length 78, recommended < 20)
	* @property {String}  SourceEmailAlias    SourceEmail specifies an email address to also allow replies to
	* @property {String}  SourceEmailName     SourceEmailName is the name on the email
	* @property {String}  SourceSMS           SourceSMS specifies the source phone number to send the SMS from
	* @property {String}  SourceSMSName       SourceSMSName specifies the source name to send the SMS from (not supported for replies)
	* @property {StringSlice} WebhookHeaderKeys   WebhookHeaderKeys sets HTTP Header keys, in CSV format
	* @property {StringSlice} WebhookHeaderValues WebhookHeaderValues sets a HTTP Header values for the keys, in CSV format
	* @property {String}  WebhookURL          WebhookURL is the URL used for making a webhook call
	* @property {Boolean} WebhookMethodGET    WebhookMethodGET signals that the Webhook will be a HTTP(S) GET call (POST is the default)
	* @property {Number}  CreatedAtNanos      When the record was first created
	* @property {Number}  UpdatedAtNanos      When the record was last updated
	* @property {Number}  DeletedAtNanos      When the record was deleted
	* @property {ENUM}    NotificationType    
	*
	*/
	PurgeNotificationTemplateByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v2/${req.SPID}/notification_template/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNotificationTemplate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeNotificationTemplateByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeNotificationTemplateByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeNotificationTemplateByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeNotificationTemplateByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeNotificationTemplateByUUID'))
			})
	},

	/**
	* SendNotification: Get a Notification Responses using search request
	* HTTP Method: POST
	* API Path: /api/v1/send_customer_notification
	* @param    {Notification} req The API payload object (Notification)
	* @return   {NotificationResponse} The API response object (NotificationResponse)
	*
	* @typedef  {Object}  Notification
	* @property {String}  UUID                       
	* @property {Number}  SPID                       Service Provider ID
	* @property {String}  CustomerUUID               Customer UUID (optional)
	* @property {ENUM}    NotificationMethod         NotificationMethod [ TXT | EMAIL ]
	* @property {Number}  TemplateID                 TemplateID determines which template number to send (or which WebhookGroupNumber to use)
	* @property {String}  Source                     Source (address/number/etc) of this Notification (eg: The textual format of the short code)
	* @property {String}  Destination                Destination (email address/mobile number/etc) for this Notification. (Will use customer information if this is not provided)
	* @property {String}  Language                   
	* @property {String}  SendingSystem              Which system is sending this notification
	* @property {String}  SendingReference           System reference for this notification
	* @property {Number}  RequestedAtNanos           When was this notification requested
	* @property {Number}  SentAtNanos                When was this notification sent
	* @property {Number}  LastErrorNanos             When did this notification last encounter and error
	* @property {String}  LastErrorMessage           The last error message this notification encountered
	* @property {String}  CustomData1                
	* @property {String}  CustomData2                
	* @property {String}  CustomData3                
	* @property {String}  CustomData4                
	* @property {String}  CustomData5                
	* @property {ENUM}    ResponseNotificationMethod 
	* @property {String}  ResponseDestination        
	* @property {Number}  ResponseTemplateID         
	* @property {Number}  RepliesValidUntilNanos     When are responses to this notification valid until (0 = never)
	* @property {String}  SourceAlias                The aliased source address/number of this reply (eg: The actual short code)
	* @property {String}  ReplyToUUID                If this is a reply, the UUID of the original message
	* @property {Number}  ReplyCount                 Number of replies to this notification
	* @property {String}  DestinationName            Destination name (Will use customer name if this is not provided)
	* @property {ENUM}    NotificationType           
	*
	* @typedef  {Object}  NotificationResponse
	* @property {String}  UUID               
	* @property {String}  CustomerUUID       
	* @property {ENUM}    NotificationMethod 
	* @property {String}  Destination        
	* @property {Number}  TemplateID         
	* @property {String}  Language           
	* @property {String}  SendingSystem      
	* @property {String}  SendingReference   
	* @property {Number}  TimeRequested      
	* @property {Number}  TimeSent           
	* @property {Number}  TimeLastError      
	* @property {String}  LastErrorMessage   
	* @property {String}  Message            
	* @property {Number}  SPID               
	*
	*/
	SendNotification({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/send_customer_notification`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SendNotification ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SendNotification ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SendNotification ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SendNotification UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SendNotification'))
			})
	},

	/**
	* UpdateNotificationTemplate: Update a NotificationTemplate record
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/notification_template/${req.UUID}
	* @param    {NotificationTemplate} req The API payload object (NotificationTemplate)
	* @return   {NotificationTemplate} The API response object (NotificationTemplate)
	*
	* @typedef  {Object}  NotificationTemplate
	* @property {String}  UUID                
	* @property {Number}  SPID                Service Provider ID
	* @property {String}  Description         Description of the Template
	* @property {Number}  TemplateID          TemplateIDs < 1000 are reserved, and should not be used for custom templates
	* @property {Boolean} Active              Is this template active?
	* @property {String}  TemplateEmailHTML   TemplateEmail contains the content of the email template in HTML format
	* @property {String}  TemplateEmailMJML   option (google.api.http) = {,      get: "/api/v2/{SPID}/notifications/{UUID}",  };,  option (google.api.http) = {,      post: "/api/v2/{SPID}/discount/{UUID}",      body: "*",  };
	* @property {String}  TemplateSMS         TemplateSMS contains the body of the SMS message template
	* @property {String}  SourceEmail         SourceEmail specified which email address to send the notification from
	* @property {String}  SourceEmailSubject  SourceEmailSubject specifies the subject of the email (max length 78, recommended < 20)
	* @property {String}  SourceEmailAlias    SourceEmail specifies an email address to also allow replies to
	* @property {String}  SourceEmailName     SourceEmailName is the name on the email
	* @property {String}  SourceSMS           SourceSMS specifies the source phone number to send the SMS from
	* @property {String}  SourceSMSName       SourceSMSName specifies the source name to send the SMS from (not supported for replies)
	* @property {StringSlice} WebhookHeaderKeys   WebhookHeaderKeys sets HTTP Header keys, in CSV format
	* @property {StringSlice} WebhookHeaderValues WebhookHeaderValues sets a HTTP Header values for the keys, in CSV format
	* @property {String}  WebhookURL          WebhookURL is the URL used for making a webhook call
	* @property {Boolean} WebhookMethodGET    WebhookMethodGET signals that the Webhook will be a HTTP(S) GET call (POST is the default)
	* @property {Number}  CreatedAtNanos      When the record was first created
	* @property {Number}  UpdatedAtNanos      When the record was last updated
	* @property {Number}  DeletedAtNanos      When the record was deleted
	* @property {ENUM}    NotificationType    
	*
	*/
	UpdateNotificationTemplate({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/notification_template/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNotificationTemplate', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateNotificationTemplate ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateNotificationTemplate ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateNotificationTemplate ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateNotificationTemplate UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateNotificationTemplate'))
			})
	},

	/**
	* VerifyNumber: 
	* HTTP Method: POST
	* API Path: /api/v1/verify_number
	* @param    {VerifyRequest} req The API payload object (VerifyRequest)
	* @return   {VerifyResponse} The API response object (VerifyResponse)
	*
	* @typedef  {Object}  VerifyRequest
	* @property {String}  MobileNumber 
	*
	* @typedef  {Object}  VerifyResponse
	* @property {String}  MobileNumber 
	*
	*/
	VerifyNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v1/verify_number`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('VerifyNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('VerifyNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('VerifyNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('VerifyNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in VerifyNumber'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};