// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: customer.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Customers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	CustomerSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	CustomerGroups: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	CustomerGroupMembers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	CustomerTags: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getCustomerByUUID: state => (UUID) => (state.Customers.UUIDs[ UUID ] && !state.Customers.UUIDs[ UUID ].expired()) ?  state.Customers.UUIDs[ UUID ].hashedData() : null,
	getCustomersForSearchText: state => (SPIDwithSearchText) => state.CustomerSearches[ SPIDwithSearchText ] ? state.CustomerSearches[ SPIDwithSearchText ] : null,
	getCustomerGroupByUUID: state => (UUID) => (state.CustomerGroups.UUIDs[ UUID ] && !state.CustomerGroups.UUIDs[ UUID ].expired()) ?  state.CustomerGroups.UUIDs[ UUID ].hashedData() : null,
	getCustomerGroupMemberByUUID: state => (UUID) => (state.CustomerGroupMembers.UUIDs[ UUID ] && !state.CustomerGroupMembers.UUIDs[ UUID ].expired()) ?  state.CustomerGroupMembers.UUIDs[ UUID ].hashedData() : null,
	getCustomerTagByUUID: state => (UUID) => (state.CustomerTags.UUIDs[ UUID ] && !state.CustomerTags.UUIDs[ UUID ].expired()) ?  state.CustomerTags.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveCustomer saves a single Customer object
	// into both the StoreHash cache and the VueX store
	mutSaveCustomer(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Customers.UUIDs)) {
			state.Customers.UUIDs[ obj.UUID ] = new StoreHash(state.Customers.lifetime)
		}
		state.Customers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCustomerByUUID removes a Customer object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCustomerByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.Customers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCustomerSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveCustomerSearch(state, { CacheKey, SPID, SearchText, Response }) {
		if (!CacheKey || !SearchText || !Response) { return } // Must have CacheKey + SearchText + Response to Save object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Customers)) {
			for (let i = 0; i < Response.Customers.length; i++) {
				storedObj.UUIDs.push(Response.Customers[i].UUID)
			}
		}
		state.CustomerSearches[ CacheKey ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(CacheKey in state.CustomerSearches.SearchTexts)) {
			state.CustomerSearches.SearchTexts[ CacheKey ] = new StoreHash(state.CustomerSearches.lifetime)
		}
		state.CustomerSearches.SearchTexts[ CacheKey ].fix() // Add item to StoreHash
	},
	// mutRemoveCustomerSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveCustomerSearch(state, CacheKey) {
		if (!CacheKey) { return } // Must have CacheKey to Remove
		state.CustomerSearches[ CacheKey ] = null               // cause VueX to trigger a data cascade
		delete state.CustomerSearches[ CacheKey ]               // remove item from VueX state
		delete state.CustomerSearches.SearchTexts[ CacheKey ]  // remove item from StoreHash
	},
	// mutSaveCustomers saves a paginated response of Customers
	mutSaveCustomers(state, { Response }) {
		if (!Response || !Array.isArray(Response.Customers)) { return } // Must have Response object with results
		for (let i = 0; i < Response.Customers.length; i++) {
			if (!(Response.Customers[i].UUID in state.Customers.UUIDs)) {
				state.Customers.UUIDs[ Response.Customers[i].UUID ] = new StoreHash(state.Customers.lifetime)
			}
			state.Customers.UUIDs[ Response.Customers[i].UUID ].fix(Response.Customers[i])  // Add single Customer to StoreHash
		}
	},
	// mutSaveCustomerGroup saves a single CustomerGroup object
	// into both the StoreHash cache and the VueX store
	mutSaveCustomerGroup(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.CustomerGroups.UUIDs)) {
			state.CustomerGroups.UUIDs[ obj.UUID ] = new StoreHash(state.CustomerGroups.lifetime)
		}
		state.CustomerGroups.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCustomerGroupByUUID removes a CustomerGroup object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCustomerGroupByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.CustomerGroups.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCustomerGroups saves a paginated response of CustomerGroups
	mutSaveCustomerGroups(state, { Response }) {
		if (!Response || !Array.isArray(Response.CustomerGroups)) { return } // Must have Response object with results
		for (let i = 0; i < Response.CustomerGroups.length; i++) {
			if (!(Response.CustomerGroups[i].UUID in state.CustomerGroups.UUIDs)) {
				state.CustomerGroups.UUIDs[ Response.CustomerGroups[i].UUID ] = new StoreHash(state.CustomerGroups.lifetime)
			}
			state.CustomerGroups.UUIDs[ Response.CustomerGroups[i].UUID ].fix(Response.CustomerGroups[i])  // Add single CustomerGroup to StoreHash
		}
	},
	// mutSaveCustomerGroupMember saves a single CustomerGroupMember object
	// into both the StoreHash cache and the VueX store
	mutSaveCustomerGroupMember(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.CustomerGroupMembers.UUIDs)) {
			state.CustomerGroupMembers.UUIDs[ obj.UUID ] = new StoreHash(state.CustomerGroupMembers.lifetime)
		}
		state.CustomerGroupMembers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCustomerGroupMemberByUUID removes a CustomerGroupMember object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCustomerGroupMemberByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.CustomerGroupMembers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCustomerGroupMembers saves a paginated response of CustomerGroupMembers
	mutSaveCustomerGroupMembers(state, { Response }) {
		if (!Response || !Array.isArray(Response.CustomerGroupMembers)) { return } // Must have Response object with results
		for (let i = 0; i < Response.CustomerGroupMembers.length; i++) {
			if (!(Response.CustomerGroupMembers[i].UUID in state.CustomerGroupMembers.UUIDs)) {
				state.CustomerGroupMembers.UUIDs[ Response.CustomerGroupMembers[i].UUID ] = new StoreHash(state.CustomerGroupMembers.lifetime)
			}
			state.CustomerGroupMembers.UUIDs[ Response.CustomerGroupMembers[i].UUID ].fix(Response.CustomerGroupMembers[i])  // Add single CustomerGroupMember to StoreHash
		}
	},
	// mutSaveCustomerTag saves a single CustomerTag object
	// into both the StoreHash cache and the VueX store
	mutSaveCustomerTag(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.CustomerTags.UUIDs)) {
			state.CustomerTags.UUIDs[ obj.UUID ] = new StoreHash(state.CustomerTags.lifetime)
		}
		state.CustomerTags.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCustomerTagByUUID removes a CustomerTag object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCustomerTagByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.CustomerTags.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCustomerTags saves a paginated response of CustomerTags
	mutSaveCustomerTags(state, { Response }) {
		if (!Response || !Array.isArray(Response.CustomerTags)) { return } // Must have Response object with results
		for (let i = 0; i < Response.CustomerTags.length; i++) {
			if (!(Response.CustomerTags[i].UUID in state.CustomerTags.UUIDs)) {
				state.CustomerTags.UUIDs[ Response.CustomerTags[i].UUID ] = new StoreHash(state.CustomerTags.lifetime)
			}
			state.CustomerTags.UUIDs[ Response.CustomerTags[i].UUID ].fix(Response.CustomerTags[i])  // Add single CustomerTag to StoreHash
		}
	},
};

const actions = {
	// getCachedCustomerByUUID fetches a Customer from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomerByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Customers.UUIDs[ UUID ] && !state.Customers.UUIDs[ UUID ].expired()) {
			return state.Customers.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetCustomerByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCustomer', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedCustomersBySearch fetches Customers from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		const cacheKey = SPID+':'+SearchText
		if (!RefreshCache && state.CustomerSearches.SearchTexts[ cacheKey ] && !state.CustomerSearches.SearchTexts[ cacheKey ].expired()) {
			// Return the cached records
			let resp = getters.getCustomersForSearchText(cacheKey)
			resp.Customers = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Customers.push(getters.getCustomerByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindCustomersPaginated', apiReq).then(respPayload => {
			commit('mutSaveCustomerSearch', { CacheKey: cacheKey, SPID: SPID, SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerSearch', cacheKey)
			return Promise.reject(error)
		})
	},

	// getCachedCustomerGroupByUUID fetches a CustomerGroup from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomerGroupByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.CustomerGroups.UUIDs[ UUID ] && !state.CustomerGroups.UUIDs[ UUID ].expired()) {
			return state.CustomerGroups.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetCustomerGroupByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCustomerGroup', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerGroupByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedCustomerGroupMemberByUUID fetches a CustomerGroupMember from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomerGroupMemberByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.CustomerGroupMembers.UUIDs[ UUID ] && !state.CustomerGroupMembers.UUIDs[ UUID ].expired()) {
			return state.CustomerGroupMembers.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetCustomerGroupMemberByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCustomerGroupMember', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerGroupMemberByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedCustomerTagByUUID fetches a CustomerTag from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomerTagByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.CustomerTags.UUIDs[ UUID ] && !state.CustomerTags.UUIDs[ UUID ].expired()) {
			return state.CustomerTags.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetCustomerTagByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCustomerTag', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerTagByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: CustomerRPC
	//
	/**
	 * RPC Method: AddCustomer
	 * Description: Add a new Customer object
	 * HTTP Method: POST
	 * API Path: /api/v2/${req.SPID}/customer
	 * @param    {Customer} req The API payload object (Customer)
	 * @return   {Customer} The API response object (Customer)
	 *
	 * @typedef  {Object}  Customer
	 * @property {String}  UUID                  The Unique ID (UUIDv4)
	 * @property {Boolean} Active                Whether or not this Customer is active or not
	 * @property {Number}  SPID                  The Service Provider ID
	 * @property {String}  FirstName             First Name
	 * @property {String}  LastName              Last Name
	 * @property {String}  Language              Language (default: en)
	 * @property {String}  Email                 Email address
	 * @property {String}  MobileNumber          Mobile phone number
	 * @property {Number}  CreatedAt             When the object was first created, in Unix timestamp format
	 * @property {Number}  UpdatedAt             CRUD: customer_tags
	 * @property {Number}  DeletedAt             When the object was deleted, in Unix timestamp format
	 * @property {ENUM}    NotifyBilling         How to notify the customer of Billing issues (reseller only)
	 * @property {ENUM}    NotifySpecialOffers   How to send Special Offers / Marketing info (reseller only)
	 * @property {Boolean} MobileVerified        Has the mobile number been verified?
	 * @property {Boolean} EmailVerified         CRUD: customer_group
	 * @property {Number}  BillingDOM            Billing Day of Month - Note the Billing DOMs are Customer specific, unless specifically mentioned in the product API - Automatically set on Customer or first product purchase
	 * @property {ENUM}    EarlyAccess           Enrolled in the EarlyAccess programme? (reseller only)
	 * @property {Boolean} IsMigrating           Is the customer being migrated? (used internally only)
	 * @property {String}  ExternalRef1          External Reference Field 1
	 * @property {String}  ExternalRef2          CRUD: customer_group_member
	 * @property {String}  ExternalRef3          External Reference Field 3
	 * @property {String}  ExternalRef4          External Reference Field 4
	 * @property {String}  ExternalRef5          External Reference Field 5
	 * @property {String}  MigrationData         Migration data
	 * @property {Number}  ExternalRef1CreatedAt External Reference Field 1 - Created At
	 * @property {Number}  ExternalRef1UpdatedAt External Reference Field 1 - Updated At
	 * @property {String}  ExternalRef1UpdatedBy External Reference Field 1 - Updated By
	 * @property {Number}  ExternalRef2CreatedAt External Reference Field 2 - Created At
	 * @property {Number}  ExternalRef2UpdatedAt External Reference Field 2 - Updated At
	 * @property {String}  ExternalRef2UpdatedBy External Reference Field 2 - Updated By
	 * @property {Number}  ExternalRef3CreatedAt External Reference Field 3 - Created At
	 * @property {Number}  ExternalRef3UpdatedAt External Reference Field 3 - Updated At
	 * @property {String}  ExternalRef3UpdatedBy External Reference Field 3 - Updated By
	 * @property {Number}  ExternalRef4CreatedAt External Reference Field 4 - Created At
	 * @property {Number}  ExternalRef4UpdatedAt External Reference Field 4 - Updated At
	 * @property {String}  ExternalRef4UpdatedBy External Reference Field 4 - Updated By
	 * @property {Number}  ExternalRef5CreatedAt External Reference Field 5 - Created At
	 * @property {Number}  ExternalRef5UpdatedAt External Reference Field 5 - Updated At
	 * @property {String}  ExternalRef5UpdatedBy External Reference Field 5 - Updated By
	 * @property {Number}  CreditLimitDays       Maximum number of days allowed to pay bills (reseller only)
	 * @property {Number}  CreditLimitCents      Maximum number of Cents allowed to be accumulated as a credit risk (reseller only)
	 * @property {String[]} Tags                  Customer tags (reseller only)
	 * @property {Number}  RawCustomerIndex      Raw Customer Index
	 * @property {Number}  CustomerNumber        Customer Number (for display, appears random)
	 * @property {String}  TypeOfEntry           Type of entry column
	 * @property {Number}  AddedToday            To be filled if the object was created today, in Unix timestamp format
	 * @property {ENUM}    NotifyNewsletters     How to send Newsletters (reseller only)
	 *
	 */
	AddCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/customer`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddCustomer'))
			})
	},

	/**
	 * RPC Method: AddCustomerGroup
	 * Description: Add a new customer group
	 * HTTP Method: POST
	 * API Path: /api/v2/${req.SPID}/customer_group
	 * @param    {CustomerGroup} req The API payload object (CustomerGroup)
	 * @return   {CustomerGroup} The API response object (CustomerGroup)
	 *
	 * @typedef  {Object}  CustomerGroup
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer group
	 * @property {String}  CustomerUUID   The customer that this customer group belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy      Who updated the customer group
	 * @property {String}  GroupCode
	 * @property {ENUM}    Type           The type of the group
	 * @property {Number}  MaxGroupSize   Maximum size this group can grow to
	 * @property {String}  Name           Name of this customer group
	 *
	 */
	AddCustomerGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/customer_group`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddCustomerGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddCustomerGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddCustomerGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddCustomerGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddCustomerGroup'))
			})
	},

	/**
	 * RPC Method: AddCustomerGroupMember
	 * Description: Add a new customer group member
	 * HTTP Method: POST
	 * API Path: /api/v2/${req.SPID}/customer_group_member
	 * @param    {CustomerGroupMember} req The API payload object (CustomerGroupMember)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	AddCustomerGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/customer_group_member`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddCustomerGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddCustomerGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddCustomerGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddCustomerGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddCustomerGroupMember'))
			})
	},

	/**
	 * RPC Method: AddCustomerTag
	 * Description: Add a new customer tag
	 * HTTP Method: POST
	 * API Path: /api/v2/${req.SPID}/customer_tag
	 * @param    {CustomerTag} req The API payload object (CustomerTag)
	 * @return   {CustomerTag} The API response object (CustomerTag)
	 *
	 * @typedef  {Object}  CustomerTag
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer tag
	 * @property {String}  CustomerUUID   The customer that this customer tag belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer tag was created,  NB: When updating a customer tag, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer tag was deleted
	 * @property {String}  UpdatedBy      Who updated the customer tag
	 * @property {String}  Name           Name of this customer tag
	 * @property {String}  Value          Value of this customer tag
	 *
	 */
	AddCustomerTag({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/customer_tag`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddCustomerTag ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddCustomerTag ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddCustomerTag ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddCustomerTag UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddCustomerTag'))
			})
	},

	/**
	 * RPC Method: CheckAddCustomerGroupMember
	 * Description: Check if we can add a new customer group member
	 * HTTP Method: POST
	 * API Path: /api/v2/${req.SPID}/customer_group_member_check
	 * @param    {CustomerGroupMember} req The API payload object (CustomerGroupMember)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	CheckAddCustomerGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v2/${req.SPID}/customer_group_member_check`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CheckAddCustomerGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CheckAddCustomerGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CheckAddCustomerGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CheckAddCustomerGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CheckAddCustomerGroupMember'))
			})
	},

	/**
	 * RPC Method: DeleteCustomer
	 * Description: Delete a Customer object using the Customer UUID (marks as deleted)
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	 * @param    {CustomerUUIDSearch} req The API payload object (CustomerUUIDSearch)
	 * @return   {Customer} The API response object (Customer)
	 *
	 * @typedef  {Object}  CustomerUUIDSearch
	 * @property {Number}  SPID Service Provider ID
	 * @property {String}  UUID The Customer UUID to search for
	 *
	 * @typedef  {Object}  Customer
	 * @property {String}  UUID                  The Unique ID (UUIDv4)
	 * @property {Boolean} Active                Whether or not this Customer is active or not
	 * @property {Number}  SPID                  The Service Provider ID
	 * @property {String}  FirstName             First Name
	 * @property {String}  LastName              Last Name
	 * @property {String}  Language              Language (default: en)
	 * @property {String}  Email                 Email address
	 * @property {String}  MobileNumber          Mobile phone number
	 * @property {Number}  CreatedAt             When the object was first created, in Unix timestamp format
	 * @property {Number}  UpdatedAt             CRUD: customer_tags
	 * @property {Number}  DeletedAt             When the object was deleted, in Unix timestamp format
	 * @property {ENUM}    NotifyBilling         How to notify the customer of Billing issues (reseller only)
	 * @property {ENUM}    NotifySpecialOffers   How to send Special Offers / Marketing info (reseller only)
	 * @property {Boolean} MobileVerified        Has the mobile number been verified?
	 * @property {Boolean} EmailVerified         CRUD: customer_group
	 * @property {Number}  BillingDOM            Billing Day of Month - Note the Billing DOMs are Customer specific, unless specifically mentioned in the product API - Automatically set on Customer or first product purchase
	 * @property {ENUM}    EarlyAccess           Enrolled in the EarlyAccess programme? (reseller only)
	 * @property {Boolean} IsMigrating           Is the customer being migrated? (used internally only)
	 * @property {String}  ExternalRef1          External Reference Field 1
	 * @property {String}  ExternalRef2          CRUD: customer_group_member
	 * @property {String}  ExternalRef3          External Reference Field 3
	 * @property {String}  ExternalRef4          External Reference Field 4
	 * @property {String}  ExternalRef5          External Reference Field 5
	 * @property {String}  MigrationData         Migration data
	 * @property {Number}  ExternalRef1CreatedAt External Reference Field 1 - Created At
	 * @property {Number}  ExternalRef1UpdatedAt External Reference Field 1 - Updated At
	 * @property {String}  ExternalRef1UpdatedBy External Reference Field 1 - Updated By
	 * @property {Number}  ExternalRef2CreatedAt External Reference Field 2 - Created At
	 * @property {Number}  ExternalRef2UpdatedAt External Reference Field 2 - Updated At
	 * @property {String}  ExternalRef2UpdatedBy External Reference Field 2 - Updated By
	 * @property {Number}  ExternalRef3CreatedAt External Reference Field 3 - Created At
	 * @property {Number}  ExternalRef3UpdatedAt External Reference Field 3 - Updated At
	 * @property {String}  ExternalRef3UpdatedBy External Reference Field 3 - Updated By
	 * @property {Number}  ExternalRef4CreatedAt External Reference Field 4 - Created At
	 * @property {Number}  ExternalRef4UpdatedAt External Reference Field 4 - Updated At
	 * @property {String}  ExternalRef4UpdatedBy External Reference Field 4 - Updated By
	 * @property {Number}  ExternalRef5CreatedAt External Reference Field 5 - Created At
	 * @property {Number}  ExternalRef5UpdatedAt External Reference Field 5 - Updated At
	 * @property {String}  ExternalRef5UpdatedBy External Reference Field 5 - Updated By
	 * @property {Number}  CreditLimitDays       Maximum number of days allowed to pay bills (reseller only)
	 * @property {Number}  CreditLimitCents      Maximum number of Cents allowed to be accumulated as a credit risk (reseller only)
	 * @property {String[]} Tags                  Customer tags (reseller only)
	 * @property {Number}  RawCustomerIndex      Raw Customer Index
	 * @property {Number}  CustomerNumber        Customer Number (for display, appears random)
	 * @property {String}  TypeOfEntry           Type of entry column
	 * @property {Number}  AddedToday            To be filled if the object was created today, in Unix timestamp format
	 * @property {ENUM}    NotifyNewsletters     How to send Newsletters (reseller only)
	 *
	 */
	DeleteCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCustomer'))
			})
	},

	/**
	 * RPC Method: DeleteCustomerGroup
	 * Description: Delete a customer group
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer_group/${req.UUID}
	 * @param    {DeleteCustomerGroupRequest} req The API payload object (DeleteCustomerGroupRequest)
	 * @return   {CustomerGroup} The API response object (CustomerGroup)
	 *
	 * @typedef  {Object}  DeleteCustomerGroupRequest
	 * @property {Number}  SPID The Service Provider ID
	 * @property {String}  UUID UUID of the customer group
	 *
	 * @typedef  {Object}  CustomerGroup
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer group
	 * @property {String}  CustomerUUID   The customer that this customer group belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy      Who updated the customer group
	 * @property {String}  GroupCode
	 * @property {ENUM}    Type           The type of the group
	 * @property {Number}  MaxGroupSize   Maximum size this group can grow to
	 * @property {String}  Name           Name of this customer group
	 *
	 */
	DeleteCustomerGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer_group/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCustomerGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCustomerGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCustomerGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCustomerGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCustomerGroup'))
			})
	},

	/**
	 * RPC Method: DeleteCustomerGroupMember
	 * Description: Delete a customer group member
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer_group_member/${req.UUID}
	 * @param    {DeleteCustomerGroupMemberRequest} req The API payload object (DeleteCustomerGroupMemberRequest)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  DeleteCustomerGroupMemberRequest
	 * @property {Number}  SPID The Service Provider ID
	 * @property {String}  UUID UUID of the customer group
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	DeleteCustomerGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer_group_member/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerGroupMember', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCustomerGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCustomerGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCustomerGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCustomerGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCustomerGroupMember'))
			})
	},

	/**
	 * RPC Method: DeleteCustomerTag
	 * Description: Delete a customer tag
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer_tag/${req.UUID}
	 * @param    {DeleteCustomerTagRequest} req The API payload object (DeleteCustomerTagRequest)
	 * @return   {CustomerTag} The API response object (CustomerTag)
	 *
	 * @typedef  {Object}  DeleteCustomerTagRequest
	 * @property {Number}  SPID The Service Provider ID
	 * @property {String}  UUID UUID of the customer tag
	 *
	 * @typedef  {Object}  CustomerTag
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer tag
	 * @property {String}  CustomerUUID   The customer that this customer tag belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer tag was created,  NB: When updating a customer tag, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer tag was deleted
	 * @property {String}  UpdatedBy      Who updated the customer tag
	 * @property {String}  Name           Name of this customer tag
	 * @property {String}  Value          Value of this customer tag
	 *
	 */
	DeleteCustomerTag({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer_tag/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerTag', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCustomerTag ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCustomerTag ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCustomerTag ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCustomerTag UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCustomerTag'))
			})
	},

	/**
	 * RPC Method: FindCustomerGroupMembersPaginated
	 * Description: Search through customer group members
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_group_member
	 * @param    {CustomerGroupMemberSearchRequest} req The API payload object (CustomerGroupMemberSearchRequest)
	 * @return   {CustomerGroupMemberSearchResponse} The API response object (CustomerGroupMemberSearchResponse)
	 *
	 * @typedef  {Object}  CustomerGroupMemberSearchRequest
	 * @property {SearchOptions[]} SearchOptions     Standard search options
	 * @property {String}  UUID              The customer group
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {Boolean} IncludeDeleted
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 * @typedef  {Object}  SearchOptions  Search options
	 * @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	 * @property {Number}  PageSize       The page size
	 * @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	 * @property {String}  SortBy         The field to sort by
	 * @property {Boolean} SortDesc       Is the sort direction descending?
	 *
	 * @typedef  {Object}  CustomerGroupMemberSearchResponse
	 * @property {PageInfo} PageInfo             PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {CustomerGroupMember[]} CustomerGroupMembers Repeated list of customer groups
	 *
	 * @typedef  {Object}   PageInfo
	 * @property {Number}   PageNumber             The page number
	 * @property {Number}   PageItemCount          Number of items on this page
	 * @property {Number}   TotalPages             Total pages available
	 * @property {Number}   TotalItemCount         Total items available
	 * @property {Number}   IndexStart             The record number this search started at
	 * @property {Number}   PageSize               The length of the page
	 * @property {Number[]} DisplayPageList        The array of page numbers to display
	 * @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	 *
	 */
	FindCustomerGroupMembersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/customer_group_member`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveCustomerGroupMembers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindCustomerGroupMembersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindCustomerGroupMembersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindCustomerGroupMembersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindCustomerGroupMembersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindCustomerGroupMembersPaginated'))
			})
	},

	/**
	 * RPC Method: FindCustomerGroupsPaginated
	 * Description: Search through customer groups
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_group
	 * @param    {CustomerGroupSearchRequest} req The API payload object (CustomerGroupSearchRequest)
	 * @return   {CustomerGroupSearchResponse} The API response object (CustomerGroupSearchResponse)
	 *
	 * @typedef  {Object}  CustomerGroupSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Standard search options
	 * @property {String}  UUID           UUID
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {Boolean} IncludeDeleted
	 * @property {String}  GroupCode
	 * @property {String}  CustomerUUID   The customer that this customer group belongs to
	 * @property {String}  Name           Name of this customer group
	 * @property {ENUM}    Type           The type of the group
	 * @property {Number}  MaxGroupSize   Maximum size this group can grow to
	 *
	 * @typedef  {Object}  SearchOptions  Search options
	 * @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	 * @property {Number}  PageSize       The page size
	 * @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	 * @property {String}  SortBy         The field to sort by
	 * @property {Boolean} SortDesc       Is the sort direction descending?
	 *
	 * @typedef  {Object}  CustomerGroupSearchResponse
	 * @property {PageInfo} PageInfo       PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {CustomerGroup[]} CustomerGroups Repeated list of customer groups
	 *
	 * @typedef  {Object}   PageInfo
	 * @property {Number}   PageNumber             The page number
	 * @property {Number}   PageItemCount          Number of items on this page
	 * @property {Number}   TotalPages             Total pages available
	 * @property {Number}   TotalItemCount         Total items available
	 * @property {Number}   IndexStart             The record number this search started at
	 * @property {Number}   PageSize               The length of the page
	 * @property {Number[]} DisplayPageList        The array of page numbers to display
	 * @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	 *
	 */
	FindCustomerGroupsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/customer_group`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveCustomerGroups', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindCustomerGroupsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindCustomerGroupsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindCustomerGroupsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindCustomerGroupsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindCustomerGroupsPaginated'))
			})
	},

	/**
	 * RPC Method: FindCustomerTagsPaginated
	 * Description: Get one customer group member by UUID
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_tag
	 * @param    {CustomerTagSearchRequest} req The API payload object (CustomerTagSearchRequest)
	 * @return   {CustomerTagSearchResponse} The API response object (CustomerTagSearchResponse)
	 *
	 * @typedef  {Object}  CustomerTagSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Standard search options
	 * @property {Boolean} IncludeDeleted
	 * @property {String}  UUID           The UUID of the customer tag to search for
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  CustomerUUID   The customer that this customer tag belongs to
	 * @property {String}  Name           Name of this customer tag
	 * @property {String}  Value          Value of this customer tag
	 *
	 * @typedef  {Object}  SearchOptions  Search options
	 * @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	 * @property {Number}  PageSize       The page size
	 * @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	 * @property {String}  SortBy         The field to sort by
	 * @property {Boolean} SortDesc       Is the sort direction descending?
	 *
	 * @typedef  {Object}  CustomerTagSearchResponse
	 * @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {CustomerTag[]} CustomerTags Repeated list of customer tags
	 *
	 * @typedef  {Object}   PageInfo
	 * @property {Number}   PageNumber             The page number
	 * @property {Number}   PageItemCount          Number of items on this page
	 * @property {Number}   TotalPages             Total pages available
	 * @property {Number}   TotalItemCount         Total items available
	 * @property {Number}   IndexStart             The record number this search started at
	 * @property {Number}   PageSize               The length of the page
	 * @property {Number[]} DisplayPageList        The array of page numbers to display
	 * @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	 *
	 */
	FindCustomerTagsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/customer_tag`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveCustomerTags', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindCustomerTagsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindCustomerTagsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindCustomerTagsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindCustomerTagsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindCustomerTagsPaginated'))
			})
	},

	/**
	 * RPC Method: FindCustomersPaginated
	 * Description: Search for Customers by given the search fields
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer
	 * @param    {CustomerSearchRequest} req The API payload object (CustomerSearchRequest)
	 * @return   {CustomerPageResponse} The API response object (CustomerPageResponse)
	 *
	 * @typedef  {Object}  CustomerSearchRequest
	 * @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	 * @property {String}  UUID            The Customer UUID to search/filter by
	 * @property {String}  Email           Email address to search/filter by
	 * @property {String}  MobileNumber    Mobile phone number to search/filter by
	 * @property {Number}  SPID            Service Provider to search/filter by
	 * @property {Boolean} IncludeInactive Include inactive customer objects in the results?
	 * @property {String}  FirstName       First Name to search/filter by
	 * @property {String}  LastName        Last Name to search/filter by
	 * @property {String}  SmartSearch     Search using multiple fields at once
	 * @property {String}  ExternalRef1    External Reference 1 to search/filter by
	 * @property {String}  ExternalRef2    External Reference 2 to search/filter by
	 * @property {String}  ExternalRef3    External Reference 3 to search/filter by
	 * @property {String}  ExternalRef4    External Reference 4 to search/filter by
	 * @property {String}  ExternalRef5    External Reference 5 to search/filter by
	 * @property {String}  UUIDs           Search for multiple UUIDs at once (takes precendence over all other search parameters)
	 * @property {Boolean} IsMigrating     Search for migrated customers only
	 * @property {Number}  CreatedAtFrom
	 * @property {Number}  CreatedAtUntil
	 * @property {CustomerSearchRequest_SearchFilterEntry[]} SearchFilter
	 * @property {Boolean} IncludeDeleted  Include deleted customer objects in the results?
	 * @property {String}  Tags
	 * @property {Boolean} ExcludeActive   Include Active
	 *
	 * @typedef  {Object}  SearchOptions  Search options
	 * @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	 * @property {Number}  PageSize       The page size
	 * @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	 * @property {String}  SortBy         The field to sort by
	 * @property {Boolean} SortDesc       Is the sort direction descending?
	 *
	 * @typedef  {Object}  CustomerPageResponse
	 * @property {PageInfo} PageInfo  PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {Customer[]} Customers The list of matching Customers
	 *
	 * @typedef  {Object}   PageInfo
	 * @property {Number}   PageNumber             The page number
	 * @property {Number}   PageItemCount          Number of items on this page
	 * @property {Number}   TotalPages             Total pages available
	 * @property {Number}   TotalItemCount         Total items available
	 * @property {Number}   IndexStart             The record number this search started at
	 * @property {Number}   PageSize               The length of the page
	 * @property {Number[]} DisplayPageList        The array of page numbers to display
	 * @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	 *
	 */
	FindCustomersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/customer`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveCustomers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindCustomersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindCustomersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindCustomersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindCustomersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindCustomersPaginated'))
			})
	},

	/**
	 * RPC Method: GetCustomerByUUID
	 * Description: Get a Customer using the unique UUID
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	 * @param    {CustomerUUIDSearch} req The API payload object (CustomerUUIDSearch)
	 * @return   {Customer} The API response object (Customer)
	 *
	 * @typedef  {Object}  CustomerUUIDSearch
	 * @property {Number}  SPID Service Provider ID
	 * @property {String}  UUID The Customer UUID to search for
	 *
	 * @typedef  {Object}  Customer
	 * @property {String}  UUID                  The Unique ID (UUIDv4)
	 * @property {Boolean} Active                Whether or not this Customer is active or not
	 * @property {Number}  SPID                  The Service Provider ID
	 * @property {String}  FirstName             First Name
	 * @property {String}  LastName              Last Name
	 * @property {String}  Language              Language (default: en)
	 * @property {String}  Email                 Email address
	 * @property {String}  MobileNumber          Mobile phone number
	 * @property {Number}  CreatedAt             When the object was first created, in Unix timestamp format
	 * @property {Number}  UpdatedAt             CRUD: customer_tags
	 * @property {Number}  DeletedAt             When the object was deleted, in Unix timestamp format
	 * @property {ENUM}    NotifyBilling         How to notify the customer of Billing issues (reseller only)
	 * @property {ENUM}    NotifySpecialOffers   How to send Special Offers / Marketing info (reseller only)
	 * @property {Boolean} MobileVerified        Has the mobile number been verified?
	 * @property {Boolean} EmailVerified         CRUD: customer_group
	 * @property {Number}  BillingDOM            Billing Day of Month - Note the Billing DOMs are Customer specific, unless specifically mentioned in the product API - Automatically set on Customer or first product purchase
	 * @property {ENUM}    EarlyAccess           Enrolled in the EarlyAccess programme? (reseller only)
	 * @property {Boolean} IsMigrating           Is the customer being migrated? (used internally only)
	 * @property {String}  ExternalRef1          External Reference Field 1
	 * @property {String}  ExternalRef2          CRUD: customer_group_member
	 * @property {String}  ExternalRef3          External Reference Field 3
	 * @property {String}  ExternalRef4          External Reference Field 4
	 * @property {String}  ExternalRef5          External Reference Field 5
	 * @property {String}  MigrationData         Migration data
	 * @property {Number}  ExternalRef1CreatedAt External Reference Field 1 - Created At
	 * @property {Number}  ExternalRef1UpdatedAt External Reference Field 1 - Updated At
	 * @property {String}  ExternalRef1UpdatedBy External Reference Field 1 - Updated By
	 * @property {Number}  ExternalRef2CreatedAt External Reference Field 2 - Created At
	 * @property {Number}  ExternalRef2UpdatedAt External Reference Field 2 - Updated At
	 * @property {String}  ExternalRef2UpdatedBy External Reference Field 2 - Updated By
	 * @property {Number}  ExternalRef3CreatedAt External Reference Field 3 - Created At
	 * @property {Number}  ExternalRef3UpdatedAt External Reference Field 3 - Updated At
	 * @property {String}  ExternalRef3UpdatedBy External Reference Field 3 - Updated By
	 * @property {Number}  ExternalRef4CreatedAt External Reference Field 4 - Created At
	 * @property {Number}  ExternalRef4UpdatedAt External Reference Field 4 - Updated At
	 * @property {String}  ExternalRef4UpdatedBy External Reference Field 4 - Updated By
	 * @property {Number}  ExternalRef5CreatedAt External Reference Field 5 - Created At
	 * @property {Number}  ExternalRef5UpdatedAt External Reference Field 5 - Updated At
	 * @property {String}  ExternalRef5UpdatedBy External Reference Field 5 - Updated By
	 * @property {Number}  CreditLimitDays       Maximum number of days allowed to pay bills (reseller only)
	 * @property {Number}  CreditLimitCents      Maximum number of Cents allowed to be accumulated as a credit risk (reseller only)
	 * @property {String[]} Tags                  Customer tags (reseller only)
	 * @property {Number}  RawCustomerIndex      Raw Customer Index
	 * @property {Number}  CustomerNumber        Customer Number (for display, appears random)
	 * @property {String}  TypeOfEntry           Type of entry column
	 * @property {Number}  AddedToday            To be filled if the object was created today, in Unix timestamp format
	 * @property {ENUM}    NotifyNewsletters     How to send Newsletters (reseller only)
	 *
	 */
	GetCustomerByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v2/${paramSPID}/customer/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerByUUID'))
			})
	},

	/**
	 * RPC Method: GetCustomerGroupByUUID
	 * Description: Get one customer group by UUID
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_group/${req.UUID}
	 * @param    {CustomerGroupUUIDSearch} req The API payload object (CustomerGroupUUIDSearch)
	 * @return   {CustomerGroup} The API response object (CustomerGroup)
	 *
	 * @typedef  {Object}  CustomerGroupUUIDSearch
	 * @property {Number}  SPID The Service Provider ID
	 * @property {String}  UUID UUID of the customer group
	 *
	 * @typedef  {Object}  CustomerGroup
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer group
	 * @property {String}  CustomerUUID   The customer that this customer group belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy      Who updated the customer group
	 * @property {String}  GroupCode
	 * @property {ENUM}    Type           The type of the group
	 * @property {Number}  MaxGroupSize   Maximum size this group can grow to
	 * @property {String}  Name           Name of this customer group
	 *
	 */
	GetCustomerGroupByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v2/${paramSPID}/customer_group/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerGroupByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerGroupByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerGroupByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerGroupByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerGroupByUUID'))
			})
	},

	/**
	 * RPC Method: GetCustomerGroupInfo
	 * Description: Get all customer group memberships for a given Customer
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_group_info/${req.CustomerUUID}
	 * @param    {CustomerGroupInfoRequest} req The API payload object (CustomerGroupInfoRequest)
	 * @return   {CustomerGroupInfoResponse} The API response object (CustomerGroupInfoResponse)
	 *
	 * @typedef  {Object}  CustomerGroupInfoRequest
	 * @property {Number}  SPID         The Service Provider ID
	 * @property {String}  CustomerUUID The customer (member)
	 *
	 * @typedef  {Object}  CustomerGroupInfoResponse
	 * @property {CustomerGroupInfo[]} CustomerGroupInfos Repeated list of customer groups
	 *
	 */
	GetCustomerGroupInfo({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		return axios.get(`/v2/${paramSPID}/customer_group_info/${paramCustomerUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerGroupInfo ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerGroupInfo ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerGroupInfo ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerGroupInfo UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerGroupInfo'))
			})
	},

	/**
	 * RPC Method: GetCustomerGroupMemberByUUID
	 * Description: Get one customer group member by UUID
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_group_member/${req.UUID}
	 * @param    {CustomerGroupMemberUUIDSearch} req The API payload object (CustomerGroupMemberUUIDSearch)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  CustomerGroupMemberUUIDSearch
	 * @property {Number}  SPID The Service Provider ID
	 * @property {String}  UUID UUID of the customer group
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	GetCustomerGroupMemberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v2/${paramSPID}/customer_group_member/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerGroupMember', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerGroupMemberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerGroupMemberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerGroupMemberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerGroupMemberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerGroupMemberByUUID'))
			})
	},

	/**
	 * RPC Method: GetCustomerTagByUUID
	 * Description: Get one customer tag by UUID
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_tag/${req.UUID}
	 * @param    {CustomerTagUUIDSearch} req The API payload object (CustomerTagUUIDSearch)
	 * @return   {CustomerTag} The API response object (CustomerTag)
	 *
	 * @typedef  {Object}  CustomerTagUUIDSearch
	 * @property {Number}  SPID The Service Provider ID
	 * @property {String}  UUID UUID of the customer tag
	 *
	 * @typedef  {Object}  CustomerTag
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer tag
	 * @property {String}  CustomerUUID   The customer that this customer tag belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer tag was created,  NB: When updating a customer tag, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer tag was deleted
	 * @property {String}  UpdatedBy      Who updated the customer tag
	 * @property {String}  Name           Name of this customer tag
	 * @property {String}  Value          Value of this customer tag
	 *
	 */
	GetCustomerTagByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v2/${paramSPID}/customer_tag/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerTag', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerTagByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerTagByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerTagByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerTagByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerTagByUUID'))
			})
	},

	/**
	 * RPC Method: GetCustomersUUIDs
	 * Description: Search for Customers by given the search fields, and return only the UUIDs
	 * HTTP Method: GET
	 * API Path: /api/v2/${req.SPID}/customer_uuids
	 * @param    {CustomerSearchRequest} req The API payload object (CustomerSearchRequest)
	 * @return   {tktcommonproto.UUIDsResponse} The API response object (tktcommonproto.UUIDsResponse)
	 *
	 * @typedef  {Object}  CustomerSearchRequest
	 * @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	 * @property {String}  UUID            The Customer UUID to search/filter by
	 * @property {String}  Email           Email address to search/filter by
	 * @property {String}  MobileNumber    Mobile phone number to search/filter by
	 * @property {Number}  SPID            Service Provider to search/filter by
	 * @property {Boolean} IncludeInactive Include inactive customer objects in the results?
	 * @property {String}  FirstName       First Name to search/filter by
	 * @property {String}  LastName        Last Name to search/filter by
	 * @property {String}  SmartSearch     Search using multiple fields at once
	 * @property {String}  ExternalRef1    External Reference 1 to search/filter by
	 * @property {String}  ExternalRef2    External Reference 2 to search/filter by
	 * @property {String}  ExternalRef3    External Reference 3 to search/filter by
	 * @property {String}  ExternalRef4    External Reference 4 to search/filter by
	 * @property {String}  ExternalRef5    External Reference 5 to search/filter by
	 * @property {String}  UUIDs           Search for multiple UUIDs at once (takes precendence over all other search parameters)
	 * @property {Boolean} IsMigrating     Search for migrated customers only
	 * @property {Number}  CreatedAtFrom
	 * @property {Number}  CreatedAtUntil
	 * @property {CustomerSearchRequest_SearchFilterEntry[]} SearchFilter
	 * @property {Boolean} IncludeDeleted  Include deleted customer objects in the results?
	 * @property {String}  Tags
	 * @property {Boolean} ExcludeActive   Include Active
	 *
	 * @typedef  {Object}  tktcommonproto.UUIDsResponse
	 *
	 */
	GetCustomersUUIDs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: FindUUIDs)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/customer_uuids`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomersUUIDs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomersUUIDs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomersUUIDs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomersUUIDs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomersUUIDs'))
			})
	},

	/**
	 * RPC Method: LeaveCustomerGroup
	 * Description: Leave a customer group
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer_group_leave/${req.CustomerUUID}/${req.CustomerGroupUUID}
	 * @param    {CustomerLeaveGroupRequest} req The API payload object (CustomerLeaveGroupRequest)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  CustomerLeaveGroupRequest
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  CustomerUUID      UUID of the customer to be removed from the group
	 * @property {String}  CustomerGroupUUID UUID of the customer group that the customer will be removed from
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	LeaveCustomerGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v2/${req.SPID}/customer_group_leave/${req.CustomerUUID}/${req.CustomerGroupUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('LeaveCustomerGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('LeaveCustomerGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('LeaveCustomerGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('LeaveCustomerGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in LeaveCustomerGroup'))
			})
	},

	/**
	 * RPC Method: PurgeCustomer
	 * Description: Permanently delete a customer object via customer UUID
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer/${req.UUID}/purge
	 * @param    {CustomerUUIDSearch} req The API payload object (CustomerUUIDSearch)
	 * @return   {Customer} The API response object (Customer)
	 *
	 * @typedef  {Object}  CustomerUUIDSearch
	 * @property {Number}  SPID Service Provider ID
	 * @property {String}  UUID The Customer UUID to search for
	 *
	 * @typedef  {Object}  Customer
	 * @property {String}  UUID                  The Unique ID (UUIDv4)
	 * @property {Boolean} Active                Whether or not this Customer is active or not
	 * @property {Number}  SPID                  The Service Provider ID
	 * @property {String}  FirstName             First Name
	 * @property {String}  LastName              Last Name
	 * @property {String}  Language              Language (default: en)
	 * @property {String}  Email                 Email address
	 * @property {String}  MobileNumber          Mobile phone number
	 * @property {Number}  CreatedAt             When the object was first created, in Unix timestamp format
	 * @property {Number}  UpdatedAt             CRUD: customer_tags
	 * @property {Number}  DeletedAt             When the object was deleted, in Unix timestamp format
	 * @property {ENUM}    NotifyBilling         How to notify the customer of Billing issues (reseller only)
	 * @property {ENUM}    NotifySpecialOffers   How to send Special Offers / Marketing info (reseller only)
	 * @property {Boolean} MobileVerified        Has the mobile number been verified?
	 * @property {Boolean} EmailVerified         CRUD: customer_group
	 * @property {Number}  BillingDOM            Billing Day of Month - Note the Billing DOMs are Customer specific, unless specifically mentioned in the product API - Automatically set on Customer or first product purchase
	 * @property {ENUM}    EarlyAccess           Enrolled in the EarlyAccess programme? (reseller only)
	 * @property {Boolean} IsMigrating           Is the customer being migrated? (used internally only)
	 * @property {String}  ExternalRef1          External Reference Field 1
	 * @property {String}  ExternalRef2          CRUD: customer_group_member
	 * @property {String}  ExternalRef3          External Reference Field 3
	 * @property {String}  ExternalRef4          External Reference Field 4
	 * @property {String}  ExternalRef5          External Reference Field 5
	 * @property {String}  MigrationData         Migration data
	 * @property {Number}  ExternalRef1CreatedAt External Reference Field 1 - Created At
	 * @property {Number}  ExternalRef1UpdatedAt External Reference Field 1 - Updated At
	 * @property {String}  ExternalRef1UpdatedBy External Reference Field 1 - Updated By
	 * @property {Number}  ExternalRef2CreatedAt External Reference Field 2 - Created At
	 * @property {Number}  ExternalRef2UpdatedAt External Reference Field 2 - Updated At
	 * @property {String}  ExternalRef2UpdatedBy External Reference Field 2 - Updated By
	 * @property {Number}  ExternalRef3CreatedAt External Reference Field 3 - Created At
	 * @property {Number}  ExternalRef3UpdatedAt External Reference Field 3 - Updated At
	 * @property {String}  ExternalRef3UpdatedBy External Reference Field 3 - Updated By
	 * @property {Number}  ExternalRef4CreatedAt External Reference Field 4 - Created At
	 * @property {Number}  ExternalRef4UpdatedAt External Reference Field 4 - Updated At
	 * @property {String}  ExternalRef4UpdatedBy External Reference Field 4 - Updated By
	 * @property {Number}  ExternalRef5CreatedAt External Reference Field 5 - Created At
	 * @property {Number}  ExternalRef5UpdatedAt External Reference Field 5 - Updated At
	 * @property {String}  ExternalRef5UpdatedBy External Reference Field 5 - Updated By
	 * @property {Number}  CreditLimitDays       Maximum number of days allowed to pay bills (reseller only)
	 * @property {Number}  CreditLimitCents      Maximum number of Cents allowed to be accumulated as a credit risk (reseller only)
	 * @property {String[]} Tags                  Customer tags (reseller only)
	 * @property {Number}  RawCustomerIndex      Raw Customer Index
	 * @property {Number}  CustomerNumber        Customer Number (for display, appears random)
	 * @property {String}  TypeOfEntry           Type of entry column
	 * @property {Number}  AddedToday            To be filled if the object was created today, in Unix timestamp format
	 * @property {ENUM}    NotifyNewsletters     How to send Newsletters (reseller only)
	 *
	 */
	PurgeCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v2/${req.SPID}/customer/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeCustomer'))
			})
	},

	/**
	 * RPC Method: RemoveOtherCustomerFromGroup
	 * Description: Delete another customer from the group
	 * HTTP Method: DELETE
	 * API Path: /api/v2/${req.SPID}/customer_group_remove_other/${req.CustomerUUID}/${req.CustomerGroupUUID}/${req.OtherCustomerUUID}
	 * @param    {RemoveOtherCustomerFromGroupRequest} req The API payload object (RemoveOtherCustomerFromGroupRequest)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  RemoveOtherCustomerFromGroupRequest
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  CustomerUUID      CustomerUUID of the customer sending the request
	 * @property {String}  OtherCustomerUUID UUID of the customer to be removed from the group
	 * @property {String}  CustomerGroupUUID UUID of the customer group that the customer will be removed from
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	RemoveOtherCustomerFromGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v2/${req.SPID}/customer_group_remove_other/${req.CustomerUUID}/${req.CustomerGroupUUID}/${req.OtherCustomerUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveOtherCustomerFromGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveOtherCustomerFromGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveOtherCustomerFromGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveOtherCustomerFromGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveOtherCustomerFromGroup'))
			})
	},

	/**
	 * RPC Method: SetCustomer
	 * Description: Update a Customer object. Keys not present in the submitted JSON payload will be ignored
	 * HTTP Method: PUT
	 * API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	 * @param    {Customer} req The API payload object (Customer)
	 * @return   {Customer} The API response object (Customer)
	 *
	 * @typedef  {Object}  Customer
	 * @property {String}  UUID                  The Unique ID (UUIDv4)
	 * @property {Boolean} Active                Whether or not this Customer is active or not
	 * @property {Number}  SPID                  The Service Provider ID
	 * @property {String}  FirstName             First Name
	 * @property {String}  LastName              Last Name
	 * @property {String}  Language              Language (default: en)
	 * @property {String}  Email                 Email address
	 * @property {String}  MobileNumber          Mobile phone number
	 * @property {Number}  CreatedAt             When the object was first created, in Unix timestamp format
	 * @property {Number}  UpdatedAt             CRUD: customer_tags
	 * @property {Number}  DeletedAt             When the object was deleted, in Unix timestamp format
	 * @property {ENUM}    NotifyBilling         How to notify the customer of Billing issues (reseller only)
	 * @property {ENUM}    NotifySpecialOffers   How to send Special Offers / Marketing info (reseller only)
	 * @property {Boolean} MobileVerified        Has the mobile number been verified?
	 * @property {Boolean} EmailVerified         CRUD: customer_group
	 * @property {Number}  BillingDOM            Billing Day of Month - Note the Billing DOMs are Customer specific, unless specifically mentioned in the product API - Automatically set on Customer or first product purchase
	 * @property {ENUM}    EarlyAccess           Enrolled in the EarlyAccess programme? (reseller only)
	 * @property {Boolean} IsMigrating           Is the customer being migrated? (used internally only)
	 * @property {String}  ExternalRef1          External Reference Field 1
	 * @property {String}  ExternalRef2          CRUD: customer_group_member
	 * @property {String}  ExternalRef3          External Reference Field 3
	 * @property {String}  ExternalRef4          External Reference Field 4
	 * @property {String}  ExternalRef5          External Reference Field 5
	 * @property {String}  MigrationData         Migration data
	 * @property {Number}  ExternalRef1CreatedAt External Reference Field 1 - Created At
	 * @property {Number}  ExternalRef1UpdatedAt External Reference Field 1 - Updated At
	 * @property {String}  ExternalRef1UpdatedBy External Reference Field 1 - Updated By
	 * @property {Number}  ExternalRef2CreatedAt External Reference Field 2 - Created At
	 * @property {Number}  ExternalRef2UpdatedAt External Reference Field 2 - Updated At
	 * @property {String}  ExternalRef2UpdatedBy External Reference Field 2 - Updated By
	 * @property {Number}  ExternalRef3CreatedAt External Reference Field 3 - Created At
	 * @property {Number}  ExternalRef3UpdatedAt External Reference Field 3 - Updated At
	 * @property {String}  ExternalRef3UpdatedBy External Reference Field 3 - Updated By
	 * @property {Number}  ExternalRef4CreatedAt External Reference Field 4 - Created At
	 * @property {Number}  ExternalRef4UpdatedAt External Reference Field 4 - Updated At
	 * @property {String}  ExternalRef4UpdatedBy External Reference Field 4 - Updated By
	 * @property {Number}  ExternalRef5CreatedAt External Reference Field 5 - Created At
	 * @property {Number}  ExternalRef5UpdatedAt External Reference Field 5 - Updated At
	 * @property {String}  ExternalRef5UpdatedBy External Reference Field 5 - Updated By
	 * @property {Number}  CreditLimitDays       Maximum number of days allowed to pay bills (reseller only)
	 * @property {Number}  CreditLimitCents      Maximum number of Cents allowed to be accumulated as a credit risk (reseller only)
	 * @property {String[]} Tags                  Customer tags (reseller only)
	 * @property {Number}  RawCustomerIndex      Raw Customer Index
	 * @property {Number}  CustomerNumber        Customer Number (for display, appears random)
	 * @property {String}  TypeOfEntry           Type of entry column
	 * @property {Number}  AddedToday            To be filled if the object was created today, in Unix timestamp format
	 * @property {ENUM}    NotifyNewsletters     How to send Newsletters (reseller only)
	 *
	 */
	SetCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/customer/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SetCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SetCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SetCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SetCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SetCustomer'))
			})
	},

	/**
	 * RPC Method: SetCustomerFields
	 * Description: Update some or all fields of a Customer object. Only the fields listed in the Fields array will be updated
	 * HTTP Method: PATCH
	 * API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	 * @param    {CustomerFields} req The API payload object (CustomerFields)
	 * @return   {Customer} The API response object (Customer)
	 *
	 * @typedef  {Object}  CustomerFields
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 * @property {Customer} Customer
	 * @property {String}  Fields
	 *
	 * @typedef  {Object}  Customer
	 * @property {String}  UUID                  The Unique ID (UUIDv4)
	 * @property {Boolean} Active                Whether or not this Customer is active or not
	 * @property {Number}  SPID                  The Service Provider ID
	 * @property {String}  FirstName             First Name
	 * @property {String}  LastName              Last Name
	 * @property {String}  Language              Language (default: en)
	 * @property {String}  Email                 Email address
	 * @property {String}  MobileNumber          Mobile phone number
	 * @property {Number}  CreatedAt             When the object was first created, in Unix timestamp format
	 * @property {Number}  UpdatedAt             CRUD: customer_tags
	 * @property {Number}  DeletedAt             When the object was deleted, in Unix timestamp format
	 * @property {ENUM}    NotifyBilling         How to notify the customer of Billing issues (reseller only)
	 * @property {ENUM}    NotifySpecialOffers   How to send Special Offers / Marketing info (reseller only)
	 * @property {Boolean} MobileVerified        Has the mobile number been verified?
	 * @property {Boolean} EmailVerified         CRUD: customer_group
	 * @property {Number}  BillingDOM            Billing Day of Month - Note the Billing DOMs are Customer specific, unless specifically mentioned in the product API - Automatically set on Customer or first product purchase
	 * @property {ENUM}    EarlyAccess           Enrolled in the EarlyAccess programme? (reseller only)
	 * @property {Boolean} IsMigrating           Is the customer being migrated? (used internally only)
	 * @property {String}  ExternalRef1          External Reference Field 1
	 * @property {String}  ExternalRef2          CRUD: customer_group_member
	 * @property {String}  ExternalRef3          External Reference Field 3
	 * @property {String}  ExternalRef4          External Reference Field 4
	 * @property {String}  ExternalRef5          External Reference Field 5
	 * @property {String}  MigrationData         Migration data
	 * @property {Number}  ExternalRef1CreatedAt External Reference Field 1 - Created At
	 * @property {Number}  ExternalRef1UpdatedAt External Reference Field 1 - Updated At
	 * @property {String}  ExternalRef1UpdatedBy External Reference Field 1 - Updated By
	 * @property {Number}  ExternalRef2CreatedAt External Reference Field 2 - Created At
	 * @property {Number}  ExternalRef2UpdatedAt External Reference Field 2 - Updated At
	 * @property {String}  ExternalRef2UpdatedBy External Reference Field 2 - Updated By
	 * @property {Number}  ExternalRef3CreatedAt External Reference Field 3 - Created At
	 * @property {Number}  ExternalRef3UpdatedAt External Reference Field 3 - Updated At
	 * @property {String}  ExternalRef3UpdatedBy External Reference Field 3 - Updated By
	 * @property {Number}  ExternalRef4CreatedAt External Reference Field 4 - Created At
	 * @property {Number}  ExternalRef4UpdatedAt External Reference Field 4 - Updated At
	 * @property {String}  ExternalRef4UpdatedBy External Reference Field 4 - Updated By
	 * @property {Number}  ExternalRef5CreatedAt External Reference Field 5 - Created At
	 * @property {Number}  ExternalRef5UpdatedAt External Reference Field 5 - Updated At
	 * @property {String}  ExternalRef5UpdatedBy External Reference Field 5 - Updated By
	 * @property {Number}  CreditLimitDays       Maximum number of days allowed to pay bills (reseller only)
	 * @property {Number}  CreditLimitCents      Maximum number of Cents allowed to be accumulated as a credit risk (reseller only)
	 * @property {String[]} Tags                  Customer tags (reseller only)
	 * @property {Number}  RawCustomerIndex      Raw Customer Index
	 * @property {Number}  CustomerNumber        Customer Number (for display, appears random)
	 * @property {String}  TypeOfEntry           Type of entry column
	 * @property {Number}  AddedToday            To be filled if the object was created today, in Unix timestamp format
	 * @property {ENUM}    NotifyNewsletters     How to send Newsletters (reseller only)
	 *
	 */
	SetCustomerFields({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: UpdateFields)
		return axios.patch(`/v2/${req.SPID}/customer/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SetCustomerFields ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SetCustomerFields ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SetCustomerFields ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SetCustomerFields UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SetCustomerFields'))
			})
	},

	/**
	 * RPC Method: UpdateCustomerGroup
	 * Description: Update a customer group
	 * HTTP Method: PUT
	 * API Path: /api/v2/${req.SPID}/customer_group/${req.UUID}
	 * @param    {CustomerGroup} req The API payload object (CustomerGroup)
	 * @return   {CustomerGroup} The API response object (CustomerGroup)
	 *
	 * @typedef  {Object}  CustomerGroup
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer group
	 * @property {String}  CustomerUUID   The customer that this customer group belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy      Who updated the customer group
	 * @property {String}  GroupCode
	 * @property {ENUM}    Type           The type of the group
	 * @property {Number}  MaxGroupSize   Maximum size this group can grow to
	 * @property {String}  Name           Name of this customer group
	 *
	 */
	UpdateCustomerGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/customer_group/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateCustomerGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateCustomerGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateCustomerGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateCustomerGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateCustomerGroup'))
			})
	},

	/**
	 * RPC Method: UpdateCustomerGroupMember
	 * Description: Update a customer group member
	 * HTTP Method: PUT
	 * API Path: /api/v2/${req.SPID}/customer_group_member/${req.UUID}
	 * @param    {CustomerGroupMember} req The API payload object (CustomerGroupMember)
	 * @return   {CustomerGroupMember} The API response object (CustomerGroupMember)
	 *
	 * @typedef  {Object}  CustomerGroupMember
	 * @property {Number}  SPID              The Service Provider ID
	 * @property {String}  UUID              The unique index for this customer group
	 * @property {String}  CustomerGroupUUID The customer group
	 * @property {String}  CustomerUUID      The customer (member)
	 * @property {Number}  CreatedAtNanos    Unixnano when this customer group was created,  NB: When updating a customer group, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos    Unixnano when this customer group was deleted
	 * @property {String}  UpdatedBy         Who updated the customer group
	 * @property {String}  FriendlyName      FriendlyName of this customer group
	 * @property {ENUM}    Type              The type of the group
	 *
	 */
	UpdateCustomerGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/customer_group_member/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerGroupMember', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateCustomerGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateCustomerGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateCustomerGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateCustomerGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateCustomerGroupMember'))
			})
	},

	/**
	 * RPC Method: UpdateCustomerTag
	 * Description: Update a customer tag
	 * HTTP Method: PUT
	 * API Path: /api/v2/${req.SPID}/customer_tag/${req.UUID}
	 * @param    {CustomerTag} req The API payload object (CustomerTag)
	 * @return   {CustomerTag} The API response object (CustomerTag)
	 *
	 * @typedef  {Object}  CustomerTag
	 * @property {Number}  SPID           The Service Provider ID
	 * @property {String}  UUID           The unique index for this customer tag
	 * @property {String}  CustomerUUID   The customer that this customer tag belongs to
	 * @property {Number}  CreatedAtNanos Unixnano when this customer tag was created,  NB: When updating a customer tag, the old one is,  deleted and a new one is created
	 * @property {Number}  DeletedAtNanos Unixnano when this customer tag was deleted
	 * @property {String}  UpdatedBy      Who updated the customer tag
	 * @property {String}  Name           Name of this customer tag
	 * @property {String}  Value          Value of this customer tag
	 *
	 */
	UpdateCustomerTag({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/customer_tag/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCustomerTag', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateCustomerTag ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateCustomerTag ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateCustomerTag ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateCustomerTag UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateCustomerTag'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};