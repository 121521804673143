import axios from 'axios'
import StoreHash from '@/utils/StoreHash'
import downloadBlob from '@/utils/downloadBlob'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const hashed = {
    invoices: {
        customers: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },
}

const state = {
    invoices: {
        customers: {}
    }
}

const getters = {
    getInvoicesByCustomerUUID: state => uuid => state.invoices.customers[uuid]
}

const mutations = {
    mutInvoices(state, { CustomerUUID, invoices }) {
        let invoiceNumbers = {}

        if (invoices.length > 0) {
            for (let i = 0; i < invoices.length; i++) {
                const invoice = invoices[i]
                const number = invoice.Number

                invoiceNumbers[number] = invoice
            }

            state.invoices.customers[CustomerUUID] = invoiceNumbers
            hashed.invoices.customers[CustomerUUID].fix()
        }
    },

    resetInvoicesCache(state) {
        hashed.invoices.customers = {}
        state.invoices.customers = {}
    }
}

const actions = {
    GetCashedInvoicesByCustomerUUID({ getters, dispatch }, { CustomerUUID, refresh }){
        if (!(CustomerUUID in hashed.invoices.customers)) {
            hashed.invoices.customers[CustomerUUID] = new StoreHash(hashed.invoices.lifetime)
        }

        //Если вермя жизни хешированных данных истекло делаем запрос на сервер
        if (hashed.invoices.customers[CustomerUUID].expired() || refresh) {
            return dispatch('GetInvoices', { CustomerUUID, refresh })
        }
        return Promise.resolve( getters.getInvoicesByCustomerUUID(CustomerUUID) )
    },

    GetInvoices({ commit }, { CustomerUUID }) {
        return axios.get(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/invoice` )
            .then(({apidata}) => {
                if (apidata && apidata.length > 0) {
                    // commit('mutInvoices', { CustomerUUID, invoices: apidata })
                    return Promise.resolve(apidata)
                }
                return Promise.reject(new Error('No Invoices'))
            })
            .catch(error => Promise.reject(error))
    },

    DownloadInvoicePDF({getters, commit}, { CustomerUUID, InvoiceNumber }) {
        return axios.get(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/invoice/${InvoiceNumber}/pdf`, { responseType: 'blob' })
            .then(({ result: blob }) => {
                return downloadBlob(`${InvoiceNumber}.pdf`, blob)
            })
            .catch(error => Promise.reject(error))
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}