import axios from "axios"
import storage from '@/utils/storage'
import router from '@/router'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const IDENTITY_PROVIDER = process.env.VUE_APP_IDENTITY_PROVIDER

const KEY_TOS = 'tos'

const state = {
    tos: {
        AdminPortalTermsAgreedAt: 0
    },
}

const getters = {
    isTos: state => state.tos.AdminPortalTermsAgreedAt > 0,
    tos: state => state.tos
}

const mutations = {
    mutTos(state, tos) {
        state.tos = tos
        storage.set(KEY_TOS, tos, null, true)
    },
    mutResetTosStore(state) {
        state.tos.AdminPortalTermsAgreedAt = 0
        storage.remove(KEY_TOS)
    }
}

const actions = {
    getTermsOfUseStatus({ commit, getters }) {
        const payload = {
            IdentityProvider: IDENTITY_PROVIDER
        }
        return axios.get(`${API_URL_RESELLER}/admin/${payload.IdentityProvider}/tos/${getters.authID}`)
            .then(response => {
                if (response?.apidata) {
                    commit('mutTos', response.apidata);
                    return Promise.resolve(response.apidata);
                }
                return Promise.reject(new Error('Get Terms of Use Status failed'))
            })
            .catch(error => {
                // Remove
                if (getters.isTestEnv) {
                    const apidata = {
                        AdminPortalTermsAgreedAt: Date.now() * 1000000
                    }
                    commit('mutTos', apidata);
                    return Promise.resolve(apidata)
                }
                return Promise.reject(error);
            })
    },
    setTermsOfUseStatus({ dispatch }) {
        const payload = {
            AuthID: getters.authID,
            IdentityProvider: IDENTITY_PROVIDER
        }
        return axios.post(`${API_URL_RESELLER}/admin/tos/agree`, payload)
            .then(response => {
                if (response.apidata) {
                    return dispatch('getTermsOfUseStatus')
                        .then(() => {
                            router.push({ name: 'dashboard', replace: true })
                        })
                }
                return Promise.reject(new Error('Get Terms of Use Status failed'))
            })
            .catch(error => Promise.reject(error))
    },
    resetTosStore({ commit }) {
        commit('mutResetTosStore')
        return Promise.resolve(true)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}