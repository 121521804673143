/**
 * Downloads Blob
 * @param {string} fileName - The file name should be saved
 * @param {Blob} blob - The blob should be downloaded
 */

const downloadBlob = (fileName, blob) => {
    if (!fileName) return Promise.reject(new Error('File name is not specified'));
    if (!blob || !(blob instanceof Blob) || blob.size == 0) return Promise.reject(new Error('File downloading error'));

    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = fileName;

    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    downloadLink.href = downloadUrl;
    document.body.append(downloadLink);
    downloadLink.click();

    setTimeout(() => {
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);
    }, 100);

    return Promise.resolve(blob)
}

export default downloadBlob;