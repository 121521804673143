import axios from 'axios';
import md5 from 'md5';

const HOTRELOAD_INTERVAL = process.env.VUE_APP_HOTRELOAD_INTERVAL
    ? (parseInt(process.env.VUE_APP_HOTRELOAD_INTERVAL) || 0) * 1000
    : 0

const state = {}

const getters = {}

const mutations = {}

const actions = {
    getIndexMD5({commit}, no_cache) {
        return axios(location.origin + '?' + String(Date.now()),
            no_cache ? {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            } : {}
        )
            .then(({data}) => Promise.resolve(md5(data)))
    },

    hotReload({dispatch}) {
        if (HOTRELOAD_INTERVAL) {
            return new Promise((resolve, reject) => {
                dispatch('getIndexMD5', false)
                    .then(initialIndexMD5 => {
                        const checkingIndexID = setInterval(() => {
                            dispatch('getIndexMD5', true)
                                .then(currentIndexMD5 => {
                                    if (initialIndexMD5 !== currentIndexMD5) {
                                        resolve(currentIndexMD5);
                                        clearInterval(checkingIndexID);
                                    }
                                })
                                .catch(() => {})
                        }, HOTRELOAD_INTERVAL)
                    })
                    .catch(error => reject(error))
            })
        } else {
            return Promise.reject(HOTRELOAD_INTERVAL)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}