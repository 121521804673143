const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN || null
const SENTRY_ENV = process.env.VUE_APP_SENTRY_ENV || null

const state = {}

const getters = {
    sentryDSN: () => SENTRY_DSN,
    sentryENV: () => SENTRY_ENV,
    isSentryReporting: () => Boolean(SENTRY_DSN)
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}