import axios from "axios"
import store from '@/store'

const E_SIM = {
    Description: 'ESIM',
    ItemUUID: 'c7152d4a-8fa5-4ebc-8f8d-9016920b4c03',
    Name: 'ESIM',
    Type: 'TYPE_VIRTUAL',
    VirtualType: "VT_ESIM",
}

const PHYSICAL_SIM = {
    Description: 'SIM Card',
    ItemUUID: 'bd218743-e96c-4a92-8a02-4f4af788c204',
    Name: 'SIM Card',
    Type: 'TYPE_PRODUCT',
    VirtualType: "VT_NONE",
}

const state = {
    cart: null,
    cartItems: {}
}

const getters = {
    cart: state => state.cart,
    cartItemByItemUUID: state => itemUUID => state.cartItems[itemUUID],
    cartItemByUUID: state => uuid => state.cartItems[uuid],
}

const mutations = {
    mutSaveCart(state, cart) {
        state.cart = cart
    },
    mutSaveCartItems(state, items) {
        items.forEach(item => {
            state.cartItems[item.ItemUUID] = item
        })
    }
}

const actions = {
    cartPayload({ getters, rootGetters }, order) {
        const isESim = order?.simType === 'eSIM'
        const availability = order?.entry?.Availabilities[0] || {}
        const characteristics = order?.entry?.Characteristics || {}
        const productReference = order?.productType || ''
        const entry = order?.entry || {}
        const delivery = order?.delivery || {}
        const customer = order?.customer || {}

        const payload = {
            CustomerUUID: order?.customer?.UUID,
            Address: {
                City: delivery.City,
                Country: delivery.Country,
                Postcode: delivery.PostCode,
                Lines:  [
                    [
                        `${delivery.NumberPre}${delivery.NumberPre ? '/' : ''}${delivery.Number}${delivery.NumberPost}`,
                        delivery.StreetName,
                        delivery.StreetType,
                    ].join(' ').trim(),
                    [
                        delivery.BuildingName,
                        delivery.Suburb,
                    ].join(' ').trim(),
                ].filter(line => Boolean(line)),
                LocationProvider: delivery.LocationProvider,
                LocationProviderIDX: delivery.LocationProviderIDX,
                Email: customer.Email,
                Name: [customer.FirstName, customer.LastName].join(' ').trim(),
                Phone: customer.MobileNumber,
            },
            Items: [
                {
                    ...(isESim ?
                            E_SIM
                            :
                            PHYSICAL_SIM
                    ),
                    PriceCents: 0,
                    Quantity: 1,
                    SPID: rootGetters.spid,
                    ...(isESim ? {} : {
                        ProductAvailabilityUUID: availability.UUID,
                        ProductCatalogUUID: availability.ProductCatalogEntryUUID,
                    }),
                    UUID: isESim ? getters.cartItemByItemUUID(E_SIM.ItemUUID)?.UUID : getters.cartItemByItemUUID(PHYSICAL_SIM.ItemUUID)?.UUID
                },
                {
                    Attributes: {
                        BillingReference: productReference,
                        MobileProductAvailabilityUUID: availability.UUID,
                        MobileProductCatalogUUID: availability.ProductCatalogEntryUUID,
                        AutoRenew: (/true/).test(characteristics?.AllowAutoRenew)
                    },
                    Description: availability.RetailDescription,
                    ItemUUID: availability.UUID,
                    Name: `${availability.RetailName}${productReference ? `(${productReference})` : ''}`,
                    PriceCatalogCents: entry.priceCatalogCents,
                    PriceCents: availability.RetailCents,
                    ProductAvailabilityUUID: availability.UUID,
                    ProductCatalogUUID: availability.ProductCatalogEntryUUID,
                    Quantity: 1,
                    SPID: rootGetters.spid,
                    Type: 'TYPE_VIRTUAL',
                    VirtualType: 'VT_PLAN',
                    UUID: getters.cartItemByItemUUID(availability.UUID)?.UUID
                }
            ],
            UUID: getters.cart?.UUID || null,
            Number: getters.cart?.Number || null,
        }

        return payload
    },
    async SaveCart({ dispatch, commit }, order) {
        let payload = null
        await dispatch('cartPayload', order)
            .then(data => payload = data)

        return axios.post('/eshop/cart', payload)
            .then(({data})=> {
                commit('mutSaveCart', data)
                commit('mutSaveCartItems', data?.Items || [])
                return Promise.resolve(data)
            })
            .catch(error => Promise.reject(error))
    },
    async Checkout({ dispatch }, order) {
        let payload = null
        await dispatch('cartPayload', order)
            .then(data => payload = data)

        return axios.post('/eshop/checkout', payload)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}