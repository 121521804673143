import { extend } from 'vee-validate';

function password() {
    extend("password", {
        validate(value) {
            // return value && value.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value)
            return /^(?=.*[A-Z])(?=(?:.*[A-Z]){3})(?=.*[a-z])(?=(?:.*[a-z]){3})(?=.*\d)(?=(?:.*\d){3}).{10,}$/.test(value)
        },
        // message: field => `${field} must contain 8 characters, at least 1 upper/lower case character and number`,
        message: field => `${field} must be at least 10 characters long, contain 3 upper case, 3 lower case characters and 3 digits`,
    });
}

export default password