import Vue from 'vue'
import VueRouter from 'vue-router'
import permissions from './permissions'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: () => import('@/views/layouts/admin'),
            children: [
                { path: 'admin', component: () => import('@/views/admin'),
                    children: [
                        { path: '',            name: 'home',      beforeEnter: (to, from, next) => next({ name: 'dashboard', replace: true }) },
                        { path: '',            name: 'admin',     beforeEnter: (to, from, next) => next({ name: 'dashboard', replace: true }) },
                        { path: 'dashboard',   name: 'dashboard',      component: () => import('@/views/admin/dashboard') },
                        { path: 'user-edit',   name: 'user-edit',      component: () => import('@/views/user') },

                        { path: 'customers', name: 'customers', component: () => import('@/views/admin/customers'), redirect: { name: 'customers-list' },
                            children: [
                                { path: 'list', component: () => import('@/views/admin/customers'),
                                    children: [
                                        { path: ':page(\\d+)?',     name: 'customers-list',     component: () => import('@/views/admin/customers/list') },
                                        { path: ':customerUUID',    component: () => import('@/views/admin/customers/view'),
                                            children: [
                                                { path: '',                             name: 'customer-details',           component: () => import('@/views/admin/customers/view/details') },
                                                { path: 'history',                      name: 'payment-history',            component: () => import('@/views/admin/customers/view/payment') },
                                                { path: 'history-details',              name: 'payment-history-details',    component: () => import('@/views/admin/customers/view/payment/details') },
                                                { path: 'payment-refund',               name: 'payment-refund',             component: ()=> import('@/views/admin/customers/view/payment/refund') },
                                                { path: 'edit',                         name: 'customer-edit',              component: () => import('@/views/admin/customers/view/edit') },
                                                { path: 'invoice',                      name: 'invoice-details',            component: () => import('@/views/admin/customers/view/invoice') },
                                                { path: 'receipt',                      name: 'receipt-details',            component: () => import('@/views/admin/customers/view/receipt') },
                                                { path: 'add-customer-group',           name: 'add-customer-group',         component: () => import('@/views/admin/customers/view/groups/add') },
                                                { path: 'edit-customer-group',          name: 'edit-customer-group',        component: () => import('@/views/admin/customers/view/groups/edit') },
                                                { path: 'details-customer-group',       name: 'details-customer-group',     component: () => import('@/views/admin/customers/view/groups/details') },
                                                { path: 'details-customer-identity',    name: 'details-customer-identity',  component: () => import('@/views/admin/customers/view/identity/details') },
                                                { path: 'bill-route-add',               name: 'bill-route-add',             component: () => import('@/views/admin/customers/view/bill-route/add') },
                                                { path: ':billingRouteUUID',    component: () => import('@/views/admin/customers/view/bill-route'),
                                                    children: [
                                                        { path: 'bill-route-edit', name: 'bill-route-edit', component: () => import('@/views/admin/customers/view/bill-route/edit') }
                                                    ]
                                                },
                                            ]
                                        },
                                        { path: 'add', name: 'add-customer', component: () => import('@/views/admin/customers/add'), beforeEnter: permissions(['allowed-write-customers']) },
                                    ],
                                    beforeEnter: permissions(['allowed-customers'])
                                },

                                { path: 'groups', component: () => import('@/views/admin/customer-groups'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'customer-groups',    component: ()=> import('@/views/admin/customer-groups/list') },
                                        { path: ':customerGroupUUID',                       component: ()=> import('@/views/admin/customer-groups/view'),
                                            children: [
                                                { path: '',     name: 'customer-group-detail',  component: () => import('@/views/admin/customer-groups/view/details') },
                                                { path: 'edit', name: 'edit-group-customer',    component: () => import('@/views/admin/customer-groups/view/edit') },
                                            ]
                                        },
                                        { path: 'add',  name: 'add-group-customer',  component: () => import('@/views/admin/customer-groups/add') },
                                    ],
                                    beforeEnter: permissions(['is-support-customer-groups'])
                                },
                            ]
                        },

                        { path: 'services', name: 'services', component: () => import('@/views/admin/services'), redirect: { name: 'services-list' },
                            children: [
                                { path: 'list', component: () => import('@/views/admin/services'),
                                    children: [
                                        { path: ':page(\\d+)?',                 name: 'services-list',              component: () => import('@/views/admin/services/list') },
                                        { path: ':uuid',                        name: 'service-details',            component: () => import('@/views/admin/services/details') },
                                        { path: 'change-msisdn',                name: 'change-msisdn',              component: () => import('@/views/admin/services/view/change-msisdn') },
                                        { path: 'add-plan',                     name: 'add-plan',                   component: ()=> import('@/views/admin/services/view/add-plan'), beforeEnter: permissions(['allowed-change-plan']) },
                                        { path: 'change-plan',                  name: 'change-plan',                component: () => import('@/views/admin/services/view/change-plan'), beforeEnter: permissions(['allowed-change-plan']) },
                                        { path: 'sim-control-panel',            name: 'sim-control-panel',          component: ()=> import('@/views/admin/services/view/sim-control-panel') },
                                        { path: 'sim-swap',                     name: 'sim-swap',                   component: () => import('@/views/admin/services/view/sim-swap') },
                                        { path: 'vas-settings',                 name: 'vas-settings',               component: () => import('@/views/admin/services/view/vas-settings') },
                                        { path: 'voucher-redeem',               name: 'voucher-redeem',             component: () => import('@/views/admin/services/view/voucher-redeem'), beforeEnter: permissions(['allowed-redeem-voucher'])},
                                        { path: 'migration',                    name: 'service-migration',          component: () => import('@/views/admin/services/view/migration') },
                                        { path: 'add-mobile-customer-group',    name: 'add-mobile-customer-group',  component: () => import('@/views/admin/services/view/groups/add') },
                                        { path: 'edit-mobile-customer-group',   name: 'edit-mobile-customer-group', component: () => import('@/views/admin/services/view/groups/edit') },
                                        { path: 'details-mobile-group',         name: 'details-mobile-group',       component: () => import('@/views/admin/services/view/groups/details') },
                                    ]
                                },
                                { path: 'usage/:productInstanceUUID/:customerUUID', name: 'usage', component: () => import('@/views/admin/services/usage') },

                                { path: 'mobile-groups', component: () => import('@/views/admin/mobile-groups'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'mobile-groups',  component: ()=> import('@/views/admin/mobile-groups/list') },
                                        { path: ':mobileGroupUUID', component: ()=> import('@/views/admin/mobile-groups/view'),
                                            children: [
                                                { path: '',     name: 'mobile-group-detail',    component: () => import('@/views/admin/mobile-groups/view/details') },
                                                { path: 'edit', name: 'edit-mobile-group',      component: () => import('@/views/admin/mobile-groups/view/edit') },
                                            ]
                                        },
                                        { path: 'add',  name: 'add-mobile-group',  component: () => import('@/views/admin/mobile-groups/add') },
                                    ],
                                    beforeEnter: permissions(['is-support-groups'])
                                }
                            ]
                        },

                        { path: 'products', component: () => import('@/views/admin/products'),
                            children: [
                                { path: ':page(\\d+)?',     name: 'products',           component: () => import('@/views/admin/products/list') },
                                { path: ':uuid/:availuuid', name: 'product-details',    component: () => import('@/views/admin/products/details') },
                            ],
                            beforeEnter: permissions(['is-sp-admin'])
                        },

                        { path: 'orders', component: () => import('@/views/admin/orders'),
                            children: [
                                {  path: ':page(\\d+)?', name: 'orders', component: () => import('@/views/admin/orders/list')  }
                            ]
                        },

                        { path: 'ufb', name: 'ufb', component: () => import('@/views/admin/ufb'),
                            children: [
                                { path: 'services', component: () => import('@/views/admin/ufb/services'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'ufb-services',           component: ()=> import('@/views/admin/ufb/services/list') },
                                        { path: ':uuid',        name: 'ufb-service-details',    component: ()=> import('@/views/admin/ufb/services/details') },
                                        { path: ':uuid/notes',  name: 'ufb-service-notes',      component: ()=> import('@/views/admin/ufb/services/notes') },
                                    ]
                                },
                                { path: 'orders', component: () => import('@/views/admin/ufb/orders'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'ufb-orders',         component: ()=> import('@/views/admin/ufb/orders/list') },
                                        { path: ':uuid',        name: 'ufb-order-details',  component: ()=> import('@/views/admin/ufb/orders/details') },
                                        { path: ':uuid/notes',  name: 'ufb-order-notes',      component: ()=> import('@/views/admin/ufb/orders/notes') },
                                    ]
                                }
                            ],
                            beforeEnter: permissions(['allowed-ufb'])
                        },

                        { path: 'tickets', name: 'tickets', component: () => import('@/views/admin/tickets'), redirect: { name: 'tickets-dashboard' },
                            children: [
                                { path: 'dashboard',        name: 'tickets-dashboard',      component: () => import('@/views/admin/tickets/dashboard') },
                                { path: 'view',  component: () => import('@/views/admin/tickets/view'),
                                    children: [
                                        { path: ':page(\\d+)?',             name: 'tickets-all',        component: () => import('@/views/admin/tickets/list') },
                                        { path: 'new/:page(\\d+)?',         name: 'tickets-new',        component: () => import('@/views/admin/tickets/list') },
                                        { path: 'open/:page(\\d+)?',        name: 'tickets-open',       component: () => import('@/views/admin/tickets/list') },
                                        { path: 'pending/:page(\\d+)?',     name: 'tickets-pending',    component: () => import('@/views/admin/tickets/list') },
                                        { path: 'hold/:page(\\d+)?',        name: 'tickets-hold',       component: () => import('@/views/admin/tickets/list') },
                                        { path: 'solved/:page(\\d+)?',      name: 'tickets-solved',     component: () => import('@/views/admin/tickets/list') },
                                        { path: 'closed/:page(\\d+)?',      name: 'tickets-closed',     component: () => import('@/views/admin/tickets/list') },
                                        { path: 'id-:ticketId',             name: 'tickets-details',    component: () => import('@/views/admin/tickets/details') },
                                    ]
                                },
                            ],
                            beforeEnter: permissions(['allowed-write-customers'])
                        },

                        { path: 'vouchers', component: () => import('@/views/admin/vouchers'),
                            children: [
                                { path: ':page(\\d+)?', name: 'vouchers',       component: () => import('@/views/admin/vouchers/list') },
                                { path: 'create',       name: 'voucher-create', component: () => import('@/views/admin/vouchers/create'),
                                    beforeEnter: permissions(['allowed-create-voucher'])
                                },
                                { path: ':uuid', component: () => import('@/views/admin/vouchers/details'),
                                    children: [
                                        { path: '', name: 'voucher-details',   component: () => import('@/views/admin/vouchers/details') },
                                    ]
                                },
                            ],
                            beforeEnter: permissions(['allowed-redeem-voucher'])
                        },

                        { path: 'commercial-view', name: 'commercial-view', component: () => import('@/views/admin/commercial-view'),
                            beforeEnter: permissions(['is-support-commercial-view'])
                        },

                        { path: 'number-porting', component: () => import('@/views/admin/number-porting'),
                            children: [
                                { path: ':page(\\d+)?', name: 'number-porting',     component: () => import('@/views/admin/number-porting/list') },
                                { path: 'create',       name: 'create-porting',     component: () => import('@/views/admin/number-porting/create') },
                                { path: ':uuid',  component: () => import('@/views/admin/number-porting'),
                                    children: [
                                        { path: '',                         name: 'porting-details',            component: () => import('@/views/admin/number-porting/details') },
                                        { path: 'cancel-port',              name: 'cancel-port',                component: () => import('@/views/admin/number-porting/cancel') },
                                        { path: 'reschedule-port',          name: 'reschedule-port',            component: () => import('@/views/admin/number-porting/reschedule') },
                                        { path: 'reschedule-port-confirm',  name: 'reschedule-port-confirm',    component: () => import('@/views/admin/number-porting/reschedule-confirm') },
                                    ]
                                },
                            ],
                            beforeEnter: permissions(['allowed-customers'])
                        },

                        { path: 'administration', name: 'administration', component: () => import('@/views/admin/administration'), redirect: { name: 'user-management' },
                            children: [
                                { path: 'user-management', name: 'user-management',  component: () => import('@/views/admin/administration/user-management'), redirect: { name: 'teams' },
                                    children: [
                                        { path: 'teams', component: () => import('@/views/admin/administration/user-management'),
                                            children: [
                                                { path: ':page(\\d+)?',     name: 'teams',          component: () => import('@/views/admin/administration/user-management/user') },
                                                { path: ':uuid',            name: 'team-details',   component: () => import('@/views/admin/administration/user-management/team/details') },
                                                { path: 'team-add',         name: 'team-add',       component: () => import('@/views/admin/administration/user-management/team/add') },
                                                { path: ':uuid/team-edit',  name: 'team-edit',      component: () => import('@/views/admin/administration/user-management/team/edit') },
                                            ]
                                        },
                                        {  path: 'members', component: () => import('@/views/admin/administration/user-management'),
                                            children: [
                                                {  path: ':page(\\d+)?',    name: 'members',        component: () => import('@/views/admin/administration/user-management/user') },
                                                {  path: ':uuid',           name: 'member-details', component: () => import('@/views/admin/administration/user-management/member/details') },
                                                {  path: 'member-add',      name: 'member-add',     component: () => import('@/views/admin/administration/user-management/member/add') }
                                            ]
                                        },
                                    ]
                                },
                                { path: 'api-key-management', component: () => import('@/views/admin/administration/api-key-management'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'api-key-management', component: () => import('@/views/admin/administration/api-key-management/list') },
                                        { path: 'add',          name: 'api-key-add',        component: () => import('@/views/admin/administration/api-key-management/add') },
                                        { path: 'edit',         name: 'api-key-edit',       component: () => import('@/views/admin/administration/api-key-management/edit') },
                                    ],
                                    beforeEnter: permissions(['is-support-api-key-management'])
                                },
                                {
                                    path: 'ivr', component: () => import('@/views/admin/administration/mobile-number-search'),
                                    children: [
                                        { path: ':mobileNumber?', name: 'mobile-number-search', component: () => import('@/views/admin/administration/mobile-number-search/list') },
                                    ]
                                }, {
                                    path: 'email-templates', component: () => import('@/views/admin/administration/email-templates'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'email-templates',        component: () => import('@/views/admin/administration/email-templates/list') },
                                        { path: 'add',          name: 'email-template-add',     component: () => import('@/views/admin/administration/email-templates/template') },
                                        { path: ':uuid',                                        component: () => import('@/views/admin/administration/email-templates/template'),
                                            children: [
                                                { path: '',         name: 'email-template-details', component: () => import('@/views/admin/administration/email-templates/template/details') },
                                                { path: 'email',    name: 'email-template-email',   component: () => import('@/views/admin/administration/email-templates/template/email') },
                                            ]
                                        },
                                    ],
                                    beforeEnter: permissions(['is-pre-release-admin'])
                                },
                            ],
                            beforeEnter: permissions(['is-sp-admin'])
                        },

                        { path: 'preactivation', name: 'preactivation', component: () => import('@/views/admin/preactivation'), redirect: { name: 'mobile-products' },
                            children: [
                                { path: 'mobile-products', component: () => import('@/views/admin/preactivation/mobile-products'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'mobile-products',            component: () => import('@/views/admin/preactivation/mobile-products/list') },
                                        { path: ':uuid',        name: 'mobile-products-details',    component: () => import('@/views/admin/preactivation/mobile-products/details') },
                                    ],
                                    beforeEnter: permissions(['is-sp-admin'])
                                },
                                { path: 'preactivate-sim', name: 'preactivate-sim', component: () => import('@/views/admin/preactivation/preactivate-sim'), beforeEnter: permissions(['allowed-write-customers']) },
                                { path: 'reset-sim',       name: 'reset-sim',       component: ()=> import('@/views/admin/preactivation/reset-sim'),        beforeEnter: permissions(['allowed-reset-sim']) },
                            ],
                        },

                        { path: 'reconciliations', name: 'reconciliations', component: () => import('@/views/admin/reconciliations'), redirect: { name: 'transactions' },
                            children: [
                                { path: 'transactions', component: () => import('@/views/admin/reconciliations/transactions'),
                                    children: [
                                        { path: ':page(\\d+)?',                 name: 'transactions',               component: () => import('@/views/admin/reconciliations/transactions/list') },
                                        { path: ':uuid',                        name: 'transaction-details',        component: () => import('@/views/admin/reconciliations/transactions/details') },
                                        { path: 'add',                          name: 'add-transaction',            component: () => import('@/views/admin/reconciliations/transactions/add') },
                                        { path: 'void',                         name: 'void-transaction',           component: () => import('@/views/admin/reconciliations/transactions/void') },
                                        { path: 'un-reconcile',                 name: 'un-reconcile-transaction',   component: () => import('@/views/admin/reconciliations/transactions/un-reconcile') },
                                        { path: 'reconcile/:uuid',              name: 'reconcile-transaction',      component: () => import('@/views/admin/reconciliations/transactions/reconcile') },
                                    ]
                                },
                                // { path: 'debts', component: () => import('@/views/admin/reconciliations/debts'),
                                //     children: [
                                //         { path: '',                 name: 'debts',          component: () => import('@/views/admin/reconciliations/debts/list') },
                                //         { path: 'reconcile-debt',   name: 'reconcile-debt', component: () => import('@/views/admin/reconciliations/debts/reconcile') },
                                //     ]
                                // },
                                { path: 'debt-accounts', component: () => import('@/views/admin/reconciliations/debt-accounts'),
                                    children: [
                                        { path: ':page(\\d+)?',              name: 'debt-accounts',          component: () => import('@/views/admin/reconciliations/debt-accounts/list') },
                                        { path: 'reconcile/:customerUUID',   name: 'reconcile-debt-account', component: () => import('@/views/admin/reconciliations/debt-accounts/reconcile') }
                                    ]
                                }
                            ],
                            beforeEnter: permissions(['allowed-reconciliation'])
                        },
                        { path: 'reports', component: () => import('@/views/admin/reports'),
                            children: [
                                { path: 'revenue', component: () => import('@/views/admin/reports/revenue'),
                                    children: [
                                        { path: '', name: 'revenue-report', component: () => import('@/views/admin/reports/revenue/list') },
                                    ]
                                }
                            ],
                            beforeEnter: permissions(['is-pre-release-admin'])
                        }, { path: 'discounts', component: () => import('@/views/admin/discounts'),
                            children: [
                                { path: ':page(\\d+)?', name: 'discounts',      component: () => import('@/views/admin/discounts/list') },
                                { path: 'add-discount', name: 'add-discount',   component: () => import('@/views/admin/discounts/add') },
                                { path: ':offerID', component: () => import('@/views/admin/discounts/view'),
                                    children: [
                                        { path: '',     name: 'discount-details',   component: () => import('@/views/admin/discounts/view/details') },
                                        { path: 'edit', name: 'discount-edit',      component: () => import('@/views/admin/discounts/view/edit') },
                                    ]
                                }
                            ]
                        },
                        { path: 'search-results/:page(\\d+)?', name: 'search-results', component: () => import('@/views/admin/search-results') },
                        { path: 'test', name: 'test', component: () => import('@/views/admin/test'), beforeEnter: permissions(['is-pre-release-admin']) }
                    ]
                },

                { path: 'eshop', name: 'eshop', component: () => import('@/views/eshop'), redirect: { name: 'categories-cruds' },
                    children: [
                        { path: 'categories', component: () => import('@/views/eshop/categories'),
                            children: [
                                { path: ':page(\\d+)?',     name: 'categories-cruds',   component: () => import('@/views/eshop/categories/list') },
                                { path: 'add',              name: 'add-category',       component: () => import('@/views/eshop/categories/add') },
                                { path: 'edit/:uuid',       name: 'edit-category',      component: () => import('@/views/eshop/categories/edit') },
                            ],
                            beforeEnter: permissions(['is-pre-release-admin'])
                        },
                        { path: 'products', component: () => import('@/views/eshop/products'),
                            children: [
                                { path: ':page(\\d+)?', name: 'merchandise-products',  component: () => import('@/views/eshop/products/list') },
                                { path: 'add',          name: 'add-product',           component: () => import('@/views/eshop/products/add') },
                                { path: 'edit/:uuid',   name: 'edit-product',          component: () => import('@/views/eshop/products/edit') },
                            ],
                            beforeEnter: permissions(['is-pre-release-admin'])
                        },
                        { path: 'templates', name: 'templates', component: () => import('@/views/eshop/templates'), redirect: { name: 'product-templates' },
                            children: [
                                { path: 'product-templates', component: () => import('@/views/eshop/templates/products'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'product-templates',      component: () => import('@/views/eshop/templates/products/list') },
                                        { path: 'add',          name: 'add-product-template',   component: () => import('@/views/eshop/templates/products/add') },
                                        { path: 'edit/:uuid',   name: 'edit-product-template',  component: () => import('@/views/eshop/templates/products/edit') },
                                    ]
                                },
                                { path: 'template-attributes', component: () => import('@/views/eshop/templates/attributes'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'template-attributes',        component: () => import('@/views/eshop/templates/attributes/list') },
                                        { path: 'add',          name: 'add-template-attribute',     component: () => import('@/views/eshop/templates/attributes/add') },
                                        { path: 'edit/:uuid',   name: 'edit-template-attribute',    component: () => import('@/views/eshop/templates/attributes/edit') },
                                    ]
                                }
                            ],
                            beforeEnter: permissions(['is-pre-release-admin'])
                        },
                        { path: 'variants', name: 'variants', component: () => import('@/views/eshop/variants'), redirect: { name: 'product-variants' },
                            children: [
                                { path: 'product-variants', component: () => import('@/views/eshop/variants/products'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'product-variants',  component: () => import('@/views/eshop/variants/products/list') },
                                    ]
                                },
                                { path: 'variant-attributes', component: () => import('@/views/eshop/variants/attributes'),
                                    children: [
                                        { path: ':page(\\d+)?', name: 'variant-attributes',  component: () => import('@/views/eshop/variants/attributes/list') },
                                    ]
                                }
                            ],
                            beforeEnter: permissions(['is-pre-release-admin'])
                        },
                        { path: 'product-orders', component: () => import('@/views/eshop/orders'),
                            children: [
                                { path: ':page(\\d+)?', name: 'product-orders',         component: () => import('@/views/eshop/orders/list') },
                                { path: ':number',      name: 'details-product-order',  component: () => import('@/views/eshop/orders/details') },
                                { path: 'create',       name: 'create-product-order',   component: () => import('@/views/eshop/orders/create') },
                            ],
                            beforeEnter: permissions(['allowed-eshop-orders'])
                        },
                        { path: 'shipments', name: 'shipments', component: () => import('@/views/eshop/shipments'), redirect: { name: 'shipments-list' },
                            children: [
                                { path: 'list/:page(\\d+)?',        name: 'shipments-list',    component: () => import('@/views/eshop/shipments/list') },
                                { path: 'dispatch/:page(\\d+)?',    name: 'shipment-dispatch', component: () => import('@/views/eshop/shipments/list') },
                                { path: ':id',                      name: 'shipment-details',  component: () => import('@/views/eshop/shipments/details') },
                                { path: ':id/process',              name: 'shipment-process',  component: () => import('@/views/eshop/shipments/process') },
                            ],
                            beforeEnter: permissions(['is-admin-and-premode'])
                        },
                    ],
                },

                { path: 'change-password', name: 'change-password', component: () => import('@/views/change-password') },

                { path: '404', name: 'not-found', component: () => import('@/views/not-found') },

            ],
            beforeEnter: permissions(['is-auth', 'is-tos'])
        },

        { path: '/', component: () => import('@/views/layouts/authorization'),
            children: [
                { path: 'sign-in',                      name: 'sign-in',                component: () => import('@/views/sign-in'), props: { users: true } },
                { path: 'forgot-password',              name: 'forgot-password',        component: () => import('@/views/forgot-password') },
                { path: 'reset_password/:token',        name: 'create-password',        component: () => import('@/views/create-password') },
                { path: 'email-verification/:token',    name: 'email-verification',     component: () => import('@/views/email-verification') },
            ],
            beforeEnter: permissions(['!is-auth'], { name: 'dashboard' })
        },

        { path: '/tos', component: () => import('@/views/layouts/service'),
            children: [
                { path: '', name: 'tos', component: () => import('@/views/tos')}
            ],
            beforeEnter: permissions(['is-auth'])
        },

        { path: '*', redirect: { name: 'not-found' },},
    ],
    scrollBehavior(to, from, savedPosition) {
        let scroll = { x: 0, y: 0 };

        if (to.hash) {
            scroll = { selector: to.hash }
        } else if (savedPosition) {
            scroll = savedPosition
        }
        return scroll
    },
})

export default router
