const VUE_APP_SA_EMAIL_BUILDER_URL = process.env.VUE_APP_SA_EMAIL_BUILDER_URL

const state = {}

const getters = {
    sa_email_builder_url: state => VUE_APP_SA_EMAIL_BUILDER_URL,
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}