// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: eshop.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Categories: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	CategorySearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	Items: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemAttributeSets: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemAttributeSetAttributes: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemCategories: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemImages: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemImageSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemVariants: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemVariantSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ItemVariantAttributes: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getCategoryByUUID: state => (UUID) => (state.Categories.UUIDs[ UUID ] && !state.Categories.UUIDs[ UUID ].expired()) ?  state.Categories.UUIDs[ UUID ].hashedData() : null,
	getCategoriesForSearchText: state => (SPIDwithSearchText) => state.CategorySearches[ SPIDwithSearchText ] ? state.CategorySearches[ SPIDwithSearchText ] : null,
	getItemByUUID: state => (UUID) => (state.Items.UUIDs[ UUID ] && !state.Items.UUIDs[ UUID ].expired()) ?  state.Items.UUIDs[ UUID ].hashedData() : null,
	getItemsForSearchText: state => (SPIDwithSearchText) => state.ItemSearches[ SPIDwithSearchText ] ? state.ItemSearches[ SPIDwithSearchText ] : null,
	getItemAttributeSetByUUID: state => (UUID) => (state.ItemAttributeSets.UUIDs[ UUID ] && !state.ItemAttributeSets.UUIDs[ UUID ].expired()) ?  state.ItemAttributeSets.UUIDs[ UUID ].hashedData() : null,
	getItemAttributeSetAttributeByUUID: state => (UUID) => (state.ItemAttributeSetAttributes.UUIDs[ UUID ] && !state.ItemAttributeSetAttributes.UUIDs[ UUID ].expired()) ?  state.ItemAttributeSetAttributes.UUIDs[ UUID ].hashedData() : null,
	getItemCategoryByUUID: state => (UUID) => (state.ItemCategories.UUIDs[ UUID ] && !state.ItemCategories.UUIDs[ UUID ].expired()) ?  state.ItemCategories.UUIDs[ UUID ].hashedData() : null,
	getItemImageByUUID: state => (UUID) => (state.ItemImages.UUIDs[ UUID ] && !state.ItemImages.UUIDs[ UUID ].expired()) ?  state.ItemImages.UUIDs[ UUID ].hashedData() : null,
	getItemImagesForSearchText: state => (SPIDwithSearchText) => state.ItemImageSearches[ SPIDwithSearchText ] ? state.ItemImageSearches[ SPIDwithSearchText ] : null,
	getItemVariantByUUID: state => (UUID) => (state.ItemVariants.UUIDs[ UUID ] && !state.ItemVariants.UUIDs[ UUID ].expired()) ?  state.ItemVariants.UUIDs[ UUID ].hashedData() : null,
	getItemVariantsForSearchText: state => (SPIDwithSearchText) => state.ItemVariantSearches[ SPIDwithSearchText ] ? state.ItemVariantSearches[ SPIDwithSearchText ] : null,
	getItemVariantAttributeByUUID: state => (UUID) => (state.ItemVariantAttributes.UUIDs[ UUID ] && !state.ItemVariantAttributes.UUIDs[ UUID ].expired()) ?  state.ItemVariantAttributes.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveCategory saves a single Category object
	// into both the StoreHash cache and the VueX store
	mutSaveCategory(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Categories.UUIDs)) {
			state.Categories.UUIDs[ obj.UUID ] = new StoreHash(state.Categories.lifetime)
		}
		state.Categories.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCategoryByUUID removes a Category object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCategoryByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.Categories.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCategorySearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveCategorySearch(state, { CacheKey, SPID, SearchText, Response }) {
		if (!CacheKey || !SearchText || !Response) { return } // Must have CacheKey + SearchText + Response to Save object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Categories)) {
			for (let i = 0; i < Response.Categories.length; i++) {
				storedObj.UUIDs.push(Response.Categories[i].UUID)
			}
		}
		state.CategorySearches[ CacheKey ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(CacheKey in state.CategorySearches.SearchTexts)) {
			state.CategorySearches.SearchTexts[ CacheKey ] = new StoreHash(state.CategorySearches.lifetime)
		}
		state.CategorySearches.SearchTexts[ CacheKey ].fix() // Add item to StoreHash
	},
	// mutRemoveCategorySearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveCategorySearch(state, CacheKey) {
		if (!CacheKey) { return } // Must have CacheKey to Remove
		state.CategorySearches[ CacheKey ] = null               // cause VueX to trigger a data cascade
		delete state.CategorySearches[ CacheKey ]               // remove item from VueX state
		delete state.CategorySearches.SearchTexts[ CacheKey ]  // remove item from StoreHash
	},
	// mutSaveCategories saves a paginated response of Categories
	mutSaveCategories(state, { Response }) {
		if (!Response || !Array.isArray(Response.Categories)) { return } // Must have Response object with results
		for (let i = 0; i < Response.Categories.length; i++) {
			if (!(Response.Categories[i].UUID in state.Categories.UUIDs)) {
				state.Categories.UUIDs[ Response.Categories[i].UUID ] = new StoreHash(state.Categories.lifetime)
			}
			state.Categories.UUIDs[ Response.Categories[i].UUID ].fix(Response.Categories[i])  // Add single Category to StoreHash
		}
	},
	// mutSaveItem saves a single Item object
	// into both the StoreHash cache and the VueX store
	mutSaveItem(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Items.UUIDs)) {
			state.Items.UUIDs[ obj.UUID ] = new StoreHash(state.Items.lifetime)
		}
		state.Items.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemByUUID removes a Item object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.Items.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveItemSearch(state, { CacheKey, SPID, SearchText, Response }) {
		if (!CacheKey || !SearchText || !Response) { return } // Must have CacheKey + SearchText + Response to Save object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Items)) {
			for (let i = 0; i < Response.Items.length; i++) {
				storedObj.UUIDs.push(Response.Items[i].UUID)
			}
		}
		state.ItemSearches[ CacheKey ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(CacheKey in state.ItemSearches.SearchTexts)) {
			state.ItemSearches.SearchTexts[ CacheKey ] = new StoreHash(state.ItemSearches.lifetime)
		}
		state.ItemSearches.SearchTexts[ CacheKey ].fix() // Add item to StoreHash
	},
	// mutRemoveItemSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveItemSearch(state, CacheKey) {
		if (!CacheKey) { return } // Must have CacheKey to Remove
		state.ItemSearches[ CacheKey ] = null               // cause VueX to trigger a data cascade
		delete state.ItemSearches[ CacheKey ]               // remove item from VueX state
		delete state.ItemSearches.SearchTexts[ CacheKey ]  // remove item from StoreHash
	},
	// mutSaveItems saves a paginated response of Items
	mutSaveItems(state, { Response }) {
		if (!Response || !Array.isArray(Response.Items)) { return } // Must have Response object with results
		for (let i = 0; i < Response.Items.length; i++) {
			if (!(Response.Items[i].UUID in state.Items.UUIDs)) {
				state.Items.UUIDs[ Response.Items[i].UUID ] = new StoreHash(state.Items.lifetime)
			}
			state.Items.UUIDs[ Response.Items[i].UUID ].fix(Response.Items[i])  // Add single Item to StoreHash
		}
	},
	// mutSaveItemAttributeSet saves a single ItemAttributeSet object
	// into both the StoreHash cache and the VueX store
	mutSaveItemAttributeSet(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ItemAttributeSets.UUIDs)) {
			state.ItemAttributeSets.UUIDs[ obj.UUID ] = new StoreHash(state.ItemAttributeSets.lifetime)
		}
		state.ItemAttributeSets.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemAttributeSetByUUID removes a ItemAttributeSet object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemAttributeSetByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ItemAttributeSets.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemAttributeSets saves a paginated response of ItemAttributeSets
	mutSaveItemAttributeSets(state, { Response }) {
		if (!Response || !Array.isArray(Response.ItemAttributeSets)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ItemAttributeSets.length; i++) {
			if (!(Response.ItemAttributeSets[i].UUID in state.ItemAttributeSets.UUIDs)) {
				state.ItemAttributeSets.UUIDs[ Response.ItemAttributeSets[i].UUID ] = new StoreHash(state.ItemAttributeSets.lifetime)
			}
			state.ItemAttributeSets.UUIDs[ Response.ItemAttributeSets[i].UUID ].fix(Response.ItemAttributeSets[i])  // Add single ItemAttributeSet to StoreHash
		}
	},
	// mutSaveItemAttributeSetAttribute saves a single ItemAttributeSetAttribute object
	// into both the StoreHash cache and the VueX store
	mutSaveItemAttributeSetAttribute(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ItemAttributeSetAttributes.UUIDs)) {
			state.ItemAttributeSetAttributes.UUIDs[ obj.UUID ] = new StoreHash(state.ItemAttributeSetAttributes.lifetime)
		}
		state.ItemAttributeSetAttributes.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemAttributeSetAttributeByUUID removes a ItemAttributeSetAttribute object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemAttributeSetAttributeByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ItemAttributeSetAttributes.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemAttributeSetAttributes saves a paginated response of ItemAttributeSetAttributes
	mutSaveItemAttributeSetAttributes(state, { Response }) {
		if (!Response || !Array.isArray(Response.ItemAttributeSetAttributes)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ItemAttributeSetAttributes.length; i++) {
			if (!(Response.ItemAttributeSetAttributes[i].UUID in state.ItemAttributeSetAttributes.UUIDs)) {
				state.ItemAttributeSetAttributes.UUIDs[ Response.ItemAttributeSetAttributes[i].UUID ] = new StoreHash(state.ItemAttributeSetAttributes.lifetime)
			}
			state.ItemAttributeSetAttributes.UUIDs[ Response.ItemAttributeSetAttributes[i].UUID ].fix(Response.ItemAttributeSetAttributes[i])  // Add single ItemAttributeSetAttribute to StoreHash
		}
	},
	// mutSaveItemCategory saves a single ItemCategory object
	// into both the StoreHash cache and the VueX store
	mutSaveItemCategory(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ItemCategories.UUIDs)) {
			state.ItemCategories.UUIDs[ obj.UUID ] = new StoreHash(state.ItemCategories.lifetime)
		}
		state.ItemCategories.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemCategoryByUUID removes a ItemCategory object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemCategoryByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ItemCategories.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemCategories saves a paginated response of ItemCategories
	mutSaveItemCategories(state, { Response }) {
		if (!Response || !Array.isArray(Response.ItemCategories)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ItemCategories.length; i++) {
			if (!(Response.ItemCategories[i].UUID in state.ItemCategories.UUIDs)) {
				state.ItemCategories.UUIDs[ Response.ItemCategories[i].UUID ] = new StoreHash(state.ItemCategories.lifetime)
			}
			state.ItemCategories.UUIDs[ Response.ItemCategories[i].UUID ].fix(Response.ItemCategories[i])  // Add single ItemCategory to StoreHash
		}
	},
	// mutSaveItemImage saves a single ItemImage object
	// into both the StoreHash cache and the VueX store
	mutSaveItemImage(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ItemImages.UUIDs)) {
			state.ItemImages.UUIDs[ obj.UUID ] = new StoreHash(state.ItemImages.lifetime)
		}
		state.ItemImages.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemImageByUUID removes a ItemImage object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemImageByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ItemImages.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemImageSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveItemImageSearch(state, { CacheKey, SPID, SearchText, Response }) {
		if (!CacheKey || !SearchText || !Response) { return } // Must have CacheKey + SearchText + Response to Save object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ItemImages)) {
			for (let i = 0; i < Response.ItemImages.length; i++) {
				storedObj.UUIDs.push(Response.ItemImages[i].UUID)
			}
		}
		state.ItemImageSearches[ CacheKey ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(CacheKey in state.ItemImageSearches.SearchTexts)) {
			state.ItemImageSearches.SearchTexts[ CacheKey ] = new StoreHash(state.ItemImageSearches.lifetime)
		}
		state.ItemImageSearches.SearchTexts[ CacheKey ].fix() // Add item to StoreHash
	},
	// mutRemoveItemImageSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveItemImageSearch(state, CacheKey) {
		if (!CacheKey) { return } // Must have CacheKey to Remove
		state.ItemImageSearches[ CacheKey ] = null               // cause VueX to trigger a data cascade
		delete state.ItemImageSearches[ CacheKey ]               // remove item from VueX state
		delete state.ItemImageSearches.SearchTexts[ CacheKey ]  // remove item from StoreHash
	},
	// mutSaveItemImages saves a paginated response of ItemImages
	mutSaveItemImages(state, { Response }) {
		if (!Response || !Array.isArray(Response.ItemImages)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ItemImages.length; i++) {
			if (!(Response.ItemImages[i].UUID in state.ItemImages.UUIDs)) {
				state.ItemImages.UUIDs[ Response.ItemImages[i].UUID ] = new StoreHash(state.ItemImages.lifetime)
			}
			state.ItemImages.UUIDs[ Response.ItemImages[i].UUID ].fix(Response.ItemImages[i])  // Add single ItemImage to StoreHash
		}
	},
	// mutSaveItemVariant saves a single ItemVariant object
	// into both the StoreHash cache and the VueX store
	mutSaveItemVariant(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ItemVariants.UUIDs)) {
			state.ItemVariants.UUIDs[ obj.UUID ] = new StoreHash(state.ItemVariants.lifetime)
		}
		state.ItemVariants.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemVariantByUUID removes a ItemVariant object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemVariantByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ItemVariants.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemVariantSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveItemVariantSearch(state, { CacheKey, SPID, SearchText, Response }) {
		if (!CacheKey || !SearchText || !Response) { return } // Must have CacheKey + SearchText + Response to Save object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.ItemVariants)) {
			for (let i = 0; i < Response.ItemVariants.length; i++) {
				storedObj.UUIDs.push(Response.ItemVariants[i].UUID)
			}
		}
		state.ItemVariantSearches[ CacheKey ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(CacheKey in state.ItemVariantSearches.SearchTexts)) {
			state.ItemVariantSearches.SearchTexts[ CacheKey ] = new StoreHash(state.ItemVariantSearches.lifetime)
		}
		state.ItemVariantSearches.SearchTexts[ CacheKey ].fix() // Add item to StoreHash
	},
	// mutRemoveItemVariantSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveItemVariantSearch(state, CacheKey) {
		if (!CacheKey) { return } // Must have CacheKey to Remove
		state.ItemVariantSearches[ CacheKey ] = null               // cause VueX to trigger a data cascade
		delete state.ItemVariantSearches[ CacheKey ]               // remove item from VueX state
		delete state.ItemVariantSearches.SearchTexts[ CacheKey ]  // remove item from StoreHash
	},
	// mutSaveItemVariants saves a paginated response of ItemVariants
	mutSaveItemVariants(state, { Response }) {
		if (!Response || !Array.isArray(Response.ItemVariants)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ItemVariants.length; i++) {
			if (!(Response.ItemVariants[i].UUID in state.ItemVariants.UUIDs)) {
				state.ItemVariants.UUIDs[ Response.ItemVariants[i].UUID ] = new StoreHash(state.ItemVariants.lifetime)
			}
			state.ItemVariants.UUIDs[ Response.ItemVariants[i].UUID ].fix(Response.ItemVariants[i])  // Add single ItemVariant to StoreHash
		}
	},
	// mutSaveItemVariantAttribute saves a single ItemVariantAttribute object
	// into both the StoreHash cache and the VueX store
	mutSaveItemVariantAttribute(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ItemVariantAttributes.UUIDs)) {
			state.ItemVariantAttributes.UUIDs[ obj.UUID ] = new StoreHash(state.ItemVariantAttributes.lifetime)
		}
		state.ItemVariantAttributes.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveItemVariantAttributeByUUID removes a ItemVariantAttribute object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveItemVariantAttributeByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ItemVariantAttributes.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveItemVariantAttributes saves a paginated response of ItemVariantAttributes
	mutSaveItemVariantAttributes(state, { Response }) {
		if (!Response || !Array.isArray(Response.ItemVariantAttributes)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ItemVariantAttributes.length; i++) {
			if (!(Response.ItemVariantAttributes[i].UUID in state.ItemVariantAttributes.UUIDs)) {
				state.ItemVariantAttributes.UUIDs[ Response.ItemVariantAttributes[i].UUID ] = new StoreHash(state.ItemVariantAttributes.lifetime)
			}
			state.ItemVariantAttributes.UUIDs[ Response.ItemVariantAttributes[i].UUID ].fix(Response.ItemVariantAttributes[i])  // Add single ItemVariantAttribute to StoreHash
		}
	},
};

const actions = {
	// getCachedCategoryByUUID fetches a Category from the cache
	// and if not in the cache, fetches it from the API
	getCachedCategoryByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Categories.UUIDs[ UUID ] && !state.Categories.UUIDs[ UUID ].expired()) {			
			return state.Categories.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetCategoryByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCategory', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCategoryByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedCategoriesBySearch fetches Categories from the cache
	// and if not in the cache, fetches it from the API
	getCachedCategoriesBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		const cacheKey = SPID+':'+SearchText
		if (!RefreshCache && state.CategorySearches.SearchTexts[ cacheKey ] && !state.CategorySearches.SearchTexts[ cacheKey ].expired()) {
			// Return the cached records
			let resp = getters.getCategoriesForSearchText(cacheKey)
			resp.Categories = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Categories.push(getters.getCategoryByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetCategoriesPaginated', apiReq).then(respPayload => {
			commit('mutSaveCategorySearch', { CacheKey: cacheKey, SPID: SPID, SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCategorySearch', cacheKey)
			return Promise.reject(error)
		})
	},

	// getCachedItemByUUID fetches a Item from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Items.UUIDs[ UUID ] && !state.Items.UUIDs[ UUID ].expired()) {			
			return state.Items.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItem', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedItemsBySearch fetches Items from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		const cacheKey = SPID+':'+SearchText
		if (!RefreshCache && state.ItemSearches.SearchTexts[ cacheKey ] && !state.ItemSearches.SearchTexts[ cacheKey ].expired()) {
			// Return the cached records
			let resp = getters.getItemsForSearchText(cacheKey)
			resp.Items = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Items.push(getters.getItemByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetItemsPaginated', apiReq).then(respPayload => {
			commit('mutSaveItemSearch', { CacheKey: cacheKey, SPID: SPID, SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemSearch', cacheKey)
			return Promise.reject(error)
		})
	},

	// getCachedItemAttributeSetByUUID fetches a ItemAttributeSet from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemAttributeSetByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ItemAttributeSets.UUIDs[ UUID ] && !state.ItemAttributeSets.UUIDs[ UUID ].expired()) {			
			return state.ItemAttributeSets.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemAttributeSetByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItemAttributeSet', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemAttributeSetByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedItemAttributeSetAttributeByUUID fetches a ItemAttributeSetAttribute from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemAttributeSetAttributeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ItemAttributeSetAttributes.UUIDs[ UUID ] && !state.ItemAttributeSetAttributes.UUIDs[ UUID ].expired()) {			
			return state.ItemAttributeSetAttributes.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemAttributeSetAttributeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItemAttributeSetAttribute', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemAttributeSetAttributeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedItemCategoryByUUID fetches a ItemCategory from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemCategoryByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ItemCategories.UUIDs[ UUID ] && !state.ItemCategories.UUIDs[ UUID ].expired()) {			
			return state.ItemCategories.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemCategoryByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItemCategory', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemCategoryByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedItemImageByUUID fetches a ItemImage from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemImageByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ItemImages.UUIDs[ UUID ] && !state.ItemImages.UUIDs[ UUID ].expired()) {			
			return state.ItemImages.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemImageByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItemImage', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemImageByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedItemImagesBySearch fetches ItemImages from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemImagesBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		const cacheKey = SPID+':'+SearchText
		if (!RefreshCache && state.ItemImageSearches.SearchTexts[ cacheKey ] && !state.ItemImageSearches.SearchTexts[ cacheKey ].expired()) {
			// Return the cached records
			let resp = getters.getItemImagesForSearchText(cacheKey)
			resp.ItemImages = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ItemImages.push(getters.getItemImageByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetItemImagesPaginated', apiReq).then(respPayload => {
			commit('mutSaveItemImageSearch', { CacheKey: cacheKey, SPID: SPID, SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemImageSearch', cacheKey)
			return Promise.reject(error)
		})
	},

	// getCachedItemVariantByUUID fetches a ItemVariant from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemVariantByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ItemVariants.UUIDs[ UUID ] && !state.ItemVariants.UUIDs[ UUID ].expired()) {			
			return state.ItemVariants.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemVariantByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItemVariant', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemVariantByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedItemVariantsBySearch fetches ItemVariants from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemVariantsBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		const cacheKey = SPID+':'+SearchText
		if (!RefreshCache && state.ItemVariantSearches.SearchTexts[ cacheKey ] && !state.ItemVariantSearches.SearchTexts[ cacheKey ].expired()) {
			// Return the cached records
			let resp = getters.getItemVariantsForSearchText(cacheKey)
			resp.ItemVariants = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.ItemVariants.push(getters.getItemVariantByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetItemVariantsPaginated', apiReq).then(respPayload => {
			commit('mutSaveItemVariantSearch', { CacheKey: cacheKey, SPID: SPID, SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemVariantSearch', cacheKey)
			return Promise.reject(error)
		})
	},

	// getCachedItemVariantAttributeByUUID fetches a ItemVariantAttribute from the cache
	// and if not in the cache, fetches it from the API
	getCachedItemVariantAttributeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ItemVariantAttributes.UUIDs[ UUID ] && !state.ItemVariantAttributes.UUIDs[ UUID ].expired()) {			
			return state.ItemVariantAttributes.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetItemVariantAttributeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveItemVariantAttribute', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveItemVariantAttributeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: EshopRPC
	// 
	/**
	* RPC Method: AddCategory
	* Description: Add a Category object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/category
	* @param    {Category} req The API payload object (Category)
	* @return   {Category} The API response object (Category)
	*
	* @typedef  {Object}  Category
	* @property {String}  UUID               UUID of the Category
	* @property {Number}  SPID               Default Service Provider ID
	* @property {Number}  CreatedAtNanos     When the object was first created
	* @property {Number}  UpdatedAtNanos     When the object was last updated
	* @property {Number}  DeletedAtNanos     When the object was deleted
	* @property {String}  ParentCategoryUUID Parent Category UUID
	* @property {String}  Name               Category Name
	* @property {String}  Description        Category Description
	*
	*/
	AddCategory({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/category`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddCategory ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddCategory ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddCategory ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddCategory UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddCategory'))
			})
	},

	/**
	* RPC Method: AddItem
	* Description: Add a Item object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/eshop_item
	* @param    {Item} req The API payload object (Item)
	* @return   {Item} The API response object (Item)
	*
	* @typedef  {Object}  Item
	* @property {String}  UUID                 UUID of the Item
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  Name                 Item Name
	* @property {String}  Brand                Item Brand
	* @property {String}  Description          Item Description
	* @property {String}  ItemAttributeSetUUID 
	*
	*/
	AddItem({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/eshop_item`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItem ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItem ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItem ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItem UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItem'))
			})
	},

	/**
	* RPC Method: AddItemAttributeSet
	* Description: Add a ItemAttributeSet object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_attribute_set
	* @param    {ItemAttributeSet} req The API payload object (ItemAttributeSet)
	* @return   {ItemAttributeSet} The API response object (ItemAttributeSet)
	*
	* @typedef  {Object}  ItemAttributeSet
	* @property {String}  UUID           UUID of the ItemAttributeSet
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Name           ItemAttributeSet Name
	*
	*/
	AddItemAttributeSet({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/item_attribute_set`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemAttributeSet ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemAttributeSet ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemAttributeSet ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemAttributeSet UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemAttributeSet'))
			})
	},

	/**
	* RPC Method: AddItemAttributeSetAttribute
	* Description: Add a ItemAttributeSetAttribute object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_attribute_set_attribute
	* @param    {ItemAttributeSetAttribute} req The API payload object (ItemAttributeSetAttribute)
	* @return   {ItemAttributeSetAttribute} The API response object (ItemAttributeSetAttribute)
	*
	* @typedef  {Object}  ItemAttributeSetAttribute
	* @property {String}  UUID                 UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID
	* @property {Number}  Order                Order in which the attributes are displayed (by default)
	* @property {String}  Name                 ItemAttributeSet Name
	* @property {String}  Value                ItemAttributeSet Value
	* @property {ENUM}    Facet                Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE)
	* @property {Boolean} Searchable           Is this field Searchable
	* @property {ENUM}    VariantAttributeType VariantAttributeTypes defines the rules for VariantAttributes (VA_NONE | VA_OPTIONAL | VA_REQUIRED)
	* @property {ENUM}    ValueType            ValueType defines the input validation rules for the Value (VALUE_TEXT | VALUE_NUMBER | VALUE_PRICE)
	*
	*/
	AddItemAttributeSetAttribute({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/item_attribute_set_attribute`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemAttributeSetAttribute ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemAttributeSetAttribute ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemAttributeSetAttribute ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemAttributeSetAttribute UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemAttributeSetAttribute'))
			})
	},

	/**
	* RPC Method: AddItemAttributeSetAttributes
	* Description: Add multiple ItemAttributeSetAttribute objects
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_attribute_set_attributes
	* @param    {ItemAttributeSetAttributes} req The API payload object (ItemAttributeSetAttributes)
	* @return   {ItemAttributeSetAttributes} The API response object (ItemAttributeSetAttributes)
	*
	* @typedef  {Object}  ItemAttributeSetAttributes
	* @property {Number}  SPID                       Service Provider ID - always required
	* @property {String}  ItemAttributeSetUUID       
	* @property {ItemAttributeSetAttribute[]} ItemAttributeSetAttributes 
	*
	*/
	AddItemAttributeSetAttributes({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/eshop/item_attribute_set_attributes`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemAttributeSetAttributes ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemAttributeSetAttributes ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemAttributeSetAttributes ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemAttributeSetAttributes UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemAttributeSetAttributes'))
			})
	},

	/**
	* RPC Method: AddItemCategory
	* Description: Add a ItemCategory object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_category
	* @param    {ItemCategory} req The API payload object (ItemCategory)
	* @return   {ItemCategory} The API response object (ItemCategory)
	*
	* @typedef  {Object}  ItemCategory
	* @property {String}  UUID           UUID of the ItemCategory
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  CategoryUUID   Category UUID
	* @property {String}  ItemUUID       Item UUID
	*
	*/
	AddItemCategory({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/item_category`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemCategory ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemCategory ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemCategory ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemCategory UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemCategory'))
			})
	},

	/**
	* RPC Method: AddItemVariant
	* Description: Add a ItemVariant object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_variant
	* @param    {ItemVariant} req The API payload object (ItemVariant)
	* @return   {ItemVariant} The API response object (ItemVariant)
	*
	* @typedef  {Object}  ItemVariant
	* @property {String}  UUID                 UUID of the ItemVariant
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemUUID             
	* @property {String}  Name                 Item Variant Name
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID is inheritted from Item by default
	* @property {String[]} ItemImageUUIDs       Valid images for this Variant (up to 50 are supported, and if this is empty, all images are shown for this variant)
	*
	*/
	AddItemVariant({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/item_variant`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemVariant ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemVariant ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemVariant ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemVariant UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemVariant'))
			})
	},

	/**
	* RPC Method: AddItemVariantAttribute
	* Description: Add a ItemVariantAttribute object
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_variant_attribute
	* @param    {ItemVariantAttribute} req The API payload object (ItemVariantAttribute)
	* @return   {ItemVariantAttribute} The API response object (ItemVariantAttribute)
	*
	* @typedef  {Object}  ItemVariantAttribute
	* @property {String}  UUID            UUID of the ItemVariantAttribute
	* @property {Number}  SPID            Default Service Provider ID
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  ItemUUID        Item UUID
	* @property {String}  ItemVariantUUID 
	* @property {String}  Name            ItemAttributeSet Name
	* @property {String}  Value           ItemAttributeSet Value
	* @property {ENUM}    Facet           Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE) - inheritted
	* @property {Boolean} Searchable      Is this field Searchable - inherited
	*
	*/
	AddItemVariantAttribute({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/eshop/item_variant_attribute`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemVariantAttribute ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemVariantAttribute ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemVariantAttribute ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemVariantAttribute UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemVariantAttribute'))
			})
	},

	/**
	* RPC Method: AddItemVariantAttributes
	* Description: Add multiple ItemVariantAttribute objects
	* HTTP Method: POST
	* API Path: /api/v3/eshop/item_variant_attributes
	* @param    {ItemVariantAttributes} req The API payload object (ItemVariantAttributes)
	* @return   {ItemVariantAttributes} The API response object (ItemVariantAttributes)
	*
	* @typedef  {Object}  ItemVariantAttributes
	* @property {Number}  SPID                  Service Provider ID - always required
	* @property {String}  ItemVariantUUID       
	* @property {ItemVariantAttribute[]} ItemVariantAttributes 
	*
	*/
	AddItemVariantAttributes({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/eshop/item_variant_attributes`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddItemVariantAttributes ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddItemVariantAttributes ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddItemVariantAttributes ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddItemVariantAttributes UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddItemVariantAttributes'))
			})
	},

	/**
	* RPC Method: DeleteCategoryByUUID
	* Description: Deletes a Category object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/category/${req.UUID}
	* @param    {CategoryUUIDSearch} req The API payload object (CategoryUUIDSearch)
	* @return   {Category} The API response object (Category)
	*
	* @typedef  {Object}  CategoryUUIDSearch
	* @property {String}  UUID UUID of the Category
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Category
	* @property {String}  UUID               UUID of the Category
	* @property {Number}  SPID               Default Service Provider ID
	* @property {Number}  CreatedAtNanos     When the object was first created
	* @property {Number}  UpdatedAtNanos     When the object was last updated
	* @property {Number}  DeletedAtNanos     When the object was deleted
	* @property {String}  ParentCategoryUUID Parent Category UUID
	* @property {String}  Name               Category Name
	* @property {String}  Description        Category Description
	*
	*/
	DeleteCategoryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/category/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCategoryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCategoryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCategoryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCategoryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCategoryByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemAttributeSetAttributeByUUID
	* Description: Deletes a ItemAttributeSetAttribute object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_attribute_set_attribute/${req.UUID}
	* @param    {ItemAttributeSetAttributeUUIDSearch} req The API payload object (ItemAttributeSetAttributeUUIDSearch)
	* @return   {ItemAttributeSetAttribute} The API response object (ItemAttributeSetAttribute)
	*
	* @typedef  {Object}  ItemAttributeSetAttributeUUIDSearch
	* @property {String}  UUID UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemAttributeSetAttribute
	* @property {String}  UUID                 UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID
	* @property {Number}  Order                Order in which the attributes are displayed (by default)
	* @property {String}  Name                 ItemAttributeSet Name
	* @property {String}  Value                ItemAttributeSet Value
	* @property {ENUM}    Facet                Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE)
	* @property {Boolean} Searchable           Is this field Searchable
	* @property {ENUM}    VariantAttributeType VariantAttributeTypes defines the rules for VariantAttributes (VA_NONE | VA_OPTIONAL | VA_REQUIRED)
	* @property {ENUM}    ValueType            ValueType defines the input validation rules for the Value (VALUE_TEXT | VALUE_NUMBER | VALUE_PRICE)
	*
	*/
	DeleteItemAttributeSetAttributeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/item_attribute_set_attribute/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSetAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemAttributeSetAttributeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemAttributeSetAttributeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemAttributeSetAttributeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemAttributeSetAttributeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemAttributeSetAttributeByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemAttributeSetByUUID
	* Description: Deletes a ItemAttributeSet object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_attribute_set/${req.UUID}
	* @param    {ItemAttributeSetUUIDSearch} req The API payload object (ItemAttributeSetUUIDSearch)
	* @return   {ItemAttributeSet} The API response object (ItemAttributeSet)
	*
	* @typedef  {Object}  ItemAttributeSetUUIDSearch
	* @property {String}  UUID UUID of the ItemAttributeSet
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemAttributeSet
	* @property {String}  UUID           UUID of the ItemAttributeSet
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Name           ItemAttributeSet Name
	*
	*/
	DeleteItemAttributeSetByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/item_attribute_set/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSet', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemAttributeSetByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemAttributeSetByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemAttributeSetByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemAttributeSetByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemAttributeSetByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemByUUID
	* Description: Deletes a Item object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/eshop_item/${req.UUID}
	* @param    {ItemUUIDSearch} req The API payload object (ItemUUIDSearch)
	* @return   {Item} The API response object (Item)
	*
	* @typedef  {Object}  ItemUUIDSearch
	* @property {String}  UUID UUID of the Item
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Item
	* @property {String}  UUID                 UUID of the Item
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  Name                 Item Name
	* @property {String}  Brand                Item Brand
	* @property {String}  Description          Item Description
	* @property {String}  ItemAttributeSetUUID 
	*
	*/
	DeleteItemByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/eshop_item/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItem', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemCategoryByUUID
	* Description: Deletes a ItemCategory object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_category/${req.UUID}
	* @param    {ItemCategoryUUIDSearch} req The API payload object (ItemCategoryUUIDSearch)
	* @return   {ItemCategory} The API response object (ItemCategory)
	*
	* @typedef  {Object}  ItemCategoryUUIDSearch
	* @property {String}  UUID UUID of the ItemCategory
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemCategory
	* @property {String}  UUID           UUID of the ItemCategory
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  CategoryUUID   Category UUID
	* @property {String}  ItemUUID       Item UUID
	*
	*/
	DeleteItemCategoryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/item_category/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemCategoryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemCategoryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemCategoryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemCategoryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemCategoryByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemImageByUUID
	* Description: Deletes a ItemImage object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/${req.SPID}/eshop_item/${req.ItemUUID}/item_image/${req.UUID}
	* @param    {ItemImageUUIDSearch} req The API payload object (ItemImageUUIDSearch)
	* @return   {ItemImage} The API response object (ItemImage)
	*
	* @typedef  {Object}  ItemImageUUIDSearch
	* @property {String}  UUID     UUID of the ItemImage
	* @property {Number}  SPID     Service Provider ID - always required
	* @property {String}  ItemUUID Search by Item UUID
	*
	* @typedef  {Object}  ItemImage
	* @property {String}  UUID           UUID of the ItemImage
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  ItemUUID       
	* @property {String}  Name           Item Variant Name
	* @property {String}  URLs           
	*
	*/
	DeleteItemImageByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/${req.SPID}/eshop_item/${req.ItemUUID}/item_image/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemImage', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemImageByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemImageByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemImageByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemImageByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemImageByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemVariantAttributeByUUID
	* Description: Deletes a ItemVariantAttribute object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_variant_attribute/${req.UUID}
	* @param    {ItemVariantAttributeUUIDSearch} req The API payload object (ItemVariantAttributeUUIDSearch)
	* @return   {ItemVariantAttribute} The API response object (ItemVariantAttribute)
	*
	* @typedef  {Object}  ItemVariantAttributeUUIDSearch
	* @property {String}  UUID UUID of the ItemVariantAttribute
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemVariantAttribute
	* @property {String}  UUID            UUID of the ItemVariantAttribute
	* @property {Number}  SPID            Default Service Provider ID
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  ItemUUID        Item UUID
	* @property {String}  ItemVariantUUID 
	* @property {String}  Name            ItemAttributeSet Name
	* @property {String}  Value           ItemAttributeSet Value
	* @property {ENUM}    Facet           Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE) - inheritted
	* @property {Boolean} Searchable      Is this field Searchable - inherited
	*
	*/
	DeleteItemVariantAttributeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/item_variant_attribute/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariantAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemVariantAttributeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemVariantAttributeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemVariantAttributeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemVariantAttributeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemVariantAttributeByUUID'))
			})
	},

	/**
	* RPC Method: DeleteItemVariantByUUID
	* Description: Deletes a ItemVariant object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_variant/${req.UUID}
	* @param    {ItemVariantUUIDSearch} req The API payload object (ItemVariantUUIDSearch)
	* @return   {ItemVariant} The API response object (ItemVariant)
	*
	* @typedef  {Object}  ItemVariantUUIDSearch
	* @property {String}  UUID UUID of the ItemVariant
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemVariant
	* @property {String}  UUID                 UUID of the ItemVariant
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemUUID             
	* @property {String}  Name                 Item Variant Name
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID is inheritted from Item by default
	* @property {String[]} ItemImageUUIDs       Valid images for this Variant (up to 50 are supported, and if this is empty, all images are shown for this variant)
	*
	*/
	DeleteItemVariantByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/eshop/item_variant/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariant', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteItemVariantByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteItemVariantByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteItemVariantByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteItemVariantByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteItemVariantByUUID'))
			})
	},

	/**
	* RPC Method: GetCategoriesPaginated
	* Description: Searches for multiple Category objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/category
	* @param    {CategorySearchRequest} req The API payload object (CategorySearchRequest)
	* @return   {CategoryPageResponse} The API response object (CategoryPageResponse)
	*
	* @typedef  {Object}  CategorySearchRequest
	* @property {SearchOptions[]} SearchOptions      Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted     IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID               (optional) SPID
	* @property {String}  Name               Search by Name
	* @property {String}  ParentCategoryUUID Search by ParentCategoryUUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  CategoryPageResponse
	* @property {PageInfo} PageInfo   PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {Category[]} Categories 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetCategoriesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/category`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveCategories', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCategoriesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCategoriesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCategoriesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCategoriesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCategoriesPaginated'))
			})
	},

	/**
	* RPC Method: GetCategoryByUUID
	* Description: Get a single Category object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/category/${req.UUID}
	* @param    {CategoryUUIDSearch} req The API payload object (CategoryUUIDSearch)
	* @return   {Category} The API response object (Category)
	*
	* @typedef  {Object}  CategoryUUIDSearch
	* @property {String}  UUID UUID of the Category
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Category
	* @property {String}  UUID               UUID of the Category
	* @property {Number}  SPID               Default Service Provider ID
	* @property {Number}  CreatedAtNanos     When the object was first created
	* @property {Number}  UpdatedAtNanos     When the object was last updated
	* @property {Number}  DeletedAtNanos     When the object was deleted
	* @property {String}  ParentCategoryUUID Parent Category UUID
	* @property {String}  Name               Category Name
	* @property {String}  Description        Category Description
	*
	*/
	GetCategoryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/category/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCategoryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCategoryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCategoryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCategoryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCategoryByUUID'))
			})
	},

	/**
	* RPC Method: GetItemAttributeSetAttributeByUUID
	* Description: Get a single ItemAttributeSetAttribute object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_attribute_set_attribute/${req.UUID}
	* @param    {ItemAttributeSetAttributeUUIDSearch} req The API payload object (ItemAttributeSetAttributeUUIDSearch)
	* @return   {ItemAttributeSetAttribute} The API response object (ItemAttributeSetAttribute)
	*
	* @typedef  {Object}  ItemAttributeSetAttributeUUIDSearch
	* @property {String}  UUID UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemAttributeSetAttribute
	* @property {String}  UUID                 UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID
	* @property {Number}  Order                Order in which the attributes are displayed (by default)
	* @property {String}  Name                 ItemAttributeSet Name
	* @property {String}  Value                ItemAttributeSet Value
	* @property {ENUM}    Facet                Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE)
	* @property {Boolean} Searchable           Is this field Searchable
	* @property {ENUM}    VariantAttributeType VariantAttributeTypes defines the rules for VariantAttributes (VA_NONE | VA_OPTIONAL | VA_REQUIRED)
	* @property {ENUM}    ValueType            ValueType defines the input validation rules for the Value (VALUE_TEXT | VALUE_NUMBER | VALUE_PRICE)
	*
	*/
	GetItemAttributeSetAttributeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/item_attribute_set_attribute/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSetAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemAttributeSetAttributeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemAttributeSetAttributeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemAttributeSetAttributeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemAttributeSetAttributeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemAttributeSetAttributeByUUID'))
			})
	},

	/**
	* RPC Method: GetItemAttributeSetAttributesPaginated
	* Description: Searches for multiple ItemAttributeSetAttribute objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_attribute_set_attribute
	* @param    {ItemAttributeSetAttributeSearchRequest} req The API payload object (ItemAttributeSetAttributeSearchRequest)
	* @return   {ItemAttributeSetAttributePageResponse} The API response object (ItemAttributeSetAttributePageResponse)
	*
	* @typedef  {Object}  ItemAttributeSetAttributeSearchRequest
	* @property {SearchOptions[]} SearchOptions        Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted       IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID                 (optional) SPID
	* @property {String}  ItemAttributeSetUUID Item UUID
	* @property {String}  Name                 ItemAttributeSetAttribute Name
	* @property {String}  Value                ItemAttributeSetAttribute Value
	* @property {Boolean} Required             Is this attribute Required on ItemVariants ?
	* @property {ENUM}    Facet                Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE)
	* @property {Boolean} Searchable           Is this field Searchable
	* @property {ENUM}    VariantAttributeType VariantAttributeTypes defines the rules for VariantAttributes (VA_NONE | VA_OPTIONAL | VA_REQUIRED)
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemAttributeSetAttributePageResponse
	* @property {PageInfo} PageInfo                   PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {ItemAttributeSetAttribute[]} ItemAttributeSetAttributes 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemAttributeSetAttributesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/item_attribute_set_attribute`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItemAttributeSetAttributes', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemAttributeSetAttributesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemAttributeSetAttributesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemAttributeSetAttributesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemAttributeSetAttributesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemAttributeSetAttributesPaginated'))
			})
	},

	/**
	* RPC Method: GetItemAttributeSetByUUID
	* Description: Get a single ItemAttributeSet object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_attribute_set/${req.UUID}
	* @param    {ItemAttributeSetUUIDSearch} req The API payload object (ItemAttributeSetUUIDSearch)
	* @return   {ItemAttributeSet} The API response object (ItemAttributeSet)
	*
	* @typedef  {Object}  ItemAttributeSetUUIDSearch
	* @property {String}  UUID UUID of the ItemAttributeSet
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemAttributeSet
	* @property {String}  UUID           UUID of the ItemAttributeSet
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Name           ItemAttributeSet Name
	*
	*/
	GetItemAttributeSetByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/item_attribute_set/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSet', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemAttributeSetByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemAttributeSetByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemAttributeSetByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemAttributeSetByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemAttributeSetByUUID'))
			})
	},

	/**
	* RPC Method: GetItemAttributeSetsPaginated
	* Description: Searches for multiple ItemAttributeSet objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_attribute_set
	* @param    {ItemAttributeSetSearchRequest} req The API payload object (ItemAttributeSetSearchRequest)
	* @return   {ItemAttributeSetPageResponse} The API response object (ItemAttributeSetPageResponse)
	*
	* @typedef  {Object}  ItemAttributeSetSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Name           ItemAttributeSet Name
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemAttributeSetPageResponse
	* @property {PageInfo} PageInfo          PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {ItemAttributeSet[]} ItemAttributeSets 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemAttributeSetsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/item_attribute_set`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItemAttributeSets', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemAttributeSetsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemAttributeSetsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemAttributeSetsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemAttributeSetsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemAttributeSetsPaginated'))
			})
	},

	/**
	* RPC Method: GetItemByUUID
	* Description: Get a single Item object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/eshop_item/${req.UUID}
	* @param    {ItemUUIDSearch} req The API payload object (ItemUUIDSearch)
	* @return   {Item} The API response object (Item)
	*
	* @typedef  {Object}  ItemUUIDSearch
	* @property {String}  UUID UUID of the Item
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Item
	* @property {String}  UUID                 UUID of the Item
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  Name                 Item Name
	* @property {String}  Brand                Item Brand
	* @property {String}  Description          Item Description
	* @property {String}  ItemAttributeSetUUID 
	*
	*/
	GetItemByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/eshop_item/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItem', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemByUUID'))
			})
	},

	/**
	* RPC Method: GetItemCategoriesPaginated
	* Description: Searches for multiple ItemCategory objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_category
	* @param    {ItemCategorySearchRequest} req The API payload object (ItemCategorySearchRequest)
	* @return   {ItemCategoryPageResponse} The API response object (ItemCategoryPageResponse)
	*
	* @typedef  {Object}  ItemCategorySearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  CategoryUUID   Category UUID
	* @property {String}  ItemUUID       Item UUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemCategoryPageResponse
	* @property {PageInfo} PageInfo       PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {ItemCategory[]} ItemCategories 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemCategoriesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/item_category`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItemCategories', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemCategoriesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemCategoriesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemCategoriesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemCategoriesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemCategoriesPaginated'))
			})
	},

	/**
	* RPC Method: GetItemCategoryByUUID
	* Description: Get a single ItemCategory object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_category/${req.UUID}
	* @param    {ItemCategoryUUIDSearch} req The API payload object (ItemCategoryUUIDSearch)
	* @return   {ItemCategory} The API response object (ItemCategory)
	*
	* @typedef  {Object}  ItemCategoryUUIDSearch
	* @property {String}  UUID UUID of the ItemCategory
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemCategory
	* @property {String}  UUID           UUID of the ItemCategory
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  CategoryUUID   Category UUID
	* @property {String}  ItemUUID       Item UUID
	*
	*/
	GetItemCategoryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/item_category/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemCategoryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemCategoryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemCategoryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemCategoryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemCategoryByUUID'))
			})
	},

	/**
	* RPC Method: GetItemImageByUUID
	* Description: Get a single ItemImage object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/${req.SPID}/eshop_item/${req.ItemUUID}/item_image/${req.UUID}
	* @param    {ItemImageUUIDSearch} req The API payload object (ItemImageUUIDSearch)
	* @return   {ItemImage} The API response object (ItemImage)
	*
	* @typedef  {Object}  ItemImageUUIDSearch
	* @property {String}  UUID     UUID of the ItemImage
	* @property {Number}  SPID     Service Provider ID - always required
	* @property {String}  ItemUUID Search by Item UUID
	*
	* @typedef  {Object}  ItemImage
	* @property {String}  UUID           UUID of the ItemImage
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  ItemUUID       
	* @property {String}  Name           Item Variant Name
	* @property {String}  URLs           
	*
	*/
	GetItemImageByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramItemUUID = encodeURIComponent(req.ItemUUID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/${paramSPID}/eshop_item/${paramItemUUID}/item_image/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemImage', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemImageByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemImageByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemImageByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemImageByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemImageByUUID'))
			})
	},

	/**
	* RPC Method: GetItemImagesPaginated
	* Description: Searches for multiple ItemImage objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/${req.SPID}/eshop_item/${req.ItemUUID}/item_image
	* @param    {ItemImageSearchRequest} req The API payload object (ItemImageSearchRequest)
	* @return   {ItemImagePageResponse} The API response object (ItemImagePageResponse)
	*
	* @typedef  {Object}  ItemImageSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Name           Search by Item Name
	* @property {String}  ItemUUID       Search by Item UUID
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemImagePageResponse
	* @property {PageInfo} PageInfo   PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {ItemImage[]} ItemImages 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemImagesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramItemUUID = encodeURIComponent(req.ItemUUID)
		return axios.get(`/v3/eshop/${paramSPID}/eshop_item/${paramItemUUID}/item_image`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItemImages', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemImagesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemImagesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemImagesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemImagesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemImagesPaginated'))
			})
	},

	/**
	* RPC Method: GetItemVariantAttributeByUUID
	* Description: Get a single ItemVariantAttribute object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_variant_attribute/${req.UUID}
	* @param    {ItemVariantAttributeUUIDSearch} req The API payload object (ItemVariantAttributeUUIDSearch)
	* @return   {ItemVariantAttribute} The API response object (ItemVariantAttribute)
	*
	* @typedef  {Object}  ItemVariantAttributeUUIDSearch
	* @property {String}  UUID UUID of the ItemVariantAttribute
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemVariantAttribute
	* @property {String}  UUID            UUID of the ItemVariantAttribute
	* @property {Number}  SPID            Default Service Provider ID
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  ItemUUID        Item UUID
	* @property {String}  ItemVariantUUID 
	* @property {String}  Name            ItemAttributeSet Name
	* @property {String}  Value           ItemAttributeSet Value
	* @property {ENUM}    Facet           Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE) - inheritted
	* @property {Boolean} Searchable      Is this field Searchable - inherited
	*
	*/
	GetItemVariantAttributeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/item_variant_attribute/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariantAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemVariantAttributeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemVariantAttributeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemVariantAttributeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemVariantAttributeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemVariantAttributeByUUID'))
			})
	},

	/**
	* RPC Method: GetItemVariantAttributesPaginated
	* Description: Searches for multiple ItemVariantAttribute objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_variant_attribute
	* @param    {ItemVariantAttributeSearchRequest} req The API payload object (ItemVariantAttributeSearchRequest)
	* @return   {ItemVariantAttributePageResponse} The API response object (ItemVariantAttributePageResponse)
	*
	* @typedef  {Object}  ItemVariantAttributeSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemVariantAttributePageResponse
	* @property {PageInfo} PageInfo              PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {ItemVariantAttribute[]} ItemVariantAttributes 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemVariantAttributesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/item_variant_attribute`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItemVariantAttributes', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemVariantAttributesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemVariantAttributesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemVariantAttributesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemVariantAttributesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemVariantAttributesPaginated'))
			})
	},

	/**
	* RPC Method: GetItemVariantByUUID
	* Description: Get a single ItemVariant object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_variant/${req.UUID}
	* @param    {ItemVariantUUIDSearch} req The API payload object (ItemVariantUUIDSearch)
	* @return   {ItemVariant} The API response object (ItemVariant)
	*
	* @typedef  {Object}  ItemVariantUUIDSearch
	* @property {String}  UUID UUID of the ItemVariant
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemVariant
	* @property {String}  UUID                 UUID of the ItemVariant
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemUUID             
	* @property {String}  Name                 Item Variant Name
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID is inheritted from Item by default
	* @property {String[]} ItemImageUUIDs       Valid images for this Variant (up to 50 are supported, and if this is empty, all images are shown for this variant)
	*
	*/
	GetItemVariantByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/eshop/item_variant/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariant', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemVariantByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemVariantByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemVariantByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemVariantByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemVariantByUUID'))
			})
	},

	/**
	* RPC Method: GetItemVariantsPaginated
	* Description: Searches for multiple ItemVariant objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/item_variant
	* @param    {ItemVariantSearchRequest} req The API payload object (ItemVariantSearchRequest)
	* @return   {ItemVariantPageResponse} The API response object (ItemVariantPageResponse)
	*
	* @typedef  {Object}  ItemVariantSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Name           Search by Item Name
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemVariantPageResponse
	* @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {ItemVariant[]} ItemVariants 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemVariantsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/item_variant`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItemVariants', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemVariantsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemVariantsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemVariantsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemVariantsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemVariantsPaginated'))
			})
	},

	/**
	* RPC Method: GetItemsPaginated
	* Description: Searches for multiple Item objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/eshop/eshop_item
	* @param    {ItemSearchRequest} req The API payload object (ItemSearchRequest)
	* @return   {ItemPageResponse} The API response object (ItemPageResponse)
	*
	* @typedef  {Object}  ItemSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Name           Search by Item Name
	* @property {String}  Brand          Search by Item Brand
	* @property {String}  Description    Search by Item Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ItemPageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {Item[]} Items    
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetItemsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/eshop/eshop_item`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveItems', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetItemsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetItemsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetItemsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetItemsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetItemsPaginated'))
			})
	},

	/**
	* RPC Method: PurgeCategoryByUUID
	* Description: Purges a Category object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/category/${req.UUID}/purge
	* @param    {CategoryUUIDSearch} req The API payload object (CategoryUUIDSearch)
	* @return   {Category} The API response object (Category)
	*
	* @typedef  {Object}  CategoryUUIDSearch
	* @property {String}  UUID UUID of the Category
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Category
	* @property {String}  UUID               UUID of the Category
	* @property {Number}  SPID               Default Service Provider ID
	* @property {Number}  CreatedAtNanos     When the object was first created
	* @property {Number}  UpdatedAtNanos     When the object was last updated
	* @property {Number}  DeletedAtNanos     When the object was deleted
	* @property {String}  ParentCategoryUUID Parent Category UUID
	* @property {String}  Name               Category Name
	* @property {String}  Description        Category Description
	*
	*/
	PurgeCategoryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/category/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeCategoryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeCategoryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeCategoryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeCategoryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeCategoryByUUID'))
			})
	},

	/**
	* RPC Method: PurgeItemAttributeSetAttributeByUUID
	* Description: Purges a ItemAttributeSetAttribute object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_attribute_set_attribute/${req.UUID}/purge
	* @param    {ItemAttributeSetAttributeUUIDSearch} req The API payload object (ItemAttributeSetAttributeUUIDSearch)
	* @return   {ItemAttributeSetAttribute} The API response object (ItemAttributeSetAttribute)
	*
	* @typedef  {Object}  ItemAttributeSetAttributeUUIDSearch
	* @property {String}  UUID UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemAttributeSetAttribute
	* @property {String}  UUID                 UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID
	* @property {Number}  Order                Order in which the attributes are displayed (by default)
	* @property {String}  Name                 ItemAttributeSet Name
	* @property {String}  Value                ItemAttributeSet Value
	* @property {ENUM}    Facet                Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE)
	* @property {Boolean} Searchable           Is this field Searchable
	* @property {ENUM}    VariantAttributeType VariantAttributeTypes defines the rules for VariantAttributes (VA_NONE | VA_OPTIONAL | VA_REQUIRED)
	* @property {ENUM}    ValueType            ValueType defines the input validation rules for the Value (VALUE_TEXT | VALUE_NUMBER | VALUE_PRICE)
	*
	*/
	PurgeItemAttributeSetAttributeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/item_attribute_set_attribute/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSetAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeItemAttributeSetAttributeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeItemAttributeSetAttributeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeItemAttributeSetAttributeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeItemAttributeSetAttributeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeItemAttributeSetAttributeByUUID'))
			})
	},

	/**
	* RPC Method: PurgeItemAttributeSetByUUID
	* Description: Purges a ItemAttributeSet object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_attribute_set/${req.UUID}/purge
	* @param    {ItemAttributeSetUUIDSearch} req The API payload object (ItemAttributeSetUUIDSearch)
	* @return   {ItemAttributeSet} The API response object (ItemAttributeSet)
	*
	* @typedef  {Object}  ItemAttributeSetUUIDSearch
	* @property {String}  UUID UUID of the ItemAttributeSet
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemAttributeSet
	* @property {String}  UUID           UUID of the ItemAttributeSet
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Name           ItemAttributeSet Name
	*
	*/
	PurgeItemAttributeSetByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/item_attribute_set/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSet', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeItemAttributeSetByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeItemAttributeSetByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeItemAttributeSetByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeItemAttributeSetByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeItemAttributeSetByUUID'))
			})
	},

	/**
	* RPC Method: PurgeItemByUUID
	* Description: Purges a Item object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/eshop_item/${req.UUID}/purge
	* @param    {ItemUUIDSearch} req The API payload object (ItemUUIDSearch)
	* @return   {Item} The API response object (Item)
	*
	* @typedef  {Object}  ItemUUIDSearch
	* @property {String}  UUID UUID of the Item
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  Item
	* @property {String}  UUID                 UUID of the Item
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  Name                 Item Name
	* @property {String}  Brand                Item Brand
	* @property {String}  Description          Item Description
	* @property {String}  ItemAttributeSetUUID 
	*
	*/
	PurgeItemByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/eshop_item/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItem', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeItemByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeItemByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeItemByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeItemByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeItemByUUID'))
			})
	},

	/**
	* RPC Method: PurgeItemCategoryByUUID
	* Description: Purges a ItemCategory object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_category/${req.UUID}/purge
	* @param    {ItemCategoryUUIDSearch} req The API payload object (ItemCategoryUUIDSearch)
	* @return   {ItemCategory} The API response object (ItemCategory)
	*
	* @typedef  {Object}  ItemCategoryUUIDSearch
	* @property {String}  UUID UUID of the ItemCategory
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemCategory
	* @property {String}  UUID           UUID of the ItemCategory
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  CategoryUUID   Category UUID
	* @property {String}  ItemUUID       Item UUID
	*
	*/
	PurgeItemCategoryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/item_category/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeItemCategoryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeItemCategoryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeItemCategoryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeItemCategoryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeItemCategoryByUUID'))
			})
	},

	/**
	* RPC Method: PurgeItemVariantAttributeByUUID
	* Description: Purges a ItemVariantAttribute object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_variant_attribute/${req.UUID}/purge
	* @param    {ItemVariantAttributeUUIDSearch} req The API payload object (ItemVariantAttributeUUIDSearch)
	* @return   {ItemVariantAttribute} The API response object (ItemVariantAttribute)
	*
	* @typedef  {Object}  ItemVariantAttributeUUIDSearch
	* @property {String}  UUID UUID of the ItemVariantAttribute
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemVariantAttribute
	* @property {String}  UUID            UUID of the ItemVariantAttribute
	* @property {Number}  SPID            Default Service Provider ID
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  ItemUUID        Item UUID
	* @property {String}  ItemVariantUUID 
	* @property {String}  Name            ItemAttributeSet Name
	* @property {String}  Value           ItemAttributeSet Value
	* @property {ENUM}    Facet           Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE) - inheritted
	* @property {Boolean} Searchable      Is this field Searchable - inherited
	*
	*/
	PurgeItemVariantAttributeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/item_variant_attribute/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariantAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeItemVariantAttributeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeItemVariantAttributeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeItemVariantAttributeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeItemVariantAttributeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeItemVariantAttributeByUUID'))
			})
	},

	/**
	* RPC Method: PurgeItemVariantByUUID
	* Description: Purges a ItemVariant object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/eshop/item_variant/${req.UUID}/purge
	* @param    {ItemVariantUUIDSearch} req The API payload object (ItemVariantUUIDSearch)
	* @return   {ItemVariant} The API response object (ItemVariant)
	*
	* @typedef  {Object}  ItemVariantUUIDSearch
	* @property {String}  UUID UUID of the ItemVariant
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  ItemVariant
	* @property {String}  UUID                 UUID of the ItemVariant
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemUUID             
	* @property {String}  Name                 Item Variant Name
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID is inheritted from Item by default
	* @property {String[]} ItemImageUUIDs       Valid images for this Variant (up to 50 are supported, and if this is empty, all images are shown for this variant)
	*
	*/
	PurgeItemVariantByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/eshop/item_variant/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariant', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeItemVariantByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeItemVariantByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeItemVariantByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeItemVariantByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeItemVariantByUUID'))
			})
	},

	/**
	* RPC Method: UpdateCategory
	* Description: Update a single Category object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/category/${req.UUID}
	* @param    {Category} req The API payload object (Category)
	* @return   {Category} The API response object (Category)
	*
	* @typedef  {Object}  Category
	* @property {String}  UUID               UUID of the Category
	* @property {Number}  SPID               Default Service Provider ID
	* @property {Number}  CreatedAtNanos     When the object was first created
	* @property {Number}  UpdatedAtNanos     When the object was last updated
	* @property {Number}  DeletedAtNanos     When the object was deleted
	* @property {String}  ParentCategoryUUID Parent Category UUID
	* @property {String}  Name               Category Name
	* @property {String}  Description        Category Description
	*
	*/
	UpdateCategory({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/category/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateCategory ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateCategory ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateCategory ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateCategory UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateCategory'))
			})
	},

	/**
	* RPC Method: UpdateItem
	* Description: Update a single Item object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/eshop_item/${req.UUID}
	* @param    {Item} req The API payload object (Item)
	* @return   {Item} The API response object (Item)
	*
	* @typedef  {Object}  Item
	* @property {String}  UUID                 UUID of the Item
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  Name                 Item Name
	* @property {String}  Brand                Item Brand
	* @property {String}  Description          Item Description
	* @property {String}  ItemAttributeSetUUID 
	*
	*/
	UpdateItem({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/eshop_item/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItem', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateItem ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateItem ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateItem ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateItem UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateItem'))
			})
	},

	/**
	* RPC Method: UpdateItemAttributeSet
	* Description: Update a single ItemAttributeSet object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/item_attribute_set/${req.UUID}
	* @param    {ItemAttributeSet} req The API payload object (ItemAttributeSet)
	* @return   {ItemAttributeSet} The API response object (ItemAttributeSet)
	*
	* @typedef  {Object}  ItemAttributeSet
	* @property {String}  UUID           UUID of the ItemAttributeSet
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  Name           ItemAttributeSet Name
	*
	*/
	UpdateItemAttributeSet({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/item_attribute_set/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSet', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateItemAttributeSet ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateItemAttributeSet ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateItemAttributeSet ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateItemAttributeSet UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateItemAttributeSet'))
			})
	},

	/**
	* RPC Method: UpdateItemAttributeSetAttribute
	* Description: Update a single ItemAttributeSetAttribute object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/item_attribute_set_attribute/${req.UUID}
	* @param    {ItemAttributeSetAttribute} req The API payload object (ItemAttributeSetAttribute)
	* @return   {ItemAttributeSetAttribute} The API response object (ItemAttributeSetAttribute)
	*
	* @typedef  {Object}  ItemAttributeSetAttribute
	* @property {String}  UUID                 UUID of the ItemAttributeSetAttribute
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID
	* @property {Number}  Order                Order in which the attributes are displayed (by default)
	* @property {String}  Name                 ItemAttributeSet Name
	* @property {String}  Value                ItemAttributeSet Value
	* @property {ENUM}    Facet                Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE)
	* @property {Boolean} Searchable           Is this field Searchable
	* @property {ENUM}    VariantAttributeType VariantAttributeTypes defines the rules for VariantAttributes (VA_NONE | VA_OPTIONAL | VA_REQUIRED)
	* @property {ENUM}    ValueType            ValueType defines the input validation rules for the Value (VALUE_TEXT | VALUE_NUMBER | VALUE_PRICE)
	*
	*/
	UpdateItemAttributeSetAttribute({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/item_attribute_set_attribute/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemAttributeSetAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateItemAttributeSetAttribute ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateItemAttributeSetAttribute ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateItemAttributeSetAttribute ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateItemAttributeSetAttribute UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateItemAttributeSetAttribute'))
			})
	},

	/**
	* RPC Method: UpdateItemCategory
	* Description: Update a single ItemCategory object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/item_category/${req.UUID}
	* @param    {ItemCategory} req The API payload object (ItemCategory)
	* @return   {ItemCategory} The API response object (ItemCategory)
	*
	* @typedef  {Object}  ItemCategory
	* @property {String}  UUID           UUID of the ItemCategory
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {String}  CategoryUUID   Category UUID
	* @property {String}  ItemUUID       Item UUID
	*
	*/
	UpdateItemCategory({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/item_category/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemCategory', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateItemCategory ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateItemCategory ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateItemCategory ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateItemCategory UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateItemCategory'))
			})
	},

	/**
	* RPC Method: UpdateItemVariant
	* Description: Update a single ItemVariant object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/item_variant/${req.UUID}
	* @param    {ItemVariant} req The API payload object (ItemVariant)
	* @return   {ItemVariant} The API response object (ItemVariant)
	*
	* @typedef  {Object}  ItemVariant
	* @property {String}  UUID                 UUID of the ItemVariant
	* @property {Number}  SPID                 Default Service Provider ID
	* @property {Number}  CreatedAtNanos       When the object was first created
	* @property {Number}  UpdatedAtNanos       When the object was last updated
	* @property {Number}  DeletedAtNanos       When the object was deleted
	* @property {String}  ItemUUID             
	* @property {String}  Name                 Item Variant Name
	* @property {String}  ItemAttributeSetUUID ItemAttributeSetUUID is inheritted from Item by default
	* @property {String[]} ItemImageUUIDs       Valid images for this Variant (up to 50 are supported, and if this is empty, all images are shown for this variant)
	*
	*/
	UpdateItemVariant({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/item_variant/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariant', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateItemVariant ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateItemVariant ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateItemVariant ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateItemVariant UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateItemVariant'))
			})
	},

	/**
	* RPC Method: UpdateItemVariantAttribute
	* Description: Update a single ItemVariantAttribute object
	* HTTP Method: PUT
	* API Path: /api/v3/eshop/item_variant_attribute/${req.UUID}
	* @param    {ItemVariantAttribute} req The API payload object (ItemVariantAttribute)
	* @return   {ItemVariantAttribute} The API response object (ItemVariantAttribute)
	*
	* @typedef  {Object}  ItemVariantAttribute
	* @property {String}  UUID            UUID of the ItemVariantAttribute
	* @property {Number}  SPID            Default Service Provider ID
	* @property {Number}  CreatedAtNanos  When the object was first created
	* @property {Number}  UpdatedAtNanos  When the object was last updated
	* @property {Number}  DeletedAtNanos  When the object was deleted
	* @property {String}  ItemUUID        Item UUID
	* @property {String}  ItemVariantUUID 
	* @property {String}  Name            ItemAttributeSet Name
	* @property {String}  Value           ItemAttributeSet Value
	* @property {ENUM}    Facet           Facet type (FACET_NONE | FACET_VALUE | FACET_RANGE) - inheritted
	* @property {Boolean} Searchable      Is this field Searchable - inherited
	*
	*/
	UpdateItemVariantAttribute({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/eshop/item_variant_attribute/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveItemVariantAttribute', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateItemVariantAttribute ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateItemVariantAttribute ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateItemVariantAttribute ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateItemVariantAttribute UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateItemVariantAttribute'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
