import axios from 'axios'
import StoreHash from '@/utils/StoreHash'
import downloadBlob from '@/utils/downloadBlob'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const hashed = {
    receipts: {
        customers: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },
}

const state = {
    receipts: {
        customers: {}
    }
}

const getters = {
    getReceiptsByCustomerID: state => uuid => state.receipts.customers[uuid]
}

const mutations = {
    mutReceipts(state, { CustomerUUID, receipts }) {
        let receiptUUIDs = {}

        if (receipts.length > 0) {
            for (let i = 0; i < receipts.length; i++) {
                const receipt = receipts[i]
                const UUID = receipt.UUID

                receiptUUIDs[UUID] = receipt
            }

            state.receipts.customers[CustomerUUID] = receiptUUIDs
            hashed.receipts.customers[CustomerUUID].fix()
        }
    },

    resetReceiptsCache(state) {
        hashed.receipts.customers = {}
        hashed.receipts = {
            customers: {}
        }
        state.receipts = {
            customers: {}
        }
    }
}

const actions = {
    GetCashedReceiptsByCustomerID({ getters, dispatch }, { CustomerUUID, refresh }){
        if (!(CustomerUUID in hashed.receipts.customers)) {
            hashed.receipts.customers[CustomerUUID] = new StoreHash(hashed.receipts.lifetime);
        }

        //Если вермя жизни хешированных данных истекло делаем запрос на сервер
        if (hashed.receipts.customers[CustomerUUID].expired() || refresh) {
            return dispatch('GetReceipts', { CustomerUUID, refresh })
        }
        return Promise.resolve( getters.getReceiptsByCustomerID(CustomerUUID) )
    },

    GetReceipts({ commit }, params) {
        return axios.get(`${API_URL_RESELLER}/customer/${params.CustomerUUID}/receipt`, { params })
            .then((response) => {
                if (!response.data) { return Promise.reject(Error('no response data')) }

                // commit('mutReceipts', { CustomerUUID: params.CustomerUUID, receipts: response.data?.Receipts || [] })
                return Promise.resolve(response.data)
            })
            .catch(error => Promise.reject(error))
    },

    DownloadReceiptPDF({getters, commit}, { CustomerUUID, UUID }) {
        return axios.get(`${API_URL_RESELLER}/customer/${CustomerUUID}/receipt/${UUID}/pdf`, { responseType: 'blob' })
            .then(({ result: blob }) => {
                return downloadBlob(`Receipt-${UUID}.pdf`, blob)
            })
            .catch(error => Promise.reject(error))
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}