export default {
    data() {
        return {
            sidebar_modal_route: null,
        }
    },

    methods: {
        pushSidebarModal(route) {
            const modal = document.getElementById('app-sidebar-modal')
            if (modal) {
                const modalBody = modal.getElementsByClassName("modal-body")[0]
                modalBody.scroll(0,0)
            }

            this.sidebar_modal_route = route
            this.$nextTick(() => {
                this.$bvModal.show('app-sidebar-modal')
            })
        },
        
        hideSidebarModal() {
            this.$bvModal.hide('app-sidebar-modal')
        },

        updateTitleSidebarModal(title) {
            this.sidebar_modal_route.$modal.title = title
        }
    },
}