const state = {
    $tablet_size: 991,              // $tablet-size: 991px;
    $mobile_size: 767                // $mobile-size: 767px;
};

const getters = {
    $tablet_size: state => state.$tablet_size,
    $mobile_size: state => state.$mobile_size
}

export default {
    state,
    getters
}