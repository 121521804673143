import rules_list from './rules'

const default_redirect = { name: 'home' }

/**
 * Проверка прав доступа к роутам
 * 
 * @param {array} rules - список проверямых правил
 * @param {object} redirect - имя роута для редиректа
 */
export default function(rules, redirect) {
    return function(to, from, next) {
        let rules_verifying = []
        
        for (let n = 0, len = rules.length; n < len; n++) {
            const rule_trim = rules[n].trim()

            const is_negative = rule_trim[0] == '!'
            const rule = is_negative ? rule_trim.slice(1) : rule_trim
    
            if (rule in rules_list) {
                const verifying = rules_list[rule](to, from, next)

                if (verifying instanceof Promise) {
                    rules_verifying.push(is_negative
                        ? new Promise((resolve, reject) => {
                            verifying
                                .then(() => reject({ allow: false, redirect: redirect || default_redirect }))
                                .catch(() => resolve(true))
                        })
                        : verifying
                    )
                } else {
                    if (is_negative ? verifying.allow : !verifying.allow) {
                        return next({
                            replace: true,
                            ...(redirect || verifying.redirect || default_redirect)
                        })
                    } else {
                        rules_verifying.push(Promise.resolve(verifying))
                    }
                }
            }
        }

        Promise.all(rules_verifying).then(verifying => {
            next()
        }).catch(verifying => {
            next({
                replace: true,
                params: to.params,
                ...(redirect || verifying.redirect || default_redirect)
            })
        })
    }
}