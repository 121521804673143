import axios from 'axios'
import qs from 'qs'

const validateStatus = status => status == 200 || status == 404

const state = {}

const getters = {}

const mutations = {}

const actions = {
    FindProductInstancesWithDetailsPaginated({}, params) {
        const config = {
            params,

            paramsSerializer: params => qs.stringify(params, { indices: false }),

            validateStatus,
        }

        return axios.get(`/v1/${ params.SPID }/product_instance`, config)
            .then(response => {
                return Promise.resolve(response.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
}

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions,
}