import { extend } from 'vee-validate';

function firstName() {
    extend("firstName", {
        validate(value) {
            const regex = new RegExp("^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\\ |\\'|\\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$");
            return regex.test(value);
        },
        message: 'First name should contain not more than 16 letters.',
    });
}

export default firstName