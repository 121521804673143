const state = {}

const getters = {
    isRoleAvailable: (state, getters) => role => role in getters.roles,

    isSPAdmin: (state, getters) => getters.isRoleAvailable('SP_ADMIN') || getters.isRoleAvailable('SUPER_ADMIN'),
    isSuperAdmin: (state, getters) => getters.isRoleAvailable('SUPER_ADMIN'),

    isSPAdminAndPremode: (state, getters) => getters.isSPAdmin && getters.isPrereleaseMode,

    hasReadCustomerRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('READ'),
    hasWriteCustomerRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('WRITE'),
    hasCustomerRole: (state, getters) => getters.hasWriteCustomerRole || getters.hasReadCustomerRole,

    hasPrePayReadRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('prepay.read'),
    hasPrePayWriteRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('prepay.write'),
    hasPrePayRole: (state, getters) => getters.hasPrePayReadRole || getters.hasPrePayWriteRole,

    hasPostPayReadRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('mobile.read'),
    hasPostPayWriteRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('mobile.write'),
    hasPostPayRole: (state, getters) => getters.hasPostPayReadRole || getters.hasPostPayWriteRole,

    hasMobileReadRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('mobile.read'),
    hasMobileManageRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('mobile.manage'),

    hasNumberManagerRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('NUMBER_MANAGER'),

    hasBillingReconcileRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('BILLING_RECONCILE'),

    hasShipmentsListRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('LOGISTICS'),
    hasShipmentsDispatchRole: (state, getters) => getters.isSPAdmin || getters.isRoleAvailable('LOGISTICS_DISPATCH'),
    hasShipmentsRole: (state, getters) => getters.isSPAdmin || getters.hasShipmentsListRole || getters.hasShipmentsDispatchRole,
    hasShipmentsListPremode: (state, getters) => getters.isPrereleaseMode && getters.hasShipmentsListRole,
    hasShipmentsDispatchPremode: (state, getters) => getters.isPrereleaseMode && getters.hasShipmentsDispatchRole,
    hasShipmentsPremode: (state, getters) => getters.isPrereleaseMode && getters.hasShipmentsRole,

    hasVoucherRedeem: (state, getters) => getters.isSupportVouchers && (getters.isSPAdmin || getters.isRoleAvailable('voucher.read')),
    hasVoucherCreate: (state, getters) => getters.isSupportVouchers && getters.isSupportVoucherCreate && (getters.isSPAdmin || getters.isRoleAvailable('voucher.create')),
    hasVoucherVoid: (state, getters) => getters.isSupportVouchers && (getters.isSPAdmin || getters.isRoleAvailable('voucher.manage')),

    hasUFBReadRole: (state, getters) => getters.isSpAdmin || getters.isRoleAvailable('fibre.read'),
    hasUFBModuleRole: (state, getters) => getters.isSpAdmin || getters.isRoleAvailable('PROD_FIBRE'),
    hasUFBManageRole: (state, getters) => getters.isSpAdmin || getters.isRoleAvailable('fibre.manage'),
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}