// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: mobile.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Mobiles: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	MobileBalances: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getMobileByUUID: state => (UUID) => (state.Mobiles.UUIDs[ UUID ] && !state.Mobiles.UUIDs[ UUID ].expired()) ?  state.Mobiles.UUIDs[ UUID ].hashedData() : null,
	getMobileBalanceByUUID: state => (UUID) => (state.MobileBalances.UUIDs[ UUID ] && !state.MobileBalances.UUIDs[ UUID ].expired()) ?  state.MobileBalances.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveMobile saves a single Mobile object
	// into both the StoreHash cache and the VueX store
	mutSaveMobile(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Mobiles.UUIDs)) {
			state.Mobiles.UUIDs[ obj.UUID ] = new StoreHash(state.Mobiles.lifetime)
		}
		state.Mobiles.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMobileByUUID removes a Mobile object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMobileByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.Mobiles.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMobileBalance saves a single MobileBalance object
	// into both the StoreHash cache and the VueX store
	mutSaveMobileBalance(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.MobileBalances.UUIDs)) {
			state.MobileBalances.UUIDs[ obj.UUID ] = new StoreHash(state.MobileBalances.lifetime)
		}
		state.MobileBalances.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMobileBalanceByUUID removes a MobileBalance object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMobileBalanceByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.MobileBalances.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedMobileByUUID fetches a Mobile from the cache
	// and if not in the cache, fetches it from the API
	getCachedMobileByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Mobiles.UUIDs[ UUID ] && !state.Mobiles.UUIDs[ UUID ].expired()) {
			return state.Mobiles.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetMobileByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMobile', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMobileByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedMobileBalanceByUUID fetches a MobileBalance from the cache
	// and if not in the cache, fetches it from the API
	getCachedMobileBalanceByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.MobileBalances.UUIDs[ UUID ] && !state.MobileBalances.UUIDs[ UUID ].expired()) {
			return state.MobileBalances.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetMobileBalanceByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMobileBalance', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMobileBalanceByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: MobileRPC
	//
	/**
	 * RPC Method: ChangeBillingReference
	 * Description: Change Billing Reference to different one, i.e, from postpay to prepaid which is for xVNO use only
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/billing_reference
	 * @param    {ChangeBillingReferenceRequest} req The API payload object (ChangeBillingReferenceRequest)
	 * @return   {Mobile} The API response object (Mobile)
	 *
	 * @typedef  {Object}  ChangeBillingReferenceRequest
	 * @property {Number}  SPID             The Service Provider ID
	 * @property {String}  BillingReference Billing Reference string for xVNO use
	 * @property {String}  MobileUUID       The mobile product instance UUID
	 * @property {String}  CustomerUUID     The customer UUID of this mobile addon (optional)
	 *
	 * @typedef  {Object}  Mobile
	 * @property {String}  UUID                                Provision sim
	 * @property {Number}  SPID                                Totally release sim, product and all addons
	 * @property {String}  SIMID                               FinaliseActivationMobileProduct activates a Mobile product,,  moving the product from the Welcome state into the active state,,  after which, Addons can be purchased.
	 * @property {String}  IMSI                                IMSI on the mobile product
	 * @property {Number}  CreatedAtNanos                      Purchase Addon
	 * @property {Number}  UpdatedAtNanos                      When the object was last updated
	 * @property {Number}  DeletedAtNanos                      When the object was deleted
	 * @property {String}  CustomerUUID                        Customer whom this product belongs to
	 * @property {String}  MSISDN                              option (google.api.http) = {,  	 get: "/api/v4/mobile/{UUID}/addons",  };
	 * @property {ENUM}    Status
	 * @property {ENUM}    PortingAction                       PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN                       option (google.api.http) = {,  	 get: "/api/v4/mobile_entries",  };
	 * @property {String}  PortingUUID                         Find all mobile product availabilities for specific catalog entry for spid x
	 * @property {Number}  PortingRFS                          Find all mobile products for spid x (only active)
	 * @property {String}  Name                                Find all mobile products addons for spid x ?
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled                    Options:,  Is voicemail enabled for this product
	 * @property {String}  ProductAvailabilityUUID             Product Catalog Availability UUID
	 * @property {Number}  MainBalCreditCents                  Amount of Money for main balance that will be topped up
	 * @property {Number}  EndUserBalCreditCents               Amount of Money for end user balance that will be topped up
	 * @property {Number}  CreditSMS                           Amount of SMSs of all addons and rollovers left
	 * @property {Number}  CreditVoiceMinutes                  Amount of VoiceMinutes of all addons and rollovers left
	 * @property {Number}  CreditDataMB                        Amount of Data MBs of all addons and rollovers left
	 * @property {Number}  CreditMMS                           Amount of MMSs  of all addons and rollovers left
	 * @property {Number}  RolloverCreditSMS                   Amount of SMSs of all rollovers
	 * @property {Number}  RolloverCreditVoiceMinutes          Amount of VoiceMinutes of all rollovers
	 * @property {Number}  RolloverCreditDataMB                Amount of Data MBs of all rollovers
	 * @property {Number}  RolloverCreditMMS                   Amount of MMSs of all rollovers
	 * @property {MobileAddon[]} Addons                              List of current addons on this product
	 * @property {Number}  TotalSMS                            Amount of SMSs of all addons, including any rollover
	 * @property {Number}  TotalVoiceMinutes                   Amount of VoiceMinutes of all addons, including any rollover
	 * @property {Number}  TotalDataMB                         Amount of Data MBs of all addons, including any rollover
	 * @property {Number}  TotalMMS                            Amount of MMSs of all addons, including any rollover
	 * @property {Number}  MainCurrencyCreditExpiresAtNanos    When the main currency credit expires
	 * @property {Number}  EndUserCurrencyCreditExpiresAtNanos When the end user currency credit expires
	 * @property {Number}  TotalRolloverSMS                    Total Amount of SMSs of all rollovers
	 * @property {Number}  TotalRolloverVoiceMinutes           Total Amount of VoiceMinutes of all rollovers
	 * @property {Number}  TotalRolloverDataMB                 Total Amount of Data MBs of all rollovers
	 * @property {Number}  TotalRolloverMMS                    Total Amount of MMSs of all rollovers
	 * @property {String}  GroupUUID                           The name of the group product to end-customers,  GroupUUID is the product group instance which this product is connected to
	 * @property {String}  GroupName
	 * @property {Number}  ServiceCreditLimitCents             The service credit limit, in cents;
	 * @property {String}  BillingReference                    BillingReference describes the payment method which is postpay or prepay
	 *
	 */
	ChangeBillingReference({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/billing_reference`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeBillingReference ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeBillingReference ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeBillingReference ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeBillingReference UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeBillingReference'))
			})
	},

	/**
	 * RPC Method: ChangeMSISDN
	 * Description: Change mobile product MSISDN (phone number) to a different MSISDN
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/msisdn
	 * @param    {MSISDNChangeRequest} req The API payload object (MSISDNChangeRequest)
	 * @return   {MSISDNChangeResponse} The API response object (MSISDNChangeResponse)
	 *
	 * @typedef  {Object}  MSISDNChangeRequest
	 * @property {Number}  SPID         Service Provider ID
	 * @property {String}  UUID         Mobile Product UUID
	 * @property {String}  CustomerUUID CustomerUUID
	 * @property {String}  NewMSISDN    NewMSISDN (phone number) to change to
	 * @property {String}  MSISDN       MSISDN of the Service to change
	 * @property {String}  ICCID        ICCID of the Service to change
	 * @property {String}  SIMID        SIMID of the Service to change
	 * @property {String}  IMSI         IMSI of the Service to change
	 *
	 * @typedef  {Object}  MSISDNChangeResponse
	 * @property {Number}  SPID Service Provider ID
	 * @property {String}  UUID Mobile Product UUID
	 *
	 */
	ChangeMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/msisdn`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeMSISDN'))
			})
	},

	/**
	 * RPC Method: ChangeMobileAddon
	 * Description: Change Mobile Addon
	 *
	 * ### Request Fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |EndAfterCycleCount| Switch between recurring and non-recurring. If <= 0, changing from non-recurring to recurring; If 1, change from recurring to non-recurring. No other choice.|
	 * |MobileAddonUUID(string)| Mobile addon product instance UUID that applies the change|
	 *
	 * ### Example Request
	 *
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/addon
	 * @param    {MobileAddonChangeRequest} req The API payload object (MobileAddonChangeRequest)
	 * @return   {MobileAddon} The API response object (MobileAddon)
	 *
	 * @typedef  {Object}  MobileAddonChangeRequest
	 * @property {Number}  SPID               The Service Provider ID
	 * @property {String}  MobileAddonUUID    The mobile addon product instance UUID
	 * @property {String}  CustomerUUID       The customer UUID of this mobile addon (optional)
	 * @property {Number}  EndAfterCycleCount If EndAfterCycleCount <= 0, recurring; 1, non-recurring
	 * @property {Number}  RetailCents        RetailCents sets the Retail Price of the MobileAddonUUID
	 *
	 * @typedef  {Object}  MobileAddon
	 * @property {Number}  SPID
	 * @property {String}  UUID                  ProductInstance of this Addon, blank if a list of available addons
	 * @property {String}  MobileUUID            Mobile instance this belongs to (if any)
	 * @property {ENUM}    State
	 * @property {String}  AddonAvailabilityUUID The product availability UUID
	 * @property {String}  Name                  Name of the Addon
	 * @property {String}  Description           Description of the Addon
	 * @property {String}  CustomerUUID          CustomerUUID
	 * @property {Number}  RecurringBaseCost     Cost that is taken from the wholesale money balance
	 * @property {Number}  RecurringRetailPrice  Cost that is taken from the customers money balance
	 * @property {Boolean} AllowCustomerPurchase This addon is able to be purchased by the customer
	 * @property {Boolean} CustomerVisible       This addon is visible to customers
	 * @property {Number}  CreditSMS             Remaining amount of SMS
	 * @property {Number}  CreditVoiceMinutes    Remaining amount of Voice Minutes
	 * @property {Number}  CreditDataMB          Remaining amount of Data MBs
	 * @property {Number}  CreditMMS             Remaining amount of MMSs
	 * @property {Number}  TotalSMS              Total amount of SMSs that are added
	 * @property {Number}  TotalVoiceMinutes     Total amount of VoiceMinutes that are added
	 * @property {Number}  TotalDataMB           Total amount of Data MBs that are added
	 * @property {Number}  TotalMMS              Total amount of MMSs that are added
	 * @property {Number}  CreatedAtNanos
	 * @property {Number}  ExpiresAtNanos
	 * @property {Number}  EndAfterCycleCount    Cycle count to control recurring
	 * @property {String}  TimePeriodInterval
	 * @property {String}  UsageRuleSMS          UsageRuleSMS describes the behaviour of usage in related to the Total/Credit.,  Values can be DataUsageRule_NOTSET, DataUsageRule_LIMITED, DataUsageRule_UNLIMITED, or DataUsageRule_THROTTLED.
	 * @property {String}  UsageRuleMMS          UsageRuleMMS describes the behaviour of usage in related to the Total/Credit.,  Values can be DataUsageRule_NOTSET, DataUsageRule_LIMITED, DataUsageRule_UNLIMITED, or DataUsageRule_THROTTLED.
	 * @property {String}  UsageRuleVoiceMinutes UsageRuleVoiceMinutes describes the behaviour of usage in related to the Total/Credit.,  Values can be DataUsageRule_NOTSET, DataUsageRule_LIMITED, DataUsageRule_UNLIMITED, or DataUsageRule_THROTTLED.
	 * @property {String}  UsageRuleDataMB       UsageRuleDataMB describes the behaviour of usage in related to the Total/Credit.\,  Values can be DataUsageRule_NOTSET, DataUsageRule_LIMITED, DataUsageRule_UNLIMITED, or DataUsageRule_THROTTLED.
	 * @property {Boolean} DataRolloverAllowed   Data rollover allowed or not
	 * @property {Boolean} SMSRolloverAllowed    SMS rollover allowed or not
	 * @property {Boolean} MMSRolloverAllowed    MMS rollover allowed or not
	 * @property {Boolean} VoiceRolloverAllowed  Voice rollover allowed or not
	 *
	 */
	ChangeMobileAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/addon`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeMobileAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeMobileAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeMobileAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeMobileAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeMobileAddon'))
			})
	},

	/**
	 * RPC Method: ChangeSIM
	 * Description: Change mobile product SIM (IMSI and SIMID) to a different one
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/sim
	 * @param    {SIMChangeRequest} req The API payload object (SIMChangeRequest)
	 * @return   {Mobile} The API response object (Mobile)
	 *
	 * @typedef  {Object}  SIMChangeRequest
	 * @property {Number}  SPID         Service Provider ID
	 * @property {String}  MobileUUID   Mobile Product Instance UUID
	 * @property {String}  CustomerUUID CustomerUUID
	 * @property {String}  SIMID        SIMID to change to
	 * @property {String}  IMSI         IMSI to change to
	 * @property {String}  ICCID        ICCID to change to
	 *
	 * @typedef  {Object}  Mobile
	 * @property {String}  UUID                                Provision sim
	 * @property {Number}  SPID                                Totally release sim, product and all addons
	 * @property {String}  SIMID                               FinaliseActivationMobileProduct activates a Mobile product,,  moving the product from the Welcome state into the active state,,  after which, Addons can be purchased.
	 * @property {String}  IMSI                                IMSI on the mobile product
	 * @property {Number}  CreatedAtNanos                      Purchase Addon
	 * @property {Number}  UpdatedAtNanos                      When the object was last updated
	 * @property {Number}  DeletedAtNanos                      When the object was deleted
	 * @property {String}  CustomerUUID                        Customer whom this product belongs to
	 * @property {String}  MSISDN                              option (google.api.http) = {,  	 get: "/api/v4/mobile/{UUID}/addons",  };
	 * @property {ENUM}    Status
	 * @property {ENUM}    PortingAction                       PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN                       option (google.api.http) = {,  	 get: "/api/v4/mobile_entries",  };
	 * @property {String}  PortingUUID                         Find all mobile product availabilities for specific catalog entry for spid x
	 * @property {Number}  PortingRFS                          Find all mobile products for spid x (only active)
	 * @property {String}  Name                                Find all mobile products addons for spid x ?
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled                    Options:,  Is voicemail enabled for this product
	 * @property {String}  ProductAvailabilityUUID             Product Catalog Availability UUID
	 * @property {Number}  MainBalCreditCents                  Amount of Money for main balance that will be topped up
	 * @property {Number}  EndUserBalCreditCents               Amount of Money for end user balance that will be topped up
	 * @property {Number}  CreditSMS                           Amount of SMSs of all addons and rollovers left
	 * @property {Number}  CreditVoiceMinutes                  Amount of VoiceMinutes of all addons and rollovers left
	 * @property {Number}  CreditDataMB                        Amount of Data MBs of all addons and rollovers left
	 * @property {Number}  CreditMMS                           Amount of MMSs  of all addons and rollovers left
	 * @property {Number}  RolloverCreditSMS                   Amount of SMSs of all rollovers
	 * @property {Number}  RolloverCreditVoiceMinutes          Amount of VoiceMinutes of all rollovers
	 * @property {Number}  RolloverCreditDataMB                Amount of Data MBs of all rollovers
	 * @property {Number}  RolloverCreditMMS                   Amount of MMSs of all rollovers
	 * @property {MobileAddon[]} Addons                              List of current addons on this product
	 * @property {Number}  TotalSMS                            Amount of SMSs of all addons, including any rollover
	 * @property {Number}  TotalVoiceMinutes                   Amount of VoiceMinutes of all addons, including any rollover
	 * @property {Number}  TotalDataMB                         Amount of Data MBs of all addons, including any rollover
	 * @property {Number}  TotalMMS                            Amount of MMSs of all addons, including any rollover
	 * @property {Number}  MainCurrencyCreditExpiresAtNanos    When the main currency credit expires
	 * @property {Number}  EndUserCurrencyCreditExpiresAtNanos When the end user currency credit expires
	 * @property {Number}  TotalRolloverSMS                    Total Amount of SMSs of all rollovers
	 * @property {Number}  TotalRolloverVoiceMinutes           Total Amount of VoiceMinutes of all rollovers
	 * @property {Number}  TotalRolloverDataMB                 Total Amount of Data MBs of all rollovers
	 * @property {Number}  TotalRolloverMMS                    Total Amount of MMSs of all rollovers
	 * @property {String}  GroupUUID                           The name of the group product to end-customers,  GroupUUID is the product group instance which this product is connected to
	 * @property {String}  GroupName
	 * @property {Number}  ServiceCreditLimitCents             The service credit limit, in cents;
	 * @property {String}  BillingReference                    BillingReference describes the payment method which is postpay or prepay
	 *
	 */
	ChangeSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeSIM'))
			})
	},

	/**
	 * RPC Method: ChangeServiceCreditLimit
	 * Description: Change Service Credit Limit
	 * Set the service credit limit, in cents. The service credit limit is a service level overdraft meter.
	 *
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/service_credit_limit
	 * @param    {ServiceCreditLimitChangeRequest} req The API payload object (ServiceCreditLimitChangeRequest)
	 * @return   {ServiceCreditLimitChangeResponse} The API response object (ServiceCreditLimitChangeResponse)
	 *
	 * @typedef  {Object}  ServiceCreditLimitChangeRequest
	 * @property {Number}  SPID                    Service Provider ID
	 * @property {String}  MobileUUID              Mobile Product Instance UUID
	 * @property {String}  CustomerUUID            CustomerUUID
	 * @property {String}  MSISDN                  MSISDN mobile product identifier
	 * @property {String}  ICCID                   SIM ICCID mobile product identifier
	 * @property {String}  SIMID                   SIM ID mobile product identifier
	 * @property {String}  IMSI                    IMSI mobile product identifier
	 * @property {Number}  ServiceCreditLimitCents The new service credit limit
	 *
	 * @typedef  {Object}  ServiceCreditLimitChangeResponse
	 * @property {Number}  SPID
	 * @property {String}  MobileUUID
	 * @property {Number}  ServiceCreditLimitCents
	 *
	 */
	ChangeServiceCreditLimit({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/service_credit_limit`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeServiceCreditLimit ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeServiceCreditLimit ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeServiceCreditLimit ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeServiceCreditLimit UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeServiceCreditLimit'))
			})
	},

	/**
	 * RPC Method: CreateMobileProduct
	 * Description: Create Mobile Product
	 * HTTP Method: POST
	 * API Path: /api/v4/mobile
	 * @param    {CreateMobileProductRequest} req The API payload object (CreateMobileProductRequest)
	 * @return   {CreateMobileProductResponse} The API response object (CreateMobileProductResponse)
	 *
	 * @typedef  {Object}  CreateMobileProductRequest
	 * @property {Number}  SPID
	 * @property {String}  IMSI                           Require IMSI or SIMID or ICCID
	 * @property {String}  SIMID
	 * @property {String}  ICCID
	 * @property {String}  MSISDN
	 * @property {String}  ProductCatalogAvailabilityUUID
	 * @property {String}  CustomerUUID                   UUID of an existing Customer to use
	 * @property {Boolean} SetServiceActive               This is to set the service active after provision, otherwise it is preactive,  This will overwrite the settings in catalog characteristic ocs.SetServiceActive
	 * @property {String}  BillingReference               BillingReference describes the payment method which is postpay or prepay
	 * @property {CreateMobileProductRequest_PreactivationMetadataEntry[]} PreactivationMetadata          PreactivationMetadata are service provider defined key-value pairs that will be available in the ,  adoption token, contained within the welcome SMS. Could be useful for storing ,  information about a pre-selected plan, or pre-purchase information.
	 * @property {Number}  ServiceCreditLimitCents        ServiceCreditLimitCents sets the service credit limit
	 *
	 * @typedef  {Object}  CreateMobileProductResponse
	 * @property {Number}  SPID                           The service provider ID
	 * @property {String}  IMSI                           IMSI of the provisioned SIM
	 * @property {String}  SIMID                          SIMID of the provisioned SIM
	 * @property {String}  ICCID                          ICCID of the provisioned SIM
	 * @property {String}  MSISDN                         MSISDN of the provisioned SIM
	 * @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID used to activate
	 * @property {String}  MobileUUID                     Mobile Product UUID
	 * @property {String}  CustomerUUID                   The Customer UUID for this product
	 *
	 */
	CreateMobileProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v4/mobile`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateMobileProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateMobileProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateMobileProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateMobileProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateMobileProduct'))
			})
	},

	/**
	 * RPC Method: CreateMobileProductAddon
	 * Description: Create Mobile Product Addon
	 * HTTP Method: POST
	 * API Path: /api/v4/mobile/addon
	 * @param    {PurchaseAddonRequest} req The API payload object (PurchaseAddonRequest)
	 * @return   {PurchaseAddonResponse} The API response object (PurchaseAddonResponse)
	 *
	 * @typedef  {Object}  PurchaseAddonRequest
	 * @property {Number}  SPID                  The service provider ID
	 * @property {String}  AddonAvailabilityUUID New Product Availability UUID for the Addon, required
	 * @property {String}  CustomerUUID          The Customer UUID for this product
	 * @property {String}  MobileUUID            Parent Product UUID of the Addon, required
	 * @property {Number}  RetailCents           RetailCents sets the Retail Price (in cents) of the AddonAvailabilityUUID,  If this is -1, then the price is inheritted from the Product Availability
	 *
	 * @typedef  {Object}  PurchaseAddonResponse
	 * @property {Number}  SPID                  The service provider ID
	 * @property {String}  AddonAvailabilityUUID New Product Availability UUID
	 * @property {String}  CustomerUUID          The Customer UUID for this product
	 * @property {String}  AddonInstanceUUID     The Product Instance UUID of the Addon
	 * @property {String}  AddonOrderUUID        The Product Order UUID of the Addon
	 *
	 */
	CreateMobileProductAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v4/mobile/addon`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreateMobileProductAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreateMobileProductAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreateMobileProductAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreateMobileProductAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreateMobileProductAddon'))
			})
	},

	/**
	 * RPC Method: FinaliseActivationMobileProduct
	 * Description: Finalise Activation Mobile Product
	 * HTTP Method: POST
	 * API Path: /api/v4/mobile/finalise_activation
	 * @param    {MobileProductActivationRequest} req The API payload object (MobileProductActivationRequest)
	 * @return   {Mobile} The API response object (Mobile)
	 *
	 * @typedef  {Object}  MobileProductActivationRequest
	 * @property {Number}  SPID                    Service Provider ID
	 * @property {String}  MobileUUID              UUID of MobileProduct
	 * @property {String}  CustomerUUID            string Token = 7;
	 * @property {String}  ProductAvailabilityUUID Product Catalog Availability UUID of the base Mobile product
	 * @property {String}  BillingReference
	 *
	 * @typedef  {Object}  Mobile
	 * @property {String}  UUID                                Provision sim
	 * @property {Number}  SPID                                Totally release sim, product and all addons
	 * @property {String}  SIMID                               FinaliseActivationMobileProduct activates a Mobile product,,  moving the product from the Welcome state into the active state,,  after which, Addons can be purchased.
	 * @property {String}  IMSI                                IMSI on the mobile product
	 * @property {Number}  CreatedAtNanos                      Purchase Addon
	 * @property {Number}  UpdatedAtNanos                      When the object was last updated
	 * @property {Number}  DeletedAtNanos                      When the object was deleted
	 * @property {String}  CustomerUUID                        Customer whom this product belongs to
	 * @property {String}  MSISDN                              option (google.api.http) = {,  	 get: "/api/v4/mobile/{UUID}/addons",  };
	 * @property {ENUM}    Status
	 * @property {ENUM}    PortingAction                       PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN                       option (google.api.http) = {,  	 get: "/api/v4/mobile_entries",  };
	 * @property {String}  PortingUUID                         Find all mobile product availabilities for specific catalog entry for spid x
	 * @property {Number}  PortingRFS                          Find all mobile products for spid x (only active)
	 * @property {String}  Name                                Find all mobile products addons for spid x ?
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled                    Options:,  Is voicemail enabled for this product
	 * @property {String}  ProductAvailabilityUUID             Product Catalog Availability UUID
	 * @property {Number}  MainBalCreditCents                  Amount of Money for main balance that will be topped up
	 * @property {Number}  EndUserBalCreditCents               Amount of Money for end user balance that will be topped up
	 * @property {Number}  CreditSMS                           Amount of SMSs of all addons and rollovers left
	 * @property {Number}  CreditVoiceMinutes                  Amount of VoiceMinutes of all addons and rollovers left
	 * @property {Number}  CreditDataMB                        Amount of Data MBs of all addons and rollovers left
	 * @property {Number}  CreditMMS                           Amount of MMSs  of all addons and rollovers left
	 * @property {Number}  RolloverCreditSMS                   Amount of SMSs of all rollovers
	 * @property {Number}  RolloverCreditVoiceMinutes          Amount of VoiceMinutes of all rollovers
	 * @property {Number}  RolloverCreditDataMB                Amount of Data MBs of all rollovers
	 * @property {Number}  RolloverCreditMMS                   Amount of MMSs of all rollovers
	 * @property {MobileAddon[]} Addons                              List of current addons on this product
	 * @property {Number}  TotalSMS                            Amount of SMSs of all addons, including any rollover
	 * @property {Number}  TotalVoiceMinutes                   Amount of VoiceMinutes of all addons, including any rollover
	 * @property {Number}  TotalDataMB                         Amount of Data MBs of all addons, including any rollover
	 * @property {Number}  TotalMMS                            Amount of MMSs of all addons, including any rollover
	 * @property {Number}  MainCurrencyCreditExpiresAtNanos    When the main currency credit expires
	 * @property {Number}  EndUserCurrencyCreditExpiresAtNanos When the end user currency credit expires
	 * @property {Number}  TotalRolloverSMS                    Total Amount of SMSs of all rollovers
	 * @property {Number}  TotalRolloverVoiceMinutes           Total Amount of VoiceMinutes of all rollovers
	 * @property {Number}  TotalRolloverDataMB                 Total Amount of Data MBs of all rollovers
	 * @property {Number}  TotalRolloverMMS                    Total Amount of MMSs of all rollovers
	 * @property {String}  GroupUUID                           The name of the group product to end-customers,  GroupUUID is the product group instance which this product is connected to
	 * @property {String}  GroupName
	 * @property {Number}  ServiceCreditLimitCents             The service credit limit, in cents;
	 * @property {String}  BillingReference                    BillingReference describes the payment method which is postpay or prepay
	 *
	 */
	FinaliseActivationMobileProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v4/mobile/finalise_activation`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FinaliseActivationMobileProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FinaliseActivationMobileProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FinaliseActivationMobileProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FinaliseActivationMobileProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FinaliseActivationMobileProduct'))
			})
	},

	/**
	 * RPC Method: FindMobileAddonPlans
	 * Description: Returns the list of available mobile Addon plans.
	 *
	 * HTTP Method: GET
	 * API Path: /api/v4/mobile/addon_plans
	 * @param    {MobileAddonPlanSearchRequest} req The API payload object (MobileAddonPlanSearchRequest)
	 * @return   {MobileAddonPlanPageResponse} The API response object (MobileAddonPlanPageResponse)
	 *
	 * @typedef  {Object}  MobileAddonPlanSearchRequest
	 * @property {SearchOptions[]} SearchOptions               Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID                        SPID is the service provider ID that the product belongs to
	 * @property {String}  CustomerUUID                WARNING: Not used/implemented
	 * @property {String}  SmartSearch                 SmartSearch can be used to search on availability Name/Description
	 * @property {String}  MobileAddonPlanUUID         Mobile addon catalog availability UUID(Addon plan UUID)
	 * @property {String}  MobileAddonCatalogEntryUUID Mobile addon Catalog Entry UUID
	 *
	 * @typedef  {Object}  MobileAddonPlanPageResponse
	 * @property {PageInfo} PageInfo         PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {MobileAddonPlan[]} MobileAddonPlans The list of matching Products
	 *
	 */
	FindMobileAddonPlans({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v4/mobile/addon_plans`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobileAddonPlans ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobileAddonPlans ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobileAddonPlans ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobileAddonPlans UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobileAddonPlans'))
			})
	},

	/**
	 * RPC Method: FindMobileAddonProducts
	 * Description: Find Mobile Products Paginated
	 * HTTP Method: GET
	 * API Path: /api/v4/mobile/addons
	 * @param    {MobileAddonProductsRequest} req The API payload object (MobileAddonProductsRequest)
	 * @return   {MobileAddonProductsPageResponse} The API response object (MobileAddonProductsPageResponse)
	 *
	 * @typedef  {Object}  MobileAddonProductsRequest
	 * @property {SearchOptions[]} SearchOptions         Standard search options
	 * @property {Number}  SPID                  Service Provider Identifier
	 * @property {String}  ProductType           The type of product
	 * @property {String}  CustomerUUID          Customer UUID
	 * @property {Boolean} IncludeDeleted        Included deleted instances
	 * @property {String}  SmartSearch           SmartSearch
	 * @property {String}  MobileUUID            UUID of the Mobile product
	 * @property {Number}  ExpiresAtNanos        State,  ProductState State = 8;,  ExpiresAtNanos
	 * @property {Number}  ExpiresAtNanosFrom
	 * @property {Number}  ExpiresAtNanosUntil
	 * @property {String}  GroupUUID             GroupInstanceUUID
	 * @property {String}  AddonAvailabilityUUID
	 *
	 * @typedef  {Object}  MobileAddonProductsPageResponse
	 * @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {MobileAddon[]} MobileAddons The list of matching Products
	 *
	 */
	FindMobileAddonProducts({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v4/mobile/addons`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobileAddonProducts ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobileAddonProducts ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobileAddonProducts ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobileAddonProducts UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobileAddonProducts'))
			})
	},

	/**
	 * RPC Method: FindMobileCatalogAvailabilities
	 * Description: Find Mobile Catalog Availabilities By Catalog Entry
	 * HTTP Method: GET
	 * API Path: /api/v4/mobile/availabilities
	 * @param    {MobileCatalogAvailabilitiesSearchRequest} req The API payload object (MobileCatalogAvailabilitiesSearchRequest)
	 * @return   {MobileCatalogAvailabilitiesPageResponse} The API response object (MobileCatalogAvailabilitiesPageResponse)
	 *
	 * @typedef  {Object}  MobileCatalogAvailabilitiesSearchRequest
	 * @property {SearchOptions[]} SearchOptions           Standard search options
	 * @property {Number}  SPID                    Service Provider Identifier
	 * @property {String}  UUID                    Product availability UUID
	 * @property {String}  ProductCatalogEntryUUID
	 * @property {String}  CustomerUUID            For apigw compiling
	 * @property {Boolean} IncludeDeleted
	 *
	 * @typedef  {Object}  MobileCatalogAvailabilitiesPageResponse
	 * @property {PageInfo} PageInfo                     PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {ProductCatalogAvailability[]} ProductCatalogAvailabilities
	 *
	 */
	FindMobileCatalogAvailabilities({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v4/mobile/availabilities`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobileCatalogAvailabilities ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobileCatalogAvailabilities ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobileCatalogAvailabilities ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobileCatalogAvailabilities UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobileCatalogAvailabilities'))
			})
	},

	/**
	 * RPC Method: FindMobilePlans
	 * Description: FindMobilePlansPaginated
	 *
	 * Returns the list of available mobile plans available to the SP customer.
	 * SmartSearch is optional. If set, backend will filter on Name/Description of plans.
	 *
	 * ### Request Fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|specifies the customer to attach this product to|
	 * You can use the SearchOptions to filter through the returned plans
	 *
	 * ### Example Request
	 *
	 *
	 * HTTP Method: GET
	 * API Path: /api/v4/mobile/plans
	 * @param    {MobilePlanSearchRequest} req The API payload object (MobilePlanSearchRequest)
	 * @return   {MobilePlanPageResponse} The API response object (MobilePlanPageResponse)
	 *
	 * @typedef  {Object}  MobilePlanSearchRequest
	 * @property {SearchOptions[]} SearchOptions          Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID                   SPID is the service provider ID that the product belongs to
	 * @property {String}  CustomerUUID           WARNING: Not used/implemented
	 * @property {String}  SmartSearch            SmartSearch can be used to search on availability Name/Description
	 * @property {String}  MobilePlanUUID         Mobile catalog availability UUID
	 * @property {String}  MobileCatalogEntryUUID Mobile Catalog Entry UUID
	 *
	 * @typedef  {Object}  MobilePlanPageResponse
	 * @property {PageInfo} PageInfo    PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {MobilePlan[]} MobilePlans The list of matching Products
	 *
	 */
	FindMobilePlans({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v4/mobile/plans`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobilePlans ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobilePlans ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobilePlans ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobilePlans UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobilePlans'))
			})
	},

	/**
	 * RPC Method: FindMobileProducts
	 * Description: Find Mobile Products Paginated
	 * HTTP Method: GET
	 * API Path: /api/v4/mobiles
	 * @param    {MobileProductsRequest} req The API payload object (MobileProductsRequest)
	 * @return   {MobileProductsPageResponse} The API response object (MobileProductsPageResponse)
	 *
	 * @typedef  {Object}  MobileProductsRequest
	 * @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID                SPID is the service provider ID that the product belongs to
	 * @property {String}  UUID                UUID is the unique ID for this product
	 * @property {String}  CustomerUUID        CustomerUUID if attached to a customer
	 * @property {String}  MSISDN              MSISDN is if we want/wanted to autoallocate a MSISDN
	 * @property {String}  SIMID               SIMID is the SIMID that this product has (SIM)
	 * @property {String}  State               State is the current product state
	 * @property {Number}  CreatedAtNanosFrom  Search from the informed date (inclusive)
	 * @property {Number}  CreatedAtNanosUntil Search until the informed date (exclusive)
	 *
	 * @typedef  {Object}  MobileProductsPageResponse
	 * @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber and PageSize set
	 * @property {Mobile[]} Mobiles  The list of matching Products
	 *
	 */
	FindMobileProducts({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v4/mobiles`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobileProducts ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobileProducts ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobileProducts ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobileProducts UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobileProducts'))
			})
	},

	/**
	 * RPC Method: GetMobileProduct
	 * Description: Get Mobile Product By UUID/MSISDN/IMSI
	 * HTTP Method: GET
	 * API Path: /api/v4/mobile
	 * @param    {MobileProductSearch} req The API payload object (MobileProductSearch)
	 * @return   {Mobile} The API response object (Mobile)
	 *
	 * @typedef  {Object}  MobileProductSearch
	 * @property {Number}  SPID           Service Provider ID - always required
	 * @property {Boolean} IncludeDeleted Deleted products are included if true, excluded by default.
	 * @property {String}  UUID
	 * @property {String}  CustomerUUID
	 * @property {String}  SIMID          Unique SIM identifier
	 * @property {String}  ICCID          SIM ICCID identifier
	 * @property {String}  IMSI           IMSI of the SIM
	 * @property {String}  MSISDN         MSISDN of the SIM
	 *
	 * @typedef  {Object}  Mobile
	 * @property {String}  UUID                                Provision sim
	 * @property {Number}  SPID                                Totally release sim, product and all addons
	 * @property {String}  SIMID                               FinaliseActivationMobileProduct activates a Mobile product,,  moving the product from the Welcome state into the active state,,  after which, Addons can be purchased.
	 * @property {String}  IMSI                                IMSI on the mobile product
	 * @property {Number}  CreatedAtNanos                      Purchase Addon
	 * @property {Number}  UpdatedAtNanos                      When the object was last updated
	 * @property {Number}  DeletedAtNanos                      When the object was deleted
	 * @property {String}  CustomerUUID                        Customer whom this product belongs to
	 * @property {String}  MSISDN                              option (google.api.http) = {,  	 get: "/api/v4/mobile/{UUID}/addons",  };
	 * @property {ENUM}    Status
	 * @property {ENUM}    PortingAction                       PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN                       option (google.api.http) = {,  	 get: "/api/v4/mobile_entries",  };
	 * @property {String}  PortingUUID                         Find all mobile product availabilities for specific catalog entry for spid x
	 * @property {Number}  PortingRFS                          Find all mobile products for spid x (only active)
	 * @property {String}  Name                                Find all mobile products addons for spid x ?
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled                    Options:,  Is voicemail enabled for this product
	 * @property {String}  ProductAvailabilityUUID             Product Catalog Availability UUID
	 * @property {Number}  MainBalCreditCents                  Amount of Money for main balance that will be topped up
	 * @property {Number}  EndUserBalCreditCents               Amount of Money for end user balance that will be topped up
	 * @property {Number}  CreditSMS                           Amount of SMSs of all addons and rollovers left
	 * @property {Number}  CreditVoiceMinutes                  Amount of VoiceMinutes of all addons and rollovers left
	 * @property {Number}  CreditDataMB                        Amount of Data MBs of all addons and rollovers left
	 * @property {Number}  CreditMMS                           Amount of MMSs  of all addons and rollovers left
	 * @property {Number}  RolloverCreditSMS                   Amount of SMSs of all rollovers
	 * @property {Number}  RolloverCreditVoiceMinutes          Amount of VoiceMinutes of all rollovers
	 * @property {Number}  RolloverCreditDataMB                Amount of Data MBs of all rollovers
	 * @property {Number}  RolloverCreditMMS                   Amount of MMSs of all rollovers
	 * @property {MobileAddon[]} Addons                              List of current addons on this product
	 * @property {Number}  TotalSMS                            Amount of SMSs of all addons, including any rollover
	 * @property {Number}  TotalVoiceMinutes                   Amount of VoiceMinutes of all addons, including any rollover
	 * @property {Number}  TotalDataMB                         Amount of Data MBs of all addons, including any rollover
	 * @property {Number}  TotalMMS                            Amount of MMSs of all addons, including any rollover
	 * @property {Number}  MainCurrencyCreditExpiresAtNanos    When the main currency credit expires
	 * @property {Number}  EndUserCurrencyCreditExpiresAtNanos When the end user currency credit expires
	 * @property {Number}  TotalRolloverSMS                    Total Amount of SMSs of all rollovers
	 * @property {Number}  TotalRolloverVoiceMinutes           Total Amount of VoiceMinutes of all rollovers
	 * @property {Number}  TotalRolloverDataMB                 Total Amount of Data MBs of all rollovers
	 * @property {Number}  TotalRolloverMMS                    Total Amount of MMSs of all rollovers
	 * @property {String}  GroupUUID                           The name of the group product to end-customers,  GroupUUID is the product group instance which this product is connected to
	 * @property {String}  GroupName
	 * @property {Number}  ServiceCreditLimitCents             The service credit limit, in cents;
	 * @property {String}  BillingReference                    BillingReference describes the payment method which is postpay or prepay
	 *
	 */
	GetMobileProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v4/mobile`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProduct'))
			})
	},

	/**
	 * RPC Method: GetMobileProductByUUID
	 * Description: Get Mobile Product By UUID
	 * HTTP Method: GET
	 * API Path: /api/v4/mobile/${req.UUID}
	 * @param    {MobileByUUIDSearch} req The API payload object (MobileByUUIDSearch)
	 * @return   {Mobile} The API response object (Mobile)
	 *
	 * @typedef  {Object}  MobileByUUIDSearch
	 * @property {String}  UUID
	 * @property {Number}  SPID           Service Provider ID - always required
	 * @property {String}  Description    (optional) Description
	 * @property {Boolean} IncludeDeleted Deleted products are included if true, excluded by default.
	 *
	 * @typedef  {Object}  Mobile
	 * @property {String}  UUID                                Provision sim
	 * @property {Number}  SPID                                Totally release sim, product and all addons
	 * @property {String}  SIMID                               FinaliseActivationMobileProduct activates a Mobile product,,  moving the product from the Welcome state into the active state,,  after which, Addons can be purchased.
	 * @property {String}  IMSI                                IMSI on the mobile product
	 * @property {Number}  CreatedAtNanos                      Purchase Addon
	 * @property {Number}  UpdatedAtNanos                      When the object was last updated
	 * @property {Number}  DeletedAtNanos                      When the object was deleted
	 * @property {String}  CustomerUUID                        Customer whom this product belongs to
	 * @property {String}  MSISDN                              option (google.api.http) = {,  	 get: "/api/v4/mobile/{UUID}/addons",  };
	 * @property {ENUM}    Status
	 * @property {ENUM}    PortingAction                       PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN                       option (google.api.http) = {,  	 get: "/api/v4/mobile_entries",  };
	 * @property {String}  PortingUUID                         Find all mobile product availabilities for specific catalog entry for spid x
	 * @property {Number}  PortingRFS                          Find all mobile products for spid x (only active)
	 * @property {String}  Name                                Find all mobile products addons for spid x ?
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled                    Options:,  Is voicemail enabled for this product
	 * @property {String}  ProductAvailabilityUUID             Product Catalog Availability UUID
	 * @property {Number}  MainBalCreditCents                  Amount of Money for main balance that will be topped up
	 * @property {Number}  EndUserBalCreditCents               Amount of Money for end user balance that will be topped up
	 * @property {Number}  CreditSMS                           Amount of SMSs of all addons and rollovers left
	 * @property {Number}  CreditVoiceMinutes                  Amount of VoiceMinutes of all addons and rollovers left
	 * @property {Number}  CreditDataMB                        Amount of Data MBs of all addons and rollovers left
	 * @property {Number}  CreditMMS                           Amount of MMSs  of all addons and rollovers left
	 * @property {Number}  RolloverCreditSMS                   Amount of SMSs of all rollovers
	 * @property {Number}  RolloverCreditVoiceMinutes          Amount of VoiceMinutes of all rollovers
	 * @property {Number}  RolloverCreditDataMB                Amount of Data MBs of all rollovers
	 * @property {Number}  RolloverCreditMMS                   Amount of MMSs of all rollovers
	 * @property {MobileAddon[]} Addons                              List of current addons on this product
	 * @property {Number}  TotalSMS                            Amount of SMSs of all addons, including any rollover
	 * @property {Number}  TotalVoiceMinutes                   Amount of VoiceMinutes of all addons, including any rollover
	 * @property {Number}  TotalDataMB                         Amount of Data MBs of all addons, including any rollover
	 * @property {Number}  TotalMMS                            Amount of MMSs of all addons, including any rollover
	 * @property {Number}  MainCurrencyCreditExpiresAtNanos    When the main currency credit expires
	 * @property {Number}  EndUserCurrencyCreditExpiresAtNanos When the end user currency credit expires
	 * @property {Number}  TotalRolloverSMS                    Total Amount of SMSs of all rollovers
	 * @property {Number}  TotalRolloverVoiceMinutes           Total Amount of VoiceMinutes of all rollovers
	 * @property {Number}  TotalRolloverDataMB                 Total Amount of Data MBs of all rollovers
	 * @property {Number}  TotalRolloverMMS                    Total Amount of MMSs of all rollovers
	 * @property {String}  GroupUUID                           The name of the group product to end-customers,  GroupUUID is the product group instance which this product is connected to
	 * @property {String}  GroupName
	 * @property {Number}  ServiceCreditLimitCents             The service credit limit, in cents;
	 * @property {String}  BillingReference                    BillingReference describes the payment method which is postpay or prepay
	 *
	 */
	GetMobileProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v4/mobile/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProductByUUID'))
			})
	},

	/**
	 * RPC Method: GetProductAvailabilityByUUID
	 * Description: Get one product availability by UUID
	 * HTTP Method: GET
	 * API Path: /api/v3/mobile/product_availability/${req.UUID}
	 * @param    {ProductAvailabilityUUIDSearch} req The API payload object (ProductAvailabilityUUIDSearch)
	 * @return   {ProductCatalogAvailabilityResponse} The API response object (ProductCatalogAvailabilityResponse)
	 *
	 * @typedef  {Object}  ProductAvailabilityUUIDSearch
	 * @property {Number}  SPID // Standard search options,  tktcommonproto.SearchOptions SearchOptions = 1;,  The Service Provider ID
	 * @property {String}  UUID The UUID of the ProductCatalogAvailability
	 *
	 * @typedef  {Object}  ProductCatalogAvailabilityResponse
	 * @property {ProductCatalogAvailability} Availability
	 *
	 */
	GetProductAvailabilityByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/mobile/product_availability/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetProductAvailabilityByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetProductAvailabilityByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetProductAvailabilityByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetProductAvailabilityByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetProductAvailabilityByUUID'))
			})
	},

	/**
	 * RPC Method: MobileProductPortCancel
	 * Description: Cancels the IPMS Port Request if the port has not yet been completed. May only be called by the gaining service provider.
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/portcancel
	 * @param    {MobileProductPortCancelRequest} req The API payload object (MobileProductPortCancelRequest)
	 * @return   {Mobile} The API response object (Mobile)
	 *
	 * @typedef  {Object}  MobileProductPortCancelRequest
	 * @property {Number}  SPID         The Service Provider ID
	 * @property {String}  UUID         Mobile Product UUID
	 * @property {String}  CustomerUUID Customer UUID (optional)
	 *
	 * @typedef  {Object}  Mobile
	 * @property {String}  UUID                                Provision sim
	 * @property {Number}  SPID                                Totally release sim, product and all addons
	 * @property {String}  SIMID                               FinaliseActivationMobileProduct activates a Mobile product,,  moving the product from the Welcome state into the active state,,  after which, Addons can be purchased.
	 * @property {String}  IMSI                                IMSI on the mobile product
	 * @property {Number}  CreatedAtNanos                      Purchase Addon
	 * @property {Number}  UpdatedAtNanos                      When the object was last updated
	 * @property {Number}  DeletedAtNanos                      When the object was deleted
	 * @property {String}  CustomerUUID                        Customer whom this product belongs to
	 * @property {String}  MSISDN                              option (google.api.http) = {,  	 get: "/api/v4/mobile/{UUID}/addons",  };
	 * @property {ENUM}    Status
	 * @property {ENUM}    PortingAction                       PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN                       option (google.api.http) = {,  	 get: "/api/v4/mobile_entries",  };
	 * @property {String}  PortingUUID                         Find all mobile product availabilities for specific catalog entry for spid x
	 * @property {Number}  PortingRFS                          Find all mobile products for spid x (only active)
	 * @property {String}  Name                                Find all mobile products addons for spid x ?
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled                    Options:,  Is voicemail enabled for this product
	 * @property {String}  ProductAvailabilityUUID             Product Catalog Availability UUID
	 * @property {Number}  MainBalCreditCents                  Amount of Money for main balance that will be topped up
	 * @property {Number}  EndUserBalCreditCents               Amount of Money for end user balance that will be topped up
	 * @property {Number}  CreditSMS                           Amount of SMSs of all addons and rollovers left
	 * @property {Number}  CreditVoiceMinutes                  Amount of VoiceMinutes of all addons and rollovers left
	 * @property {Number}  CreditDataMB                        Amount of Data MBs of all addons and rollovers left
	 * @property {Number}  CreditMMS                           Amount of MMSs  of all addons and rollovers left
	 * @property {Number}  RolloverCreditSMS                   Amount of SMSs of all rollovers
	 * @property {Number}  RolloverCreditVoiceMinutes          Amount of VoiceMinutes of all rollovers
	 * @property {Number}  RolloverCreditDataMB                Amount of Data MBs of all rollovers
	 * @property {Number}  RolloverCreditMMS                   Amount of MMSs of all rollovers
	 * @property {MobileAddon[]} Addons                              List of current addons on this product
	 * @property {Number}  TotalSMS                            Amount of SMSs of all addons, including any rollover
	 * @property {Number}  TotalVoiceMinutes                   Amount of VoiceMinutes of all addons, including any rollover
	 * @property {Number}  TotalDataMB                         Amount of Data MBs of all addons, including any rollover
	 * @property {Number}  TotalMMS                            Amount of MMSs of all addons, including any rollover
	 * @property {Number}  MainCurrencyCreditExpiresAtNanos    When the main currency credit expires
	 * @property {Number}  EndUserCurrencyCreditExpiresAtNanos When the end user currency credit expires
	 * @property {Number}  TotalRolloverSMS                    Total Amount of SMSs of all rollovers
	 * @property {Number}  TotalRolloverVoiceMinutes           Total Amount of VoiceMinutes of all rollovers
	 * @property {Number}  TotalRolloverDataMB                 Total Amount of Data MBs of all rollovers
	 * @property {Number}  TotalRolloverMMS                    Total Amount of MMSs of all rollovers
	 * @property {String}  GroupUUID                           The name of the group product to end-customers,  GroupUUID is the product group instance which this product is connected to
	 * @property {String}  GroupName
	 * @property {Number}  ServiceCreditLimitCents             The service credit limit, in cents;
	 * @property {String}  BillingReference                    BillingReference describes the payment method which is postpay or prepay
	 *
	 */
	MobileProductPortCancel({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/portcancel`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MobileProductPortCancel ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MobileProductPortCancel ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MobileProductPortCancel ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MobileProductPortCancel UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MobileProductPortCancel'))
			})
	},

	/**
	 * RPC Method: MobileProductPortRequest
	 * Description: Places a Port Request with the tkt-nzlmnp-svc (calls ServiceProviderRequestPort). If successful, begins an automated process involving the IPMS, the gaining and losing service providers, and each respective carrier (if an inter-carrier port). The product service will be notified when the number is ready to be provisioned by the tkt-nzlmnp-svc.
	 *
	 * The Port Request Process can be summarised briefly as follows:
	 * - A Service Provider creates a Port Request with the IPMS (Industry Portability Management System), a government system responsible for facilitating local and mobile porting between NZ Service Providers. For each Port Request, the Service Provider that placed it is referred to as the GSP (Gaining Service Provider), while the receiver of the port request is referred to as the LSP (Losing Service Provider).
	 * - Each NZ Service Provider must periodically make requests to the IPMS to check if there are any Port Requests for which they are either the gaining or losing Service Provider, or to acknowledge Port Requests for which they are a third party.
	 * - The LSP (Losing Service Provider) will then discover the Port Request on its next IPMS poll. The LSP will then use either the OldSIMID (if coming from Prepay) or the AccountNumber (if coming from on-account) to validate the request. If there is no conflict between these parameters in the Port Request and what the LSP has on record, then the port must be allowed.
	 * - If the GSP and LSP are operating on different Carriers then the carriers will undergo a similar process where the Gaining and Losing Carriers will acknowledge the Port Request before it is eventually completed and the new service can be provisioned on the GSP after which the new service is ready to use. Successful Ports will then show a status of Closed. If the LSP rejects the port request (because the AccountNumber or OldSIMID do not match what it has on record for the given customer) then the port will show a status of Rejected.
	 * - In the normal course of events a port between two Service Providers on the same Carrier will pass through the following states: Awaiting LSP Response, Awaiting GSP Approval, Approved, Closed. A port between two Service Providers on different Carriers will pass through the following states: Awaiting LSP Response, Awaiting GSP Approval, Approved, In Progress, GC and LC Complete, Closed.
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/portnumber
	 * @param    {MobileProductNumberPortRequest} req The API payload object (MobileProductNumberPortRequest)
	 * @return   {MobileProductNumberPortResponse} The API response object (MobileProductNumberPortResponse)
	 *
	 * @typedef  {Object}  MobileProductNumberPortRequest
	 * @property {Number}  SPID                The Service Provider ID
	 * @property {String}  UUID                Mobile Product UUID
	 * @property {String}  MSISDN              The MSISDN (phone number) to keep
	 * @property {String}  OldSIMID            The SIMID of the current service, on current provider. Required if coming from Prepay.
	 * @property {String}  CustomerUUID
	 * @property {Number}  LSP                 The LSP (Losing Service Provider) IPMS (Industry Portability Management System) ID. ,  If omitted/zero, LMNP will attempt to discover LSP itself by querying the IPMS.,  If set, the LSPOverride flag will be 'true' in the request to the IPMS, or 'false' if the LSP if discovered.
	 * @property {String}  AccountNumber       The account number of the customer's current service (on current provider). Required if coming from OnAccount.
	 * @property {String}  CustomerName        The name on the customer's account at the current service (on current provider). If coming from OnAccount, LMNP will attempt to use first and last name from the customer service using the CustomerUUID. Ideally this should be provided to avoid mis-match.
	 * @property {Boolean} SMSResponseRequired Enable two factor authentication for this port
	 * @property {Number}  PortRFSNanos        Ready for service date time of this port
	 *
	 * @typedef  {Object}  MobileProductNumberPortResponse
	 * @property {Number}  SPID                      The Service Provider ID
	 * @property {String}  UUID                      UUID of Mobile Product
	 * @property {String}  MSISDN                    The MSISDN (phone number) being ported
	 * @property {Number}  PortRFSNanos              When the port is expected to take place
	 * @property {Boolean} TwoFANotificationExpected Will the port require a 2FA response
	 * @property {ServiceProviderData[]} ServiceProviderList
	 *
	 */
	MobileProductPortRequest({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/portnumber`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MobileProductPortRequest ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MobileProductPortRequest ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MobileProductPortRequest ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MobileProductPortRequest UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MobileProductPortRequest'))
			})
	},

	/**
	 * RPC Method: MobileProductRequestApprovedPortChange
	 * Description: Change an existing port in the approved state. Primarily used to update the RFS time of a port.
	 * HTTP Method: PUT
	 * API Path: /api/v4/mobile/approved_port_change
	 * @param    {MobileProductApprovedPortChangeRequest} req The API payload object (MobileProductApprovedPortChangeRequest)
	 * @return   {MobileProductApprovedPortChangeResponse} The API response object (MobileProductApprovedPortChangeResponse)
	 *
	 * @typedef  {Object}  MobileProductApprovedPortChangeRequest
	 * @property {Number}  SPID                The Service Provider ID
	 * @property {String}  UUID                Mobile Product UUID
	 * @property {String}  MSISDN              The MSISDN (phone number) to keep
	 * @property {String}  CustomerUUID
	 * @property {Boolean} SMSResponseRequired Enable two factor authentication for this port
	 * @property {Number}  PortRFSNanos        Ready for service date time of this port
	 *
	 * @typedef  {Object}  MobileProductApprovedPortChangeResponse
	 * @property {Number}  SPID         The Service Provider ID
	 * @property {String}  UUID         Mobile Product UUID
	 * @property {String}  MSISDN
	 * @property {Number}  PortRFSNanos When the port is expected to take place
	 *
	 */
	MobileProductRequestApprovedPortChange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v4/mobile/approved_port_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MobileProductRequestApprovedPortChange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MobileProductRequestApprovedPortChange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MobileProductRequestApprovedPortChange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MobileProductRequestApprovedPortChange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MobileProductRequestApprovedPortChange'))
			})
	},

	/**
	 * RPC Method: TerminateMobileProduct
	 * Description: Terminate a SIM on the network: totally release the sim, products and addons
	 * HTTP Method: DELETE
	 * API Path: /api/v4/mobile
	 * @param    {TerminateRequest} req The API payload object (TerminateRequest)
	 * @return   {TerminateResponse} The API response object (TerminateResponse)
	 *
	 * @typedef  {Object}  TerminateRequest
	 * @property {Number}  SPID         The service provider ID
	 * @property {String}  IMSI         IMSI of the provisioned SIM
	 * @property {String}  SIMID        SIMID of the provisioned SIM
	 * @property {String}  ICCID        ICCID of the provisioned SIM
	 * @property {String}  MSISDN       MSISDN of the provisioned SIM
	 * @property {String}  MobileUUID   Product UUID
	 * @property {String}  CustomerUUID CustomerUUID identifies the customer
	 *
	 * @typedef  {Object}  TerminateResponse
	 * @property {Number}  SPID      The service provider ID
	 * @property {String}  IMSI      IMSI of the provisioned SIM
	 * @property {String}  SIMID     SIMID of the provisioned SIM
	 * @property {String}  ICCID     ICCID of the provisioned SIM
	 * @property {String}  MSISDN    MSISDN of the provisioned SIM
	 * @property {String}  OrderUUID The Product Order UUID of the product
	 *
	 */
	TerminateMobileProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v4/mobile`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TerminateMobileProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TerminateMobileProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TerminateMobileProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TerminateMobileProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TerminateMobileProduct'))
			})
	},

	/**
	 * RPC Method: TerminateMobileProductAddon
	 * Description: Terminate Mobile Product Addon
	 * HTTP Method: DELETE
	 * API Path: /api/v4/mobile/addon
	 * @param    {TerminateAddonRequest} req The API payload object (TerminateAddonRequest)
	 * @return   {CancelAddonResponse} The API response object (CancelAddonResponse)
	 *
	 * @typedef  {Object}  TerminateAddonRequest
	 * @property {Number}  SPID         The service provider ID
	 * @property {String}  IMSI         Need IMSI or SIMID for verification purpose,  IMSI of the provisioned SIM
	 * @property {String}  SIMID        SIMID of the provisioned SIM
	 * @property {String}  CustomerUUID The Customer UUID for this product
	 * @property {String}  AddonUUID    The Product Instance UUID of the Addon, required
	 *
	 * @typedef  {Object}  CancelAddonResponse
	 * @property {Number}  SPID           The service provider ID
	 * @property {String}  IMSI           Need IMSI or SIMID for verification purpose,  IMSI of the provisioned SIM
	 * @property {String}  SIMID          SIMID of the provisioned SIM
	 * @property {String}  CustomerUUID   The Customer UUID for this product
	 * @property {String}  AddonUUID      The Product Instance UUID of the Addon
	 * @property {String}  AddonOrderUUID The Product Order UUID of the Addon
	 *
	 */
	TerminateMobileProductAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v4/mobile/addon`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TerminateMobileProductAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TerminateMobileProductAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TerminateMobileProductAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TerminateMobileProductAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TerminateMobileProductAddon'))
			})
	},

	/**
	 * RPC Method: TopupMobileProduct
	 * Description: Applies Addons and balance topup
	 * HTTP Method: POST
	 * API Path: /api/v4/mobile/topup
	 * @param    {MobileTopupRequest} req The API payload object (MobileTopupRequest)
	 * @return   {MobileTopupResponse} The API response object (MobileTopupResponse)
	 *
	 * @typedef  {Object}  MobileTopupRequest
	 * @property {Number}  SPID                 AutoPurchase is used for PAYG auto renewed packages
	 * @property {String}  UUID                 UUID of the Mobile Product
	 * @property {String}  MSISDN               MSISDN of the Mobile Product
	 * @property {String}  Reference            Reference is the caller's unique reference for this topup.
	 * @property {ENUM}    TopupType            TopupType is the type of topup, such as AutoTopup
	 * @property {AddonPurchaseEntry[]} AddonsToApply        AddonsToApply identifies the plans (Product Catalog Availability) of Addons to add,,  and the associated Retail Price
	 * @property {Number}  EndUserBalTopupCents Amount in cents to credit the enduser balance with
	 * @property {Number}  MainBalTopupCents    Amount in cents to credit the main balance with
	 * @property {Boolean} GetPriceOnly         If GetPriceOnly is true, the required topup amount to purchase addons is returned, but without purchasing or topping up.
	 * @property {String}  ICCID
	 * @property {String}  SIMID
	 * @property {String}  IMSI
	 * @property {String}  CustomerUUID         UUID of the Customer
	 *
	 * @typedef  {Object}  MobileTopupResponse
	 * @property {Mobile} Mobile             Did the request succeed
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explanation of the response
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 *
	 */
	TopupMobileProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v4/mobile/topup`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TopupMobileProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TopupMobileProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TopupMobileProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TopupMobileProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TopupMobileProduct'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};