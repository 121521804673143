import StoreHash from '@/utils/StoreHash';

const hashed = {
    // FindProductCatalogEntriesWithDetailsPaginated
    prePayMobileAddons: new StoreHash(3 * 60 * 1000)
};

const state = {
    prePayMobileAddons: {}
};

const getters = {
    prePayMobileAddons: state => Object.values(state.prePayMobileAddons),
    prePayMobileAddonByUUID: state => uuid => state.prePayMobileAddons[uuid],
};

const mutations = {
    mutPrePayMobileAddons(state, prePayMobileAddons) {
        const result = {};

        prePayMobileAddons.forEach(prePayMobileAddon => {
            result[prePayMobileAddon.UUID] = prePayMobileAddon;
        })
        state.prePayMobileAddons = result;
        hashed.prePayMobileAddons.fix();
    },
    resetPrePayMobileAddonsCache(state) {
        hashed.prePayMobileAddons.reset();
        state.prePayMobileAddons = {};
    }
};

const actions = {
    getAllCaсhedPrePayMobileAddons({dispatch, getters, commit}, params) {
        const refresh = params?.refresh;
        const payload = {
            SPID: getters.spid,
            ProductType: 'prepaymobileAddon',
            'SearchOptions.PageSize': -1
        }
        if (hashed.prePayMobileAddons.expired() || refresh) {
            return dispatch('api_product_catalog/FindProductCatalogEntriesWithDetailsPaginated', payload)
                .then(apidata => {
                    if (apidata?.ProductCatalogEntryWithDetailsItems) {
                        const prePayMobileAddons = apidata.ProductCatalogEntryWithDetailsItems.map(({ProductCatalogAvailability}) => ProductCatalogAvailability);
                        commit('mutPrePayMobileAddons', prePayMobileAddons);
                        return Promise.resolve(prePayMobileAddons);
                    }
                    return Promise.reject(new Error('No PrePayMobileAddons'))
                })
                .catch(error => Promise.reject(error))
        }
        return Promise.resolve(getters.prePayMobileAddons);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}