import axios from 'axios'

const USAGES = {
    Data: {
        history: {},
        kind: 'data',
        unit: 'MB',
    },
    // Mins: {
    //     history: {},
    //     kind: 'mins',
    //     unit: 'mins',
    // },
    // TXTs: {
    //     history: {},
    //     kind: 'txts',
    //     unit: 'SMS',
    // },
    // PXTs: {
    //     history: {},
    //     kind: 'pxts',
    //     unit: 'MMS',
    // },
};

const DEFAULT_BUCKET = Array.from({ length: 30 }).fill(0)


const state = {
    usage: null,
    isBusyUsage: false,
    isFailureUsages: false,
}

const getters = {
    usage: state => state.usage,
    isBusyUsage: state => state.isBusyUsage,
    isFailureUsages: state => state.isFailureUsages,
}

const mutations = {
    saveUsages(state, usage) {
        state.usage = usage
    },
    setBusyUsage(state, isBusyUsage) {
        state.isBusyUsage = isBusyUsage
    },
    setFailureUsages(state, isFailureUsages) {
        state.isFailureUsages = isFailureUsages
    }
}

const actions = {
    async GetUsageByTypeAndRange({ getters, commit, dispatch }, { CustomerUUID, ProductInstanceUUID, Period, ProductType }) {
        commit('saveUsages', null)
        commit('setBusyUsage', true)
        commit('setFailureUsages', false)
        const dateNow = Date.now()

        const payload = {
            CustomerUUID,
            ProductInstanceUUID,
            ProductType,
            Range: {
                FirstNanos: (dateNow - Period * 24 * 60 * 60 * 1000) * 1000000,
                FinalNanos: dateNow * 1000000,
            },
            IntervalNanos: 24 * 60 * 60 * 1000 * 1000000,
        };

        const statsPromises = Object.values(USAGES)
            .map(({ kind }) => kind)
            .map(kind => axios.post(`/v2/:spid/usage/${kind}/histogram`, payload))
            // .map(kind => import(`./hardcoded-test-data/usages/${kind}.json`))

        const result = await Promise.allSettled(statsPromises)

        let rejectCount = 0
        result.forEach(item => {
            if (item.status === 'rejected') {
                rejectCount++
            }
        })

        if (rejectCount === Object.values(USAGES).length) {
            commit('setFailureUsages', true)
            return
        }

        const usagesKeys = Object.keys(USAGES)
        const usageDate = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate() + 1)
        const usageDates = Array.from({ length: Period }, (_) => new Date(usageDate.setDate(usageDate.getDate() - 1)))

        const usagesWithStats = result.reduce((acc, curr, index) => {
            const { status } = curr

            let bucket = DEFAULT_BUCKET

            if (status === 'fulfilled' && curr.value.result.Buckets) {
                if (acc[usagesKeys[index]]['kind'] === 'data') {
                    bucket = curr.value.result.Buckets.map(val => +(val / 1048576).toFixed(2)).reverse()
                } else {
                    bucket = [...curr.value.result.Buckets].reverse()
                }
            }

            bucket.forEach((val, i) => {
                acc[usagesKeys[index]]['history'][usageDates[i].toDateString()] = {
                // acc[usagesKeys[index]]['history'][usageDates[i].getTime()] = {
                    date: usageDates[i],
                    usage: val,
                    timestamp: usageDates[i].getTime(),
                }
            })

            return acc

        }, { ...USAGES })

        commit('setBusyUsage', false)
        commit('saveUsages', usagesWithStats)
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}