import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

const FIBRE_ORDER_TYPES = {
        'ConnectPrimary': {
            value: 'ConnectPrimary',
            title: 'Connect Primary',
            ordering: true,
            terminating: false,
            transferring: false,
            requiresPrimary: true,
        },
        'ConnectAdditionalONT': {
            value: 'ConnectAdditionalONT',
            title: 'Connect Additional ONT',
            ordering: true,
            terminating: false,
            transferring: false,
            requiresPrimary: true,
        },
        'ConnectSecondary': {
            value: 'ConnectSecondary',
            title: 'Connect Secondary',
            ordering: true,
            terminating: false,
            transferring: false,
            requiresPrimary: false,
        },
        'ConnectAndReplace': {
            value: 'ConnectAndReplace',
            title: 'Connect and Replace',
            ordering: true,
            terminating: false,
            transferring: false,
            requiresPrimary: true,
        },
        'DisconnectPrimary': {
            value: 'DisconnectPrimary',
            title: 'Disconnect Primary',
            ordering: false,
            terminating: true,
            transferring: false,
            requiresPrimary: true,
        },
        'DisconnectSecondary': {
            value: 'DisconnectSecondary',
            title: 'Disconnect Secondary',
            ordering: false,
            terminating: true,
            transferring: false,
            requiresPrimary: false,
        },
        'ChangeOffer': {
            value: 'ChangeOffer',
            title: 'Change Offer',
            ordering: false,
            terminating: false,
            transferring: false,
            requiresPrimary: false,
        },
        'ModifyAttribute': {
            value: 'ModifyAttribute',
            title: 'Modify Attribute',
            ordering: false,
            terminating: false,
            transferring: false,
            requiresPrimary: false,
        },
        'MovePrimary': {
            value: 'MovePrimary',
            title: 'Move Primary',
            ordering: false,
            terminating: false,
            transferring: true,
            requiresPrimary: true,
        },
        'MoveAdditionalONT': {
            value: 'MoveAdditionalONT',
            title: 'Move AdditionalONT',
            ordering: false,
            terminating: false,
            transferring: true,
            requiresPrimary: true,
        },
        'MoveAndReplace': {
            value: 'MoveAndReplace',
            title: 'Move and Replace',
            ordering: false,
            terminating: false,
            transferring: true,
            requiresPrimary: true,
        },
        'TransferPrimary': {
            value: 'TransferPrimary',
            title: 'Transfer Primary',
            ordering: false,
            terminating: false,
            transferring: true,
            requiresPrimary: true,
        },
        'TransferSecondary': {
            value: 'TransferSecondary',
            title: 'Transfer Secondary',
            ordering: false,
            terminating: false,
            transferring: true,
            requiresPrimary: false,
        },
};

let hashed = {
    fibre_services: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    }
}

const state = {
    fibre_services: {},
}

const getters = {
    getFibreOrderTypes: () => () => FIBRE_ORDER_TYPES,
    getFibreOrderTypesOrdering: (state, getters) => {
        let ordertypes = {}

        for (const key in FIBRE_ORDER_TYPES) {
            if (FIBRE_ORDER_TYPES[key].ordering) {
                ordertypes[key] = FIBRE_ORDER_TYPES[key]
            }
        }
        
        return ordertypes
    },
    getFibreServiceByUUID: state => uuid => state.fibre_services[uuid],
}

const mutations = {
    saveFibreServices(state, services) {
        let services_list = {}

        for (let n = 0, len = services.length; n < len; n++) {
            const fibre_service = services[n]
            const uuid = fibre_service.UUID

            if (!(uuid in hashed.fibre_services.uuids)) {
                hashed.fibre_services.uuids[ uuid ] = new StoreHash(hashed.fibre_services.lifetime)
            }
            
            services_list[ uuid ] = fibre_service

            hashed.fibre_services.uuids[ uuid ].fix()
        }

        if (Object.keys(services_list).length) {
            state.fibre_services = {
                ...state.fibre_services,
                ...services_list
            }
        }
    },

    saveFibreService(state, service) {
        const uuid = service.UUID

        if (!(uuid in hashed.fibre_services.uuids)) {
            hashed.fibre_services.uuids[ uuid ] = new StoreHash(hashed.fibre_services.lifetime)
        }

        state.fibre_services = {
            ...state.fibre_services,
            [ uuid ]: service
        }

        hashed.fibre_services.uuids[ uuid ].fix()
    },

    deleteFibreServiceByUUID(state, uuid) {
        if (uuid in hashed.fibre_services.uuids) {
            delete hashed.fibre_services.uuids[ uuid ]
        }

        if (uuid in state.fibre_services) {
            delete state.fibre_services[ uuid ]
        }
    },

    resetHashedFibre(state) {
        state.fibre_services = {}

        hashed.fibre_services.uuids = {}
    }
}

const actions = {
    getFibreServices({ commit }, { params }) {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/fibre/fibre-services-list.json').then(response => {
            const services = response.default.Services

        // return axiosUnique.get(`/v2/:spid/fibre/services`, { params }).then(response => {
        //     const services = response.apidata.Services

            commit('saveFibreServices', services)

            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })

    },

    getFibreServiceByUUID({getters, commit}, { uuid, refresh }) {
        if (!(uuid in hashed.fibre_services.uuids)) {
            hashed.fibre_services.uuids[ uuid ] = new StoreHash(hashed.fibre_services.lifetime)
        }

        if (refresh || hashed.fibre_services.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 3 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/fibre/fibre-service-by-uuid.json').then(apidata => {
                const service = apidata.default
                uuid = service.UUID

                return Promise.resolve(service)
                
            // return axiosUnique.get(`/v2/:spid/fibre/service/${ uuid }`).then(({apidata}) => {
            //     const service = apidata

                commit('saveFibreService', service)
                return Promise.resolve(getters.getFibreServiceByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getFibreServiceByUUID(uuid))
        }
    },

    deleteFibreServiceByUUID({ commit }, { uuid }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/fibre/successfully-deleted-fibre-service.json')
                    .then(response => {
                        const service = response.default

                        commit('deleteFibreServiceByUUID', uuid)

                        resolve({ service })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 2000)
        })

        /*
            return axiosUnique.delete(`/v2/:spid/fibre/service/${ uuid }`)
                .then(({ apidata }) => {
                    const service = apidata

                    commit('deleteFibreServiceByUUID', uuid)

                    return Promise.resolve({ service })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    },

    getFibreOrders({ commit }, { params }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/fibre/orders-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Orders = response.default.Orders
    
                        resolve({ PageInfo, Orders })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            return axiosUnique.get('/v2/:spid/fibre/orders', { params })
                .then(response => {
                    const PageInfo = response.apidata.PageInfo
                    const Orders = response.apidata.Orders
    
                    resolve({ PageInfo, Orders })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */

    },

    getFibreProducts({ commit }, { params }) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                import('./hardcoded-test-data/fibre/products-list.json')
                    .then(response => {
                        const PageInfo = response.default.PageInfo
                        const Products = response.default.Products
    
                        resolve({ PageInfo, Products })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }, 1000)
        })

        /*
            return axiosUnique.get('/v2/:spid/fibre/products', { params })
                .then(response => {
                    const PageInfo = response.apidata.PageInfo
                    const Products = response.apidata.Products
    
                    resolve({ PageInfo, Products })
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        */
    
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}