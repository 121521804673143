import Vue from 'vue'
import axios from 'axios'

import storage from '@/utils/storage'
import store from '@/store'

import router from '@/router'

import errMessageApi from "@/helpers/errMessageApi"

const AUTH_URL = process.env.VUE_APP_API_HOST
const AUTH_SPID = 1
const IDENTITY_PROVIDER = process.env.VUE_APP_IDENTITY_PROVIDER

const GOOGLE_AUTH_ENABLED = process.env.VUE_APP_GOOGLE_AUTH_ENABLED
    ? isNaN(process.env.VUE_APP_GOOGLE_AUTH_ENABLED)
        ? process.env.VUE_APP_GOOGLE_AUTH_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_GOOGLE_AUTH_ENABLED > 0
    : false
const GOOGLE_AUTH_IDENTITY_PROVIDER = 'GOOGLE'

const MFA_AUTH_TOGGLER_ENABLED = process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED
    ? isNaN(process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED)
        ? process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED > 0
    : false
const MFA_AUTH_TYPE = 'TOTP'

const KEY_AUTH_DATA = 'user'
const KEY_AUTH_TOKEN = 'token'
const KEY_AUTH_TOKEN_EXPIRY = 'token-expire'
const KEY_TOS = 'tos'

const JWT_EXPIRY_DIALOG = process.env.VUE_APP_JWT_EXPIRY_DIALOG ? parseInt(process.env.VUE_APP_JWT_EXPIRY_DIALOG) || 0 : 0
const jwt_expiry_dialog = JWT_EXPIRY_DIALOG * 60 * 1000

const POOR_REFRESH_EXPERIENCE = process.env.VUE_APP_POOR_REFRESH_EXPERIENCE
    ? isNaN(process.env.VUE_APP_POOR_REFRESH_EXPERIENCE)
        ? process.env.VUE_APP_POOR_REFRESH_EXPERIENCE.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_POOR_REFRESH_EXPERIENCE > 0
    : false

const state = {
    user: null,
    token: null,
    tokenExpiry: 0,
    logoutTimeoutID: null,
    dialogTimeoutID: null,
    isShowJWTExpiryModal: false,
    roles: {},
    serviceProviderSettings: []
}

const getters = {
    isAuth: state => state.token ? Boolean(state.token) : false,
    token: state => state.token ? state.token : '',
    tokenExpiry: state => state.tokenExpiry ? state.tokenExpiry : 0,

    user: state => state.user,
    fullName: state => state.user ? `${state.user.FirstName} ${state.user.LastName}` : '',
    firstName: state => state.user ? state.user.FirstName : '',
    lastName: state => state.user ? state.user.LastName : '',
    email: state => state.user ? state.user.Email : '',
    mobileNumber: state => state.user ? state.user.MobileNumber : '',
    authID: state => state.user ? state.user.AuthID : null,
    identityProvider: state => state.user ? state.user.IdentityProvider : null,
    usedMFA: state => state.user ? state.user.UsedMFA : '',
    userSPID: state => state.user ? state.user.SPID : null,

    roles: state => state.roles,

    is_google_auth_enabled: state => GOOGLE_AUTH_ENABLED,
    isMfaAuthToggleEnabled: state => MFA_AUTH_TOGGLER_ENABLED,

    logoutTimeoutID: state => state.logoutTimeoutID,
    dialogTimeoutID: state => state.dialogTimeoutID,
    isShowJWTExpiryModal: state => state.isShowJWTExpiryModal,

    usePoorRefreshExperience: () => POOR_REFRESH_EXPERIENCE,

    serviceProviderSettings: state => state.serviceProviderSettings,
    isRequireEmailValidation: (state, getters) => {
        if (getters.serviceProviderSettings.length > 0) {
            for (let i = 0; i < getters.serviceProviderSettings.length; i++) {
                const setting = getters.serviceProviderSettings[i]
                if (setting.Key === 'require_local_user_email_validation') {
                    return (/true/).test(setting.Value)
                }
            }
        }
        return false
    }
}

const mutations = {
    mutSignIn(state, apidata) {
        state.token = apidata.JWT
        state.tokenExpiry = apidata.JWTExpiry
        state.user = apidata

        storage.set(KEY_AUTH_TOKEN, apidata.JWT, apidata.JWTExpiry)
        storage.set(KEY_AUTH_DATA, apidata, null, true)

        const roles = apidata?.Roles
        if (roles && roles.length > 0) {
            const result = {}
            for (let i = 0; i < roles.length; i++) {
                const role = roles[i]
                const roleName = roles[i]?.RoleName
                if (roleName) {
                    result[roleName] = role
                }
            }
            state.roles = result
        }

        store.dispatch('checkExpiredJWT')
    },

    mutResetAuthStore(state) {
        state.user = null
        state.token = null
        state.tokenExpiry = 0
        state.roles = {}
        storage.remove(KEY_AUTH_DATA)
        storage.remove(KEY_AUTH_TOKEN)
    },

    mutJwt(state, token) {
        state.token = token
    },

    // mut_update_jwt is desgined to be called from the Axios handler. When the
    // axios middleware recieves the X-Auth-Token + X-Auth-Token-Expiry headers
    // in the HTTP(S) response, then the backend has auto-renewed the token,
    // and it should call this to update the locally cached JWT and JWTExpiry
    mutUpdateJwt(state, { token, tokenExpiry }) {
        if (token &&
            tokenExpiry &&
            (tokenExpiry > Math.round(new Date().getTime() / 1000))
        ) {
            state.token = token
            state.tokenExpiry = tokenExpiry
            storage.set(KEY_AUTH_TOKEN, token, tokenExpiry)
        } else {
            state.user = null
            state.token = null
            state.tokenExpiry = 0
            storage.remove(KEY_AUTH_TOKEN)
        }
    },

    setMFA(state, used_mfa) {
        if (state.user) {
            Vue.set(state.user, 'UsedMFA', used_mfa)
        }
    },

    setLogoutTimeoutID(state, id) {
        state.logoutTimeoutID = id
    },

    setDialogTimeoutID(state, id) {
        state.dialogTimeoutID = id
    },

    isShowJWTExpiryModal(state, isShow) {
        state.isShowJWTExpiryModal = isShow
    },

    setServiceProviderSettings(state, serviceProviderSettings) {
        state.serviceProviderSettings = serviceProviderSettings
    }
}

const actions = {
    async signIn({ dispatch, getters }, payload) {
        const authCheck = await dispatch('authCheck', payload)

        if (getters.isAuth) {
            await dispatch('getTermsOfUseStatus')
                .then(() => {
                    router.push({ name: getters.isTos ? 'dashboard' : 'tos', replace: true })
                })
                .catch(error => {
                    if (error?.details?.response?.data?.err_code == 403) {
                        errMessageApi(error)
                        return dispatch('signOut')
                    }
                    router.push({ name:'tos', replace: true })
                })

            await dispatch('getServiceProviderSettings')
                .catch(() => {})
        }

        return authCheck
    },

    authCheck({ dispatch, commit, getters }, params) {
        const payload = {
            ...params,
            IdentityProvider: IDENTITY_PROVIDER,
            IncludeRoles: true,
        }

        return axios.post(`${AUTH_URL}/v2/${getters.spid}/auth_check`, payload)
            .then(response => {
                if (response?.apidata) {
                    const roles = response.apidata?.Roles || []
                    if (roles.length == 0) {
                        return Promise.reject(new Error('Missing Authentication Roles'))
                    }
                    commit('mutSignIn', response.apidata)
                    return Promise.resolve(response.apidata)
                }
                return Promise.reject(new Error('Auth failed'))
            })
            .catch(error => {
                const mfaErrorsCode = [412, 428]
                const errorCodeResp = error?.response?.data?.err_code
                if (errorCodeResp && mfaErrorsCode.includes(errorCodeResp)) {
                    return Promise.reject({
                        'mode': 'MFA',
                        payload,
                        error
                    })
                }

                return Promise.reject(error)
            })
    },

    signInByGoogle({dispatch}) {
        return dispatch('googleSignIn').then(GoogleUser => {
            const payload = {
                IdentityProvider: GOOGLE_AUTH_IDENTITY_PROVIDER,
                AuthID: GoogleUser.getId() || 'anonymous',
                AuthNonce: GoogleUser.getAuthResponse().id_token,
                IncludeRoles: true,
            }

            return dispatch('signIn', payload)
        }).catch(error => Promise.reject(error))
    },

    signInByTOTP({dispatch}, { payload, code }) {
        return dispatch('signIn', {...payload, MFACode: code})
            .then(data => Promise.resolve(data))
            // .catch(error => error?.mode && error?.error
            //     ? error.error
            //     : error
            // )
            .catch(error => {
                if (error?.mode && error?.error) {
                    return Promise.reject(error.error)
                }
                return Promise.reject(error)
            })
    },

    checkExpiredJWT({ getters, dispatch, commit }) {
        if (getters.logoutTimeoutID !== null) {
            clearTimeout(getters.logoutTimeoutID)
        }
        if (getters.dialogTimeoutID !== null) {
            clearTimeout(getters.dialogTimeoutID)
        }

        const now = Date.now();
        const logoutTimeoutDelay = getters.tokenExpiry * 1000 - now
        const logoutTimeoutID = setTimeout(() => dispatch('logoutJWTExpiry'), logoutTimeoutDelay)
        commit('setLogoutTimeoutID', logoutTimeoutID)

        if (JWT_EXPIRY_DIALOG > 0) {
            const dialogTimeoutDelay = getters.tokenExpiry * 1000 - now - jwt_expiry_dialog
            const dialogTimeoutID = setTimeout(() => dispatch('checkJWTExpiryModal'), dialogTimeoutDelay)
            commit('setDialogTimeoutID', dialogTimeoutID)
        }
    },

    logoutJWTExpiry({ getters, dispatch, commit }) {
        const now = Date.now()
        const jwtExpiry = getters.tokenExpiry * 1000

        const timeDifference = now - jwtExpiry

        if (timeDifference >= 0) {
            if (getters.isShowJWTExpiryModal) {
                dispatch('closeJWTExpiryModal').then(() => {}).catch(() => {})
            }

            dispatch('signOutByResetStores')
        } else {
            const logoutTimeoutID = setTimeout(() => dispatch('logoutJWTExpiry'), Math.abs(timeDifference))
            commit('setLogoutTimeoutID', logoutTimeoutID)
        }
    },

    checkJWTExpiryModal({ getters, dispatch, commit }) {
        const now = Date.now()
        const jwtExpiry = getters.tokenExpiry * 1000

        const timeDifference = now - (jwtExpiry - jwt_expiry_dialog)

        if (timeDifference >= 0) {
            dispatch('showJWTExpiryModal').then(() => {}).catch(() => {})
        } else {
            const dialogTimeoutID = setTimeout(() => dispatch('checkJWTExpiryModal'), Math.abs(timeDifference))
            // console.log('RECURSION checkJWTExpiryModal', timeDifference)
            commit('setDialogTimeoutID', dialogTimeoutID)
        }
    },

    showJWTExpiryModal({ commit }) {
        commit('isShowJWTExpiryModal', true)
        return Promise.resolve()
    },

    closeJWTExpiryModal({ commit }) {
        commit('isShowJWTExpiryModal', false)
        return Promise.resolve()
    },

    refreshAuthJWT({ dispatch, commit, getters }, jwtArg = null) {
        const jwt = jwtArg || getters.token

        const payload = {
            AuthCode: jwt,
            AuthID: 'jwt',
            IdentityProvider: 'JWT_REFRESH',
            IncludeRoles: true,
        }

        return axios.post(`/v2/${ getters.spid }/auth_check`, payload)
            .then(response => {
                if (response?.apidata) {
                    commit('mutSignIn', response.apidata)
                    dispatch('closeJWTExpiryModal')

                    dispatch('getServiceProviderSettings')

                    return Promise.resolve(response.apidata)
                }
                return Promise.reject(new Error('JWT REFRESH FAILED'))
            })
            .catch(error => Promise.reject(error))
    },

    getServiceProviderSettings({ dispatch, commit, getters }) {
        if (getters.isSPAdmin) {
            const payload = {
                SPID: getters.spid,
                KeyGroup: 'auth',
                'SearchOptions.PageSize': -1
            }

            return dispatch('api_serviceprovider/FindServiceProviderSettingsPaginated', payload)
                .then(apidata => {
                    commit('setServiceProviderSettings', apidata?.ServiceProviderSettings || [])
                    return Promise.resolve(apidata)
                })
                .catch(error => Promise.reject(error))
        }
        return Promise.resolve()
    },

    signOut({dispatch, getters, commit}) {
        if (getters.token) {
            commit('setLoader', true)
            const payload = {
                SPID: getters.spid,
                JWT: getters.token
            }
            dispatch('api_auth/AuthLogout', payload)
                .then(() => {
                    dispatch('googleSignOut').catch(()=>{})
                    dispatch('signOutByResetStores')
                    return Promise.resolve(true)
                })
                .catch(error => Promise.reject(error))
                .finally(() => commit('setLoader', false))
        } else {
            dispatch('signOutByResetStores')
            return Promise.resolve(true)
        }
    },

    signOutByResetStores({ dispatch }) {
        const auth = dispatch('resetAuthStore')
        const tos = dispatch('resetTosStore')

        return Promise.all([auth, tos])
            .then(() => {
                router.push({ name:'sign-in' })
                    .then()
                    .catch(() => {})
            })
            .catch(error => Promise.reject(error))
    },

    resetAuthStore({commit, getters}) {
        commit('mutResetAuthStore')
        const resets = [
            'payments/resetPaymentsCache',
            'invoices/resetInvoicesCache',
            'resetHashedMembers',
            'resetPrePayMobileAddonsCache',
            'teams/resetTeamsCache',
            'resetUsersCache',
            'resetPostpayMobilePlansCache',
            'resetPostpayAddonsCache',
            'stpm/resetMobileAddonPlansCache',
            'stpm/resetMobileAddonsCache',
        ]

        for (let i = 0, len = resets.length; i < len; i++) {
            // try {
            //     commit(resets[i])
            // } catch (e) {}
            commit(resets[i])
        }

        if (getters.logoutTimeoutID !== null) {
            clearTimeout(getters.logoutTimeoutID)
        }
        if (getters.dialogTimeoutID !== null) {
            clearTimeout(getters.dialogTimeoutID)
        }

        return Promise.resolve(true)
    },

    // setJWT is called from axios middleware to update the local cache
    // of the JWT and JWT Expirty
    setJWT({ getters, commit, dispatch }, { token, tokenExpiry }) {
        if (!token || token == "-" || tokenExpiry == -1) {
            dispatch('signOut')
            return
        }
        if (!tokenExpiry) {
            commit('mutUpdateJwt', { token, tokenExpiry: getters.tokenExpiry })
            return
        }
        commit('mutUpdateJwt', { token, tokenExpiry })
    },

    refreshAuthJWTByReload({ dispatch, getters }) {
        const token = storage.get(KEY_AUTH_TOKEN)

        if (token) {
            return dispatch('refreshAuthJWT', token)
                .then(apidata => Promise.resolve(apidata))
                .catch(error => Promise.resolve())
        }

        return Promise.resolve()
    },

    loadAuthInfo({ commit, dispatch, getters }) {
        const authData = storage.get(KEY_AUTH_DATA, true)
        const token = storage.get(KEY_AUTH_TOKEN)

        if (authData && token) {
            commit('mutSignIn', authData)
        }

        const tos = storage.get(KEY_TOS, true)
        if (tos) {
            commit('mutTos', tos)
        }
    },

    addMFA({getters}) {
        if (getters.isMfaAuthToggleEnabled) {
            const payload = {
                AuthID: getters.authID,
                IdentityProvider: getters.identityProvider,
                MFAType: MFA_AUTH_TYPE,
            }
            
            return axios.post(`${AUTH_URL}/v2/${getters.userSPID}/auth/add_mfa`, payload)
                .then(({apidata}) => Promise.resolve(apidata))
                .catch(error => Promise.reject(error))
        } else {
            return Promise.reject(new Error('The MFA editing is not enabled'))
        }
    },

    removeMFA({getters}, code) {
        if (getters.isMfaAuthToggleEnabled) {
            const payload = {
                AuthID: getters.authID,
                IdentityProvider: getters.identityProvider,
                MFAType: MFA_AUTH_TYPE,
                MFACode: code,
            }
            
            return axios.post(`${AUTH_URL}/v2/${getters.userSPID}/auth/remove_mfa`, payload)
                .then(({apidata}) => Promise.resolve(apidata))
                .catch(error => Promise.reject(error))
        } else {
            return Promise.reject(new Error('The MFA editing is not enabled'))
        }
    },

    confirmMFA({getters}, code) {
        const payload = {
            AuthID: getters.authID,
            IdentityProvider: getters.identityProvider,
            MFAType: MFA_AUTH_TYPE,
            MFACode: code,
        }

        return axios.post(`${AUTH_URL}/v2/${getters.userSPID}/auth/confirm_mfa`, payload)
            .then(({apidata}) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },

    setMFA({getters, commit}, used_mfa) {
        commit('setMFA', used_mfa)
        return Promise.resolve(getters.usedMFA)
    },

    validateEmail({}, { token }) {
        return axios.get(`/v1/validate_email/${ token }`)
            .then(response => {
                return Promise.resolve(response.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}