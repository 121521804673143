// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: postpay.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: PostpayMobileRPC
	//
	/**
	 * RPC Method: ActivePostpayAddon
	 * Description: Applies an AddOn to a PostpayProduct
	 * HTTP Method: POST
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.PostpayProductUUID}/addon/${req.AddonAvailabilityUUID}
	 * @param    {PostpayProductAddonRequest} req The API payload object (PostpayProductAddonRequest)
	 * @return   {PostpayProductCommonRequestResponse} The API response object (PostpayProductCommonRequestResponse)
	 *
	 * @typedef  {Object}  PostpayProductAddonRequest
	 * @property {Number}  SPID                  SPID
	 * @property {String}  PostpayProductUUID    PostpayProductUUID
	 * @property {String}  AddonAvailabilityUUID AddonAvailabilityUUID
	 *
	 * @typedef  {Object}  PostpayProductCommonRequestResponse
	 * @property {PostpayProduct} PostpayProduct     Did the request succeed, if 0 then yes, if > 0 then an error code
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explaination of why it failed, or some kind of success message
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 * @property {PostpayAddon} PostpayAddon
	 *
	 */
	ActivePostpayAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v1/${req.SPID}/postpay_mobile/${req.PostpayProductUUID}/addon/${req.AddonAvailabilityUUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ActivePostpayAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ActivePostpayAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ActivePostpayAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ActivePostpayAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ActivePostpayAddon'))
			})
	},

	/**
	 * RPC Method: ChangeMSISDN
	 * Description: ## ChangeMSISDN
	 *
	 * The change MSISDN feature allows a mobile subscriber that has an active mobile service to change the primary MSISDN
	 * that was associated with their subscription at time of initial order.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|Change the customer the product is attached to|
	 * |MSISDN|MSISDN(string)|Change the MSISDN this product has|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	 *        "MSISDN": "6401150310635192",
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/msisdn_change
	 * @param    {MSISDNChangeRequest} req The API payload object (MSISDNChangeRequest)
	 * @return   {MSISDNChangeResponse} The API response object (MSISDNChangeResponse)
	 *
	 * @typedef  {Object}  MSISDNChangeRequest
	 * @property {Number}  SPID         SPID is the service provider ID that the product belongs to
	 * @property {String}  UUID         Limit search to a particular product instance
	 * @property {String}  CustomerUUID CustomerUUID if attached to a customer
	 * @property {String}  MSISDN       MSISDN you want to change to
	 *
	 * @typedef  {Object}  MSISDNChangeResponse
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 *
	 */
	ChangeMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/msisdn_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeMSISDN'))
			})
	},

	/**
	 * RPC Method: ChangePlan
	 * Description: ## ChangePlan
	 *
	 * Post a product change order into the system
	 *
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/plan_change
	 * @param    {PlanChangeRequest} req The API payload object (PlanChangeRequest)
	 * @return   {PlanChangeResponse} The API response object (PlanChangeResponse)
	 *
	 * @typedef  {Object}  PlanChangeRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 * @property {String}  CustomerUUID
	 * @property {String}  PlanUUID
	 *
	 * @typedef  {Object}  PlanChangeResponse
	 * @property {Number}  SPID
	 * @property {String}  ProductOrderUUID
	 *
	 */
	ChangePlan({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/plan_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangePlan ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangePlan ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangePlan ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangePlan UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangePlan'))
			})
	},

	/**
	 * RPC Method: CreatePostpayAddon
	 * Description: Applies a separate postpay AddOn
	 * HTTP Method: POST
	 * API Path: /api/v1/${req.SPID}/postpay_addon
	 * @param    {PostpayAddonRequest} req The API payload object (PostpayAddonRequest)
	 * @return   {PostpayAddon} The API response object (PostpayAddon)
	 *
	 * @typedef  {Object}  PostpayAddonRequest
	 * @property {Number}  SPID                  SPID
	 * @property {String}  CustomerUUID          CustomerUUID
	 * @property {String}  AddonAvailabilityUUID AddonAvailabilityUUID
	 * @property {String}  ProductInstanceUUID   ProductInstanceUUID is the UUID of this product instance
	 *
	 * @typedef  {Object}  PostpayAddon
	 * @property {Number}  SPID                  Terminated:,  - For recurring, was cancelled and has finished,  - For non-recurring. billing period has finished
	 * @property {String}  UUID                  ProductInstance of this AddOn, blank if a list of available addons
	 * @property {String}  PostpayProductUUID    PostpayProduct instance this belongs to (if any),  Each postpay add-on is a product instance of its own
	 * @property {ENUM}    State                 The state of the addon
	 * @property {String}  AddonAvailabilityUUID The product availability UUID
	 * @property {String}  Name                  Name of the Addon
	 * @property {String}  Description           Description of the Addon
	 * @property {Number}  RetailPriceCents      Cost that is taken from the customers money balance
	 * @property {Boolean} AllowCustomerPurchase This addon is able to be purchased by the customer
	 * @property {Boolean} CustomerVisible       This addon is visible to customers
	 * @property {Number}  CreditSMS             Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes    Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB          Amount of Data MBs that are added
	 * @property {Number}  CreditMMS             Amount of MMSs that are added
	 * @property {String}  AddonCatalogUUID      AddonCatalogUUID is the catalog entry this Addon was based off of
	 *
	 */
	CreatePostpayAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v1/${req.SPID}/postpay_addon`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreatePostpayAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreatePostpayAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreatePostpayAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreatePostpayAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreatePostpayAddon'))
			})
	},

	/**
	 * RPC Method: CreatePostpayProduct
	 * Description: ## CreatePostpayProduct
	 *
	 * Creates a new postpay mobile product provisioning order.
	 * VoiceSettings is optional. If not set, backend will use default value. But if set, need to set all products.
	 * DataEnabled is optional. If not set, backend will use default value. But if set, need to set all products.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|specifies the customer to attach this product to|
	 * |ProductAvailabilityUUID|UUID(string)|this is the UUID from|
	 * |MSISDN|MSISDN(string)|is the MSIDN for this product|
	 * |SIMID|SIMID(string)|The SIMID that this product will be activated on
	 *
	 * ### Example Request (VoiceSettings and DataEnabled show the default values)
	 *
	 *     {
	 *     	"CustomerUUID": "da387d24-38a5-4ab1-bafa-cb7178db4f29",
	 *     	"ProductAvailabilityUUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	 *     	"MSISDN": "+642123456705",
	 *     	"SIMID": "6401150310635192",
	 *     	"AutoAllocateMSISDN": true,
	 *     	"Voicemail": false,
	 *     	"NewGroup": true,
	 *        "VoiceSettings": {
	 *          "OutgoingCallerID": 3,
	 *          "IncomingCallerID": true,
	 *          "AllowConferencing": true,
	 *          "CallWaitingAvailable": true,
	 *          "CallWaitingEnabled": true,
	 * 	        "CFBActive":false,
	 * 	        "CFBAvailable":true,
	 * 	        "CFBDestination":"",
	 * 	        "CFNRCActive":false,
	 * 	        "CFNRCAvailable":true,
	 * 	        "CFNRCDestination":"",
	 * 	        "CFNRYAvailable":true,
	 * 	        "CFNRYActive":false,
	 * 	        "CFNRYDestination":true,
	 * 	        "CFNRYRingtime":30,
	 * 	        "CFUActive":false,
	 * 	        "CFUAvailable":true,
	 * 	        "CFUDestination":"",
	 * 	        "BarIncomingCalls":false,
	 * 	        "BarOutgoingCalls":false,
	 * 	        "AllowIncomingCalls":true,
	 * 	        "AllowOutgoingCalls":true,
	 * 	        "AllowSMSSend":true,
	 * 	        "AllowSMSRecieve":true,
	 * 	        "OCSBlockPremiumCalls":false,
	 * 	        "OCSBlockPremiumSMS":false,
	 * 	        "OCSOutgoingIDDBarred":false,
	 * 	        "OCSOutgoingTextBarred":false,
	 * 	        "OCSRoamingBarred":false,
	 * 	        "OCSOutCallsWhileRoamingBarred":false,
	 * 	        "OCSInCallsWhileRoamingBarred":false,
	 * 	        "OCSOOBWhileRoamingBarred":false,
	 *        }
	 *        "DataEnabled": {
	 *            "OCSChargeableDataRGBarred": false,
	 *        }
	 *     }
	 *
	 * HTTP Method: POST
	 * API Path: /api/v1/${req.SPID}/postpay_mobile
	 * @param    {PostpayMobileCreateRequest} req The API payload object (PostpayMobileCreateRequest)
	 * @return   {PostpayMobileCreateResponse} The API response object (PostpayMobileCreateResponse)
	 *
	 * @typedef  {Object}  PostpayMobileCreateRequest
	 * @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	 * @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	 * @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	 * @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	 * @property {String}  MSISDN                  MSISDN is the MSIDN to allocate to this product
	 * @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	 * @property {Boolean} Voicemail               Voicemail enabled or not
	 * @property {Boolean} NewGroup                NewGroup is if this product should be allocated into a new group
	 * @property {String}  GroupUUID               GroupUUID is the product group instance which this product is connected to
	 * @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	 * @property {PostpayMobileCreateRequest_CharacteristicsEntry[]} Characteristics         List of characteristics that will overwrite the default postpay mobile Characteristics
	 * @property {VoiceSettings[]} VoiceSettings           When creating product, set the default values of VoiceSettings such as CLIR/CLIP etc.
	 * @property {DataEnabled} DataEnabled             When creating product, set the default values of DataEnabled.
	 * @property {Number}  LosingServiceProvider   Losing Service Provider, used when porting number on creation of new product
	 * @property {String}  LosingAccountNumber     Account Number of losing service if comming from postpay, used when porting number on creation of new product
	 * @property {String}  CustomerName            Customer Name, used when porting number on creation of new product
	 * @property {String}  OldSIMID                SIM ID of Losing Service Provider, used when porting number on creation of new product
	 * @property {String}  PortOutAccountNumber    Account Number for this service, used to validate when porting out
	 * @property {Boolean} SMSResponseRequired     Require 2FA when porting number on creation of a new product
	 * @property {String}  GroupName               The name of the group product to end-customers
	 * @property {Number}  PortRFS                 If creating port request set Port RFS time to
	 *
	 * @typedef  {Object}  PostpayMobileCreateResponse
	 * @property {Number}  SPID
	 * @property {String}  ProductOrderUUID
	 * @property {String}  ProductInstanceUUID
	 *
	 */
	CreatePostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v1/${req.SPID}/postpay_mobile`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CreatePostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CreatePostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CreatePostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CreatePostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CreatePostpayProduct'))
			})
	},

	/**
	 * RPC Method: FinaliseActivationPostpayProduct
	 * Description: Complete activation of a Preprovisioned Postpay Product
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/postpay_mobile/finalise_activation
	 * @param    {PostpayProductActivationRequest} req The API payload object (PostpayProductActivationRequest)
	 * @return   {PostpayProduct} The API response object (PostpayProduct)
	 *
	 * @typedef  {Object}  PostpayProductActivationRequest
	 * @property {Number}  SPID                    Service Provider ID
	 * @property {String}  MobileProductUUID       UUID of MobileProduct (required)
	 * @property {String}  CustomerUUID            UUID of Customer
	 * @property {String}  ProductAvailabilityUUID Product Availablity UUID
	 *
	 * @typedef  {Object}  PostpayProduct
	 * @property {String}  UUID                    UUID is the unique ID for this product
	 * @property {Number}  SPID                    Which direction is the port currently in action happening
	 * @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	 * @property {String}  SubscriptionUUID        SubscriptionUUID is the subscription that pays for this service
	 * @property {String}  ProductCatalogUUID      ProductCatalogUUID is the catalog entry this product was based off of
	 * @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	 * @property {String}  GroupUUID               GroupUUID is the availability that this product was based off
	 * @property {Number}  Cents                   Cents is the cost of this product in cents
	 * @property {Number}  CreatedAtNanos          CreatedAtCents is when this record was created in epoch nanoseconds
	 * @property {Number}  UpdatedAtNanos          UpdatedAtCents is when this record was last updated in epoch nanoseconds
	 * @property {Number}  DeletedAtNanos          DeletedAtCents is when this record was deleted in epoch nanoseconds
	 * @property {Number}  ActivatedAtNanos        ActivatedAtNanos is when this product was activated in epoch nanoseconds
	 * @property {Number}  TerminatedAtNanos       TerminatedAtNanos is when this product was terminated in epoch nanoseconds
	 * @property {String}  MSISDN                  MSISDN is for specifying the MSISDN when not auto-allocating MSISDNs
	 * @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	 * @property {Boolean} Voicemail               Voicemail enabled or not
	 * @property {String}  State                   State is the current product state
	 * @property {String}  CellularServiceUUID     Cellular Service UUID
	 * @property {String}  VoiceServiceUUID        Voice Service UUID
	 * @property {String}  VoicemailServiceUUID    Voicemail Service UUID
	 * @property {String}  MessagingServiceUUID    Messaging Service UUID
	 * @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN - Does not apply when providing a MSISDN manually
	 * @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	 * @property {VoiceSettings[]} VoiceSettings           Voice Settings
	 * @property {ENUM}    PortingState
	 * @property {ENUM}    PortingAction           PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {DataEnabled} DataEnabled             Enable data
	 * @property {String}  PortingUUID             UUID of the current Port Request
	 * @property {Boolean} SIMEnabled              is the SIM enabled?
	 * @property {PostpayAddon[]} Addons                  List of current addons on this product
	 * @property {String}  PortOutAccountNumber    The retail account number of this service, used to validate port outs
	 * @property {Number}  BillingPeriodStartNanos The start of this billing period
	 * @property {Number}  BillingPeriodEndNanos   The end of this billing period
	 * @property {String}  ProductOrderUUID        ProductOrderUUID
	 * @property {Number}  CreditSMS               Remaining amount of SMSs
	 * @property {Number}  CreditVoiceMinutes      Remaining amount of VoiceMinutes
	 * @property {Number}  CreditDataMB            Remaining amount of Data MBs
	 * @property {Number}  CreditMMS               Remaining amount of MMSs
	 * @property {Number}  TotalSMS                Total amount of SMSs that are added
	 * @property {Number}  TotalVoiceMinutes       Total amount of VoiceMinutes that are added
	 * @property {Number}  TotalDataMB             Total amount of Data MBs that are added
	 * @property {Number}  TotalMMS                Total amount of MMSs that are added
	 * @property {String}  Name
	 * @property {String}  GroupName               Name of Group that product belongs
	 * @property {String}  EsimStatus              Esim Status which will be updated by Smdpplus Esim Notifier
	 * @property {Boolean} GroupOnly               Whether the Product is GroupOnly
	 *
	 */
	FinaliseActivationPostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/postpay_mobile/finalise_activation`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FinaliseActivationPostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FinaliseActivationPostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FinaliseActivationPostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FinaliseActivationPostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FinaliseActivationPostpayProduct'))
			})
	},

	/**
	 * RPC Method: FindPostpayMobilePlansPaginated
	 * Description: ## FindPostpayMobilePlansPaginated
	 *
	 * Returns the list of available postpay mobile plans available to the SP customer.
	 *
	 * ### Request Fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|specifies the customer to attach this product to|
	 * You can use the SearchOptions to filter through the returned plans
	 *
	 * ### Example Request
	 *
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/postpay_mobile_plans
	 * @param    {PostpayMobilePlanSearchRequest} req The API payload object (PostpayMobilePlanSearchRequest)
	 * @return   {PostpayMobilePlanPageResponse} The API response object (PostpayMobilePlanPageResponse)
	 *
	 * @typedef  {Object}  PostpayMobilePlanSearchRequest
	 * @property {SearchOptions[]} SearchOptions Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID          SPID is the service provider ID that the product belongs to
	 * @property {String}  CustomerUUID  WARNING: Not used/implemented
	 *
	 * @typedef  {Object}  PostpayMobilePlanPageResponse
	 * @property {PageInfo} PageInfo           PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PostpayMobilePlan[]} PostpayMobilePlans The list of matching Products
	 *
	 */
	FindPostpayMobilePlansPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile_plans`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindPostpayMobilePlansPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindPostpayMobilePlansPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindPostpayMobilePlansPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindPostpayMobilePlansPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindPostpayMobilePlansPaginated'))
			})
	},

	/**
	 * RPC Method: FindPostpayProductsPaginated
	 * Description: ## FindPostpayProductsPaginated
	 *
	 * Returns the list of current or past postpay mobile products.
	 *
	 * ### Example Request
	 * {
	 *     /api/v1/1/postpay_mobile?SearchOptions.PageNumber=5&ReferenceCode=fuzzy
	 * }
	 *
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/postpay_mobile
	 * @param    {PostpayMobileSearchRequest} req The API payload object (PostpayMobileSearchRequest)
	 * @return   {PostpayMobilePageResponse} The API response object (PostpayMobilePageResponse)
	 *
	 * @typedef  {Object}  PostpayMobileSearchRequest
	 * @property {SearchOptions[]} SearchOptions Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID          SPID is the service provider ID that the product belongs to
	 * @property {String}  UUID          UUID is the unique ID for this product
	 * @property {String}  CustomerUUID  CustomerUUID if attached to a customer
	 * @property {String}  MSISDN        MSISDN is if we want/wanted to autoallocate a MSISDN
	 * @property {String}  SIMID         SIMID is the SIMID that this product has (SIM)
	 *
	 * @typedef  {Object}  PostpayMobilePageResponse
	 * @property {PageInfo} PageInfo        PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PostpayProduct[]} PostpayProducts The list of matching Products
	 *
	 */
	FindPostpayProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindPostpayProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindPostpayProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindPostpayProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindPostpayProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindPostpayProductsPaginated'))
			})
	},

	/**
	 * RPC Method: GetAvailablePostpayAddonsPaginated
	 * Description: Searches for available addon objects using a search request
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/mobilepostpay_addon
	 * @param    {PostpayAddonCatalogSearchRequest} req The API payload object (PostpayAddonCatalogSearchRequest)
	 * @return   {PostpayAddonCatalogPageResponse} The API response object (PostpayAddonCatalogPageResponse)
	 *
	 * @typedef  {Object}  PostpayAddonCatalogSearchRequest
	 * @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID                SPID
	 * @property {String}  ProductInstanceUUID Product Instance UUID which may be used for filtering in the future
	 *
	 * @typedef  {Object}  PostpayAddonCatalogPageResponse
	 * @property {PageInfo} PageInfo             PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PostpayAddonCatalog[]} PostpayAddonCatalogs
	 *
	 */
	GetAvailablePostpayAddonsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/mobilepostpay_addon`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetAvailablePostpayAddonsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetAvailablePostpayAddonsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetAvailablePostpayAddonsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetAvailablePostpayAddonsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetAvailablePostpayAddonsPaginated'))
			})
	},

	/**
	 * RPC Method: GetDataSettings
	 * Description: ## GetDataSettings
	 *
	 * Get Data Settings of the product
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 *
	 * ### Example Request
	 *
	 * {
	 * /api/v1/1/postpay_mobile/1bcdb203-ec7e-461c-8870-d8467a167a78/data_settings
	 * }
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/data_settings
	 * @param    {PostpayMobileGetDataSettingsRequest} req The API payload object (PostpayMobileGetDataSettingsRequest)
	 * @return   {PostpayMobileGetDataSettingsResponse} The API response object (PostpayMobileGetDataSettingsResponse)
	 *
	 * @typedef  {Object}  PostpayMobileGetDataSettingsRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID         ProductInstanceUUID
	 * @property {String}  CustomerUUID For authentication
	 *
	 * @typedef  {Object}  PostpayMobileGetDataSettingsResponse
	 * @property {DataEnabled} DataEnabled
	 *
	 */
	GetDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile/${paramUUID}/data_settings`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetDataSettings'))
			})
	},

	/**
	 * RPC Method: GetPostpayProductByUUID
	 * Description: ## GetPostpayProductByUUID
	 *
	 * This endpoint allows for fetching of a Postpay product
	 *
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/customer/${req.CustomerUUID}/postpay_mobile/${req.UUID}
	 * @param    {PostpayGetRequest} req The API payload object (PostpayGetRequest)
	 * @return   {PostpayProduct} The API response object (PostpayProduct)
	 *
	 * @typedef  {Object}  PostpayGetRequest
	 * @property {Number}  SPID         This is your service provider ID
	 * @property {String}  CustomerUUID Limit search to a specific customer
	 * @property {String}  UUID         Limit search to a particular product
	 *
	 * @typedef  {Object}  PostpayProduct
	 * @property {String}  UUID                    UUID is the unique ID for this product
	 * @property {Number}  SPID                    Which direction is the port currently in action happening
	 * @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	 * @property {String}  SubscriptionUUID        SubscriptionUUID is the subscription that pays for this service
	 * @property {String}  ProductCatalogUUID      ProductCatalogUUID is the catalog entry this product was based off of
	 * @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	 * @property {String}  GroupUUID               GroupUUID is the availability that this product was based off
	 * @property {Number}  Cents                   Cents is the cost of this product in cents
	 * @property {Number}  CreatedAtNanos          CreatedAtCents is when this record was created in epoch nanoseconds
	 * @property {Number}  UpdatedAtNanos          UpdatedAtCents is when this record was last updated in epoch nanoseconds
	 * @property {Number}  DeletedAtNanos          DeletedAtCents is when this record was deleted in epoch nanoseconds
	 * @property {Number}  ActivatedAtNanos        ActivatedAtNanos is when this product was activated in epoch nanoseconds
	 * @property {Number}  TerminatedAtNanos       TerminatedAtNanos is when this product was terminated in epoch nanoseconds
	 * @property {String}  MSISDN                  MSISDN is for specifying the MSISDN when not auto-allocating MSISDNs
	 * @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	 * @property {Boolean} Voicemail               Voicemail enabled or not
	 * @property {String}  State                   State is the current product state
	 * @property {String}  CellularServiceUUID     Cellular Service UUID
	 * @property {String}  VoiceServiceUUID        Voice Service UUID
	 * @property {String}  VoicemailServiceUUID    Voicemail Service UUID
	 * @property {String}  MessagingServiceUUID    Messaging Service UUID
	 * @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN - Does not apply when providing a MSISDN manually
	 * @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	 * @property {VoiceSettings[]} VoiceSettings           Voice Settings
	 * @property {ENUM}    PortingState
	 * @property {ENUM}    PortingAction           PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {DataEnabled} DataEnabled             Enable data
	 * @property {String}  PortingUUID             UUID of the current Port Request
	 * @property {Boolean} SIMEnabled              is the SIM enabled?
	 * @property {PostpayAddon[]} Addons                  List of current addons on this product
	 * @property {String}  PortOutAccountNumber    The retail account number of this service, used to validate port outs
	 * @property {Number}  BillingPeriodStartNanos The start of this billing period
	 * @property {Number}  BillingPeriodEndNanos   The end of this billing period
	 * @property {String}  ProductOrderUUID        ProductOrderUUID
	 * @property {Number}  CreditSMS               Remaining amount of SMSs
	 * @property {Number}  CreditVoiceMinutes      Remaining amount of VoiceMinutes
	 * @property {Number}  CreditDataMB            Remaining amount of Data MBs
	 * @property {Number}  CreditMMS               Remaining amount of MMSs
	 * @property {Number}  TotalSMS                Total amount of SMSs that are added
	 * @property {Number}  TotalVoiceMinutes       Total amount of VoiceMinutes that are added
	 * @property {Number}  TotalDataMB             Total amount of Data MBs that are added
	 * @property {Number}  TotalMMS                Total amount of MMSs that are added
	 * @property {String}  Name
	 * @property {String}  GroupName               Name of Group that product belongs
	 * @property {String}  EsimStatus              Esim Status which will be updated by Smdpplus Esim Notifier
	 * @property {Boolean} GroupOnly               Whether the Product is GroupOnly
	 *
	 */
	GetPostpayProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/customer/${paramCustomerUUID}/postpay_mobile/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPostpayProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPostpayProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPostpayProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPostpayProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPostpayProductByUUID'))
			})
	},

	/**
	 * RPC Method: GetVoiceSettings
	 * Description: ## GetVoiceSettings
	 *
	 * Get voice settings of the product
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |UUID|string|The product instance UUID|
	 * |CustomerUUID|UUID(string)|The customer the product is attached to|
	 *
	 * ### Example Request
	 *
	 * {
	 * /api/v1/1/postpay_mobile/1bcdb203-ec7e-461c-8870-d8467a167a78/voicesettings
	 * }
	 *
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicesettings
	 * @param    {PostpayMobileGetVoiceSettingsRequest} req The API payload object (PostpayMobileGetVoiceSettingsRequest)
	 * @return   {PostpayMobileGetVoiceSettingsResponse} The API response object (PostpayMobileGetVoiceSettingsResponse)
	 *
	 * @typedef  {Object}  PostpayMobileGetVoiceSettingsRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID         ProductInstanceUUID
	 * @property {String}  CustomerUUID UUID of the customer
	 *
	 * @typedef  {Object}  PostpayMobileGetVoiceSettingsResponse
	 * @property {VoiceSettings[]} VoiceSettings
	 *
	 */
	GetVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/postpay_mobile/${paramUUID}/voicesettings`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVoiceSettings'))
			})
	},

	/**
	 * RPC Method: PostpayProductPortCancel
	 * Description: Cancels the IPMS Port Request if the port has not yet been completed. May only be called if we are the gaining service provider.
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portcancel
	 * @param    {PostpayProductPortCancelRequest} req The API payload object (PostpayProductPortCancelRequest)
	 * @return   {PostpayProduct} The API response object (PostpayProduct)
	 *
	 * @typedef  {Object}  PostpayProductPortCancelRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID         UUID of PostpayProduct
	 * @property {String}  CustomerUUID
	 *
	 * @typedef  {Object}  PostpayProduct
	 * @property {String}  UUID                    UUID is the unique ID for this product
	 * @property {Number}  SPID                    Which direction is the port currently in action happening
	 * @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	 * @property {String}  SubscriptionUUID        SubscriptionUUID is the subscription that pays for this service
	 * @property {String}  ProductCatalogUUID      ProductCatalogUUID is the catalog entry this product was based off of
	 * @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	 * @property {String}  GroupUUID               GroupUUID is the availability that this product was based off
	 * @property {Number}  Cents                   Cents is the cost of this product in cents
	 * @property {Number}  CreatedAtNanos          CreatedAtCents is when this record was created in epoch nanoseconds
	 * @property {Number}  UpdatedAtNanos          UpdatedAtCents is when this record was last updated in epoch nanoseconds
	 * @property {Number}  DeletedAtNanos          DeletedAtCents is when this record was deleted in epoch nanoseconds
	 * @property {Number}  ActivatedAtNanos        ActivatedAtNanos is when this product was activated in epoch nanoseconds
	 * @property {Number}  TerminatedAtNanos       TerminatedAtNanos is when this product was terminated in epoch nanoseconds
	 * @property {String}  MSISDN                  MSISDN is for specifying the MSISDN when not auto-allocating MSISDNs
	 * @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	 * @property {Boolean} Voicemail               Voicemail enabled or not
	 * @property {String}  State                   State is the current product state
	 * @property {String}  CellularServiceUUID     Cellular Service UUID
	 * @property {String}  VoiceServiceUUID        Voice Service UUID
	 * @property {String}  VoicemailServiceUUID    Voicemail Service UUID
	 * @property {String}  MessagingServiceUUID    Messaging Service UUID
	 * @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN - Does not apply when providing a MSISDN manually
	 * @property {String}  SIMID                   IMSI is the IMSI that this product has (SIM)
	 * @property {VoiceSettings[]} VoiceSettings           Voice Settings
	 * @property {ENUM}    PortingState
	 * @property {ENUM}    PortingAction           PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {DataEnabled} DataEnabled             Enable data
	 * @property {String}  PortingUUID             UUID of the current Port Request
	 * @property {Boolean} SIMEnabled              is the SIM enabled?
	 * @property {PostpayAddon[]} Addons                  List of current addons on this product
	 * @property {String}  PortOutAccountNumber    The retail account number of this service, used to validate port outs
	 * @property {Number}  BillingPeriodStartNanos The start of this billing period
	 * @property {Number}  BillingPeriodEndNanos   The end of this billing period
	 * @property {String}  ProductOrderUUID        ProductOrderUUID
	 * @property {Number}  CreditSMS               Remaining amount of SMSs
	 * @property {Number}  CreditVoiceMinutes      Remaining amount of VoiceMinutes
	 * @property {Number}  CreditDataMB            Remaining amount of Data MBs
	 * @property {Number}  CreditMMS               Remaining amount of MMSs
	 * @property {Number}  TotalSMS                Total amount of SMSs that are added
	 * @property {Number}  TotalVoiceMinutes       Total amount of VoiceMinutes that are added
	 * @property {Number}  TotalDataMB             Total amount of Data MBs that are added
	 * @property {Number}  TotalMMS                Total amount of MMSs that are added
	 * @property {String}  Name
	 * @property {String}  GroupName               Name of Group that product belongs
	 * @property {String}  EsimStatus              Esim Status which will be updated by Smdpplus Esim Notifier
	 * @property {Boolean} GroupOnly               Whether the Product is GroupOnly
	 *
	 */
	PostpayProductPortCancel({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portcancel`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PostpayProductPortCancel ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PostpayProductPortCancel ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PostpayProductPortCancel ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PostpayProductPortCancel UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PostpayProductPortCancel'))
			})
	},

	/**
	 * RPC Method: PostpayProductPortRequest
	 * Description: Places a Port Request with the tkt-nzlmnp-svc (calls ServiceProviderRequestPort). If successful, begins an automated process involving the IPMS, the gaining and losing service providers, and each respective carrier (if an inter-carrier port). The product service will be notified when the number is ready to be provisioned by the tkt-nzlmnp-svc.
	 *
	 * The Port Request Process can be summarised briefly as follows:
	 * - A Service Provider creates a Port Request with the IPMS (Industry Portability Management System), a government system responsible for facilitating local and mobile porting between NZ Service Providers. For each Port Request, the Service Provider that placed it is referred to as the GSP (Gaining Service Provider), while the receiver of the port request is referred to as the LSP (Losing Service Provider).
	 * - Each NZ Service Provider must periodically make requests to the IPMS to check if there are any Port Requests for which they are either the gaining or losing Service Provider, or to acknowledge Port Requests for which they are a third party.
	 * - The LSP (Losing Service Provider) will then discover the Port Request on its next IPMS poll. The LSP will then use either the OldSIMID (if coming from Prepay) or the AccountNumber (if coming from on-account) to validate the request. If there is no conflict between these parameters in the Port Request and what the LSP has on record, then the port must be allowed.
	 * - If the GSP and LSP are operating on different Carriers then the carriers will undergo a similar process where the Gaining and Losing Carriers will acknowledge the Port Request before it is eventually completed and the new service can be provisioned on the GSP after which the new service is ready to use. Successful Ports will then show a status of Closed. If the LSP rejects the port request (because the AccountNumber or OldSIMID do not match what it has on record for the given customer) then the port will show a status of Rejected.
	 * - In the normal course of events a port between two Service Providers on the same Carrier will pass through the following states: Awaiting LSP Response, Awaiting GSP Approval, Approved, Closed. A port between two Service Providers on different Carriers will pass through the following states: Awaiting LSP Response, Awaiting GSP Approval, Approved, In Progress, GC and LC Complete, Closed.
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portnumber
	 * @param    {PostpayProductNumberPortRequest} req The API payload object (PostpayProductNumberPortRequest)
	 * @return   {PostpayProductNumberPortResponse} The API response object (PostpayProductNumberPortResponse)
	 *
	 * @typedef  {Object}  PostpayProductNumberPortRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID                UUID of PrepayProduct
	 * @property {String}  MSISDN              The PhoneNumber the customer wants to keep
	 * @property {String}  OldSIMID            The SIMID of the customer's current service (on current provider). Required if coming from Prepay.
	 * @property {String}  CustomerUUID
	 * @property {Number}  LSP                 Losing Service Provider IPMS ID. If omitted/zero, LMNP will attempt to discover LSP itself by querying the IPMS. However, if unsuccessful will return an error.,  In the case where it is provided, the LSPOverride flag will be 'true' in the request to the IPMS. It will be 'false' if the LSP if discovered.
	 * @property {String}  AccountNumber       The account number of the customer's current service (on current provider). Required if coming from OnAccount.
	 * @property {String}  CustomerName        The name on the customer's account at the current service (on current provider). If coming from OnAccount, LMNP will attempt to use first and last name from the customer service using the CustomerUUID. Ideally this should be provided to avoid mis-match.
	 * @property {Boolean} SMSResponseRequired Enable two factor authentication for this port
	 * @property {Number}  PortRFS             Ready for service date time of this port
	 *
	 * @typedef  {Object}  PostpayProductNumberPortResponse
	 * @property {Number}  SPID
	 * @property {String}  UUID                      UUID of PostpayProduct
	 * @property {String}  MSISDN                    The SIMID to swap onto, the old SIM will be marked as ineligable
	 * @property {Number}  PortRFS                   When the port is expected to take place
	 * @property {Boolean} TwoFANotificationExpected Will the port require a 2FA response
	 * @property {ServiceProviderData[]} ServiceProviderList
	 *
	 */
	PostpayProductPortRequest({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/portnumber`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PostpayProductPortRequest ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PostpayProductPortRequest ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PostpayProductPortRequest ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PostpayProductPortRequest UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PostpayProductPortRequest'))
			})
	},

	/**
	 * RPC Method: PostpayProductRequestApprovedPortChange
	 * Description: Change an existing port in the approved state. Primarily used to update the RFS time of a port.
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/approved_port_change
	 * @param    {PostpayProductApprovedPortChangeRequest} req The API payload object (PostpayProductApprovedPortChangeRequest)
	 * @return   {PostpayProductApprovedPortChangeResponse} The API response object (PostpayProductApprovedPortChangeResponse)
	 *
	 * @typedef  {Object}  PostpayProductApprovedPortChangeRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID                UUID of PrepayProduct
	 * @property {String}  MSISDN              The PhoneNumber the customer wants to keep
	 * @property {String}  CustomerUUID        The SIMID of the customer's current service (on current provider). Required if coming from Prepay.
	 * @property {Boolean} SMSResponseRequired Enable two factor authentication for this port
	 * @property {Number}  PortRFS             Ready for service date time of this port
	 *
	 * @typedef  {Object}  PostpayProductApprovedPortChangeResponse
	 * @property {Number}  SPID
	 * @property {String}  UUID    UUID of PostpayProduct
	 * @property {String}  MSISDN  The SIMID to swap onto, the old SIM will be marked as ineligable
	 * @property {Number}  PortRFS When the port is expected to take place
	 *
	 */
	PostpayProductRequestApprovedPortChange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobilepostpay/${req.UUID}/approved_port_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PostpayProductRequestApprovedPortChange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PostpayProductRequestApprovedPortChange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PostpayProductRequestApprovedPortChange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PostpayProductRequestApprovedPortChange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PostpayProductRequestApprovedPortChange'))
			})
	},

	/**
	 * RPC Method: SIMEnabled
	 * Description: ## SIMEnabled
	 *
	 * The SIM Enable feature provides the ability to remove a temporary block that has previously been applied
	 * on an active subscriber SIM card.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|Change the customer the product is attached to|
	 * |Enabled|boolean|Change the SIM status this product has|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	 *        "Enabled": "false",
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/sim_enabled
	 * @param    {SIMEnableReq} req The API payload object (SIMEnableReq)
	 * @return   {SIMEnableResponse} The API response object (SIMEnableResponse)
	 *
	 * @typedef  {Object}  SIMEnableReq
	 * @property {Number}  SPID         SPID is the service provider ID that the product belongs to
	 * @property {String}  UUID         Limit search to a particular product instance
	 * @property {Boolean} Enabled      Enable the SIM service or disable
	 * @property {String}  CustomerUUID CustomerUUID if attached to a customer
	 *
	 * @typedef  {Object}  SIMEnableResponse
	 * @property {Number}  SPID
	 * @property {String}  ProductOrderUUID
	 *
	 */
	SIMEnabled({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/sim_enabled`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('SIMEnabled ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('SIMEnabled ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('SIMEnabled ERROR:', error)
					return Promise.reject(error)
				}
				console.log('SIMEnabled UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in SIMEnabled'))
			})
	},

	/**
	 * RPC Method: TerminatePostpayAddon
	 * Description: ## TerminatePostpayAddon
	 *
	 * Post a terminate order for a product. The order will take affect within normal processing parameters.
	 *
	 * HTTP Method: DELETE
	 * API Path: /api/v1/${req.SPID}/postpay_addon/${req.UUID}
	 * @param    {PostpayAddonTerminateRequest} req The API payload object (PostpayAddonTerminateRequest)
	 * @return   {PostpayAddonTerminateResponse} The API response object (PostpayAddonTerminateResponse)
	 *
	 * @typedef  {Object}  PostpayAddonTerminateRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 *
	 * @typedef  {Object}  PostpayAddonTerminateResponse
	 * @property {Number}  SPID
	 * @property {String}  ProductOrderUUID
	 *
	 */
	TerminatePostpayAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v1/${req.SPID}/postpay_addon/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TerminatePostpayAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TerminatePostpayAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TerminatePostpayAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TerminatePostpayAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TerminatePostpayAddon'))
			})
	},

	/**
	 * RPC Method: TerminatePostpayProduct
	 * Description: ## TerminatePostpayProduct
	 *
	 * Post a terminate order for a product. The order will take affect within normal processing parameters.
	 *
	 * HTTP Method: DELETE
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}
	 * @param    {PostpayMobileTerminateRequest} req The API payload object (PostpayMobileTerminateRequest)
	 * @return   {PostpayMobileTerminateResponse} The API response object (PostpayMobileTerminateResponse)
	 *
	 * @typedef  {Object}  PostpayMobileTerminateRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 *
	 * @typedef  {Object}  PostpayMobileTerminateResponse
	 * @property {Number}  SPID
	 * @property {String}  ProductOrderUUID
	 *
	 */
	TerminatePostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v1/${req.SPID}/postpay_mobile/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TerminatePostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TerminatePostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TerminatePostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TerminatePostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TerminatePostpayProduct'))
			})
	},

	/**
	 * RPC Method: UpdateDataSettings
	 * Description: ## UpdateDataSettings
	 *
	 * Update Data Settings of the product
	 * All DataEnabled products need to be set at the same time
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |DataEnabled|object(DataEnabled)|The DataEnabled to be set|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "SPID": 1,
	 *        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	 *        "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	 *     	"DataEnabled": {
	 *            "OCSChargeableDataRGBarred": false,
	 *        }
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/data_settings
	 * @param    {PostpayMobileUpdateDataSettingsRequest} req The API payload object (PostpayMobileUpdateDataSettingsRequest)
	 * @return   {PostpayMobileUpdateDataSettingsResponse} The API response object (PostpayMobileUpdateDataSettingsResponse)
	 *
	 * @typedef  {Object}  PostpayMobileUpdateDataSettingsRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 * @property {String}  CustomerUUID
	 * @property {DataEnabled} DataEnabled
	 *
	 * @typedef  {Object}  PostpayMobileUpdateDataSettingsResponse
	 * @property {DataEnabled} DataEnabled
	 *
	 */
	UpdateDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/data_settings`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateDataSettings'))
			})
	},

	/**
	 * RPC Method: UpdatePostpayProduct
	 * Description: ## UpdatePostpayProduct
	 *
	 * Post a product change order into the system
	 *
	 * ### Request Fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |SearchOptions||the usual search and pagination options|
	 * |CustomerUUID|UUID(string)|UUID of the Customer that the product is attached to (read-only)
	 * |ReferenceCode|string|Change the reference code this product has
	 * |ProductAvailabilityUUID|UUID(string)|Change the product catalog item this product is based off (aka change plan)
	 * |MSISDN|MSISDN(string)|When provided, changes the MSISDN this product has
	 * |IMSI|IMSI(string)|When provided, changes this product to use a new SIM (IMSI)
	 * |Voicemail|boolean|Enable or disable voicemail service
	 * |GroupUUID|UUID(string)|Change or remove the sharing group this product is in
	 *
	 * ### Example Request
	 * {
	 *     "ReferenceCode": "cust123/plan53",
	 *     "Voicemail": false
	 * }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}
	 * @param    {PostpayMobileUpdateRequest} req The API payload object (PostpayMobileUpdateRequest)
	 * @return   {PostpayMobileUpdateResponse} The API response object (PostpayMobileUpdateResponse)
	 *
	 * @typedef  {Object}  PostpayMobileUpdateRequest
	 * @property {String}  UUID                    UUID is the unique ID for this product
	 * @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	 * @property {String}  CustomerUUID            CustomerUUID if attached to a customer
	 * @property {String}  ProductAvailabilityUUID ProductAvailabilityUUID is the availability that this product was based off
	 * @property {Boolean} AutoAllocateMSISDN      AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	 * @property {String}  MSISDN                  AutoAllocateMSISDN is if we want/wanted to autoallocate a MSISDN
	 * @property {String}  ICCID                   ICCID is the ICCID that this product has (SIM)
	 * @property {String}  IMSI                    IMSI is the IMSI that this product has (SIM)
	 * @property {Boolean} Voicemail               Voicemail enabled or not
	 * @property {String}  GroupUUID               GroupUUID is the sharing group this product is in
	 * @property {VoiceSettings[]} VoiceSettings           For updating VoiceSettings such as CLIR, CLIP etc.
	 * @property {DataEnabled} DataEnabled             For enable/disable data
	 *
	 * @typedef  {Object}  PostpayMobileUpdateResponse
	 * @property {Number}  SPID
	 * @property {String}  ProductOrderUUID
	 *
	 */
	UpdatePostpayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePostpayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePostpayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePostpayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePostpayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePostpayProduct'))
			})
	},

	/**
	 * RPC Method: UpdateVoiceMailStatus
	 * Description: ## UpdateVoiceMailStatus
	 *
	 * The Voicemail service gives callers to a mobile subscriber the ability to leave a recorded message in the event the subscriber is
	 * busy on an exiting call or not available to answer.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|Change the customer the product is attached to|
	 * |Enabled|boolean|Enable the voicemail or not|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	 *        "Enabled": "false",
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicemail_change
	 * @param    {VoiceMailEnableRequest} req The API payload object (VoiceMailEnableRequest)
	 * @return   {VoiceMailEnableResponse} The API response object (VoiceMailEnableResponse)
	 *
	 * @typedef  {Object}  VoiceMailEnableRequest
	 * @property {Number}  SPID         SPID is the service provider ID that the product belongs to
	 * @property {String}  UUID         Limit search to a particular product instance
	 * @property {Boolean} Enabled      Enable voice message or not
	 * @property {String}  CustomerUUID CustomerUUID if attached to a customer
	 *
	 * @typedef  {Object}  VoiceMailEnableResponse
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 * @property {String}  CustomerUUID
	 *
	 */
	UpdateVoiceMailStatus({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicemail_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoiceMailStatus ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoiceMailStatus ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoiceMailStatus ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoiceMailStatus UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoiceMailStatus'))
			})
	},

	/**
	 * RPC Method: UpdateVoiceSettings
	 * Description: ## UpdateVoiceSettings
	 *
	 * Update voice settings of the product.
	 * All VoiceSettings products need to be set at the same time.
	 *
	 * ### Request fields
	 * |**Field**|**Type**|**Description**|
	 * |--|--|--|
	 * |CustomerUUID|UUID(string)|The customer the product is attached to|
	 * |VoiceSettings|object(VoiceSettings)|The voice settings to be set|
	 *
	 * ### Example Request
	 *
	 *     {
	 *        "SPID": 1,
	 *        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	 *        "CustomerUUID": "000c6ca1-ba32-4764-9cdc-360143e0e90f",
	 *     	"VoiceSettings": {
	 *          "OutgoingCallerID": 3,
	 *          "IncomingCallerID": true,
	 *          "AllowConferencing": true,
	 *          "CallWaitingAvailable": true,
	 *          "CallWaitingEnabled": true,
	 * 	        "CFBActive":false,
	 * 	        "CFBAvailable":true,
	 * 	        "CFBDestination":"CFBDestination stub",
	 * 	        "CFNRCActive":false,
	 * 	        "CFNRCAvailable":true,
	 * 	        "CFNRCDestination":"CFNRCDestination stub",
	 * 	        "CFNRYAvailable":true,
	 * 	        "CFNRYActive":false,
	 * 	        "CFNRYDestination":true,
	 * 	        "CFNRYRingtime":30,
	 * 	        "CFUActive":false,
	 * 	        "CFUAvailable":true,
	 * 	        "CFUDestination":"CFUDestination stub",
	 * 	        "BarIncomingCalls":false,
	 * 	        "BarOutgoingCalls":false,
	 * 	        "AllowIncomingCalls":true,
	 * 	        "AllowOutgoingCalls":true,
	 * 	        "AllowSMSSend":true,
	 * 	        "AllowSMSRecieve":true,
	 * 	        "OCSBlockPremiumCalls":false,
	 * 	        "OCSBlockPremiumSMS":false,
	 * 	        "OCSOutgoingIDDBarred":false,
	 * 	        "OCSOutgoingTextBarred":false,
	 * 	        "OCSRoamingBarred":false,
	 * 	        "OCSOutCallsWhileRoamingBarred":false,
	 * 	        "OCSInCallsWhileRoamingBarred":false,
	 * 	        "OCSOOBWhileRoamingBarred":false,
	 *        }
	 *     }
	 *
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicesettings
	 * @param    {PostpayMobileUpdateVoiceSettingsRequest} req The API payload object (PostpayMobileUpdateVoiceSettingsRequest)
	 * @return   {PostpayMobileUpdateVoiceSettingsResponse} The API response object (PostpayMobileUpdateVoiceSettingsResponse)
	 *
	 * @typedef  {Object}  PostpayMobileUpdateVoiceSettingsRequest
	 * @property {Number}  SPID          Service Provider ID
	 * @property {String}  UUID          UUID
	 * @property {VoiceSettings[]} VoiceSettings
	 * @property {String}  CustomerUUID
	 *
	 * @typedef  {Object}  PostpayMobileUpdateVoiceSettingsResponse
	 * @property {VoiceSettings[]} VoiceSettings
	 *
	 */
	UpdateVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v1/${req.SPID}/postpay_mobile/${req.UUID}/voicesettings`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoiceSettings'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
