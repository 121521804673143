import axios from 'axios'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const state = {
    groupTypes: {
        COMPANION: 'Companion',
        ENTERPRISE: 'Enterprise'
    },
}

const getters = {
    groupTypes: state => state.groupTypes,
    groupTypeTitle: (state, getters) => type => getters.groupTypes[type]
}

const mutations = {}

const actions = {
    SetEmailVerificationCode({}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/customer/${req.CustomerUUID}/email_verification/setcode`, req)
            .then(response => {
                if (!response.data) { return Promise.reject(Error('no response data')) }
                return Promise.resolve(response.data)
            })
            .catch(error => Promise.reject(error))
    },
    SearchCustomersByEmail({}, req) {
        const paramEMAIL = req.email
        return axios.get(`${API_URL_RESELLER}/customers/search-customer-by-email/${paramEMAIL}`)
            .then(response => {
                // Success response
                if (!response.data) { return Promise.reject(Error('no response data')) }

                if (response.data?.CustomerServices) {
                    return Promise.resolve(response.data.CustomerServices)
                }

                return Promise.reject(new Error('No Services'))
            })
            .catch(error => Promise.reject(error))
    },
    MigrationServicesBetweenCustomers({}, req) {
        return axios.post(`${API_URL_RESELLER}/customers/migration-services`, req)
            .then(response => {
                if (!response.data) { return Promise.reject(Error('no response data')) }
                return Promise.resolve(response.data)
            })
            .catch(error => Promise.reject(error))
    },
    CreateCustomerWithEmail({}, req) {
        const paramEMAIL = req.Email
        return axios.put(`${API_URL_RESELLER}/customers/create-customer-with-email/${paramEMAIL}`, req)
            .then(response => {
                if (!response.data) { return Promise.reject(Error('no response data')) }
                return Promise.resolve(response.data)
            })
            .catch(error => Promise.reject(error))
    },
    GetCustomerAccount({}, req) {
        // return axios.get(`${API_URL_RESELLER}/admin/${req.CustomerUUID}/account`, { params: req })
        return axios.get(`${API_URL_RESELLER}/admin/account/${req.CustomerUUID}`, { params: req })
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    }
}
export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}