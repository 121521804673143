import axios from "axios";
import StoreHash from '@/utils/StoreHash';

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST;

const hashed = {
    transactions: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },
    debtAccounts: {
        customerUUIDs: {},
        lifetime: 5 * 60 * 1000, // 5 min
    }
}

const state = {
    transactionStatuses: [
        {
            key: 'BT_PENDING',
            label: 'pending',
            value: 0
        }, {
            key: 'BT_VOIDED',
            label: 'voided',
            value: 1
        }, {
            key: 'BT_UNRECONCILED',
            label: 'unreconciled',
            value: 2
        }, {
            key: 'BT_RECONCILED',
            label: 'reconciled',
            value: 3
        }
    ],
    transactions: {},
    debtAccounts: {}
}

const getters = {
    transactionStatuses(state) {
        return state.transactionStatuses;
    },
    transactionByUUID(state) {
        return uuid => {
            return state.transactions[uuid]
        }
    },
    debtAccountByCustomerUUID(state) {
        return customerUUID => {
            return state.debtAccounts[customerUUID]
        }
    }
}

const mutations = {
    mutTransactions(state, transactions){
        for (let i = 0, len = transactions.length; i < len; i++) {
            const transaction = transactions[i];
            const uuid = transaction.UUID;

            //Проверяем есть уже такой форум в кеше
            if (!(uuid in hashed.transactions.uuids)) {
                hashed.transactions.uuids[uuid] = new StoreHash(hashed.transactions.lifetime);
            }

            state.transactions[uuid] = transaction;
            hashed.transactions.uuids[uuid].fix();
        }
    },
    mutTransactionByUUID(state, transaction){
        const uuid = transaction.UUID;
        state.transactions[uuid] = transaction;

        if (uuid in hashed.transactions.uuids) {
            hashed.transactions.uuids[uuid].reset(transaction);
        }
    },
    mutDebtAccounts(state, debtAccounts){
        for (let i = 0, len = debtAccounts.length; i < len; i++) {
            const debtAccount = debtAccounts[i];
            const customerUUID = debtAccount.CustomerUUID;

            //Проверяем есть уже такой форум в кеше
            if (!(customerUUID in hashed.debtAccounts.customerUUIDs)) {
                hashed.debtAccounts.customerUUIDs[customerUUID] = new StoreHash(hashed.debtAccounts.lifetime);
            }

            state.debtAccounts[customerUUID] = debtAccount;
            hashed.debtAccounts.customerUUIDs[customerUUID].fix();
        }
    },
    mutDebtAccountByCustomerUUID(state, debtAccount){
        const customerUUID = debtAccount.CustomerUUID;
        state.debtAccounts[customerUUID] = debtAccount;

        if (customerUUID in hashed.debtAccounts.customerUUIDs) {
            hashed.debtAccounts.customerUUIDs[customerUUID].reset(debtAccount);
        }
    },
}

const actions = {
    getBankTransactions({ commit }, req) {
        return axios.post(`${API_URL_RESELLER}/admin/bank-transaction/search`, req )
            .then(({ apidata }) => {
                commit('mutTransactions', apidata.BankTransactions);
                return Promise.resolve(apidata);
            })
            .catch(error => Promise.reject(error))
    },
    getBankTransactionByUUID({ getters, commit }, { UUID, isRefresh }){
        if (!(UUID in hashed.transactions.uuids)) {
            hashed.transactions.uuids[UUID] = new StoreHash(hashed.transactions.lifetime);
        }

        if (hashed.transactions.uuids[UUID].expired() || isRefresh) {
            return axios.get(`${API_URL_RESELLER}/admin/bank-transaction/${UUID}`)
                .then(({apidata}) => {
                    commit('mutTransactionByUUID', apidata);
                    hashed.transactions.uuids[UUID].fix();
                    return Promise.resolve(getters.transactionByUUID(UUID))
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve( getters.transactionByUUID(UUID) )
        }
    },
    voidBankTransactionByUUID({}, req) {
        return axios.delete(`${API_URL_RESELLER}/admin/bank-transaction/${req.UUID}`, { data: req })
            .then(({ apidata }) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    getDueOrOverdueInvoices({}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/debts`, req)
            .then(({ apidata }) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    addOneBankTransaction({}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/bank-transaction`, req )
            .then(({ apidata }) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    uploadBankTransactions({}, { file, mode }) {
        return axios.post(`${API_URL_RESELLER}/admin/bank-transaction/upload?mode=${mode}`,
            file,
            {
                headers: {
                    'Content-Type': 'text/csv'
                }
            }
        )
            .then(({ apidata }) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    reconcileBankTransaction({}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/bank-transaction/reconcile`, req)
            .then(({ apidata }) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    unReconcileBankTransaction({}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/bank-transaction/un-reconcile`, req)
            .then(({ apidata }) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    getDebtAccounts({commit}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/accounts`, req)
            .then(({ apidata }) => {
                commit('mutDebtAccounts', apidata.Accounts)
                return Promise.resolve(apidata)
            })
            .catch(error => Promise.reject(error))
    },
    getDebtAccountByCustomerUUID({ getters, commit }, { CustomerUUID, isRefresh }){
        console.log('CustomerUUID', CustomerUUID)
        if (!(CustomerUUID in hashed.debtAccounts.customerUUIDs)) {
            hashed.debtAccounts.customerUUIDs[CustomerUUID] = new StoreHash(hashed.debtAccounts.lifetime);
        }

        if (hashed.debtAccounts.customerUUIDs[CustomerUUID].expired() || isRefresh) {
            return axios.get(`${API_URL_RESELLER}/admin/account/${CustomerUUID}`)
                .then(({apidata}) => {
                    commit('mutDebtAccountByCustomerUUID', apidata);
                    hashed.debtAccounts.customerUUIDs[CustomerUUID].fix();
                    return Promise.resolve(getters.debtAccountByCustomerUUID(CustomerUUID));
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve( getters.debtAccountByCustomerUUID(CustomerUUID) )
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}