import axios from 'axios'
import StoreHash from '@/utils/StoreHash'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST
const VOUCHER_EXPIRY_TIME = (process.env.VUE_APP_VOUCHER_EXPIRY_TIME || 24) * 3600000000000

const hashed = {
    paymentMethods: {
        customers: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },
    paymentHistory: {
        customers: {},
        lifetime: 5 * 60 * 1000, // 5 min
    },
}

const state = {
    paymentMethods: {
        customers: {}
    },
    paymentHistory: {
        customers: {}
    },
    voucherExpiryTime: VOUCHER_EXPIRY_TIME
}

const getters = {
    paymentMethodsByCustomerID(state) {
        return uuid => {
            return state.paymentMethods.customers[uuid]
        }
    },
    paymentHistoryByCustomerID: state => (uuid, year) => {
        return state.paymentHistory.customers[uuid][year]
    },
    voucherExpiryTime: state => state.voucherExpiryTime
}

const mutations = {
    mutPaymentMethod(state, { CustomerUUID, payments }) {
        let paymentUUIDs = {}

        if (payments && payments.length > 0) {
            for (let i = 0; i < payments.length; i++) {
                const payment = payments[i]
                const uuid = payment.UUID

                // if (!(CustomerUUID in hashed.paymentMethods.customers) && !(hashed.paymentMethods.customers[CustomerUUID] && uuid in hashed.paymentMethods.customers[CustomerUUID])) {
                //     hashed.paymentMethods.customers[CustomerUUID] = new StoreHash(hashed.paymentMethods.lifetime)
                // }

                paymentUUIDs[uuid] = payment
            }

            state.paymentMethods.customers[CustomerUUID] = paymentUUIDs
            hashed.paymentMethods.customers[CustomerUUID].fix()
        }
    },
    mutDeletePaymentMethod(state, { CustomerUUID, UUID }) {
        delete state.paymentMethods.customers[CustomerUUID][UUID]
        hashed.paymentMethods.customers[CustomerUUID].reset()
    },
    mutPaymentRank(state, { CustomerUUID, UUID }) {
        for (let paymentUUID in state.paymentMethods.customers[CustomerUUID]) {
            let payment = state.paymentMethods.customers[CustomerUUID][paymentUUID]
            payment.Rank = 0
        }
        state.paymentMethods.customers[CustomerUUID][UUID].Rank = 1
        hashed.paymentMethods.customers[CustomerUUID].fix()
    },
    mutPaymentsHistory(state, { CustomerUUID, refresh, year, payments }) {
        let paymentsPerYear = []
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        months.forEach(month => {
            if (payments[month] && payments[month].length > 0) {
                payments[month].forEach(payment => {
                    paymentsPerYear.push(payment)
                })
            }
        })

        if (!(year in hashed.paymentHistory.customers[CustomerUUID])) {
            hashed.paymentHistory.customers[CustomerUUID][year] = new StoreHash(hashed.paymentHistory.lifetime)
        }

        paymentsPerYear = paymentsPerYear.sort((a, b) => {
            return new Date(b.CreatedAt) - new Date(a.CreatedAt)
        })

        if ((CustomerUUID in state.paymentHistory.customers)) {
            state.paymentHistory.customers[CustomerUUID][year] = paymentsPerYear
        } else {
            state.paymentHistory.customers[CustomerUUID] = {
                [year]: paymentsPerYear
            }
        }

        hashed.paymentHistory.customers[CustomerUUID][year].fix()
    },
    resetPaymentsCache(state) {
        hashed.paymentMethods.customers = {}
        hashed.paymentHistory.customers = {}
        state.paymentMethods = {
            customers: {}
        }
        state.paymentHistory = {
            customers: {}
        }
    }

}

const actions = {
    GetCashedPaymentMethodsByCustomerID({ getters, dispatch }, { CustomerUUID, refresh }){
        if (!(CustomerUUID in hashed.paymentMethods.customers)) {
            hashed.paymentMethods.customers[CustomerUUID] = new StoreHash(hashed.paymentMethods.lifetime)
        }

        //Если вермя жизни хешированных данных истекло делаем запрос на сервер
        if (hashed.paymentMethods.customers[CustomerUUID].expired() || refresh) {
            return dispatch('GetPaymentMethods', { CustomerUUID, refresh })
        } else {
            // return Promise.resolve( getters.paymentHistoryByCustomerID(CustomerUUID) )
            return Promise.resolve( getters.paymentMethodsByCustomerID(CustomerUUID) )
        }
    },
    GetPaymentMethods({ commit }, { CustomerUUID, refresh }) {
        return axios.get(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/payment-methods`, { params: { CustomerUUID, refresh } })
            .then(response => {
                if (response?.apidata) {
                    commit('mutPaymentMethod', { CustomerUUID, payments: response.apidata })
                    return Promise.resolve(response.apidata)
                }
                return Promise.reject(new Error('No Payment Methods'))
            })
            .catch(error => Promise.reject(error))
    },
    // getCashedPaymentHistoryByCustomerID({ getters, dispatch }, { CustomerUUID, refresh, year }) {
    //     if (!(CustomerUUID in hashed.paymentHistory.customers)) {
    //         hashed.paymentHistory.customers[CustomerUUID] = {
    //             [year]: new StoreHash(hashed.paymentHistory.lifetime)
    //         }
    //     }
    //
    //     const isPaymentYear = year in hashed.paymentHistory.customers[CustomerUUID]
    //
    //     // Если даты (года) нет в кеше и вермя жизни хешированных данных истекло делаем запрос на сервер
    //     // if (!isPaymentYear || (isPaymentYear && hashed.paymentHistory.customers[CustomerUUID][year].expired()) || refresh) {
    //     if (!isPaymentYear || hashed.paymentHistory.customers[CustomerUUID][year].expired() || refresh) {
    //         return dispatch('getPaymentHistory', { CustomerUUID, refresh, year })
    //     } else {
    //         return Promise.resolve( getters.paymentHistoryByCustomerID(CustomerUUID, year) )
    //     }
    // },
    //
    // getPaymentHistory({ getters, commit }, { CustomerUUID, refresh, year }) {
    //     return axios.get(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/charges/${year}`, { params: { refresh } }).then(({apidata}) => {
    //         commit('mutPaymentsHistory', { CustomerUUID, refresh, year, payments: apidata })
    //         // return Promise.resolve(apidata)
    //         return Promise.resolve( getters.paymentHistoryByCustomerID(CustomerUUID, year) )
    //     }).catch(error => {
    //         console.log('ERROR', error)
    //         return Promise.reject(error)
    //     })
    // },
    GetPaymentHistoryByCustomerUUID({}, params) {
        return axios.get(`${API_URL_RESELLER}/v2/admin/customer/${params.CustomerUUID}/charges`, { params })
            .then(({ data }) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    DeletePaymentMethods({ commit }, { CustomerUUID, UUID }) {
        return axios.delete(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/payment-methods/${UUID}`)
            .then(({apidata}) => {
                commit('mutDeletePaymentMethod', { CustomerUUID, UUID })
                return Promise.resolve(apidata)
            })
            .catch(error => Promise.reject(error))
    },
    SetDefaultPaymentMethod({ commit }, { CustomerUUID, UUID }) {
        return axios.put(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/payment-methods/${UUID}`, { Rank: 1 })
            .then(({apidata}) => {
                commit('mutPaymentRank', { CustomerUUID, UUID })
                return Promise.resolve(apidata)
            })
            .catch(error => Promise.reject(error))
    },
    UnsubscribeAddonByCustomerUUID({}, { CustomerUUID, ProductInstanceUUID, PrepayAddonUUID }) {
        return axios.delete(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/prepay_addon/${ProductInstanceUUID}/${PrepayAddonUUID}`)
            .then(({apidata})=> Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },
    RefundPayment({}, params) {
        return axios.post(`${API_URL_RESELLER}/admin/customer/${params.CustomerUUID}/refunds`, params)
            .then(({apidata})=> Promise.resolve(apidata))
            .catch(error => Promise.reject(error)
        )
    },
    UpdatePaymentSchedule({}, { Account, Dry, NewPaymentSchedule }) {
        return axios.put(`${API_URL_RESELLER}/payments/v1/account/${Account}/update-schedule/${Dry}(req)`, { NewPaymentSchedule })
            .then(({apidata})=> Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}