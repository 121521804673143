import axios from 'axios'

const DASHBOARD_API_URL = process.env.VUE_APP_DASHBOARD_API_URL || '/:spid/dashboard'

const state = {}

const getters = {
    dashboardApiUrl: () => DASHBOARD_API_URL,
}

const mutations = {}

const actions = {
    getDashboardData({ getters }) {
        /**
         * @todo
         */
        const SPID = getters.spid;
        console.log('OOOOOO')
        return axios.get(getters.dashboardApiUrl.replace(/:spid(\/|\?|$)/gi, `${ SPID }$1`))
            .then(response => {
                return Promise.resolve(response.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}