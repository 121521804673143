import axios from 'axios'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const state = {}

const getters = {}

const mutations = {}

const actions = {
    GetNillingRoutes({}, params) {
        const CustomerUUID = params?.CustomerUUID
        return axios.get(`${API_URL_RESELLER}/customers/${CustomerUUID}/billing-routes`, { params })
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    AddBillingRoute({}, params) {
        return axios.post(`${API_URL_RESELLER}/admin/billing-routes`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    UpdateBillingRoute({}, params) {
        return axios.put(`${API_URL_RESELLER}/admin/billing-routes/${params.UUID}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    DeleteBillingRoute({}, params) {
        return axios.delete(`${API_URL_RESELLER}/admin/billing-routes/${params.UUID}`).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}