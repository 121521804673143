import axios from "axios"

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const BALANCES = {

}

const state = {
    balance: null,
    isBalanceSuccess: true
}

const getters = {
    balance: state => state.balance,
    isBalanceSuccess: state => state.isBalanceSuccess,
}

const mutations = {
    setBalance(state, balance) {
        state.balance = balance
    },
    setBalanceSuccess(state, isSuccess) {
        state.isBalanceSuccess = isSuccess
    },
    resetBalance(state, balance) {
        state.balance = null
    }
}

const actions = {
    GetPostPayCustomerBalance({ commit }, { UUID, CustomerUUID }) {
        return axios.post(`${API_URL_RESELLER}/postpay/balance`, { UUID, CustomerUUID })
        // return import('./hardcoded-test-data/usages/balance.json')
            .then(({result: usage}) => {
                commit('setBalance', usage)
                commit('setBalanceSuccess', true)
            })
            .catch(error => {
                commit('setBalance', null)
                commit('setBalanceSuccess', false)
                return Promise.reject(error)
            })
    },
    GetPrePayCustomerBalance({ commit }, { UUID, CustomerUUID }) {
        return axios.get(`${API_URL_RESELLER}/customers/${CustomerUUID}/products/mobile/prepay/${UUID}/balance`)
        // return import('./hardcoded-test-data/usages/balance.json')
            .then(({result: usage}) => {
                commit('setBalance', usage)
                commit('setBalanceSuccess', true)
            })
            .catch(error => {
                commit('setBalance', null)
                commit('setBalanceSuccess', false)
                return Promise.reject(error)
            })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}