import axios from "axios"

const state = {
    cart: null,
    cartItems: {}
}

const getters = {
    cart: state => state.cart,
    cartItemByItemUUID: state => itemUUID => state.cartItems[itemUUID],
    cartItemByUUID: state => uuid => state.cartItems[uuid],
    productUUIDByItemUUID: (state, getters) => uuid => getters.cartItemByUUID(uuid)?.Products[0].UUID || null
}

const mutations = {
    mutSaveCart(state, cart) {
        state.cart = cart
    },
    mutSaveCartItems(state, items) {
        items.forEach(item => {
            state.cartItems[item.ItemUUID] = item
        })
    },
    resetCart(state) {
        state.cart = null
        state.cartItems = {}
    }
}

const actions = {
    cartPayload({ getters, rootGetters }, order) {
        const delivery = order?.delivery || {}
        const customer = order?.customer || {}

        const payload = {
            CustomerUUID: customer?.UUID,
            UUID: getters.cart?.UUID || null,
            Number: getters.cart?.Number || null,
            Items: order.items.map((item, index) => {
                const availability = item?.Availabilities[0] || {}
                const productReference = item?.type || ''
                const isESim = item?.simType === 'eSIM'
                const characteristics = order?.entry?.Characteristics || {}
                return {
                    UUID: getters.cartItemByItemUUID(availability.UUID)?.UUID || null,
                    PriceCents: availability.RetailCents,
                    Name: `SIM Card ${index + 1}`,
                    Type: isESim ? "TYPE_VIRTUAL" : "TYPE_PRODUCT",
                    VirtualType: isESim ? "VT_ESIM" : "VT_NONE",
                    ItemUUID: availability.UUID,
                    SelfManaged: item.SelfManaged,
                    Products: [
                        {
                            UUID: getters.productUUIDByItemUUID(availability.UUID),
                            Name: `${availability.RetailName}${productReference ? `(${productReference})` : ''}`,
                            PriceCatalogCents: item.priceCatalogCents,
                            PriceCents: availability.RetailCents,
                            Type: isESim ? "TYPE_VIRTUAL" : "TYPE_PRODUCT",
                            VirtualType: isESim ? "VT_ESIM" : "VT_NONE",
                            Attributes: {
                                BillingReference: productReference, // Note: B.E will give preference to the one from config
                                MobileProductAvailabilityUUID: availability.UUID,
                                MobileProductCatalogUUID: availability.ProductCatalogEntryUUID,
                                AutoRenew: (/true/).test(characteristics?.AllowAutoRenew),
                                DiscountUUID: item.appliedOfferID || ''
                            }
                        }
                    ]
                }
            }),
            ...(
                Object.values(delivery).length > 0
                    ? {
                        Address: {
                            City: delivery.City,
                            Country: delivery.Country,
                            Postcode: delivery.PostCode,
                            Lines:  [
                                [
                                    `${delivery.NumberPre}${delivery.NumberPre ? '/' : ''}${delivery.Number}${delivery.NumberPost}`,
                                    delivery.StreetName,
                                    delivery.StreetType,
                                ].join(' ').trim(),
                                [
                                    delivery.BuildingName,
                                    delivery.Suburb,
                                ].join(' ').trim(),
                            ].filter(line => Boolean(line)),
                            LocationProvider: delivery.LocationProvider,
                            LocationProviderIDX: delivery.LocationProviderIDX,
                            Email: customer.Email,
                            Name: [customer.FirstName, customer.LastName].join(' ').trim(),
                            Phone: customer.MobileNumber,
                        }
                    }
                    : {}
            )
        }

        return payload
    },
    async SaveCart({ dispatch, commit }, order) {
        let payload = null
        await dispatch('cartPayload', order)
            .then(data => payload = data)

        return axios.post('/eshop/v2/cart', payload)
            .then(({data})=> {
                commit('mutSaveCart', data)
                commit('mutSaveCartItems', data?.Items || [])
                return Promise.resolve(data)
            })
            .catch(error => Promise.reject(error))
    },
    async Checkout({ dispatch }, order) {
        let payload = null
        await dispatch('cartPayload', order)
            .then(data => payload = data)

        return axios.post('/eshop/v2/checkout', payload)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}