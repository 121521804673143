import axios from 'axios';

const TICKETS_URL = `${process.env.VUE_APP_API_ZENDESK_HOST}/tickets`;
const TICKETS_SEARCH_URL = `${process.env.VUE_APP_API_ZENDESK_HOST}/search`;

const state = {
    tickets: {},
    count: 0,
    comments: []
};

const getters = {
    ticketByID: state => id => state.tickets[id],
    count: state => state.count,
    comments: state => state.comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
};

const mutations = {
    mutTicketsList(state, tickets){
        for (let i = 0, len = tickets.length; i < len; i++) {
            const ticket = tickets[i];
            const id = ticket.id;
            state.tickets[id] = ticket;
        }
    },

    mutTicketsCount(state, count) {
        state.count = count;
    },

    mutCommentsList(state, comments){
        state.comments = comments;
    },

    mutAddComment(state, comment) {
        state.comments.push(comment);
    },
};

const actions = {
    getTickets({ commit }, params) {
        return axios.get(TICKETS_URL, { params, noLogoutIfErrorAPI: true }).then(({apidata}) => {
            commit('mutTicketsList', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getSearchedTickets({ commit }, params) {
        const ticketType = 'type:ticket';

        const { query = '' } = params;
        params.query = `${ticketType} ${query}`

        return axios.get(TICKETS_SEARCH_URL, { params, noLogoutIfErrorAPI: true }).then(({apidata}) => {
            commit('mutTicketsList', apidata.results);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getTicketsCount({ commit }) {
        return axios.get(`${TICKETS_SEARCH_URL}/count?query=type:ticket`, { noLogoutIfErrorAPI: true }).then(({apidata}) => {
            commit('mutTicketsCount', apidata.count);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getTicketById({ }, { id, params }){
        return axios.get(`${TICKETS_URL}/${id}`, { params, noLogoutIfErrorAPI: true }).then(({apidata}) => {
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    getCommentsByTicketId({ commit, getter }, id){
        return axios.get(`${TICKETS_URL}/${id}/comments`, { noLogoutIfErrorAPI: true }).then(({apidata}) => {
            commit('mutCommentsList', apidata.comments)
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    setCommentByTicketId({ commit }, ticket){
        return axios.put(`${TICKETS_URL}/${ticket.id}`, { ticket }, { noLogoutIfErrorAPI: true }).then(({apidata}) => {
            const comment = apidata.audit.events[0];
            comment.created_at = apidata.audit.created_at;
            commit('mutAddComment', comment);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}