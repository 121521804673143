import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const USERS_URL = `${process.env.VUE_APP_API_ZENDESK_HOST}/users`;

const hashed = {
    users: {
        ids: {}, // new StoreHash(5 * 60 * 1000),
        lifetime: 5 * 60 * 1000, // 5 min
    },
};

const state = {
    users: {}
};

const getters = {
    userById(state){
        return id => {
            return state.users[id]
        }
    },
};

const mutations = {
    mutUsersList(state, users){
        for (let i = 0, len = users.results.length; i < len; i++) {
            const user = users.results[i];
            const id = user._id;

            //Проверяем есть уже такой форум в кеше
            if (!(id in hashed.users.ids)) {
                hashed.users.ids[id] = new StoreHash(hashed.users.lifetime);
            }

            state.users[id] = user;
            hashed.users.ids[id].fix();
        }
    },

    mutUserById(state, user){
        const id = user.id;
        state.users[ id ] = user;

        if (id in hashed.users.ids) {
            hashed.users.ids[id].reset(user);
        }
    },

    resetUsersCache(state) {
        hashed.users = {
            ids: {}
        }
        state.users = {}
    }
};

const actions = {
    getRequestedTickets({}, { id, params }) {
        return axios.get(`${USERS_URL}/${id}/tickets/requested`, { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getUserById({ getters, commit }, id){
        //Проверяем есть ли id уже в кеше, если нет то добавляем
        if (!(id in hashed.users.ids)) {
            hashed.users.ids[id] = new StoreHash(hashed.users.lifetime);
        }

        //Если вермя жизни хешированных данных истекло делаем запрос на сервер
        if (hashed.users.ids[id].expired()) {
            return axios.get(`${USERS_URL}/${id}`).then(({apidata}) => {
                commit('mutUserById', apidata.user);
                hashed.users.ids[id].fix();
                return Promise.resolve( getters.userById(id) )
            }).catch(error => Promise.reject(error))
        } else {
            //Иначе возвращаем геттерс
            return Promise.resolve( getters.userById(id) )
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}