import { extend } from 'vee-validate'
function confirm() {
    let confirmPhrase = 'CONFIRM'
    extend("confirm", {
        params: ['phrase'],
        validate(value, { phrase }) {
            if (phrase) {
                confirmPhrase = phrase
            }

            return confirmPhrase === value
        },
        message: field => `Enter "${confirmPhrase}"`
    })
}
export default confirm