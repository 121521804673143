import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

const MEMBER_TYPE_SELECT_ENABLED = process.env.VUE_APP_MEMBER_TYPE_SELECT_ENABLED
    ? isNaN(process.env.VUE_APP_MEMBER_TYPE_SELECT_ENABLED)
        ? process.env.VUE_APP_MEMBER_TYPE_SELECT_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_MEMBER_TYPE_SELECT_ENABLED > 0
    : false

let hashed = {
    members: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
}

const state = {
    members: {},
    members_filter: null,
}

const getters = {
    getMembersByUUID: state => uuid => state.members[uuid],

    members_filter: state => state.members_filter,

    member_type_select_enabled: () => MEMBER_TYPE_SELECT_ENABLED,
}

const mutations = {
    saveMembers(state, { members }) {
        let members_list = {}
        if (!members) {
            return
        }
        for (let n = 0, len = members.length; n < len; n++) {
            const member = members[n]

            const uuid = member.AuthRoleGroupMember.UUID

            if (!(uuid in hashed.members.uuids)) {
                hashed.members.uuids[ uuid ] = new StoreHash(hashed.members.lifetime)
            }

            members_list[ uuid ] = member

            hashed.members.uuids[ uuid ].fix()
        }

        if (Object.keys(members_list).length) {
            state.members = {
                ...state.members,
                ...members_list
            }
        }
    },

    saveMember(state, member) {
        const uuid = member.AuthRoleGroupMember ? member.AuthRoleGroupMember.UUID : member.UUID

        if (!(uuid in hashed.members.uuids)) {
            hashed.members.uuids[ uuid ] = new StoreHash(hashed.members.lifetime)
        }

        state.members = {
            ...state.members,
            [ uuid ]: member
        }

        hashed.members.uuids[ uuid ].fix()
    },

    addMemberTeam(state, { uuid, team }) {
        if (uuid in state.members) {
            const member = state.members[uuid]
            const team_index = member.Teams.findIndex(item => item.UUID == team.UUID)

            if (team_index == -1) {
                member.Teams.push(team)
            }

            state.members = {
                ...state.members,
                [ uuid ]: member
            }
        }
    },

    saveMembersFilter(state, filter) {
        state.members_filter = filter
    },

    clearMembersFilter(state) {
        state.members_filter = null
    },

    resetHashedMembers(state) {
        state.members = {}

        hashed.members.uuids = {}
    },
}

const actions = {
    getMembers({commit, dispatch}, { params, filter }) {
        return axiosUnique.get('/v3/auth_role_group_members_with_groups', { params })
            .then(response => {
                if (!response.apidata.AuthRoleGroupMembersWithGroups) {
                    response.apidata.AuthRoleGroupMembersWithGroups = []
                }
                const members = response.apidata.AuthRoleGroupMembersWithGroups

                commit('saveMembers', { members })

                const action = filter ? 'saveMembersFilter' : 'clearMembersFilter'
                const resolve = () => Promise.resolve(response.apidata)

                return dispatch(action, filter)
                    .then(resolve)
                    .catch(resolve)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

    getMemberByAuthID({}, { params }) {
        return axiosUnique.get('/v3/auth_role_group_members_with_groups', { params })
            .then(({ apidata }) => {
                return Promise.resolve(apidata)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

    getMemberByUUID({ getters, commit, dispatch }, { uuid, refresh }) {
        if (!(uuid in hashed.members.uuids)) {
            hashed.members.uuids[ uuid ] = new StoreHash(hashed.members.lifetime)
        }

        if (refresh || hashed.members.uuids[ uuid ].expired()) {
            /**
             * @todo Replace 3 corresponding lines below for real data
             */
            return import('./hardcoded-test-data/members/member-by-uuid.json').then(apidata => {
                const member = apidata.default
                uuid = member.UUID

                // return axiosUnique.get(`/v2/:spid/member/${ uuid }`).then(({apidata}) => {
                //     const member = apidata

                commit('saveMember', member)
                return Promise.resolve(getters.getMembersByUUID(uuid))
            }).catch(error => {
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.getMembersByUUID(uuid))
        }
    },

    updateMember({getters, commit}, { uuid, member }) {
        /**
         * @todo Replace for real data
         */
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commit('saveMember', member)

                resolve(getters.getMembersByUUID(uuid))
            }, 2000)
        })
    },

    getMembersOutOfTeam({commit}, { team_uuid, params }) {
        params = {
            "OutOfTeamUUID": team_uuid,
        }

        /**
         * @todo Replace corresponding line below for real data
         */
        return import('./hardcoded-test-data/members/members-out-of-team.json').then(response => {

            // return axiosUnique.get(`/v2/:spid/members`, { params }).then(response => {

            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    loadMembersFilter({getters}) {
        const filter = getters.members_filter

        return filter
            ? Promise.resolve(filter)
            : Promise.reject(filter)
    },
    saveMembersFilter({commit, getters}, filter) {
        commit('saveMembersFilter', filter)
        return Promise.resolve(getters.members_filter)
    },
    clearMembersFilter({commit, getters}) {
        commit('clearMembersFilter')
        return Promise.resolve(getters.members_filter)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}