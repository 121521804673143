import { errMessage } from '@/helpers/errMessage';

export default function errMessageApi(error, default_message = 'unknown error') {

    const messageText = error?.response?.data
        ? typeof error.response.data === 'string'
            ?   error.response.data
            :   error.response.data?.error?.message ||
                error.response.data?.err_message ||
                error.response.data?.message ||
                error.response.data?.err_field ||
                error.response.data?.description ||
                default_message
        : error
            ? error?.err_message || error?.message || default_message
            : default_message

    errMessage(messageText);
}
