<template>
    <component
        :is="tag"
        :class="['app-icon-btn', { button },  color, { rotate }]"
        :type="typeBtn"
        :disabled="disabled"
        v-on="$listeners"
    >
<!--        @click="handleClick"-->
        <inline-svg :src="require(`./images/${type}.svg`)" :width="width" :height="height"/>
    </component>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
    props: {
        params: Object,
        type: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: [String, Number],
            default: 20
        },
        height: {
            type: [String, Number],
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
        rotate: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: 'button'
        },
        button: {
            type: Boolean,
            default: false
        },
        stop: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InlineSvg
    },
    computed: {
        typeBtn() {
            return this.tag === 'button' ? 'button' : null;
        }
    },
    // methods: {
    //     handleClick() {
    //         // if (this.stop) {
    //         //     this.$emit('click.stop')
    //         //     return
    //         // }
    //         this.$emit('click', this.params)
    //     }
    // }
}
</script>

<style lang="scss">
.app-icon-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    flex: none;
    &:hover {
        opacity: 0.6;
    }
    &:disabled {
        opacity: 0.6;
        cursor: no-drop;
        //&:hover {
        //    svg {
        //        fill: $theme-color;
        //    }
        //}
    }
    svg {
        fill: $theme-color;
    }
    &.dark {
        svg {
            fill: $color-text3;
        }
    }
    &.grey {
        svg {
            fill: $color-text2;
        }
    }
    &.rotate {
        transform: rotate(180deg);
    }
    &.button {
        border: none;
        padding: 0;
        background: $btn-bg;
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        svg {
            fill: $color-text3;
        }
    }
}
@media screen and (max-width: $grid-breakpoint-md) {
    .app-icon-btn {
        &.button {
            width: 24px;
            height: 24px;
            border-radius: $border-radius-mobile;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}
</style>