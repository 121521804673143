import StoreHash from '@/utils/StoreHash';

const hashed = {
    ufbPlansAll: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    ufbPlans: [],
}

const getters = {
    ufbPlans(state) {
        return state.ufbPlans;
    }
}

const mutations = {
    mutUfbPlans(state, ufbPlans) {
        state.ufbPlans = ufbPlans;
        hashed.ufbPlansAll.fix();
    },
    resetUfbPlansCache(state) {
        state.ufbPlans = [];
        hashed.ufbPlansAll.reset();
    }
}

const actions = {
    getCachedUfbPlans({ dispatch, getters, commit }, params) {
        if (hashed.ufbPlansAll.expired()) {
            return dispatch('api_ufbprod/GetUFBPlans', params)
                .then(({UFBPlans}) => {
                    commit('mutUfbPlans', UFBPlans);
                    return Promise.resolve(UFBPlans);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
        } else {
            return Promise.resolve(getters.ufbPlans);
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}