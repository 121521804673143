<template>
    <div :class="['app-spinner', { fixed: isFixed }, { 'over-preload': isOverload }, { bordered }]">
        <b-spinner :small="small" :type="type"></b-spinner>
    </div>
</template>

<script>
export default {
    props: {
        isFixed: {
            type: Boolean,
            default: false
        },
        isBody: {
            type: Boolean,
            default: false
        },
        isOverload: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'border'
        }
    },
    mounted() {
        if (this.isBody) {
            document.body.classList.add('loading')
        }
    },
    destroyed() {
        if (this.isBody) {
            document.body.classList.remove('loading')
        }
    }
}
</script>

<style lang="scss">
.app-spinner {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    &.fixed {
        position: fixed;
    }
    &.bordered {
        border-radius: $border-radius;
    }
}
</style>