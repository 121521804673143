import { extend } from 'vee-validate'

const from = 10
const to = 990

let start = 0
let end = 0

let v = 0

function orderPriority() {
    extend("orderPriority", {
        validate(value) {
            const length = Math.round(to / from)

            const values = Array.from({ length }, (_, i) => {
                return (i + 1) * 10
            })

            if (values.includes(value)) {
                return true
            }

            const roundedValue = Math.round(value / 10) * 10
            v = value

            if (roundedValue > value) {
                start = roundedValue - 10
                end = roundedValue
            } else {
                start = roundedValue
                end = roundedValue + 10
            }

            return false
        },
        message: field => `${v >= from && v <= to ? `Invalid value. You can choose ${start} or ${end}` : `Value must be between ${from} and ${to} and divisible by 10 without a remainder`}`,
    })
}

export default orderPriority