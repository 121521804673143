import { extend } from 'vee-validate';

function simid() {
    extend("simid", {
        validate(value) {
            const regex = new RegExp(/^(\d{15,16}|\d{19})$/);
            return regex.test(value);
        },
        message: field => `${field} should contains 15 digits(IMSI) or 16 digits(SIMID) or 19 digits(ICCID)`,
    });
}

export default simid