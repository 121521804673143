const state = {
    ufbConfigStates: {
        RequiresNewRFSTime: {
            state: 'RequiresNewRFSTime',
            title: 'Book RFS',
            display: 'Book RFS',
            mode: 'RFS',
        },

        RequiresSiteVisitScopeBooking: {
            state: 'RequiresSiteVisitScopeBooking',
            title: 'Book Scoping Visit',
            display: 'Book Scoping Visit',
            mode: 'SiteVisitScope',
        },

        RequiresSiteVisitInstallBooking: {
            state: 'RequiresSiteVisitInstallBooking',
            title: 'Book Install Visit',
            display: 'Book Install Visit',
            mode: 'SiteVisitInstall',
        },

        RequiresSiteVisitInstallCSEBooking: {
            state: 'RequiresSiteVisitInstallCSEBooking',
            title: 'Book Install & CSE',
            display: 'Book Install & CSE',
            mode: 'SiteVisitInstallCSE',
        },

        RequiresSiteVisitCSEBooking: {
            state: 'RequiresSiteVisitCSEBooking',
            title: 'Book CSE Visit',
            display: 'Book CSE Visit',
            mode: 'SiteVisitCSE',
        },

        RequiresSiteVisitChangeONTBooking: {
            state: 'RequiresSiteVisitChangeONTBooking',
            title: 'Book Change ONT Visit',
            display: 'Book Change ONT Visit',
            mode: 'SiteVisitChangeONT',
        },

        RequiresSiteVisitReplaceONTBooking: {
            state: 'RequiresSiteVisitReplaceONTBooking',
            title: 'Book Site Visit',
            display: 'Book Site Visit',
            mode: 'SiteVisitReplaceONT',
        },
    },
}

const getters = {
    ufbConfigStates(state) {
        return state.ufbConfigStates;
    }
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}