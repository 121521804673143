import axios from 'axios';

const actions = {
    AddImage({}, req) {
        if (!req) { req = {} }
        return axios.post(`/v3/eshop/2/eshop_item/${req.ProductUUID}/item_image`, req.upload).then(response => {
            // Success response
            if (!response.data) { return Promise.reject(Error('no response data')) }

            return Promise.resolve(response.data)
        })
            .catch(error => {
                // Error response received from API
                if (error.response) {
                    // Server responded with a non-404 HTTP status code
                    console.log('AddImage ERROR Response:', error)
                    return Promise.reject(error)
                } else if (error.request) {
                    // Request was made but no response was received
                    console.log('AddImage ERROR making request:', error)
                    return Promise.reject(error)
                }
                // Something went wrong in setting up the request
                if (error) {
                    console.log('AddImage ERROR:', error)
                    return Promise.reject(error)
                }
                console.log('AddImage UNKNOWN ERROR')
                return Promise.reject(Error('unknown error in AddImage'))
            })
    }
}

export default {
    namespaced: true,
    actions,
};
