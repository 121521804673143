import axios from "axios"
const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const state = {}

const getters = {}

const mutations = {}

const actions = {
    GetBlackoutWindows() {
        /**
         * @todo Replace 2 corresponding lines below for real data
         */
        return import('./hardcoded-test-data/number-porting/porting.json').then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
    },
    MobileProductPortCancel({}, req) {
        return axios.put(`${API_URL_RESELLER}/admin/customer/${req.CustomerUUID}/cancel_mobile_porting`, req)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}