const tags = {
    'input': true,
}

function vSelect(Vue) {
    Vue.directive('select', {
        inserted: function (el) {
            const el_tag = el.tagName.toLowerCase()

            if (tags[el_tag]) {
                el.focus()
                el.select()
            } else {
                let child = null

                for (const tag in tags) {
                    child = el.querySelector(tag)

                    if (child) {
                        break
                    }
                }

                if (child) {
                    child.focus()
                    child.select()
                }
            }
        }
    })
}

export default vSelect