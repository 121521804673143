/**
 * The file enables `@/store/index.js` to import all vuex modules without listed in without_list
 * in a one-shot manner. There should not be any reason to edit this file.
 */

export default (without_list) => {
    const files = require.context('.', false, /\.js$/)
    const modules = {}

    files.keys().forEach(filename => {
        if (filename != './index.js'
            && (!without_list || without_list.findIndex(rule => {
                let is_matched = false

                if (rule instanceof RegExp) {
                    is_matched = rule.test(filename)
                    rule.lastIndex = 0
                } else {
                    is_matched = filename == `./${ rule }.js`
                }

                return is_matched
            }) === -1)
        ) {
            modules[filename.replace(/(\.\/|\.js)/g, '')] = files(filename).default
        }
    })

    return modules
}
