import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const TIME_ZONE = process.env.VUE_APP_TIME_ZONE || 'Etc/UTC'
const SENTRY_SAMPLE_RATE = process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.10

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import vSelect from "vue-select"
Vue.component('v-select', vSelect)

import stylesCSS from '@/assets/scss/styles.scss'

import axiosMiddleware from '@/middlewares/axios-middleware'

import axios from 'axios'

import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.use(axiosMiddleware, axios, store, router)
Vue.prototype.$axios = axios

import directives from '@/directives'
Vue.use(directives)

// Vue Validator
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
localize('en', en)
// install rules
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
});
import validators from '@/validators'
Vue.use(validators)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Sweetalert
import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2)

import VueEllipseProgress from 'vue-ellipse-progress'
Vue.use(VueEllipseProgress)

// Moment
// import moment from 'moment-timezone'
// moment.tz.setDefault(TIME_ZONE)
//
// Vue.use(require('vue-moment'), {
//     moment
// })

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/en'
Vue.use(DatePicker)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

// Sidebar modal
import sidebarModal from '@/mixins/sidebarModal'

import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

const isSentryReporting = store.getters.isSentryReporting
if (isSentryReporting) {
    let environment = store.getters.environment
    if (store.getters.sentryENV) {
        environment = store.getters.sentryENV
    }
    const host = store.getters.host
    const dsn = store.getters.sentryDSN
    Sentry.init({
        Vue,
        dsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [host, /^\//]
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: SENTRY_SAMPLE_RATE,
        environment
    })
}

Vue.config.productionTip = false

store.dispatch('loadAuthInfo').then(()=>{}).catch(()=>{})

const THEME = store.getters.theme
const load = async () => {
    const themeFonts = await import(/* webpackChunkName: "Fonts" */ '@themeFonts/fonts.scss')

    store.dispatch('refreshAuthJWTByReload')
        .then(() => {
            new Vue({
                mixins: [sidebarModal],
                router,
                store,
                themeFonts,
                stylesCSS,
                render: h => h(App)
            }).$mount('#app')
        })
        .catch(() => {
            console.log('Refresh Auth Bt Reload failed')
        })
}

load()
