import axios from 'axios'
import StoreHash from '@/utils/StoreHash'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST


const hashed = {
    mobileAddonPlans: new StoreHash(3 * 60 * 1000),
    mobileAddons: new StoreHash(3 * 60 * 1000),
    mobileAddonsWithCharacteristics: new StoreHash(3 * 60 * 1000),
}

const state = {
    mobileAddonPlans: {},
    mobileAddons: {},
    mobileAddonsWithCharacteristics: {},
}

const getters = {
    mobileAddonPlans: state => Object.values(state.mobileAddonPlans),
    mobileAddonPlansByUUID: state => uuid => state.mobileAddonPlans[uuid],
    mobileAddons: state => Object.values(state.mobileAddons),
    mobileAddonsByUUID: state => uuid => state.mobileAddons[uuid],
    mobileAddonsWithCharacteristics: state => Object.values(state.mobileAddonsWithCharacteristics),
    mobileAddonBasePlans: (state, getters) => getters.mobileAddonPlans.filter(addon => addon.IsBasePlan)
}

const mutations = {
    mutMobileAddonPlans(state, mobileAddonPlans) {
        const result = {}

        mobileAddonPlans.forEach(mobileAddonPlan => {
            result[mobileAddonPlan.UUID] = mobileAddonPlan
        })
        state.mobileAddonPlans = result
        hashed.mobileAddonPlans.fix()
    },
    resetMobileAddonPlansCache(state) {
        hashed.mobileAddonPlans.reset()
        state.mobileAddonPlans = {}
    },
    mutMobileAddons(state, mobileAddons) {
        const result = {}

        mobileAddons.forEach(mobileAddon => {
            const addon = mobileAddon?.ProductCatalogAvailability
            const addonUUID = addon?.UUID
            if (addon && addonUUID) {
                result[addonUUID] = addon
            }
        })
        state.mobileAddons = result
        hashed.mobileAddons.fix()
    },
    mutMobileAddonsWithCharacteristics(state, mobileAddons) {
        const result = {}

        mobileAddons.forEach(mobileAddon => {
            const addonUUID = mobileAddon?.ProductCatalogAvailability?.UUID
            if (addonUUID) {
                result[addonUUID] = mobileAddon
            }
        })
        state.mobileAddonsWithCharacteristics = result
        hashed.mobileAddonsWithCharacteristics.fix()
    },
    resetMobileAddonsCache(state) {
        hashed.mobileAddons.reset()
        hashed.mobileAddonPlans.reset()
        state.mobileAddons = {}
        state.mobileAddonPlans = {}
    }
}

const actions = {
    GetAllCaсhedMobileAddonPlans({dispatch, getters, commit}, params) {
        const refresh = params?.refresh
        const payload = {
            'SearchOptions.PageSize': -1
        }
        if (hashed.mobileAddonPlans.expired() || refresh) {
            return dispatch('api_mobile/FindMobileAddonPlans', payload, { root: true })
                .then(response => {
                    if (response?.MobileAddonPlans) {
                        const addons = response.MobileAddonPlans.filter(addon => {
                            if ((/true/).test(addon?.IsBasePlan) || (/true/).test(addon?.IsAddon)) {
                                return true
                            }
                            return false
                        })
                        commit('mutMobileAddonPlans', addons)
                        return Promise.resolve(addons)
                    }
                    return Promise.resolve([])
                })
                .catch(error => Promise.reject(error))
        }
        return Promise.resolve(getters.mobileAddonPlans)
    },
    FindMobileAddonsPaginated({ dispatch, getters, rootGetters }, params) {
        const payload = {
            SPID: rootGetters.spid,
            ProductType: 'mobileAddon',
            ...params
        }
        return dispatch('api_product_catalog/FindProductCatalogEntriesWithDetailsPaginated', payload, { root: true })
            .then(response => {
                if (response) {
                    return Promise.resolve(response)
                }
                return Promise.reject(new Error('No MobileAddons'))
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    GetAllCaсhedMobileAddons({dispatch, getters, commit}, params) {
        const refresh = params?.refresh
        const payload = {
            'SearchOptions.PageSize': -1
        }
        if (hashed.mobileAddons.expired() || refresh) {
            return dispatch('FindMobileAddonsPaginated', payload)
                .then(response => {
                    commit('mutMobileAddons', response?.ProductCatalogEntryWithDetailsItems || [])
                    commit('mutMobileAddonsWithCharacteristics', response?.ProductCatalogEntryWithDetailsItems || [])
                    return Promise.resolve(response.ProductCatalogEntryWithDetailsItems)
                })
                .catch(error => Promise.reject(error))
        }
        return Promise.resolve(getters.mobileAddons)
    },
    LegacyAdminTerminateAddon({}, req) {
        return axios.post(`${API_URL_RESELLER}/admin/customer/addon-termination`, req)
            .then(response => {
                if (!response.data) { return Promise.reject(Error('no response data')) }
                return Promise.resolve(response.data)
            })
            .catch(error => Promise.reject(error))
    },
    PurchaseAddon({}, req) {
        return axios.post(`${API_URL_RESELLER}/postpay/addon`, req)
            .then(response => {
                if (!response.data) { return Promise.reject(Error('no response data')) }
                return Promise.resolve(response.data)
            })
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}