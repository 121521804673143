/**
 * The file enables `@/main.js` to import all directives
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.js$/)

function validators(Vue) {
    files.keys().forEach(key => {
        if (key === './index.js') return
        Vue.use(files(key).default)
    })
}

export default validators