import axios from 'axios'

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST
const CHANGE_MOBILE_PLAN_MODE = process.env.VUE_APP_CHANGE_MOBILE_PLAN_MODE || 'NOW'

const TYPES = {
    postpay: 'PostPay',
    prepay: 'PrePay',
    payg: 'PAYG'
}

const state = {
    mobiles: {},
    statuses: ['created','inprogress','preactivated','','active','terminated','expired'],
}

const getters = {
    changeMobilePlanMode: () => CHANGE_MOBILE_PLAN_MODE,
    isNowChangeMobilePlanMode: (state, getters) => getters.changeMobilePlanMode === 'NOW',
    isLaterChangeMobilePlanMode: (state, getters) => getters.changeMobilePlanMode === 'LATER',
    isBothChangeMobilePlanMode: (state, getters) => getters.changeMobilePlanMode === 'BOTH',
    isNoneChangeMobilePlanMode: (state, getters) => getters.changeMobilePlanMode === 'NONE',
    mobilesByCustomerUUID: state => uuid => state.mobiles[uuid],
    statusByIndex: state => index => state.statuses[index],
    type: state => type => TYPES[type] || null
}

const mutations = {
    mutMobilesByCustomerUUID(state, { customerUUID, mobiles }) {
        state.mobiles[customerUUID] = mobiles
    }
}

const actions = {
    ChangeAddonAutoRenew({}, params) {
        return axios.post(`${API_URL_RESELLER}/update/change-addon-auto-renew`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    },
    RedeemVoucher({}, params) {
        const CustomerUUID = params?.CustomerUUID
        const ProductInstanceUUID = params?.ProductInstanceUUID
        const VoucherCode = params?.VoucherCode
        return axios.post(`${API_URL_RESELLER}/customer/${CustomerUUID}/redeem_voucher/${ProductInstanceUUID}/${VoucherCode}`, params).then(({data}) => {
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}