import { extend } from 'vee-validate';

function templateID() {
    extend("templateID", {
        validate(value) {
            return value >= 1000
        },
        message: field => `${ field } must be >= 1000`,
    });
}

export default templateID