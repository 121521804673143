import axios from "axios"
import ordinalSuffixes from "@/helpers/ordinalSuffixes"

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const state = {
    entryPrices: []
}

const getters = {
    entryPrices: state => state.entryPrices
}

const mutations = {
    mutEntryPrices(state, data) {
        let { Entries = [], Prices } = data

        Entries = Entries.filter(entry => {
            return (/true/).test(entry?.Characteristics?.IsBasePlan)
        })

        Entries.forEach(entry => {
            entry.priceCatalogCents = entry.Availabilities?.[0]?.RetailCents
        })

        if (Prices && Prices.length) {
            Prices.forEach((priceItem, index) => {
                const hasDiscount = priceItem.DiscountedPrice < priceItem.OrigianlPrice
                const hasOfferCredit = Boolean(priceItem.CreditPrice)
                const hasSpecialOffersToShow = priceItem.AmountInPeriod?.length > 1

                if (hasDiscount || hasOfferCredit || hasSpecialOffersToShow) {
                    const entry = Entries.find(entry => entry.Availabilities?.[0]?.UUID == priceItem.ProductID)

                    if (entry) {
                        entry.appliedOfferID = priceItem?.AppliedOfferID

                        if (hasDiscount) {
                            entry.Availabilities[0].RetailCents = priceItem.DiscountedPrice
                            entry.origianlPrice = priceItem.OrigianlPrice
                            entry.discount = priceItem.OrigianlPrice - priceItem.DiscountedPrice
                            entry.discountPrice = entry.origianlPrice - entry.discount
                        }

                        if (hasOfferCredit) {
                            entry.offerCredit = priceItem.CreditPrice
                        }

                        if (hasSpecialOffersToShow) {
                            entry.specialOffersToShow = priceItem.AmountInPeriod.map((offers, index) => {
                                let offer = ''
                                let amount = ''

                                if (offers.DiscountedAmount) {
                                    offer = 'price'
                                    amount = ((priceItem.OrigianlPrice - offers.DiscountedAmount) / 100).toFixed(2)
                                } else {
                                    offer = 'credit'
                                    amount = (offers.CreditAmount / 100).toFixed(2)
                                }

                                return {
                                    text: `${ordinalSuffixes(index + 1)} period ${offer}:`,
                                    amount: `$${amount}`
                                }
                            })
                        }
                    }
                }
            })
        }
        state.entryPrices = Entries
    }
}

const actions = {
    GetEntries({ commit }, params) {
        const payload = {
            ...params,
            ProductTypeList: ['mobileAddon'],
            SearchOptions: {
                PageSize: 1000
            }
        }
        return axios.post(`${API_URL_RESELLER}/catalog/v2/entries`, payload).then(({data}) => {
            commit('mutEntryPrices', data)
            return Promise.resolve(data)
        }).catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}