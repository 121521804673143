<template>
    <div class="app-page-title">
        <div class="h1">{{title}}</div>
        <div v-if="subtitle" class="subtitle">
            <app-back-to
                v-if="backTo"
                :title="subtitle"
                :name="backTo"
                :page="page"
            />
            <template v-else>{{subtitle}}</template>
        </div>
    </div>
</template>

<script>
import AppBackTo from "@/components/app-back-to"
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            default: ''
        },
        backTo: {
            type: String,
            default: ''
        },
        page: {
            type: Number | String,
            default: 1
        }
    },
    components: {
        AppBackTo
    },
}
</script>

<style lang="scss">
.app-page-title {
    margin-bottom: 40px;
    padding-right: 300px;
    .h1 {
        margin: 0;
    }

    .subtitle {
        font-size: 16px;
        color: $color-text;
        margin: 4px 0 0;
        font-weight: normal;

        &:empty {
            display: none;
        }
    }
}
@media screen and (max-width: $grid-breakpoint-lg) {
    .app-page-title {
        padding: 0;
        margin-bottom: 20px;
    }
}
@media screen and (max-width: $grid-breakpoint-md) {
    .app-page-title {
        font-size: 32px;
        margin-bottom: 10px;
        .subtitle {
            font-size: 14px;
        }
    }
}
</style>