import StoreHash from '@/utils/StoreHash'

const hashed = {
    authRoleGroups: new StoreHash(5 * 60 * 1000), // 5 min
    teamsRoles: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    authRoleGroups: {},
    teamsRoles: {},
}

const getters = {
    getTeamByUUID: state => uuid => state.authRoleGroups[uuid],
    authRoleGroups: state => state.authRoleGroups,
    teamsRoles: state => state.teamsRoles,
}

const mutations = {
    saveAuthRoleGroups(state, authRoleGroups) {
        state.authRoleGroups = {}
        for (let i = 0; i < authRoleGroups.length; i++) {
            const authRoleGroup = authRoleGroups[i]
            const uuid = authRoleGroup.UUID

            state.authRoleGroups[uuid] = authRoleGroup
            hashed.authRoleGroups.fix()
        }
    },

    saveTeamsRoles(state, roles) {
        let teamsRoles = {}

        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i]

            teamsRoles[ role.Name ] = role
        }

        state.teamsRoles = teamsRoles
    },

    resetTeamsCache(state) {
        hashed.authRoleGroups.reset()
        hashed.teamsRoles.reset()
        state.authRoleGroups = {}
        state.teamsRoles = {}
    }
}

const actions = {
    GetCachedAllAuthRoleGroups({ getters, dispatch, commit }) {
        if (hashed.authRoleGroups.expired()) {
            const payload = {
                SPID: getters.spid,
                IncludeDeleted: false,
                'SearchOptions.PageSize': -1,
            }

            return dispatch('api_auth/FindAuthRoleGroupsPaginated', payload, { root:true })
                .then(response => {
                    if (response?.AuthRoleGroups && response.AuthRoleGroups.length > 0) {
                        const authRoleGroups = response.AuthRoleGroups
                        commit('saveAuthRoleGroups', authRoleGroups)
                        return Promise.resolve(authRoleGroups)
                    }
                    return Promise.resolve([])
                })
                .catch(error => Promise.reject(error))
        }
        return Promise.resolve(getters.teams)
    },
    AddCachedAuthRoleGroup({ dispatch }, params) {
        return dispatch('api_auth/AddAuthRoleGroup', params, { root:true })
            .then(response => {
                hashed.authRoleGroups.reset()
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    },
    UpdateCachedAuthRoleGroup({ dispatch }, params) {
        return dispatch('api_auth/UpdateAuthRoleGroup', params, { root:true })
            .then(response => {
                hashed.authRoleGroups.reset()
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    },
    DeleteCachedAuthRoleGroup({ dispatch }, { UUID }) {
        return dispatch('api_auth/DeleteAuthRoleGroup', { UUID }, { root:true })
            .then(response => {
                hashed.authRoleGroups.reset()
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    },
    GetTeamsRoles({ getters, commit }) {
        /**
         * @todo Replace corresponding lines below for real data
         */

        if (hashed.teamsRoles.expired()) {
            return import('./hardcoded-test-data/teams/teams-roles.json')
                .then(({ Roles }) => {
                    commit('saveTeamsRoles', Roles)
                    return Promise.resolve(getters.teamsRoles)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        }
        return Promise.resolve(getters.teamsRoles)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}