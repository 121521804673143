// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: simdb.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	SIMS: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	SIMSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	SIMRanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getSIMByUUID: state => (UUID) => (state.SIMS.UUIDs[ UUID ] && !state.SIMS.UUIDs[ UUID ].expired()) ?  state.SIMS.UUIDs[ UUID ].hashedData() : null,
	getSIMSForSearchText: state => (SearchText) => state.SIMSearches[ SearchText ] ? state.SIMSearches[ SearchText ] : null,
	getSIMRangeByUUID: state => (UUID) => (state.SIMRanges.UUIDs[ UUID ] && !state.SIMRanges.UUIDs[ UUID ].expired()) ?  state.SIMRanges.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveSIM saves a single SIM object
	// into both the StoreHash cache and the VueX store
	mutSaveSIM(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.SIMS.UUIDs)) {
			state.SIMS.UUIDs[ obj.UUID ] = new StoreHash(state.SIMS.lifetime)
		}
		state.SIMS.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSIMByUUID removes a SIM object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSIMByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.SIMS.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSIMSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveSIMSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) { return } // Must have SearchText to Save and Response object
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.SIMS)) {
			for (let i = 0; i < Response.SIMS.length; i++) {
				storedObj.UUIDs.push(Response.SIMS[i].UUID)
			}
		}
		state.SIMSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.SIMSearches.SearchTexts)) {
			state.SIMSearches.SearchTexts[ SearchText ] = new StoreHash(state.SIMSearches.lifetime)
		}
		state.SIMSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveSIMSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveSIMSearch(state, SearchText) {
		if (!SearchText) { return } // Must have SearchText to Remove
		state.SIMSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.SIMSearches[ SearchText ]               // remove item from VueX state
		delete state.SIMSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveSIMS saves a paginated response of SIMS
	mutSaveSIMS(state, { Response }) {
		if (!Response || !Array.isArray(Response.SIMS)) { return } // Must have Response object with results
		for (let i = 0; i < Response.SIMS.length; i++) {
			if (!(Response.SIMS[i].UUID in state.SIMS.UUIDs)) {
				state.SIMS.UUIDs[ Response.SIMS[i].UUID ] = new StoreHash(state.SIMS.lifetime)
			}
			state.SIMS.UUIDs[ Response.SIMS[i].UUID ].fix(Response.SIMS[i])  // Add single SIM to StoreHash
		}
	},
	// mutSaveSIMRange saves a single SIMRange object
	// into both the StoreHash cache and the VueX store
	mutSaveSIMRange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.SIMRanges.UUIDs)) {
			state.SIMRanges.UUIDs[ obj.UUID ] = new StoreHash(state.SIMRanges.lifetime)
		}
		state.SIMRanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveSIMRangeByUUID removes a SIMRange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveSIMRangeByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.SIMRanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveSIMRanges saves a paginated response of SIMRanges
	mutSaveSIMRanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.SIMRanges)) { return } // Must have Response object with results
		for (let i = 0; i < Response.SIMRanges.length; i++) {
			if (!(Response.SIMRanges[i].UUID in state.SIMRanges.UUIDs)) {
				state.SIMRanges.UUIDs[ Response.SIMRanges[i].UUID ] = new StoreHash(state.SIMRanges.lifetime)
			}
			state.SIMRanges.UUIDs[ Response.SIMRanges[i].UUID ].fix(Response.SIMRanges[i])  // Add single SIMRange to StoreHash
		}
	},
};

const actions = {
	// getCachedSIMByUUID fetches a SIM from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.SIMS.UUIDs[ UUID ] && !state.SIMS.UUIDs[ UUID ].expired()) {
			return state.SIMS.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetSIMByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSIM', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedSIMSBySearch fetches SIMS from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMSBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.SIMSearches.SearchTexts[ SearchText ] && !state.SIMSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getSIMSForSearchText(SearchText)
			resp.SIMS = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.SIMS.push(getters.getSIMByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindSIMSPaginated', apiReq).then(respPayload => {
			commit('mutSaveSIMSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedSIMRangeByUUID fetches a SIMRange from the cache
	// and if not in the cache, fetches it from the API
	getCachedSIMRangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.SIMRanges.UUIDs[ UUID ] && !state.SIMRanges.UUIDs[ UUID ].expired()) {
			return state.SIMRanges.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetSIMRangeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveSIMRange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveSIMRangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: SIMDBRPC
	//
	/**
	 * RPC Method: AddSIM
	 * Description: This endpoint allows for adding a new SIM record for the provided Service Provider.
	 * HTTP Method: POST
	 * API Path: /api/v1/${req.SPID}/SIM
	 * @param    {SIM} req The API payload object (SIM)
	 * @return   {SIM} The API response object (SIM)
	 *
	 * @typedef  {Object}  SIM
	 * @property {String}  UUID                 Universally Unique Identifier of the SIM record
	 * @property {Number}  SPID                 AllocateESIM uses the given SPID unique allocation ID to allocate an ESIM, ,  and until the ESIM has been installed, duplicate calls to DownloadESIM will,  normally return the same ESIM, this function is idempotent and safe to call ,  concurrently. The returned ESIM has the required information to be installed,  on the end-user's mobile device.
	 * @property {Boolean} Enabled              Whether the SIM is enabled or not
	 * @property {String}  ServiceInstanceUUID  Reference to the SIM's ServiceInstance
	 * @property {Number}  CreatedAtNanos       When the SIM entry was created
	 * @property {Number}  UpdatedAtNanos       When the SIM entry was updated
	 * @property {Number}  DeletedAtNanos       When the SIM entry was deleted
	 * @property {String}  SIMID                This is the SIMID
	 * @property {String}  IMSI                 The International Mobile Subscriber Identity programmed on the SIM
	 * @property {StringSlice} Tags                 Tags associated with the SIM
	 * @property {Boolean} Active               Whether this SIM is in service or not
	 * @property {String}  ICCID                Integrated Circuit Card Identification Number
	 * @property {String}  PreAllocatedMSISDN   This is the (optional) pre-allocated MSISDN
	 * @property {Boolean} Reserved             Whether this SIM is reserved or not (for example for pre-activation)
	 * @property {Boolean} IsEsim               Whether this SIM is an esim or a physical sim
	 * @property {String}  SIMRangeUUID         SIMRange association UUID
	 * @property {String}  ExternalAllocationID ExternalAllocationID is a SPID-unique ID, chosen by the SPID that associates this SIM to,  an another entity during allocation. Within MVNE, this will usually be a customer ID.
	 *
	 */
	AddSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v1/${req.SPID}/SIM`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddSIM'))
			})
	},

	/**
	 * RPC Method: AddSIMRange
	 * Description: This endpoint allows for adding a new SIM Range for the provided Service Provider.
	 * HTTP Method: POST
	 * API Path: /api/v3/sim_range
	 * @param    {SIMRange} req The API payload object (SIMRange)
	 * @return   {SIMRange} The API response object (SIMRange)
	 *
	 * @typedef  {Object}  SIMRange
	 * @property {String}  UUID           Unique identifier
	 * @property {Number}  SPID           Service provider ID
	 * @property {Number}  CreatedAtNanos When the SIM range was created
	 * @property {Number}  UpdatedAtNanos When the SIM range was updated
	 * @property {Number}  DeletedAtNanos When the SIM range was deleted
	 * @property {String}  ALGID
	 * @property {String}  KBDID
	 * @property {String}  ACSUB
	 * @property {String}  STK
	 * @property {String}  SIMRangeStart
	 * @property {String}  SIMRangeEnd
	 * @property {String}  IMSIRangeStart
	 * @property {String}  IMSIRangeEnd
	 *
	 */
	AddSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/sim_range`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddSIMRange'))
			})
	},

	/**
	 * RPC Method: AllocateESIM
	 * Description: Download and/or allocate an ESIM for the given SPID-unique ExternalAllocationID (ie. use the customer's ID). Until the ESIM has been installed, the same ESIM will normally be returned for the same ExternalAllocationID. Once an ExternalAllocationID has been associated with an ESIM, it cannot be changed, and DownloadESIM garuantees to never return the same ESIM to other callers with any other ExternalAllocationID.
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/ESIM/${req.ID}
	 * @param    {ExternalAllocation} req The API payload object (ExternalAllocation)
	 * @return   {ESIM} The API response object (ESIM)
	 *
	 * @typedef  {Object}  ExternalAllocation
	 * @property {Number}  SPID
	 * @property {String}  ID
	 *
	 * @typedef  {Object}  ESIM
	 * @property {String}  ID
	 * @property {String}  Data             Text data that can be scanned by the device to begin installation (this data needs ,  to be presented as a QR code). Several web-based client-side packages exist for this,,  see https://github.com/soldair/node-qrcode or https://github.com/davidshimjs/qrcodejs
	 * @property {String}  ConfirmationCode
	 *
	 */
	AllocateESIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramID = encodeURIComponent(req.ID)
		return axios.get(`/v1/${paramSPID}/ESIM/${paramID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AllocateESIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AllocateESIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AllocateESIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AllocateESIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AllocateESIM'))
			})
	},

	/**
	 * RPC Method: DeleteSIMRange
	 * Description: This endpoint allows for deleting an existing SIM Range.
	 * HTTP Method: DELETE
	 * API Path: /api/v3/sim_range/${req.UUID}
	 * @param    {SIMRangeUUIDSearch} req The API payload object (SIMRangeUUIDSearch)
	 * @return   {SIMRange} The API response object (SIMRange)
	 *
	 * @typedef  {Object}  SIMRangeUUIDSearch
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 *
	 * @typedef  {Object}  SIMRange
	 * @property {String}  UUID           Unique identifier
	 * @property {Number}  SPID           Service provider ID
	 * @property {Number}  CreatedAtNanos When the SIM range was created
	 * @property {Number}  UpdatedAtNanos When the SIM range was updated
	 * @property {Number}  DeletedAtNanos When the SIM range was deleted
	 * @property {String}  ALGID
	 * @property {String}  KBDID
	 * @property {String}  ACSUB
	 * @property {String}  STK
	 * @property {String}  SIMRangeStart
	 * @property {String}  SIMRangeEnd
	 * @property {String}  IMSIRangeStart
	 * @property {String}  IMSIRangeEnd
	 *
	 */
	DeleteSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/sim_range/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveSIMRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteSIMRange'))
			})
	},

	/**
	 * RPC Method: FindSIMSAvailablePaginated
	 * Description: This endpoint allows for fetching of multiple Available and Enabled SIMs, optionally filtered by IMSI, SIMID, ICCID and Tags.
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/SIMSAvailable
	 * @param    {SIMSearchRequest} req The API payload object (SIMSearchRequest)
	 * @return   {SIMPageResponse} The API response object (SIMPageResponse)
	 *
	 * @typedef  {Object}  SIMSearchRequest
	 * @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID                Internal Identifier of the Service Provider making the request (mandatory)
	 * @property {String}  UUID                Universally Unique Identifier of the SIM record
	 * @property {String}  SIMID               Number on the SIM Card
	 * @property {String}  IMSI                International Mobile Subscriber Identity associated with the SIM Card
	 * @property {String}  SmartSearch         Smart Search Field
	 * @property {String}  ServiceInstanceUUID Reference to the SIM's Service Instance record
	 * @property {String}  Tags                Tags associated with the SIM
	 * @property {Boolean} Enabled             Filter for whether the SIM is enabled
	 * @property {Boolean} Active              Filter for whether the SIM is active
	 * @property {Boolean} IncludeDeleted      Option for whether to include previously deleted records
	 * @property {String}  ICCID               Filter for Integrated Circuit Card Identification Number
	 * @property {Boolean} IsEsim              Filter for whether the SIM is eSim
	 *
	 * @typedef  {Object}  SIMPageResponse
	 * @property {PageInfo} PageInfo Information about the page
	 * @property {SIM[]} SIMS     List of SIM entries that match the search request
	 *
	 */
	FindSIMSAvailablePaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/SIMSAvailable`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindSIMSAvailablePaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindSIMSAvailablePaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindSIMSAvailablePaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindSIMSAvailablePaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindSIMSAvailablePaginated'))
			})
	},

	/**
	 * RPC Method: FindSIMSPaginated
	 * Description: This endpoint allows for fetching of multiple SIMs, optionally filtered by IMSI, SIMID, ICCID and Tags.
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/SIM
	 * @param    {SIMSearchRequest} req The API payload object (SIMSearchRequest)
	 * @return   {SIMPageResponse} The API response object (SIMPageResponse)
	 *
	 * @typedef  {Object}  SIMSearchRequest
	 * @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	 * @property {Number}  SPID                Internal Identifier of the Service Provider making the request (mandatory)
	 * @property {String}  UUID                Universally Unique Identifier of the SIM record
	 * @property {String}  SIMID               Number on the SIM Card
	 * @property {String}  IMSI                International Mobile Subscriber Identity associated with the SIM Card
	 * @property {String}  SmartSearch         Smart Search Field
	 * @property {String}  ServiceInstanceUUID Reference to the SIM's Service Instance record
	 * @property {String}  Tags                Tags associated with the SIM
	 * @property {Boolean} Enabled             Filter for whether the SIM is enabled
	 * @property {Boolean} Active              Filter for whether the SIM is active
	 * @property {Boolean} IncludeDeleted      Option for whether to include previously deleted records
	 * @property {String}  ICCID               Filter for Integrated Circuit Card Identification Number
	 * @property {Boolean} IsEsim              Filter for whether the SIM is eSim
	 *
	 * @typedef  {Object}  SearchOptions  Search options
	 * @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	 * @property {Number}  PageSize       The page size
	 * @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	 * @property {String}  SortBy         The field to sort by
	 * @property {Boolean} SortDesc       Is the sort direction descending?
	 *
	 * @typedef  {Object}  SIMPageResponse
	 * @property {PageInfo} PageInfo Information about the page
	 * @property {SIM[]} SIMS     List of SIM entries that match the search request
	 *
	 * @typedef  {Object}   PageInfo
	 * @property {Number}   PageNumber             The page number
	 * @property {Number}   PageItemCount          Number of items on this page
	 * @property {Number}   TotalPages             Total pages available
	 * @property {Number}   TotalItemCount         Total items available
	 * @property {Number}   IndexStart             The record number this search started at
	 * @property {Number}   PageSize               The length of the page
	 * @property {Number[]} DisplayPageList        The array of page numbers to display
	 * @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	 *
	 */
	FindSIMSPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v1/${paramSPID}/SIM`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveSIMS', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindSIMSPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindSIMSPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindSIMSPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindSIMSPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindSIMSPaginated'))
			})
	},

	/**
	 * RPC Method: GetSIMByUUID
	 * Description: This endpoint allows for fetching of a single SIM record.
	 * HTTP Method: GET
	 * API Path: /api/v1/${req.SPID}/SIM/${req.UUID}
	 * @param    {SIMUUIDSearch} req The API payload object (SIMUUIDSearch)
	 * @return   {SIM} The API response object (SIM)
	 *
	 * @typedef  {Object}  SIMUUIDSearch
	 * @property {Number}  SPID Internal Identifier of Service Provider making the search request
	 * @property {String}  UUID Universally Unique Identifier of the SIM record
	 *
	 * @typedef  {Object}  SIM
	 * @property {String}  UUID                 Universally Unique Identifier of the SIM record
	 * @property {Number}  SPID                 AllocateESIM uses the given SPID unique allocation ID to allocate an ESIM, ,  and until the ESIM has been installed, duplicate calls to DownloadESIM will,  normally return the same ESIM, this function is idempotent and safe to call ,  concurrently. The returned ESIM has the required information to be installed,  on the end-user's mobile device.
	 * @property {Boolean} Enabled              Whether the SIM is enabled or not
	 * @property {String}  ServiceInstanceUUID  Reference to the SIM's ServiceInstance
	 * @property {Number}  CreatedAtNanos       When the SIM entry was created
	 * @property {Number}  UpdatedAtNanos       When the SIM entry was updated
	 * @property {Number}  DeletedAtNanos       When the SIM entry was deleted
	 * @property {String}  SIMID                This is the SIMID
	 * @property {String}  IMSI                 The International Mobile Subscriber Identity programmed on the SIM
	 * @property {StringSlice} Tags                 Tags associated with the SIM
	 * @property {Boolean} Active               Whether this SIM is in service or not
	 * @property {String}  ICCID                Integrated Circuit Card Identification Number
	 * @property {String}  PreAllocatedMSISDN   This is the (optional) pre-allocated MSISDN
	 * @property {Boolean} Reserved             Whether this SIM is reserved or not (for example for pre-activation)
	 * @property {Boolean} IsEsim               Whether this SIM is an esim or a physical sim
	 * @property {String}  SIMRangeUUID         SIMRange association UUID
	 * @property {String}  ExternalAllocationID ExternalAllocationID is a SPID-unique ID, chosen by the SPID that associates this SIM to,  an another entity during allocation. Within MVNE, this will usually be a customer ID.
	 *
	 */
	GetSIMByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/SIM/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveSIM', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMByUUID'))
			})
	},

	/**
	 * RPC Method: GetSIMRangeByUUID
	 * Description: This endpoint allows for fetching of a single SIM Range.
	 * HTTP Method: GET
	 * API Path: /api/v3/sim_range/${req.UUID}
	 * @param    {SIMRangeUUIDSearch} req The API payload object (SIMRangeUUIDSearch)
	 * @return   {SIMRange} The API response object (SIMRange)
	 *
	 * @typedef  {Object}  SIMRangeUUIDSearch
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 *
	 * @typedef  {Object}  SIMRange
	 * @property {String}  UUID           Unique identifier
	 * @property {Number}  SPID           Service provider ID
	 * @property {Number}  CreatedAtNanos When the SIM range was created
	 * @property {Number}  UpdatedAtNanos When the SIM range was updated
	 * @property {Number}  DeletedAtNanos When the SIM range was deleted
	 * @property {String}  ALGID
	 * @property {String}  KBDID
	 * @property {String}  ACSUB
	 * @property {String}  STK
	 * @property {String}  SIMRangeStart
	 * @property {String}  SIMRangeEnd
	 * @property {String}  IMSIRangeStart
	 * @property {String}  IMSIRangeEnd
	 *
	 */
	GetSIMRangeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/sim_range/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveSIMRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMRangeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMRangeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMRangeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMRangeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMRangeByUUID'))
			})
	},

	/**
	 * RPC Method: GetSIMRangesPaginated
	 * Description: This endpoint allows for fetching of multiple SIM Ranges, optionally filtered by ALGID, KBDID, ACSUB and STK.
	 * HTTP Method: GET
	 * API Path: /api/v3/sim_range
	 * @param    {SIMRangeSearchRequest} req The API payload object (SIMRangeSearchRequest)
	 * @return   {SIMRangePageResponse} The API response object (SIMRangePageResponse)
	 *
	 * @typedef  {Object}  SIMRangeSearchRequest
	 * @property {SearchOptions[]} SearchOptions
	 * @property {String}  UUID
	 * @property {Number}  SPID
	 * @property {String}  ALGID
	 * @property {String}  KBDID
	 * @property {String}  ACSUB
	 * @property {String}  STK
	 * @property {Boolean} IncludeDeleted
	 *
	 * @typedef  {Object}  SearchOptions  Search options
	 * @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	 * @property {Number}  PageSize       The page size
	 * @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	 * @property {String}  SortBy         The field to sort by
	 * @property {Boolean} SortDesc       Is the sort direction descending?
	 *
	 * @typedef  {Object}  SIMRangePageResponse
	 * @property {PageInfo} PageInfo
	 * @property {SIMRange[]} SIMRanges
	 *
	 * @typedef  {Object}   PageInfo
	 * @property {Number}   PageNumber             The page number
	 * @property {Number}   PageItemCount          Number of items on this page
	 * @property {Number}   TotalPages             Total pages available
	 * @property {Number}   TotalItemCount         Total items available
	 * @property {Number}   IndexStart             The record number this search started at
	 * @property {Number}   PageSize               The length of the page
	 * @property {Number[]} DisplayPageList        The array of page numbers to display
	 * @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	 *
	 */
	GetSIMRangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/sim_range`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveSIMRanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSIMRangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSIMRangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSIMRangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSIMRangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSIMRangesPaginated'))
			})
	},

	/**
	 * RPC Method: MigrateSIMsToSPID
	 * Description: This endpoint is for migrating a range of SIMs to another Service Provider. Up to 1000 SIMs can be migrated per API call.
	 * HTTP Method: POST
	 * API Path: /api/v1/SIM_Migrate
	 * @param    {MigrateSIMsRequest} req The API payload object (MigrateSIMsRequest)
	 * @return   {MigrateSIMsResponse} The API response object (MigrateSIMsResponse)
	 *
	 * @typedef  {Object}  MigrateSIMsRequest
	 * @property {String}  SIMIDStartRange The start of the SIMID range to move
	 * @property {String}  SIMIDEndRange   The end of the SIMID range to move
	 * @property {Number}  SPID            The SPID to move the SIMs onto
	 *
	 * @typedef  {Object}  MigrateSIMsResponse
	 * @property {Number}  SuccessfullyMoved
	 * @property {Number}  FailedToMove
	 *
	 */
	MigrateSIMsToSPID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v1/SIM_Migrate`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('MigrateSIMsToSPID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('MigrateSIMsToSPID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('MigrateSIMsToSPID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('MigrateSIMsToSPID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in MigrateSIMsToSPID'))
			})
	},

	/**
	 * RPC Method: UpdateSIM
	 * Description: This endpoint is for making changes to a SIM record. The only fields that can be updated are: ???
	 * HTTP Method: PUT
	 * API Path: /api/v1/${req.SPID}/SIM/${req.UUID}
	 * @param    {SIM} req The API payload object (SIM)
	 * @return   {SIM} The API response object (SIM)
	 *
	 * @typedef  {Object}  SIM
	 * @property {String}  UUID                 Universally Unique Identifier of the SIM record
	 * @property {Number}  SPID                 AllocateESIM uses the given SPID unique allocation ID to allocate an ESIM, ,  and until the ESIM has been installed, duplicate calls to DownloadESIM will,  normally return the same ESIM, this function is idempotent and safe to call ,  concurrently. The returned ESIM has the required information to be installed,  on the end-user's mobile device.
	 * @property {Boolean} Enabled              Whether the SIM is enabled or not
	 * @property {String}  ServiceInstanceUUID  Reference to the SIM's ServiceInstance
	 * @property {Number}  CreatedAtNanos       When the SIM entry was created
	 * @property {Number}  UpdatedAtNanos       When the SIM entry was updated
	 * @property {Number}  DeletedAtNanos       When the SIM entry was deleted
	 * @property {String}  SIMID                This is the SIMID
	 * @property {String}  IMSI                 The International Mobile Subscriber Identity programmed on the SIM
	 * @property {StringSlice} Tags                 Tags associated with the SIM
	 * @property {Boolean} Active               Whether this SIM is in service or not
	 * @property {String}  ICCID                Integrated Circuit Card Identification Number
	 * @property {String}  PreAllocatedMSISDN   This is the (optional) pre-allocated MSISDN
	 * @property {Boolean} Reserved             Whether this SIM is reserved or not (for example for pre-activation)
	 * @property {Boolean} IsEsim               Whether this SIM is an esim or a physical sim
	 * @property {String}  SIMRangeUUID         SIMRange association UUID
	 * @property {String}  ExternalAllocationID ExternalAllocationID is a SPID-unique ID, chosen by the SPID that associates this SIM to,  an another entity during allocation. Within MVNE, this will usually be a customer ID.
	 *
	 */
	UpdateSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v1/${req.SPID}/SIM/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveSIM', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateSIM'))
			})
	},

	/**
	 * RPC Method: UploadSIMRange
	 * Description: This endpoint allows for importing a new SIM Range from an encrypted, base64 encoded, pgp file. Upon adding the range, it adds each SIM, setting it to be enabled. Therefore it does the equivalent AddSIMRange and AddSIM calls after decrypting the payload.
	 * HTTP Method: POST
	 * API Path: /api/v3/sim_range/upload
	 * @param    {UploadSIMRangeRequest} req The API payload object (UploadSIMRangeRequest)
	 * @return   {UploadSIMRangeResponse} The API response object (UploadSIMRangeResponse)
	 *
	 * @typedef  {Object}  UploadSIMRangeRequest
	 * @property {Number}  SPID Default Service Provider ID
	 * @property {Object}  Data Base64 encoded string file content
	 *
	 * @typedef  {Object}  UploadSIMRangeResponse
	 * @property {UploadSIMRangeResponse_SimResultsEntry[]} SimResults import result of each included sim
	 *
	 */
	UploadSIMRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/sim_range/upload`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UploadSIMRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UploadSIMRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UploadSIMRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UploadSIMRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UploadSIMRange'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};