// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: tktnzlmnpapi.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	ServiceProviderApprovedNumberPortChanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderApprovedPortChanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderNumberPorts: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	ServiceProviderPorts: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getServiceProviderApprovedNumberPortChangeByUUID: state => (UUID) => (state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].expired()) ?  state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderApprovedPortChangeByUUID: state => (UUID) => (state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].expired()) ?  state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderNumberPortByUUID: state => (UUID) => (state.ServiceProviderNumberPorts.UUIDs[ UUID ] && !state.ServiceProviderNumberPorts.UUIDs[ UUID ].expired()) ?  state.ServiceProviderNumberPorts.UUIDs[ UUID ].hashedData() : null,
	getServiceProviderPortByUUID: state => (UUID) => (state.ServiceProviderPorts.UUIDs[ UUID ] && !state.ServiceProviderPorts.UUIDs[ UUID ].expired()) ?  state.ServiceProviderPorts.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveServiceProviderApprovedNumberPortChange saves a single ServiceProviderApprovedNumberPortChange object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderApprovedNumberPortChange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderApprovedNumberPortChanges.UUIDs)) {
			state.ServiceProviderApprovedNumberPortChanges.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderApprovedNumberPortChanges.lifetime)
		}
		state.ServiceProviderApprovedNumberPortChanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderApprovedNumberPortChangeByUUID removes a ServiceProviderApprovedNumberPortChange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderApprovedNumberPortChangeByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderApprovedNumberPortChanges saves a paginated response of ServiceProviderApprovedNumberPortChanges
	mutSaveServiceProviderApprovedNumberPortChanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderApprovedNumberPortChanges)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ServiceProviderApprovedNumberPortChanges.length; i++) {
			if (!(Response.ServiceProviderApprovedNumberPortChanges[i].UUID in state.ServiceProviderApprovedNumberPortChanges.UUIDs)) {
				state.ServiceProviderApprovedNumberPortChanges.UUIDs[ Response.ServiceProviderApprovedNumberPortChanges[i].UUID ] = new StoreHash(state.ServiceProviderApprovedNumberPortChanges.lifetime)
			}
			state.ServiceProviderApprovedNumberPortChanges.UUIDs[ Response.ServiceProviderApprovedNumberPortChanges[i].UUID ].fix(Response.ServiceProviderApprovedNumberPortChanges[i])  // Add single ServiceProviderApprovedNumberPortChange to StoreHash
		}
	},
	// mutSaveServiceProviderApprovedPortChange saves a single ServiceProviderApprovedPortChange object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderApprovedPortChange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderApprovedPortChanges.UUIDs)) {
			state.ServiceProviderApprovedPortChanges.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderApprovedPortChanges.lifetime)
		}
		state.ServiceProviderApprovedPortChanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderApprovedPortChangeByUUID removes a ServiceProviderApprovedPortChange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderApprovedPortChangeByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderApprovedPortChanges saves a paginated response of ServiceProviderApprovedPortChanges
	mutSaveServiceProviderApprovedPortChanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderApprovedPortChanges)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ServiceProviderApprovedPortChanges.length; i++) {
			if (!(Response.ServiceProviderApprovedPortChanges[i].UUID in state.ServiceProviderApprovedPortChanges.UUIDs)) {
				state.ServiceProviderApprovedPortChanges.UUIDs[ Response.ServiceProviderApprovedPortChanges[i].UUID ] = new StoreHash(state.ServiceProviderApprovedPortChanges.lifetime)
			}
			state.ServiceProviderApprovedPortChanges.UUIDs[ Response.ServiceProviderApprovedPortChanges[i].UUID ].fix(Response.ServiceProviderApprovedPortChanges[i])  // Add single ServiceProviderApprovedPortChange to StoreHash
		}
	},
	// mutSaveServiceProviderNumberPort saves a single ServiceProviderNumberPort object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderNumberPort(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderNumberPorts.UUIDs)) {
			state.ServiceProviderNumberPorts.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderNumberPorts.lifetime)
		}
		state.ServiceProviderNumberPorts.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderNumberPortByUUID removes a ServiceProviderNumberPort object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderNumberPortByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ServiceProviderNumberPorts.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderNumberPorts saves a paginated response of ServiceProviderNumberPorts
	mutSaveServiceProviderNumberPorts(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderNumberPorts)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ServiceProviderNumberPorts.length; i++) {
			if (!(Response.ServiceProviderNumberPorts[i].UUID in state.ServiceProviderNumberPorts.UUIDs)) {
				state.ServiceProviderNumberPorts.UUIDs[ Response.ServiceProviderNumberPorts[i].UUID ] = new StoreHash(state.ServiceProviderNumberPorts.lifetime)
			}
			state.ServiceProviderNumberPorts.UUIDs[ Response.ServiceProviderNumberPorts[i].UUID ].fix(Response.ServiceProviderNumberPorts[i])  // Add single ServiceProviderNumberPort to StoreHash
		}
	},
	// mutSaveServiceProviderPort saves a single ServiceProviderPort object
	// into both the StoreHash cache and the VueX store
	mutSaveServiceProviderPort(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.ServiceProviderPorts.UUIDs)) {
			state.ServiceProviderPorts.UUIDs[ obj.UUID ] = new StoreHash(state.ServiceProviderPorts.lifetime)
		}
		state.ServiceProviderPorts.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveServiceProviderPortByUUID removes a ServiceProviderPort object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveServiceProviderPortByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.ServiceProviderPorts.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveServiceProviderPorts saves a paginated response of ServiceProviderPorts
	mutSaveServiceProviderPorts(state, { Response }) {
		if (!Response || !Array.isArray(Response.ServiceProviderPorts)) { return } // Must have Response object with results
		for (let i = 0; i < Response.ServiceProviderPorts.length; i++) {
			if (!(Response.ServiceProviderPorts[i].UUID in state.ServiceProviderPorts.UUIDs)) {
				state.ServiceProviderPorts.UUIDs[ Response.ServiceProviderPorts[i].UUID ] = new StoreHash(state.ServiceProviderPorts.lifetime)
			}
			state.ServiceProviderPorts.UUIDs[ Response.ServiceProviderPorts[i].UUID ].fix(Response.ServiceProviderPorts[i])  // Add single ServiceProviderPort to StoreHash
		}
	},
};

const actions = {
	// getCachedServiceProviderApprovedNumberPortChangeByUUID fetches a ServiceProviderApprovedNumberPortChange from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderApprovedNumberPortChangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].expired()) {
			return state.ServiceProviderApprovedNumberPortChanges.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetServiceProviderApprovedNumberPortChangeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderApprovedNumberPortChange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderApprovedNumberPortChangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderApprovedPortChangeByUUID fetches a ServiceProviderApprovedPortChange from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderApprovedPortChangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ] && !state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].expired()) {
			return state.ServiceProviderApprovedPortChanges.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetServiceProviderApprovedPortChangeByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderApprovedPortChange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderApprovedPortChangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderNumberPortByUUID fetches a ServiceProviderNumberPort from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderNumberPortByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderNumberPorts.UUIDs[ UUID ] && !state.ServiceProviderNumberPorts.UUIDs[ UUID ].expired()) {
			return state.ServiceProviderNumberPorts.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetServiceProviderNumberPortByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderNumberPort', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderNumberPortByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedServiceProviderPortByUUID fetches a ServiceProviderPort from the cache
	// and if not in the cache, fetches it from the API
	getCachedServiceProviderPortByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.ServiceProviderPorts.UUIDs[ UUID ] && !state.ServiceProviderPorts.UUIDs[ UUID ].expired()) {
			return state.ServiceProviderPorts.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetServiceProviderPortByUUID', { UUID }).then(respPayload => {
			commit('mutSaveServiceProviderPort', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveServiceProviderPortByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: LMNPRPC
	//
	/**
	* RPC Method: GetAllServiceProviderPortsPaginated
	* Description: Searches for multiple Port objects using a search request. Unless deleted ports are included, only ports in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_ports_all
	* @param    {ServiceProviderPortsAllSearchRequest} req The API payload object (ServiceProviderPortsAllSearchRequest)
	* @return   {ServiceProviderPortsAllPageResponse} The API response object (ServiceProviderPortsAllPageResponse)
	*
	* @typedef  {Object}  ServiceProviderPortsAllSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {String}  Description    (optional) Description
	* @property {Number}  SOM
	* @property {Number}  SPID
	*
	* @typedef  {Object}  ServiceProviderPortsAllPageResponse
	* @property {PageInfo} PageInfo             PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderPort[]} ServiceProviderPorts
	*
	*/
	GetAllServiceProviderPortsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.get(`/v3/ipms/service_provider_ports_all`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetAllServiceProviderPortsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetAllServiceProviderPortsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetAllServiceProviderPortsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetAllServiceProviderPortsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetAllServiceProviderPortsPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderApprovedNumberPortChangesPaginated
	* Description: Searches for multiple ServiceProviderApprovedNumberPortChange objects using a search request. Unless deleted ServiceProviderApprovedPortChange are included, only ServiceProviderApprovedPortChange in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_approved_number_port_change
	* @param    {ServiceProviderApprovedNumberPortChangeSearchRequest} req The API payload object (ServiceProviderApprovedNumberPortChangeSearchRequest)
	* @return   {ServiceProviderApprovedNumberPortChangePageResponse} The API response object (ServiceProviderApprovedNumberPortChangePageResponse)
	*
	* @typedef  {Object}  ServiceProviderApprovedNumberPortChangeSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderApprovedNumberPortChangePageResponse
	* @property {PageInfo} PageInfo                                 PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderApprovedNumberPortChange[]} ServiceProviderApprovedNumberPortChanges
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of products on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total products available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderApprovedNumberPortChangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/service_provider_approved_number_port_change`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveServiceProviderApprovedNumberPortChanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderApprovedNumberPortChangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderApprovedNumberPortChangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderApprovedNumberPortChangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderApprovedNumberPortChangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderApprovedNumberPortChangesPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderApprovedPortChangesPaginated
	* Description: Searches for multiple ServiceProviderApprovedPortChange objects using a search request. Unless deleted ServiceProviderApprovedPortChanges are included, only ServiceProviderApprovedPortChanges in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_approved_port_change
	* @param    {ServiceProviderApprovedPortChangeSearchRequest} req The API payload object (ServiceProviderApprovedPortChangeSearchRequest)
	* @return   {ServiceProviderApprovedPortChangePageResponse} The API response object (ServiceProviderApprovedPortChangePageResponse)
	*
	* @typedef  {Object}  ServiceProviderApprovedPortChangeSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderApprovedPortChangePageResponse
	* @property {PageInfo} PageInfo                           PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderApprovedPortChange[]} ServiceProviderApprovedPortChanges
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of products on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total products available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderApprovedPortChangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/ipms/service_provider_approved_port_change`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveServiceProviderApprovedPortChanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderApprovedPortChangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderApprovedPortChangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderApprovedPortChangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderApprovedPortChangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderApprovedPortChangesPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderNumberPortsPaginated
	* Description: Searches for multiple PortNumber objects using a search request. Unless deleted PortNumbers are included, only PortNumbers in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/service_provider_number_port
	* @param    {ServiceProviderNumberPortSearchRequest} req The API payload object (ServiceProviderNumberPortSearchRequest)
	* @return   {ServiceProviderNumberPortPageResponse} The API response object (ServiceProviderNumberPortPageResponse)
	*
	* @typedef  {Object}  ServiceProviderNumberPortSearchRequest
	* @property {SearchOptions[]} SearchOptions                  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted                 IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID                           (optional) SPID
	* @property {String}  Description                    (optional) Description
	* @property {String}  ServiceProviderPortRequestUUID ServiceProviderPortRequestUUID of the associated ServiceProviderPort
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderNumberPortPageResponse
	* @property {PageInfo} PageInfo                   PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderNumberPort[]} ServiceProviderNumberPorts
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of products on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total products available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderNumberPortsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/service_provider_number_port`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveServiceProviderNumberPorts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderNumberPortsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderNumberPortsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderNumberPortsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderNumberPortsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderNumberPortsPaginated'))
			})
	},

	/**
	* RPC Method: GetServiceProviderPortByUUID
	* Description: Get a single Service Provider Port object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/ipms/service_provider_port/${req.UUID}
	* @param    {ServiceProviderPortUUIDSearch} req The API payload object (ServiceProviderPortUUIDSearch)
	* @return   {ServiceProviderPort} The API response object (ServiceProviderPort)
	*
	* @typedef  {Object}  ServiceProviderPortUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  ServiceProviderPort
	* @property {String}  UUID
	* @property {Number}  SPID                           IQHive Service Provider ID for this port
	* @property {Number}  CreatedAtNanos                 When the object was first created
	* @property {Number}  UpdatedAtNanos                 When the object was last updated
	* @property {Number}  DeletedAtNanos                 When the object was deleted
	* @property {String}  AccountNumber                  Customers Postpay account number requesting this port
	* @property {String}  AdditionalCustomerInfo
	* @property {String}  Category
	* @property {String}  CustomerContactName
	* @property {String}  CustomerContactNumber
	* @property {String}  CustomerExistingServiceAddress
	* @property {String}  CustomerName
	* @property {String}  GainingServiceProviderContact
	* @property {String}  GspInternalReference
	* @property {Number}  LosingServiceProviderID
	* @property {Number}  GainingCarrierID
	* @property {Number}  LosingCarrierID
	* @property {Boolean} IsLspOverride
	* @property {Boolean} PrePayPrePaid
	* @property {Number}  RfsDateTimeStartNanos
	* @property {Number}  SOM
	* @property {String}  ProductType
	* @property {String}  Status
	* @property {String}  ApprovedPortChangeState
	* @property {Boolean} IsPortIn
	* @property {Number}  Version
	* @property {String}  Comments
	* @property {Number}  IPMSSPID                       IPMS Service Provider ID for this port
	* @property {String}  CustomerUUID
	* @property {Number}  IPMSGainingServiceProviderID   IPMS GSPID
	* @property {String}  ReasonForRejection             Reason LSP rejected port
	*
	*/
	GetServiceProviderPortByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/ipms/service_provider_port/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveServiceProviderPort', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderPortByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderPortByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderPortByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderPortByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderPortByUUID'))
			})
	},

	/**
	* RPC Method: GetServiceProviderPortsPaginated
	* Description: Searches for multiple Port objects using a search request. Unless deleted ports are included, only ports in progress will be displayed.
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ipms/service_provider_port
	* @param    {ServiceProviderPortSearchRequest} req The API payload object (ServiceProviderPortSearchRequest)
	* @return   {ServiceProviderPortPageResponse} The API response object (ServiceProviderPortPageResponse)
	*
	* @typedef  {Object}  ServiceProviderPortSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	* @property {Number}  SOM
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  ServiceProviderPortPageResponse
	* @property {PageInfo} PageInfo             PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {ServiceProviderPort[]} ServiceProviderPorts
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of products on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total products available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetServiceProviderPortsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/ipms/service_provider_port`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveServiceProviderPorts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceProviderPortsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceProviderPortsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceProviderPortsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceProviderPortsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceProviderPortsPaginated'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
