import axios from "axios"

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST

const state = {}

const getters = {}

const mutations = {}

const actions = {
    PreactivateSIM({ commit }, params){
        return axios.post(`${API_URL_RESELLER}/sim/preactivation`, params, { timeout: 3 * 60000 })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    ChangeSimEnabledStatus({}, { payload, enable = true }) {
        return axios.post(`${API_URL_RESELLER}/sim/${enable ? 'enable' : 'disable'}`, payload)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    ResetSIM({}, params) {
        const simid = params?.SIMID
        const msisdn = params?.MSISDN
        return axios.post(`${API_URL_RESELLER}/sim/reset/${msisdn}/${simid}`, params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    DeprovisionSIM({}, params) {
        return axios.post(`${API_URL_RESELLER}/sim/deprovision`, params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    SIMSwap({}, params) {
        return axios.post(`${API_URL_RESELLER}/sim/swap`, params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    GetDailyRoamingAutoActive({}, params) {
        return axios.get(`${API_URL_RESELLER}/sim/daily-roaming/auto-active/${params.CustomerUUID}/${params.MobileUUID}`, params)
            .then(({data}) => Promise.resolve(data))
            .catch(error => Promise.reject(error))
    },
    ChangeDailyRoamingAutoActive ({}, params) {
        return axios.post(`${API_URL_RESELLER}/sim/daily-roaming/auto-active`, params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    ChangeProductType({}, payload) {
        const params = payload?.params
        return axios.put(`${API_URL_RESELLER}/sim/v1/product-type/${payload.Dry}`, params)
            .then(({apidata})=> Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}