const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT || '';

const state = {
    environment: ENVIRONMENT
}

const getters = {
    environment: state => state.environment,
    isTestEnv: state => state.environment === 'test',
    isProdEnv: state => state.environment === 'production',
    isPreProdEnv: state => state.environment === 'preproduction',
    isDevelopEnv: state => state.environment === 'development' || state.environment === '',
    exceptProdEnv: (state, getters) => getters.isTestEnv || getters.isPreProdEnv || getters.isDevelopEnv,
}

export default {
    state,
    getters
}