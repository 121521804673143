// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: loyalty.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Vouchers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	VoucherSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getVoucherByUUID: state => (UUID) => (state.Vouchers.UUIDs[ UUID ] && !state.Vouchers.UUIDs[ UUID ].expired()) ?  state.Vouchers.UUIDs[ UUID ].hashedData() : null,
	getVouchersForSearchText: state => (SearchText) => state.VoucherSearches[ SearchText ] ? state.VoucherSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveVoucher saves a single Voucher object
	// into both the StoreHash cache and the VueX store
	mutSaveVoucher(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Vouchers.UUIDs)) {
			state.Vouchers.UUIDs[ obj.UUID ] = new StoreHash(state.Vouchers.lifetime)
		}
		state.Vouchers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveVoucherByUUID removes a Voucher object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveVoucherByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Vouchers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveVoucherSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveVoucherSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Vouchers)) {
			for (let i = 0; i < Response.Vouchers.length; i++) {
				storedObj.UUIDs.push(Response.Vouchers[i].UUID)
			}
		}
		state.VoucherSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.VoucherSearches.SearchTexts)) {
			state.VoucherSearches.SearchTexts[ SearchText ] = new StoreHash(state.VoucherSearches.lifetime)
		}
		state.VoucherSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveVoucherSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveVoucherSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.VoucherSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.VoucherSearches[ SearchText ]               // remove item from VueX state
		delete state.VoucherSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveVouchers saves a paginated response of Vouchers
	mutSaveVouchers(state, { Response }) {
		if (!Response || !Array.isArray(Response.Vouchers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Vouchers.length; i++) {
			if (!(Response.Vouchers[i].UUID in state.Vouchers.UUIDs)) {
				state.Vouchers.UUIDs[ Response.Vouchers[i].UUID ] = new StoreHash(state.Vouchers.lifetime)
			}
			state.Vouchers.UUIDs[ Response.Vouchers[i].UUID ].fix(Response.Vouchers[i])  // Add single Voucher to StoreHash
		}
	},
};

const actions = {
	// getCachedVoucherByUUID fetches a Voucher from the cache
	// and if not in the cache, fetches it from the API

	/**
	 * @todo Add UUID param to the action getCachedVoucherByUUID and to the dispatch GetVoucherByUUID
	 */
	getCachedVoucherByUUID({ state, dispatch, commit }, { UUID, SPID, RefreshCache }) {
		if (!RefreshCache && state.Vouchers.UUIDs[ UUID ] && !state.Vouchers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Vouchers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetVoucherByUUID', { UUID, SPID }).then(respPayload => {
			commit('mutSaveVoucher', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveVoucherByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedVouchersBySearch fetches Vouchers from the cache
	// and if not in the cache, fetches it from the API
	getCachedVouchersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.VoucherSearches.SearchTexts[ SearchText ] && !state.VoucherSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getVouchersForSearchText(SearchText)
			resp.Vouchers = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Vouchers.push(getters.getVoucherByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetVouchersPaginated', apiReq).then(respPayload => {
			commit('mutSaveVoucherSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveVoucherSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: LoyaltyRPC
	//
	/**
	* RPC Method: AddVoucher
	* Description: Add a Voucher object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/voucher
	* @param    {Voucher} req The API payload object (Voucher)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	AddVoucher({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/${req.SPID}/voucher`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddVoucher ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddVoucher ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddVoucher ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddVoucher UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddVoucher'))
			})
	},

	/**
	* RPC Method: CheckVoucherByVoucherCode
	* Description: Check a Voucher using the Voucher Code
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/voucher_by_code/${req.VoucherCode}/check
	* @param    {VoucherValidateRequest} req The API payload object (VoucherValidateRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherValidateRequest
	* @property {String}  UUID              (optional)
	* @property {Number}  SPID              Service Provider ID - always required
	* @property {ENUM}    VoucherProvider   Voucher Provider (optional if UUID supplied)
	* @property {String}  VoucherCode       Voucher Code (optional if UUID supplied)
	* @property {String}  RedemptionDetails RedemptionDetails stores the redemption details
	* @property {String}  CustomerUUID      (optional) CustomerUUID
	* @property {String}  PurchaseReference PurchaseReference stores the redemption details
	* @property {String}  ProductReference  ProductReference (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {String}  ProductUUID       ProductUUID (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {Boolean} AlreadyRedeemed
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	CheckVoucherByVoucherCode({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramVoucherCode = encodeURIComponent(req.VoucherCode)
		return axios.get(`/v3/${paramSPID}/voucher_by_code/${paramVoucherCode}/check`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CheckVoucherByVoucherCode ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CheckVoucherByVoucherCode ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CheckVoucherByVoucherCode ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CheckVoucherByVoucherCode UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CheckVoucherByVoucherCode'))
			})
	},

	/**
	* RPC Method: DeleteVoucherByUUID
	* Description: Deletes a Voucher object
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/voucher/${req.UUID}
	* @param    {VoucherUUIDSearch} req The API payload object (VoucherUUIDSearch)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID         Service Provider ID - always required
	* @property {String}  CustomerUUID
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	DeleteVoucherByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/voucher/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveVoucher', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteVoucherByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteVoucherByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteVoucherByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteVoucherByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteVoucherByUUID'))
			})
	},

	/**
	* RPC Method: GetVoucherByUUID
	* Description: Get a single Voucher object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/voucher/${req.UUID}
	* @param    {VoucherUUIDSearch} req The API payload object (VoucherUUIDSearch)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID         Service Provider ID - always required
	* @property {String}  CustomerUUID
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	GetVoucherByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/voucher/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveVoucher', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVoucherByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVoucherByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVoucherByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVoucherByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVoucherByUUID'))
			})
	},

	/**
	* RPC Method: GetVouchersPaginated
	* Description: Searches for multiple Voucher objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/voucher
	* @param    {VoucherSearchRequest} req The API payload object (VoucherSearchRequest)
	* @return   {VoucherPageResponse} The API response object (VoucherPageResponse)
	*
	* @typedef  {Object}  VoucherSearchRequest
	* @property {SearchOptions[]} SearchOptions       Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted      IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID                (optional) SPID
	* @property {String}  VoucherCode         Search by VoucherCode
	* @property {String}  RedeemDetails       Details for tracking redemption
	* @property {String}  CustomerUUID        (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider
	* @property {ENUM}    VoucherType
	* @property {String}  RedeemedBy
	* @property {ENUM}    RequestedByProvider
	* @property {String}  HoldUUID
	* @property {String}  SmartSearch
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  VoucherPageResponse
	* @property {PageInfo} PageInfo PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Voucher[]} Vouchers
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of products on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total products available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetVouchersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/voucher`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveVouchers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVouchersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVouchersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVouchersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVouchersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVouchersPaginated'))
			})
	},

	/**
	* RPC Method: HoldVoucherByVoucherCode
	* Description: Check and hold a Voucher using the Voucher Code
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/voucher_by_code/${req.VoucherCode}/hold
	* @param    {VoucherValidateRequest} req The API payload object (VoucherValidateRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherValidateRequest
	* @property {String}  UUID              (optional)
	* @property {Number}  SPID              Service Provider ID - always required
	* @property {ENUM}    VoucherProvider   Voucher Provider (optional if UUID supplied)
	* @property {String}  VoucherCode       Voucher Code (optional if UUID supplied)
	* @property {String}  RedemptionDetails RedemptionDetails stores the redemption details
	* @property {String}  CustomerUUID      (optional) CustomerUUID
	* @property {String}  PurchaseReference PurchaseReference stores the redemption details
	* @property {String}  ProductReference  ProductReference (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {String}  ProductUUID       ProductUUID (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {Boolean} AlreadyRedeemed
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	HoldVoucherByVoucherCode({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramVoucherCode = encodeURIComponent(req.VoucherCode)
		return axios.get(`/v3/${paramSPID}/voucher_by_code/${paramVoucherCode}/hold`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('HoldVoucherByVoucherCode ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('HoldVoucherByVoucherCode ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('HoldVoucherByVoucherCode ERROR:', error)
					return Promise.reject(error)
				}
				console.log('HoldVoucherByVoucherCode UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in HoldVoucherByVoucherCode'))
			})
	},

	/**
	* RPC Method: PurgeVoucherByUUID
	* Description: Purges a Voucher object
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/voucher/${req.UUID}/purge
	* @param    {VoucherUUIDSearch} req The API payload object (VoucherUUIDSearch)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID         Service Provider ID - always required
	* @property {String}  CustomerUUID
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	PurgeVoucherByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/voucher/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveVoucher', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeVoucherByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeVoucherByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeVoucherByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeVoucherByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeVoucherByUUID'))
			})
	},

	/**
	* RPC Method: RedeemVoucherByVoucherCode
	* Description: Redeem a Voucher using the Voucher Code
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/voucher_by_code/${req.VoucherCode}/redeem
	* @param    {VoucherValidateRequest} req The API payload object (VoucherValidateRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherValidateRequest
	* @property {String}  UUID              (optional)
	* @property {Number}  SPID              Service Provider ID - always required
	* @property {ENUM}    VoucherProvider   Voucher Provider (optional if UUID supplied)
	* @property {String}  VoucherCode       Voucher Code (optional if UUID supplied)
	* @property {String}  RedemptionDetails RedemptionDetails stores the redemption details
	* @property {String}  CustomerUUID      (optional) CustomerUUID
	* @property {String}  PurchaseReference PurchaseReference stores the redemption details
	* @property {String}  ProductReference  ProductReference (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {String}  ProductUUID       ProductUUID (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {Boolean} AlreadyRedeemed
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	RedeemVoucherByVoucherCode({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramVoucherCode = encodeURIComponent(req.VoucherCode)
		return axios.get(`/v3/${paramSPID}/voucher_by_code/${paramVoucherCode}/redeem`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RedeemVoucherByVoucherCode ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RedeemVoucherByVoucherCode ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RedeemVoucherByVoucherCode ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RedeemVoucherByVoucherCode UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RedeemVoucherByVoucherCode'))
			})
	},

	/**
	* RPC Method: ReleaseVoucherByVoucherCode
	* Description: Release a held Voucher using the Voucher Code
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/voucher_by_code/${req.VoucherCode}/release
	* @param    {VoucherValidateRequest} req The API payload object (VoucherValidateRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherValidateRequest
	* @property {String}  UUID              (optional)
	* @property {Number}  SPID              Service Provider ID - always required
	* @property {ENUM}    VoucherProvider   Voucher Provider (optional if UUID supplied)
	* @property {String}  VoucherCode       Voucher Code (optional if UUID supplied)
	* @property {String}  RedemptionDetails RedemptionDetails stores the redemption details
	* @property {String}  CustomerUUID      (optional) CustomerUUID
	* @property {String}  PurchaseReference PurchaseReference stores the redemption details
	* @property {String}  ProductReference  ProductReference (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {String}  ProductUUID       ProductUUID (optional, for redemption either ProductUUID or ProductReference is required)
	* @property {Boolean} AlreadyRedeemed
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	ReleaseVoucherByVoucherCode({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramVoucherCode = encodeURIComponent(req.VoucherCode)
		return axios.get(`/v3/${paramSPID}/voucher_by_code/${paramVoucherCode}/release`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ReleaseVoucherByVoucherCode ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ReleaseVoucherByVoucherCode ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ReleaseVoucherByVoucherCode ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ReleaseVoucherByVoucherCode UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ReleaseVoucherByVoucherCode'))
			})
	},

	/**
	* RPC Method: UpdateVoucher
	* Description: Update a single Voucher object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/voucher/${req.UUID}
	* @param    {Voucher} req The API payload object (Voucher)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	UpdateVoucher({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/${req.SPID}/voucher/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveVoucher', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoucher ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoucher ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoucher ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoucher UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoucher'))
			})
	},

	/**
	* RPC Method: VoidVoucherByVoucherCode
	* Description: Voids a Voucher by VoucherCode
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/void_voucher_by_code
	* @param    {VoucherVoidRequest} req The API payload object (VoucherVoidRequest)
	* @return   {Voucher} The API response object (Voucher)
	*
	* @typedef  {Object}  VoucherVoidRequest
	* @property {String}  UUID              (optional)
	* @property {Number}  SPID              Service Provider ID - always required
	* @property {ENUM}    VoucherProvider   Voucher Provider (optional if UUID supplied)
	* @property {String}  VoucherCode       Voucher Code (optional if UUID supplied)
	* @property {String}  CustomerUUID      (optional) CustomerUUID
	* @property {String}  PurchaseReference PurchaseReference stores the redemption details
	* @property {String}  ProviderKey
	*
	* @typedef  {Object}  Voucher
	* @property {String}  UUID                   Voucher Type determines the type of voucher
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {String}  CreatedBy              Who the object was created by
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {String}  UpdatedBy              Who the object was created by
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {String}  DeletedBy              Who the object was created by
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {ENUM}    VoucherProvider        Voucher Provider
	* @property {String}  VoucherCode            Voucher Code (max 100 chars, immutable after created)
	* @property {ENUM}    VoucherType            Voucher Type
	* @property {String}  Description            Voucher Description (Presented to Customer)
	* @property {Number}  RedeemedAtNanos        When the object was redeemed
	* @property {String}  RedeemedBy             Who the object was Redeemed by
	* @property {String}  RedemptionDetails      Details to store with the redemption
	* @property {Number}  ArchivedAtNanos        When the object was archived (typically 60-90 days after redemption)
	* @property {Number}  CustomerPriceCents     The price (in cents) the end-user customer pays for this entitlement
	* @property {Number}  CreditBalanceCents     [Type=PREPAY] The credit (in cents) applied to the customer's monetary balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceDataMB    [Type=PREPAY] The number of MB added to the customer's data balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceVoiceMins [Type=PREPAY] The number of minutes added to the customer's in-bundle voice balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceSMS       [Type=PREPAY] The number of SMSs added to the customer's in-bundle SMS balance when this entitlement is redeemed
	* @property {Number}  CreditBalanceMMS       [Type=PREPAY] The number of MMSs added to the customer's in-bundle MMS balance when this entitlement is redeemed
	* @property {ENUM}    RequestedByProvider    Voucher Provider Request
	* @property {String}  ProviderKey
	* @property {String}  HoldUUID
	* @property {Number}  HeldAtNanos
	* @property {StringSlice} IncludedProductUUIDs
	*
	*/
	VoidVoucherByVoucherCode({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/void_voucher_by_code`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('VoidVoucherByVoucherCode ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('VoidVoucherByVoucherCode ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('VoidVoucherByVoucherCode ERROR:', error)
					return Promise.reject(error)
				}
				console.log('VoidVoucherByVoucherCode UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in VoidVoucherByVoucherCode'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
